@import 'style/colors.scss';

.wallet-pending-withdraws {
    display: flex;
    flex-direction: column;
    gap: 6px;

    .wallet-pending-withdraws__heading {
        color: rgba(#FFF, 0.8);
    }

    .divider {
        margin: 20px 0px;
    }
}

.wallet-pending-withdraw__loading-skeleton {
    height: 200px;
    border-radius: 12px;
    padding: 0;
    margin-top: -20px;

    .MuiSkeleton-root {
        background-color: $pending-skeleton-loading-bgColor;
    }
}