@import 'style/colors.scss';
@import 'style/size.scss';
@import 'style/layout.scss';
@import 'style/font.scss';

.signUp-gift-view {
    color: $white;
    padding: 20px;
    height: auto;

    @media (max-width: $mobile) {
        padding: auto 16px;
    }

    @media (min-width: $min-extra-large-device) {
        width: 650px;
        margin: auto;
    }

    .welcome-img-container {
        width: 150px;
        height: 100px;
        margin: 20px auto 25px;
        margin-top: 5px;

        img {
            max-width: 100%;
            max-height: 100%;
        }
    }

    h3 {
        @include fontWeightSize(bold, 32px);
        color: $white;
        margin-bottom: 14px;
    }


    .primary-button {
        @include fontWeightSize(extra-bold, 15px);
        width: initial;
        padding: 6px 45px;
    }

    .profile-form {
        @media (min-width: $min-extra-large-device) {
            width: 70%;
            margin: auto;
        }

        .form_label {
            color: $white;
            @include fontWeightSize(medium, 14px);
        }

        .sublabel {
            color: $white;
            @include fontWeightSize(medium, 12px);
            opacity: 0.8;
        }

        .primary-button {
            margin-top: 25px;
        }


        select {
            border-right: 16px solid transparent;
        }

        div:has(> select),
        .react-tel-input {
            border: solid 1px $white;
            border-radius: 5px;

            select {
                cursor: pointer;
                background-color: transparent;
                border: 0;
                color: $white !important;
                border-right: solid 15px transparent;
            }


            input {
                color: $white;
                width: 100%;
                background-color: transparent;
                border: 0;
            }

            .flag-dropdown,
            .flag-dropdown:hover {
                background-color: transparent;
                border: 0;
            }

            .flag {
                border-radius: 2px;
            }

            .arrow {
                border-top-color: $white;
            }

            .selected-flag:hover,
            .country-list,
            .open {
                background-color: rgba(255, 255, 255, 0.15) !important;
            }

            select * {
                background-color: rgba(0, 0, 0, 0.65);
            }

            .country-list {
                backdrop-filter: blur(10px);
                display: flex;
                flex-direction: column;
                text-align: left;
            }

            .country:hover {
                background-color: rgba(255, 255, 255, 0.25);
            }

            .highlight {
                background-color: rgba(255, 255, 255, 0.25) !important;
            }

            .dial-code {
                color: $white !important;
            }

            .form-control.invalid-number:focus {
                background-color: #ff000036;
            }
        }
    }

    .button-submit {

        @media (max-width: $max-small-device) {
            position: absolute;
            bottom: 50px;
            left: 0px;
        }

    }
}