.cancel-button {
    cursor: pointer;

    &.align-right {
        text-align: end;
        margin-bottom: -12px;

        .cross-icon {
            margin-right: 12px;
        }
    }

    .cross-icon {
        margin-left: 12px;
        margin-top: 12px;
    }
}