.user-information-form {
    padding: 20px;

    .light_demo & svg {
        color: #1b1e21;
    }

    .button-submit {
        margin-top: 30px;
    }
}
