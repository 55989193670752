@import "style/size.scss";
@import "style/colors.scss";
@import "style/font.scss";

.top-bar {
    justify-content: space-between;
    width: 100%;
    padding: 10px;

    &.hide {
        display: none;
    }
}

.active-top-bar {
    @media (max-width: $min-extra-large-device) {
        background: $app-background;
    }
}