@import 'style/size.scss';
@import 'style/colors.scss';
@import 'style/font.scss';

.body-modal {
    .success-buy-asset-SharePage_customModal {
        padding: 12px 20px;
    }
}

.success-buy-asset-share-page {
    text-align: left;
    margin-bottom: 20px;

    .header-image-wrapper {
        margin-left: 20px;
        height: fit-content;
        width: fit-content;
        position: relative;

        .asset-avatar-container {
            width: 63px;
            height: 63px;

            img {
                width: 63px;
                height: 63px;
                border-radius: 12px;
                object-fit: cover;
            }
        }

        .transaction-icon {
            height: 25px;
            width: 25px;
            position: absolute;
            right: 90%;
            top: 50%;
            transform: translateY(-50%);
            background: rgba(83, 208, 129, 0.44);
            padding: 3px;
            border-radius: 50%;

            svg {
                fill: #53D081;
                height: 100%;
                width: 100%;
                border-radius: 50%;
            }

            &.red {
                background: rgba(230, 111, 129, 0.44);

                svg {
                    fill: #E66F81
                }
            }
        }

        .bottom-clock-gif {
            position: absolute;
            width: 35px;
            height: 35px;
            top: 40px;
            left: 40px;
            border-radius: 50%;
            background: #FEEDD7;
            box-shadow: 0px 5px 5px #F6EFEA;
        }

        .bottom-validate-gif {
            position: absolute;
            width: 30px;
            height: 30px;
            top: 40px;
            left: 40px;
            border-radius: 50%;
            background: #FEEDD7;
            box-shadow: 0px 5px 5px #F6EFEA;
        }

    }

    .success-buy-asset-share-page-header {
        @include plus-jakarta-bold-font;
        text-align: left;
        font-size: 22px;
        margin-top: 25px;
    }

    .success-buy-asset-share-page-content {
        @include plus-jakarta-medium-font;
        text-align: left;
        margin: 8px 0;
        color: #606373;
    }

    .amount-breakdown {
        border-radius: 8px;
        background-color: #FFEEC4;
        color: #D08713;
        display: inline-flex;
        align-items: center;
        padding: 10px 12px;

        svg {
            margin-right: 5px;

            path {
                fill: #D08713;
            }
        }

        span {
            @include fontWeightSize('medium', 14px)
        }
    }

    .success-buy-asset-share-page-yield {
        @include fontWeightSize('medium', 14px);
        display: flex;
        align-items: center;
        color: $yield-text-color;
        margin-top: 10px;

        img {
            height: 20px;
            margin-right: 8px;

            @media (max-width: $mobile) {
                top: 3px;
                height: 18px;
                position: relative;
            }
        }
    }

    .avatar-50 {
        width: 70px;
        height: 70px;

        @media (max-width: $mobile) {
            width: 40px;
            height: 40px;
        }
    }

    &-content {
        display: grid;
        grid-template-columns: 1fr;

        &>div {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            padding: 10px;
            color: $tdx-vert;
        }

        &-vip {
            border: 1px dashed $violet;
            border-radius: 20px;

            &-token-quantity {
                font-size: 22px;
                font-weight: 500;
                margin-top: 15px;
                margin-bottom: 10px;
            }

            &-name {
                text-transform: uppercase;
                margin: 0;
            }
        }

        &-for {
            color: #222222;
        }

        &-total-price {
            font-size: 30px;
            margin-bottom: 0;
            margin-top: 15px;
            color: $tdx-vert;

            @media (max-width: $mobile) {
                font-size: 30px;
            }
        }
    }

    .vip-token-quantity {
        font-size: 22px;
    }
}