@import "style/colors.scss";
@import "style/font.scss";

.token-yield-information-banner {
    margin: 1px 0px;
    padding: 20px;
    border-radius: 20px;
    display: flex;
    gap: 10px;
    color: $fade-violet;
    background: $yield-banner-bg;

    .token-yield-information-banner__details {
        display: flex;
        flex-direction: column;
        align-self: center;

        .details__title {
            @include fontWeightSize(bold, 13px);
            margin-bottom: 4px
        }

        .details__info {
            @include fontWeightSize(medium, 13px);
            margin-bottom: 0px;

            .details__learn-more {
                margin-left: 1px;
                @include fontWeightSize(bold, 13px);
                text-decoration: underline;

            }
        }
    }
}