@import 'src/style/colors.scss';
@import 'src/style/size.scss';
@import 'style/font.scss';

.tournament-header {
    * {
        position: relative;
    }
    margin-bottom: 20px;
    
    .trendex-tournament-logo {
        box-shadow: 0px 3px 20px 0px rgba(21,24,43,0.25);
        width: 100%;
        background-size: cover;
        background-position: 50% 70%;
        background-repeat: no-repeat;
        border-radius: 15px;
        position: relative;
        overflow: hidden;
        padding-bottom: 20px;
        
        @media (max-width: $min-small-device) {
            background-position: 50% 50%;
        }

        .tournament-prize {
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            margin-top: 20px;

            .title {
                font-size: 12px;
                margin-bottom: 7px;
            }

            .prize-pool {
                @include fontWeightSize(extra-bold, 25px);
                margin: 0px;
                height: 26px;
                color: transparent;
                -webkit-background-clip: text;
                text-shadow: 0px 3px 20px #ECBC58;
                opacity: 1;
                background-image: linear-gradient(98deg, #FFD667, #CB913E, #FFD667, #CB913E, #FFD667);
            }

            margin-bottom: 5px;

            @media (max-width: $min-small-device) {
                margin-top: 5px;
                margin-bottom: 0;
            }
        }

        .flexbox {
            display: flex;
        }

        .column {
            flex-direction: column;
        }

        .tournament-type {
            justify-content: center;
            margin: auto;
            padding-top: 10px;

            .center-bloc {
                display: flex;
                align-items: center;

                .category-icon {
                    height: 45px;
                    width: 45px;
                    margin-right: 5px;

                    @media (max-width: $min-small-device) {
                        height: 38px;
                        width: 38px;
                    }

                    svg {
                        fill: #FFF;
                        height: 45px;
                        width: 45px;

                        @media (max-width: $min-small-device) {
                            height: 38px;
                            width: 38px;
                        }
                    }
                }


                .title {
                    @include fontWeightSize(extra-bold, 25px);
                    white-space: pre-wrap;
                    text-transform: uppercase;
                    font-family: "Radwave Demo";
                    line-height: 22px;
                    padding: 0;

                    @media (max-width: $min-small-device) {
                        @include fontWeightSize(extra-bold, 18px);
                    }
                }
            }
        }

        .countdown-container {
            padding-top: 5px;
            text-transform: uppercase;
            text-align: center;

            .end-in {
                font-family: "Plus Jakarta Sans";
                margin-bottom: 5px;
            }

            .countdown p {
                font-size: 22px;
                font-family: "Radwave Demo";
                @media (max-width: $min-small-device) {
                    font-size: 18px;
                }
            }

            .countdown .small-countdown {
                font-size: 12px;
            }

        }
    }

    .container-headband,
    .container-top-vip {
        & .cashprizes-headband {
            margin: 10px auto 0 auto;
        }
    }

    .golden-gradient .cashprizes-headband {
        background-image: linear-gradient(114deg, #CEAF7C 0%, #C49344 100%);
    }
}

.tournament-list-header-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0.8rem 0.4rem;
    margin: 14px auto 14px auto;
    background: $white-list-bgColor;
    border-radius: 10px;

    &__title {
        display: flex;
        font-size: 16px;
        font-weight: 700;
        padding-left: 6px;
        border-radius: 4px;
        text-align: center;
        align-items: center;
        white-space: nowrap;
        background: -webkit-linear-gradient(266deg, #6677F5, #F94179);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;

        svg {
            width: 16px;
            fill: #EC4584;
            margin-right: 5px;
        }
    }

    &__remaining-time-container {
        display: grid;
        grid-template-columns: auto auto;
        grid-gap: 10px;
        margin-right: 10px;

        &>p {
            color: $fade-violet;
            line-height: inherit;
        }

        svg {
            .fa-secondary {
                opacity: 0.3;
                fill: $fade-violet ;
            }

            .fa-primary {
                fill: $fade-violet ;
            }
        }

        @media (max-width:$min-large-mobile) {
            .hide {
                display: none;
            }
        }
    }

    &__attendees {
        margin: 0px;
        display: flex;
        padding: 0px 10px;
        align-items: center;
        color: $fade-violet;

        svg {
            .fa-secondary {
                opacity: 0.4;
            }
        }

        span {
            margin-left: 8px;
        }

    }
}

.tournament-on-header {
    background: linear-gradient(100deg, rgba(249, 65, 121, 1) 0%, rgba(102, 119, 245, 1) 100%);
    border-radius: 20px;
    padding: 5px;

    .trendex-tournament-logo::before {
        content: "";
        display: block;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        background-image: linear-gradient(135deg, #0f1964f5, #f9417985);
        opacity: 0.6;
        background-blend-mode: overlay;
    }
}