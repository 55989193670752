@import "style/colors.scss";

.body-modal {
    .padding:has(.share-profile-container) {
        padding: 0px;

        .referral-container {
            background: $smoke-blue;
            padding: 24px 15px 15px 15px;
            border-radius: 11px;

            .referral-info-data {
                border: 1px solid $whitesh-blue;
                border-radius: 8px;
                padding: 9px;
                font-size: 11px;
                font-weight: 600;
                color: $fade-violet;
                margin-top: 16px;


                .refer-info {
                    display: flex;
                    justify-content: space-between;
                    padding: 5px 0px;

                    &:not(:last-child) {
                        border-bottom: 1px solid $blacky-blue;
                    }

                    .referInfo-label {
                        display: flex;
                        align-items: center;

                        svg {
                            margin-right: 10px;
                        }
                    }

                    svg {
                        width: 20px;
                        width: 20px;
                    }
                }
            }
        }
    }
}


.share-profile-container {
    .share-profile-top-section {
        padding: 20px 20px 0px;
        margin-bottom: 20px;

        .title {
            color: $tertiary-grey;
            font-size: 20px;
            font-weight: 600;
            margin-bottom: 18px;
        }

        .link-container {
            display: inline-flex;
            flex-wrap: wrap;

            .link-social-network {
                display: grid;

                &:not(:last-child) {
                    margin-right: 5px;
                }

                .link-button-wrapper {
                    background-color: $smoke-blue;
                    width: 75px;
                    height: 75px;
                    border-radius: 50%;
                    padding-top: 2.5px;
                    margin-bottom: 4px;

                    .share-button-size {
                        width: 75px;
                        height: 75px;

                        svg {
                            height: 40px;
                            width: 40px;
                        }
                    }
                }

                h5 {
                    color: $tertiary-grey;
                    font-weight: 400;
                    font-size: 11px;
                    margin: 0px;
                }
            }
        }
    }

    .share-profile-footer-section{
        margin: 0 20px;
    }
}