@import 'style/_variable.scss';
@import 'style/font.scss';
@import 'style/size.scss';

.wallet-portfolio {
    padding-top: 17px;
    border-top: 1px solid #FFFFFF59;

    .asset-grid-box .grid-wrapper {
        box-shadow: none;
    }

    .wallet-portfolio-header {
        position: relative;

        .wallet-portfolio-tabs {
            @include plus-jakarta-bold-font;
            display: flex;
            gap: 1em;
            font-size: 20px;

            .wallet-portfolio-tab {
                cursor: pointer;

                &.inactive {
                    opacity: 0.5;
                }
            }
        }

        .toggle-display-icon {
            position: absolute;
            right: 0;
            top: 6px;
            cursor: pointer;

            svg>path {
                fill: #FFFFFF;
            }
        }
    }

    .wallet-portfolio-list {
        margin: 10px 0px;
        display: flex;
        flex-direction: column;
        gap: 8px;

        &.onboarding-mode {
            margin-bottom: 200px;
        }
    }

    .portfolio-asset-actions {
        display: flex;
        justify-content: flex-start;
        column-gap: 3px;
        margin-top: 17px;

        button {
            @include plus-jakarta-bold-font;
            border: none;
            width: 80px;
            text-align: center;
            color: white;
            font-size: 12px;
            border-radius: 8px;
            padding: 7.5px 0px;
            border: 1px solid #FFFFFF33;
            cursor: pointer;

            &.portfolio-asset-actions_view {
                background: #4D5164;
            }

            &.portfolio-asset-actions_buy {
                background: #53D081;
            }

            &.portfolio-asset-actions_sell {
                background: #E66F81;

            }

            &:disabled {
                cursor: not-allowed;
                opacity: 0.5;
            }
        }

        &.stacked {
            flex-direction: column;
            position: absolute;
            top: 128px;
            row-gap: 3px;
            z-index: 3;
            width: 100%;

            button {
                width: 100%;
            }
        }
    }

    .team-widget {
        margin-top: 10px;
        padding: 12px;
        border: solid 2px #686B7C;
        border-radius: 12px;

        &_header {
            display: flex;
            align-items: center;
            justify-content: space-between;

            color: #FFFFFFCC;

            &_title {
                display: flex;
                align-items: center;
                gap: 9px;

                @include plus-jakarta-bold-font;
                font-size: 14px;
                line-height: 18px;
            }

            &_score {
                display: flex;
                align-items: center;
                gap: 9px;

                font-size: 13px;
                line-height: 16px;
            }
        }

        &_body {
            &_assets-list {
                display: flex;
                justify-content: center;
                align-items: center;
                margin-top: 20px;
                gap: 12px;

                .team-asset {
                    width: 56px;
                    height: 56px;
                    border-radius: 8px;
                    padding: 2px;
                    position: relative;

                    img {
                        border-radius: 8px;
                        object-fit: cover;
                        height: 100%;
                        width: 100%;
                        left: 0;
                        top: 0;
                        position: absolute;
                        padding: 2px;
                    }
                }
            }
        }

        &_tournament {

            .countdown-widget {
                .title {
                    display: flex;
                    justify-content: center;
                    margin-top: 20px;
                    margin-bottom: 10px;
                }

                .content {
                    display: flex;
                    justify-content: center;
                    gap: 14px;

                    .separator {
                        margin-top: 7px;
                        font-size: 24px;
                        line-height: 18px;
                    }

                    .hours,
                    .minutes,
                    .seconds,
                    .days {
                        display: flex;
                        flex-direction: column;
                        align-items: center;

                        .value {
                            display: flex;
                            gap: 3px;

                            span {
                                width: 28px;
                                height: 37px;
                                display: inline-flex;
                                justify-content: center;
                                align-items: center;
                                border-radius: 6px;
                                background-color: #2F3143;
                                font-size: 20px;
                                line-height: 18px;
                                font-family: 'Monument Extended';
                            }

                        }

                        .text {
                            font-size: 10px;
                            line-height: 14px;
                            color: #FFFFFFCC;
                            text-transform: uppercase;
                            letter-spacing: 1.2px;
                        }
                    }
                }
            }

            &_subscription,
            .title {
                display: flex;
                justify-content: center;
                margin: 10px 0px;
                font-size: 10px;
                line-height: 14px;
                color: #FFFFFFCC;
            }
        }
    }

    .asset-grid-view-container {
        .asset-grid-view-secondary-container {
            grid-template-columns: repeat(3, 1fr);

            @media screen and(min-width:400px) {
                grid-template-columns: repeat(4, 1fr)
            }
        }

        .asset-grid-box {
            cursor: pointer;
        }
    }

    &.sidepane {
        .grid-wrapper {
            width: $grid-box-width;
            height: $grid-box-height;
        }

        .grid-IRL-score {
            font-size: 11px;
        }

        .grid-name,
        .grid-currentValue {
            @include fontWeightSize(bold, 13px);
        }

        .grid-totalPriceChange {
            @include fontWeightSize(medium, 8px);
        }
    }

    .assets-title {
        margin-top: 20px;
        margin-bottom: 0px;
        font-size: 13px;
        line-height: 16px;
        color: #FFFFFFCC;
    }

    .skip-onboarding-step {
        margin-top: -10px;
    }
}

.MuiTooltip-popperArrow {

    .MuiTooltip-tooltip {
        background-color: #EBEDFA;
        color: #4C4E5E;
        font-size: 13px;
        line-height: 20px;
        padding: 20px 16px;
        border-radius: 12px;

        @media screen and(max-width:$tablet) {
            margin-top: 10px;
            margin-left: 10px;
            max-width: 96vw;
        }

        @media screen and(max-width:$mobile) {
            margin-top: 10px;
            margin-left: 10px;
            max-width: 99vw;
        }

        .MuiTooltip-arrow {
            color: #EBEDFA;
        }

        .onboarding-wallet-sell {
            display: flex;
            flex-direction: column;
            gap: 10px;
            align-items: center;

            .primary-button {
                width: fit-content;
            }
        }
    }
}