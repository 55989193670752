@import 'style/colors.scss';
@import 'style/size.scss';
@import 'style/font.scss';

.verify-email-modal {
    @include plus-jakarta-font;
    color: $tertiary-grey;
    display: flex;
    flex-direction: column;
    padding: 12px;

    .model_container p {
        @include fontWeightSize(medium);
        color: $coral-black;
        margin-bottom: 28px;
    }

    .model_title {
        margin-top: 42px;

        h4 {
            @include fontWeightSize(bold, 15px);
            margin-bottom: 12px;
        }
    }

    .verify_btn {
        @include fontWeightSize(extra-bold, 15px);
        width: auto;
        line-height: initial;
        padding: 12px 45px;
        margin-bottom: 13px;
    }
}