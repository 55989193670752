@import 'style/colors.scss';
@import 'style/font.scss';

.know-more-message {
    padding:0px 8px 0;
    text-align: left;
    color: $tertiary-grey;

    h5 {
        @include fontWeightSize(bold, 15px);
    }

    p {
        @include fontWeightSize(medium);
        opacity: 0.8;
    }

    .explainer-img1{
        width: 55px;
        margin-bottom: 16px;
    }

    .explainer-img2{
        width: 73px;
        margin: 10px 0 16px;
    }
}

.confirm-dialog{
    &.knowMoreMessage-confirm-button{
        padding-top: 0px ;
    }
}

.buttons-container {
    .knowMoreMessage-confirm-button {
        background: $primary-button-color;
        @include fontWeightSize(bold);
        text-transform: capitalize;
        margin: 8px 0;
    }
}