@import '../../../style/size.scss';

.custom-dialog {
    .dialog-full-width {
        margin: 0;
        width: 100%;
    }

    .body-modal {
        text-align: center;
        box-sizing: border-box;
        background: #202442;
        width: 100%;
        height: 100%;
        color: white;
        padding: 20px;

        @media (max-width: $mobile) {
            padding: 0px;
        }
    }

    .full-screen-modal {
        @media (max-width: $mobile) {
            .content-modal {
                width: 100%;
                margin: 0;
                border-radius: 0;
            }
        }
    }
}


.modal-bg-blur{
    background: #15182b33;
    backdrop-filter: blur(6px);

    .MuiPaper-root{
        background-color: transparent;
    }
    .MuiPaper-elevation24{
        box-shadow: none;
    }
    
}

.modal-at-bottom{
    .MuiPaper-root{
    background-color: #F7F8FF;
    position: absolute;
    bottom: 3.5%;
    margin: 0;
    filter: drop-shadow(0px 5px 6px #15182B12);
    }
}
