@import 'style/font.scss';
@import 'style/colors.scss';
@import 'assets/scss/custom_mixins.scss';

.team-asset-score-details {
    .list-card {

        .information_row_label {
            color: $team-score-details-textColor;
        }

        .asset-score {
            padding: 3px 7px;
            color: $white;
            @include fontWeightSize(bold, 14px);

            .asset-score_icon{
                @include scoreLabelIconSize(14px, 14px, $white);
                margin-right: 5px;
            }

            .asset-score_boosted {
                @include scoreLabelIconSize(14px, 14px, $white);
            }
        }

        .user-tokens,
        .highlight-league {
            @include fontWeightSize(bold, 12px);
            color: $team-score-details-value-color;
        }

        .buy-tokens-higher-league {
            @include fontWeightSize(medium, 11px);

            .tokens {
                @include fontWeightSize(bold, 11px);
            }
        }

        .league-relations {
            padding: 0;

            .title-container {
                .title {
                    font-size: 11px;
                }
            }
        }
    }
}