@import 'style/colors.scss';
@import 'style/font.scss';

.referYourFriends-title {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: left;
    row-gap: 10px;
    margin-bottom: 20px;

    .refer-title-icon {
        display: flex;
        align-items: center;
        margin: 0px 15px 0px;

        svg {
            width: 45px;
            height: 36px;
        }
    }

    .refer-title-info {

        h3 {
            color: $tertiary-grey;
            opacity: 0.65;
            font-weight: 600;
            font-size: 14px;
            margin-bottom: 10px;
        }

        p {
            color: $tertiary-grey;
            opacity: 0.65;
            @include fontWeightSize(medium, 13px);
            margin: 0px;
            white-space: pre-line;
        }
    }
}