@import "style/font.scss";
@import "style/colors.scss";

.identity-pending {
    @include plus-jakarta-font;
    @include fontWeightSize(medium, 13px);
    display: flex;
    width: 100%;
    background: rgba(235, 236, 246, 0.75);
    justify-content: flex-start;
    padding: 17px;
    border-radius: 14px;
    gap: 18px;

    .identity-pending_icons {
        display: flex;
        align-items: center;
        gap: 5px;

        .clock-icon {
            height: 15px;
            width: 15px;

            [data-name="circle-box"] {
                fill: #E0AD22;
            }
        }

        .proof-of-identity-icon {
            height: 31px;
            width: 28px;
        }
    }

    .identity-pending_content {
        display: flex;
        flex-direction: column;
        gap: 8px;
        text-align: left;

        .identity-pending_title {
            text-transform: uppercase;
            color: #868895;
        }

        .identity-pending_text {
            color: $tertiary-grey;
            opacity: 0.65;
        }
    }
}