@import 'style/size.scss';

.share-referral-buttons {
    margin-top: 20px;
    display: flex;
    justify-content: space-evenly;

    button {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 70px;
      height: 50px;
      border-radius: 14px;

      @media (max-width: $min-small-device) {
        width: 55px;
        height: 40px;
      }

      svg {
        @media (max-width: $min-small-device) {
          width: 35px;
          height: 20px;
        }
      }
    }
  }