.crypto-payment-card {
    width: 100%;
    background: #FFFFFF59;
    padding: 18px 0px;
    height: 115px;

    img {
        width: 50%;
        margin-left: 50%;
        height: 100%;
        transform: translateX(-50%);
    }
}