@import "style/font.scss";
@import "style/colors.scss";

.top-bar-actions {
    display: flex;
    gap: 6px;

    .top-bar-btn {
        @include plus-jakarta-bold-font;
        border-radius: 16px;
        font-size: 13px;
        width: fit-content;
        text-transform: none;
        white-space: nowrap;

        &.login-btn {
            background: $tertiary-violet;
            color: white;
        }

        &.signup-btn {
            background: #EDEEF7;
            color: $tertiary-violet;
        }
    }
}