@import 'style/colors.scss';
@import 'style/size.scss';
@import 'style/font.scss';

$reference-font-size : 11px;
$affiliation-container-text-color : #15182B80;

.affiliation_coupon {
    position: relative;
    display: block;
    text-align: center;

    .coupon_btns {
        position: relative;

        @media (max-width: 813px) {
            margin-bottom: 10px;
        }

        .custom_btn {
            padding: 12px 20px 12px 20px !important;
            margin: 0 20px 0px 0;
        }

        .affiliation-container {
            padding: 5px 5px 5px 20px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: auto;
            margin: auto;
            border-radius: 50px;
            box-sizing: border-box;
            column-gap: 10px;
            color: $affiliation-container-text-color;
            background: #F7F8FF;

            @media (max-width: 600px) {
                width: 100%;
                font-size: smaller;
            }

            @media (max-width: 615px) {
                font-size: smaller;
                justify-content: center;
            }

            .affiliated-link  {
                width: 100%;
                @include fontWeightSize(medium, $reference-font-size);
                white-space: nowrap;
                text-align: left;
                overflow: hidden;
                text-overflow: ellipsis;

                @media (min-width: $min-small-device) {
                    font-size: calc(1.3*#{$reference-font-size});
                  }
            }

            .msg-text{
                color: #6677F5;
                white-space: nowrap;
                @include fontWeightSize(medium, $reference-font-size);
                text-align: right;

                @media (min-width: $min-small-device) {
                    font-size: calc(1.3*#{$reference-font-size});
                  }
            }

            .green-text{
                color: $medium-green;
            }

            .green-bg{
                background-color: #53D0811A;
            }

            .affiliation-copy-container {
                display: flex;
                align-items: center;
                gap: 10px;
                cursor: pointer;
            }
            
            .affiliation-copy {
                width: 40px;
                height: 33px;
                background: $tdx-vert;
                background: #8187D01A;
                border-radius: 50px;
                display: flex;
                align-items: center;
                justify-content: center;
                padding: 9px;
            }
        }      
    }

    .general-terms{
        margin: 20px 0 0;

        .cgu-label{
          color: $tertiary-grey;
          @include fontWeightSize(medium, 13px);
        }
      }
}