@import "style/colors.scss";
@import "style/font.scss";

.mixed-chart {
    @include plus-jakarta-font;
    margin: 0px auto 0 auto;

    .apexcharts-tooltip {
        background: none;
        border: none;
        box-shadow: none;
        color: $tertiary-violet;
        font-size: 16px;
        font-weight: 500;

        .apexcharts-tooltip-text{
            color: $tertiary-violet;;
        }
    }

    .apexcharts-xaxistooltip-bottom {
        display: none;
    
        &.apexcharts-active {
            display: inline;
            border: none;
            background: none;
            color: $tertiary-violet;
            opacity: 0.8;
        }
    }

    .apexcharts-svg {
        border-radius: 14px;
        box-shadow: 0px 8px 9px #15182B12;
        background: linear-gradient(180deg, #F7F8FF, #FFFFFF) !important;

        .apexcharts-xcrosshairs.apexcharts-active {
            stroke: $tertiary-violet;
            stroke-width: 2;
            stroke-dasharray: none;
        }

        .apexcharts-xaxis line {
            display: none;

        }
    }

    .mixed-chart_header {
        display: flex;
        justify-content: space-between;

        .mixed-chart_title {
            text-transform: uppercase;
            @include fontWeightSize(medium, 12px);
            opacity: 0.5;
            color: $tertiary-grey;
            margin-bottom: 20px;
        }

        .mixed-chart_price_change {
            @include fontWeightSize(bold, 13px);
            color: #53D081;
            text-align: right;

            &.fill-red {
                color: red
            }

            svg {
                position: relative;
                top: 6px;
                height: 20px;
                width: 20px;
            }
        }
    }

    .mixed-chart_range {
        @include fontWeightSize(medium, 18px);
        width: 100%;
        color: $tertiary-grey;
        display: flex;
        justify-content: space-between;

        span {
            cursor: pointer;
            opacity: 0.35;

            &:hover {
                color: $tertiary-violet
            }
        }

        .active {
            color: $tertiary-violet;
            opacity: 1;
            @include plus-jakarta-bold-font;
        }
    }
}