@import 'style/font.scss';
@import 'style/colors.scss';
@import 'style/size.scss';

.wallet-container {
    @include plus-jakarta-font;
    @include fontWeightSize(medium);
    position: relative;
    min-height: calc(100vh - 50px);
    z-index: 999;
    width: 100%;
    border-radius: 12px 12px 9px 9px;
    padding: 0 12px;

    .gift-first-depot {
        text-align: center;
        padding: 15px;
        color: white;
        cursor: pointer;
    }

    .wallet-title {
        @include plus-jakarta-bold-font;
        text-align: center;
        font-size: 14px;
    }

    .close-wallet {
        position: absolute;
        top: -8px;
        cursor: pointer;

        &.rotate {
            transform: rotate(-180deg);
            transition: transform 0.2s ease-in;
        }

        svg {
            height: 32px;
            width: 32px
        }
    }

    .wallet-transaction-buttons {
        margin-top: 22px;
        display: flex;
        justify-content: space-between;

        button {
            @include fontWeightSize(medium, 13px);
            display: flex;
            align-items: center;
            justify-content: center;
            flex-basis: 48%;
            border-radius: 8px;
            background: #FFFFFF10;
            padding: 16px 12px;
            border: none;
            box-shadow: none;
            color: #FFFFFF;
            cursor: pointer;
            text-align: center;

            svg {
                margin-right: 14px;
                position: relative;
                z-index: 2;
                height: 16px;
                width: 16px;

                @media (max-width:$min-small-mobile) {
                    margin-right: 5px;
                }
            }
        }
    }

    button.add-money {
        background-color: $tertiary-violet;

        &:hover {
            transition: 0.2s all ease;
            background: $primary-button-hover-color;
        }
    }

    .wallet-overview {
        margin-top: 24px;
        margin-bottom: 24px;

        .wallet-summary {
            .color-green {
                color: $medium-green;
            }

            .color-red {
                color: $primary-red;
            }

            .wallet-summary_info {
                margin-top: 22px;
                // display: flex;
                justify-content: space-between;
                flex-wrap: wrap;

                .user-level {
                    flex-basis: 48%;
                    display: flex;
                    padding: 10px 0px;
                    border-radius: 8px;
                    justify-content: center;
                    background-color: $background-light;
                    align-items: center;

                    @media (max-width: $mobile) {
                        flex-basis: 100%;
                        margin-top: 20px;
                    }

                    svg {
                        width: 30px;
                        height: 40px;
                    }

                    .info {
                        margin-left: 5px;

                        .title {
                            font-size: 9px;
                            color: $pale;
                            text-transform: uppercase;
                        }

                        p {
                            margin: inherit;
                        }

                        .name {
                            @include fontWeightSize(medium, 13px);
                        }
                    }

                }

                .wallet-summary_value {
                    flex-basis: 48%;

                    @media (max-width: $mobile) {
                        flex-basis: 100%;
                    }

                    &_header {
                        @include fontWeightSize(medium, 14px);
                        color: #FFFFFFCC;
                        text-transform: uppercase;
                    }

                    &_content {
                        font-size: 32px;
                        @include plus-jakarta-bold-font;
                        color: #FFFFFF;

                        &.font-small {
                            font-size: 24px;
                        }

                        svg {
                            position: relative;
                            top: 5px;
                            right: 5px;
                            height: 35px;
                            width: 35px;
                        }
                    }

                    &_change {
                        @include fontWeightSize(bold, 0.75rem);
                        color: $medium-green;

                        &.color-red {
                            color: $primary-red;
                        }
                    }
                }
            }

            .wallet-summary_generated {
                flex-basis: 50%;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                border-radius: 8px;
                background-image: url("../../../assets/images/wallet-generated-bg.png");
                background-size: cover;

                .color-gold {
                    color: #F4D56F;
                }
            }

            .wallet-summary_generated_title {
                @include fontWeightSize(medium, 10px);
                color: #FFFFFFCC;
                text-transform: uppercase;
            }

            .wallet-summary_generated_content {
                margin-top: 3px;
                @include fontWeightSize(bold, 18px);
                display: flex;
                align-items: center;

                .i-info {
                    margin-left: 3px;

                    svg {
                        color: #818291;
                    }
                }
            }
        }
    }

    .wallet-items-list {
        margin-top: 35px;
        height: auto;
        display: flex;
        flex-direction: column;
        row-gap: 12px;
        column-gap: 2%;
        padding-right: 10px;
        overflow-y: auto;

        .wallet-item-container {
            display: flex;
            justify-content: space-between;

            .wallet-item_value {
                @include fontWeightSize(bold, 18px);
                align-self: center;
                white-space: nowrap;

                .wallet-item_superscript {
                    position: relative;
                    bottom: 5px;
                    font-size: 11px;
                }

                @media (max-width: $max-small-device) {
                    font-size: 13px;
                }
            }

            .wallet-item_label {
                @include fontWeightSize(medium, 12px);
                opacity: 0.8;
                display: flex;
                align-items: center;

                @media (max-width: $max-small-device) {
                    font-size: 11px;
                }

                .wallet-item-icon {
                    margin-right: 8px;

                    svg {
                        height: 25px;
                        width: 25px;
                        fill: white
                    }
                }

                .wallet-item-tooltip {
                    font-size: 1rem;
                    width: 1em;
                    height: 1em;
                    margin-left: 4px;
                    fill: #818291;
                    cursor: pointer;
                }
            }
        }
    }

    .onboarding-mode .wallet-summary {
        display: flex;

        .wallet-summary_info {
            margin-top: 22px;
            display: flex;
            justify-content: space-between;

            .wallet-summary_value {
                flex-basis: 48%;

                &_header {
                    margin: 0px;
                    font-size: 14px;
                    line-height: 18px;
                }

                &_content {
                    font-size: 32px;
                    line-height: 40px;
                }
            }

            .user-level {
                display: flex;
                align-items: center;
                justify-content: flex-start;
                padding: 0px 0px 0px 20px;
                margin: 0px;
                border-radius: 8px;
                background-color: $background-light;
                gap: 5px;

                svg {
                    width: 25px;
                    height: 25px;
                }

                p {
                    margin: inherit;
                }

                .info {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                }

                .title {
                    font-size: 8px;
                    line-height: 10px;
                    color: $pale;
                    text-transform: uppercase;
                }

                .name {
                    @include fontWeightSize(medium, 13px);
                    line-height: 16px;
                }
            }
        }
    }
}

.body-modal {
    .confirm-dialog.verify-identity_customModal {
        padding-top: 10px;
    }

    .show-short-msg_customModal {
        padding: 20px;
        margin-bottom: 12px;
    }
}