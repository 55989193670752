@import 'style/size.scss';

.overlay-container {
    position: absolute;
    top: 7px;
    left: 7px;

    background: linear-gradient(100deg, rgba(102, 119, 245, 1) 0%, rgba(249, 65, 121, 1) 100%);
    border-radius: 19px;
    width: fit-content;
    padding: 6px 9px 6px 9px;

    @media (max-width: $mobile) {
        padding: 4px 6px 4px 6px;
    }

    .text {
        color: #fff;
        font-weight: bold;
        justify-content: flex-start;
        @media (max-width: $mobile) {
            font-size: 10px;
        }
    }

    svg {
        width: 13px;
        height: 13px;
        margin-right: 5px;
    }
    
    .display-flex {
        display: flex;
    }

    .vertical-align {
        align-items: center;
    }

    .no-margin {
        margin: 0;
    }
}