@import 'style/colors.scss';
@import 'style/font.scss';
@import "../../../style/size.scss";

$text-color-in-available-soon : #646775;

.private-sell-container {
    margin-bottom: 20px;

    .countdown-ico-not-open {
        background-color: $countdown-bgColor;
        backdrop-filter: blur(42px) opacity(0.85);
        border-radius: 14px;
        box-shadow: 0px 5px 6px #15182B12;
        margin: 0px 16px;
        overflow: auto;

        .countdown-top-section {
            padding: 16px 12px;

            .countdown-top-section__title {
                font-size: 13px;
                font-weight: bold;
                @include fontWeightSize(medium, 13px);
                color: $text-color-in-available-soon;
                text-transform: uppercase;
                margin-bottom: 2px;
            }

            .countdown-top-section__subtitle {
                @include fontWeightSize(medium, 12px);
                color: $text-color-in-available-soon;
                margin-top: 10px;
            }
        }
    }

    .private-sell__btn-section {
        margin-top: 12px;

        .auto-margin {
            margin: 0 auto;
        }

        .notificationUi-block {
            margin-bottom: 12px;

            @media screen and (min-width: $mobile) {
                width: 400px;
            }
        }
    }

    .private-sell__video-container {
        box-shadow: 0px 3px 6px #6677F526;
    }
}

.full-container {
    .first-deposit-button {
        cursor: auto;
        padding: 15px;
        background-color: #EBECF0;
        color: #8D9383;
        border-radius: 8px;
        margin-bottom: 20px;
        @media (max-width: $max-small-device) {
            white-space: pre-wrap;
            margin-top: 10px;
        }

        line-height: normal;
    }
}