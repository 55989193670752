.transfer-page {
    margin-top: 70px;
    .transfer-container {
        width: 100%;
        margin: auto;

        .card-style {
            margin-top: 25px;
        }
    }

    .action-buttons {
        display: inline-flex;
        align-items: center;
        column-gap: 30px;
        width: 100%;
        margin-bottom: 30px;
        color: black;
        .item {
            flex-basis: 15%;
        }
    }
}
