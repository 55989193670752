@import 'style/size.scss';
@import 'style/_variable.scss';

.article-component {
    margin: auto;
	color:#404354;
	display: flex;
	justify-content: space-around;
	align-items: center;
	margin-top: 20px;
	padding: 15px 15px;
	border: 1px solid #e0e0e0;
	border-radius: 15px;
	box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
	font-weight: 500;
	max-height: 140px;

		.article {
			width: 80%;
            margin-right: 20px;

        h4 {
            font-size: $normal-font;
            font-weight: 600;
            margin-bottom: 10px;
            color: #404354;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
        }

		.article-footer, .article-header {
            display: flex;
            font-size: $extra-small-font;
            color: #a0a0a0;
		}

        .article-header {
            margin-bottom: 10px;
            display: flex;
            align-items: center;
            .source-logo {
                width: 20px;
                height: 20px;
                margin-right: 5px;
                img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }
        }

        .article-footer {
            margin-top: 10px;
            justify-content: flex-end;
        }
	}

		.thumbnail {
			border-radius: 5px;
			overflow: hidden;
			width: 40%;
			max-height: 105px;
			img {
				width: 100%;
				height: 100%;
				object-fit: cover;
			}
		}
}
