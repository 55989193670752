@import 'style/size.scss';

.first-purchase-banner-fun-cards {
    background-color: #F7C566;
    border-radius: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 15px;

    .content {
        margin: 0px 20px 0px 20px;
        padding: 20px 0px 10px 0px;

        @media (max-width: $min-medium-device) {
            width: 65%;
        }

        p {
            color: #000000;
            line-height: 1.4;
            text-align: center;

            @media (max-width: $mobile) {
                font-size: 14px;
                line-height: 1.3;
            }

            @media (max-width: 380px) {
                font-size: 12px;
            }

            &.title-content-banner-first-purchase-fun-cards {
                font-weight: 700;
            }
        }
    }

    img {
        max-width: 8%;
    }
}