.overview-influencer {
    margin-top: 70px;
    .action-buttons {
        color: black;
        display: inline-flex;
        align-items: center;
        column-gap: 30px;
        width: 100%;
        margin-bottom: 30px;

        .item {
            flex-basis: 20%;
            .active-button {
                background: linear-gradient(90deg, rgba(254, 121, 103, 1) 0%, rgba(246, 43, 123, 1) 100%);
            }
        }
    }
}
