@import "./_mixins";
@import "../../assets/scss/components/mixins";
@import '../../../style/size.scss';
@import "style/colors.scss";
@import "style/font.scss";
/*
.MuiDialog-root {
  //margin-top: calc(env(safe-area-inset-top) + 50px) !important; /* iOS 11.0 */
//margin-bottom: calc(env(safe-area-inset-bottom) + 50px) !important; /* > iOS 11.0 */
//margin-top: calc(constant(safe-area-inset-top) + 50px) !important; /* iOS 11.0 */
//margin-bottom: calc(constant(safe-area-inset-bottom) + 50px) !important; /* > iOS 11.0 */
//}

body {
  padding-top: env(safe-area-inset-top);
  font-family: "Plus Jakarta Display" !important;
  @include scrollbar;

  * {
    @include scrollbar;
  }
}

// this force the css crisp client, keep in case of need
//.crisp-client {
//  .cc-1xry .cc-unoo .cc-7doi {
//    @media (max-width: 700px) {
//      margin-bottom: 60px !important;
//      margin-right: -10px !important;
//    }
//  }
//}

.playerName {
  text-align: left;
}

p {
  line-height: 18px;
}

.hidden {
  display: none;
}

.nameClamp {
  $name-clamp-height: 1.4;
  $name-max-width: 200px;
  @include clamp($name-clamp-height, $name-max-width, $name-fontsize, $name-lines-to-show);
}

.subClamp {
  $subtitle-line-height: 1.4;
  $subtitle-max-width: 400px;
  @include clamp($subtitle-line-height, $subtitle-max-width, $subtitle-fontsize, $subtitle-lines-to-show, "true");
}

.clamp {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

// Loading Page
.loading-page {
  width: 100%;
  height: 100%;
  background-color: #202442;
}

// Main Farme Start
.mainframe_section {
  @include plus-jakarta-font;
  position: relative;
  width: 100%;
  display: flex;
  height: 100%;
  background: $app-background;
}

// Main Farme End

// Side-Menu Section Start
.sidemenu_section {
  width: $sideMenuWidth;
  background-color: #202442;
  z-index: 20;
  top: 0;
  bottom: 0;
  position: fixed;
  overflow-y: auto;
  overflow-x: hidden;
  @include scrollbar;
  
  @media(min-width:$min-extra-large-device){
    height: 100vh;
    position: sticky;
    top:0;
  }
}

.sidemenu_profile {
  .profil_section {
    .profile_img {
      width: 110px;
      height: 110px;
      position: relative;
      border-radius: 50%;
      z-index: 1;
      display: flex;
      align-items: center;
      justify-content: center;
      margin: auto;

      img {
        border-radius: 50%;
        width: 75px;
        height: 75px;
        object-fit: cover;
      }

      &::before {
        content: "";
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        background-image: url("../../../assets/images/user_back.png");
        background-repeat: no-repeat;
        width: 100%;
        z-index: -1;
        background-size: cover;
        height: 100%;
      }

      span {
        position: absolute;
        right: 5px;
        bottom: 5px;
        background-image: $gradient2;
        border: 5px solid $darktheme;
        width: 45px;
        height: 45px;
        border-radius: 50%;
        text-align: center;
        align-items: center;
        display: flex;
        justify-content: center;
        font-size: 14px;
      }
    }
  }

  .menu_profile_name {
    text-align: center;
    text-transform: uppercase;
    margin: 10px 0 0 0;
    font-weight: 600;
    font-size: 18px;
    color: #dae8ff;
    // font-family: $secondary-font;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.side_menulinks {
  position: relative;

  .sidemenu_logo {
    width: 100%;
    margin: 0 0 15px 0;

    img {
      width: 91px;
    }
  }

  .profile_logosec {
    padding: 45px 10px 10px 10px;
    
      @media (min-width:1200px) {
        padding-bottom: 0px
      }
  }

    .close_menusec {
    width: auto;
    height: 40px;
    display: none;
    align-items: center;
    border-radius: 7px;
    margin: 0px 15px 0px 0px;
    position: absolute;
    top: 45px;
    font-size: 18px;
  }
}

.sidemenu_links_sec {
  position: relative;

  // Widget Section Side Bar
  .sidemenu_widget {
    position: relative;
    width: 100%;
    margin-top: 12px;
    a {
      width: 100%;
      display: flex;
      align-items: center;
      font-size: 15px;
      font-weight: 500;
      padding: 0px 0 0 30px;
      color: #d7d8ff;
      letter-spacing: 0.04em;
      font-weight: 400;
      text-transform: capitalize;
      transition: 0.2s all ease;
      // font-family: $secondary-font;
      position: relative;
      background-image: linear-gradient(to right, transparent -20%, transparent 90%);

      svg {
        margin: 0 10px 0px 0;
        font-size: 22px;
      }

      &:hover {
        transition: 0.2s all ease;
        background-image: linear-gradient(to right, #912c6b -30%, transparent 85%);
        color: #ffffff;
      }

      &.active {
        background-image: linear-gradient(to right, #912c6b -30%, transparent 85%);
        color: #ffffff;
      }
    }
  }

  // Widget Section Side Bar

  // trading Side Bar
  .sidemenu_trading {
    position: relative;
    padding: 40px 0 40px 0px;

    .title-section-side-menu {
      // font-family: $secondary-font;
      font-size: 18px;
      font-weight: 500;
      margin: 0 0 20px 0;
      padding: 0 0 0 30px;
      letter-spacing: 0.06em;
    }

    a {
      width: 100%;
      display: flex;
      align-items: center;
      font-size: 14px;
      height: 41px;
      padding: 0px 0 0 30px;
      border-left: 1px solid #2d3259;
      color: #d7d8ff;
      letter-spacing: 0.04em;
      font-weight: 400;
      text-transform: capitalize;
      margin: 4px 0 4px 0;
      transition: 0.2s all ease;
      // font-family: $secondary-font;
      position: relative;
      background-image: linear-gradient(to right, #242849 -20%, transparent 80%);

      .linkside_logo {
        span {
          font-size: 22px;
          margin: 0 10px 0 0;
          color: #96a9de;
        }
      }

      &::before {
        content: "";
        position: absolute;
        width: 100%;
        height: 1px;
        top: 0;
        left: 0;
        background-image: linear-gradient(to right, rgba(255, 255, 255, 0.05) -20%, transparent 90%);
      }

      &::after {
        content: "";
        position: absolute;
        width: 100%;
        height: 1px;
        bottom: 0;
        left: 0;
        background-image: linear-gradient(to right, rgba(255, 255, 255, 0.05) -20%, transparent 90%);
      }

      &:hover {
        transition: 0.2s all ease;
        background-image: linear-gradient(to right, #912c6b -30%, transparent 85%);
        color: #ffffff;

        &::before {
          content: "";
          position: absolute;
          left: 0;
          top: 0;
          width: 3px;
          height: 100%;
          background-color: rgba(255, 0, 159, 0.2);
        }

        .linkside_logo {
          span {
            color: #ffffff;
          }
        }
      }

      &.active {
        background-image: linear-gradient(to right, #912c6b -30%, transparent 85%);
        color: #ffffff;

        &::before {
          content: "";
          position: absolute;
          left: 0;
          top: 0;
          width: 3px;
          height: 100%;
          background-color: rgba(255, 0, 159, 0.2);
        }

        .linkside_logo {
          span {
            color: #ffffff;
          }
        }
      }
    }
  }

  .container-side-menu-deconnexion {
    text-align: center;
    display: flex;
    margin-top: 15px;
    padding: 0 0 15px 0;

    .btn-logout {
      cursor: pointer;
      background: transparent;
      border: none;
      font-size: 18px;
      color: #b52e3c;
      
      &:hover {
        text-decoration: underline;
      }
    }

  }
}

// Side-Menu Section End

// Main Section Start (Header,Middel,Footer)
.main_section {
  width: calc(100% - 325px);
  box-sizing: border-box;
  position: relative;
  height: 100%;
  min-height: 100vh;
  margin-left: auto;
  overflow-x: hidden;

  @media (max-width: 1100px) {
    width: 100%;
  }
}

// Main Section End (Header,Middel,Footer)

// Register-Page Section Start
.auth_page {
  background:#F7F8FF;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  width: 100%;
  position: relative;
  box-sizing: border-box;
  padding: 0px;

  @media(min-width:$min-extra-large-device){
   height: auto;
  }

  .auth_structure {
    height: fit-content;
    width: 700px;
    position: relative;
    background-color: #181e4e;
    padding: 0px 0px 0px 0px;

    .container-logo {
      margin: 0 auto 20px auto;
      text-align: center;
      position: relative;
      z-index: 10;

      @media (max-height: 568px) {
        display: none;
      }
    }
  }

  .logreg_title {
    margin: 0 0 40px 0;
    position: relative;
    display: flex;
    justify-content: center;
    z-index: 10;

    h3 {
      text-align: center;
      margin: 0 0 0 0;
      font-size: 36px;
      font-weight: 500;
      max-width: 450px;
      color: $textwhite;
    }
  }
}

.loginregi_form {
  .forgot-password {
    color: $subtitle;
    font-size: 14px;
    margin: 20px 0 0px 0;
    width: 100%;
  }
}

.custinput_group {
  position: relative;
  margin: 0 0 20px 0;

  .form_label {
    font-size: 14px;
    color: $subtitle;
    letter-spacing: 0.03em;
    margin: 0 0 10px 0;
    line-height: 14px;
  }

  input {
    color: white;
    border: 0;
    padding: 0 15px;
    min-height: 45px;
    border-radius: 10px;
    height: inherit;
  }
}

.MuiInputBase-formControl {
  border: 1px solid rgba(99, 66, 255, 0.5) !important;
  border-radius: 7px !important;
}

.MuiFormHelperText-contained {
  margin-left: 0 !important;
  margin-right: 0 !important;
}

.loginsocial_buttons {
  a {
    margin: 0 10px;
  }

  a:first-child {
    margin-left: 0;
  }

  a:last-child {
    margin-right: 0;
  }

  display: flex;
  align-items: center;
  justify-content: space-between;

  .custom_btn {
    width: 30%;
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    background-image: none !important;

    img {
      width: 22px;
      height: 22px;
      object-fit: contain;
      margin: 0 10px 0px 0;
    }

    &:hover {
      background-position: inherit !important;
      background-image: $gradient  !important;
    }
  }
}

.separate_or {
  padding: 0px 0;
  margin: 15px 0;
  position: relative;
  text-align: center;

  @media (max-height: 732px) and (max-width: 420px) {
    display: none;
  }

  span {
    position: relative;
    text-align: center;
    width: auto;
    display: inline-block;
    font-size: 14px;
    color: #939dc4;
    background-color: #181e4e;
    padding: 1px 10px;
    margin: 0 auto;
  }

  &:before {
    content: "";
    position: absolute;
    width: 100%;
    height: 1px;
    left: 0;
    top: 50%;
    background-color: #ffffff;
  }
}

.help_label {
  position: relative;

  .MuiSvgIcon-root {
    position: absolute;
    margin: 0 5px;
  }
}

// Register-Page Section End

// Form Section Start

// Form Section End

// About Section Start
.about_card {
  // position: relative;
  justify-content: center;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  background-color: $darktheme;
  width: 100%;
  overflow: hidden;

  .main_profile {
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;
    padding: 40px 40px 0 40px;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    background-image: url("../../images/register_bg.jpg");

    .profileimg_info {
      display: flex;
      width: 100%;
      position: relative;
      align-items: flex-start;
      bottom: -60px;

      .profile_img {
        width: 150px;
        height: 150px;
        position: relative;
        margin: 0 30px 0 0;
        border-radius: 50%;
        z-index: 1;
        display: flex;
        align-items: center;
        justify-content: center;

        img {
          border-radius: 50%;
          width: 115px;
          height: 115px;
          object-fit: cover;
        }

        &::before {
          content: "";
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
          background-image: url("../../images/user_back.png");
          background-repeat: no-repeat;
          width: 165px;
          z-index: -1;
          background-size: cover;
          height: 165px;
        }

        span {
          position: absolute;
          right: 5px;
          bottom: 5px;
          background-image: $gradient2;
          border: 5px solid $darktheme;
          width: 45px;
          height: 45px;
          border-radius: 50%;
          text-align: center;
          align-items: center;
          display: flex;
          justify-content: center;
          font-size: 14px;
        }
      }

      .profile_name {
        .country_icon {
          display: flex;
          align-items: center;
          position: relative;
          margin: 0 0 10px 0;

          h4 {
            font-size: 18px;
            margin: 0 0 0 10px;
            color: $textwhite;
          }
        }

        h3 {
          font-size: 30px;
          color: $textwhite;
          font-weight: 500;
          margin: 0 0 0 0;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
          max-width: 500px;
        }
      }
    }
  }

  .card_follower {
    pointer-events: none;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    z-index: 5;
    padding: 20px 30px 20px 220px;

    .follower_section {
      display: flex;
      align-items: center;
      position: relative;

      .cardfollo_sec {
        display: flex;
        align-items: center;
        border-right: 1px solid $aborder;
        margin: 0 25px 0px 0;
        padding: 0 25px 0 0;

        .cardfollo_name {
          margin: 0 0 0 10px;

          h4 {
            margin: 0 0 6px 0;
            font-size: 14px;
          }

          p {
            margin: 0 0 0 0;
            font-size: 14px;
            text-transform: uppercase;
            color: #848ba4;
          }
        }

        &:last-child {
          padding: 0 0 0 0;
          margin: 0 0 0 0;
          border: 0;
        }
      }
    }

    .profile_info {
      display: flex;
      align-items: center;

      .footl_icon {
        width: 35px;
        height: 35px;
        border: 1px solid #4a4972;
        border-radius: 50%;
        align-items: center;
        justify-content: center;
        display: flex;
        margin: 0 13px 0 0;
      }
    }
  }
}

.atitle_sec {
  position: relative;
  width: 100%;
  margin: 40px 0 30px 0;

  h3 {
    font-size: 26px;
    font-weight: 700;
    color: $textwhite;
    margin: 0;
    // font-family: $secondary-font;
  }
}

.about_sec {
  position: relative;
  margin: 40px 0 0 0;

  .about_text {
    .about_p {
      font-size: 14px;
      font-weight: 200;
      line-height: 26px;
      margin: 0 0 20px 0;
      color: #d7d8ff;
    }

    ul {
      li {
        display: flex;
        align-items: flex-start;
        width: 100%;

        p {
          display: block;
          margin: 0 0 15px 10px;
          font-size: 15px;
        }
      }
    }
  }
}

.chart_section {
  position: relative;
  background-color: #343a6a;
  border-radius: 10px;
  margin: 40px 0 0 0;
  padding: 20px 20px 20px 20px;
}

// About Section End

// Sidepanel Section Start
.side_title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px 15px 15px 15px;
  background-color: #343a6a;
  border-radius: 7px;
  margin: 0 0 20px 0;

  h3 {
    font-size: 20px;
    margin: 0 0 0 0;
    color: $textwhite;
    font-weight: 500;
    letter-spacing: 0.02em;
  }

  .view_btns {
    color: #c8c1ff;
    text-transform: uppercase;
  }
}

.cust_section {
  position: relative;
  background-color: $darktheme;
  border-radius: 10px;
  margin: 0 0 20px 0;
  overflow: hidden;

  .cards_info {
    .cards_imgs {
      text-align: center;
      display: inline-block;
      background-image: url("../../images/trendex_plaquettes.png");
      background-repeat: round;
      width: 100%;
      margin: 0px 0px 90px 0;
    }

    .cards_titles {
      position: relative;
      bottom: -60px;

      .cards_name {
        margin: 0px 0px 0px 0;
        position: relative;
        bottom: -20px;

        h4 {
          margin: 0px 0px 0px 0;
          position: relative;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          overflow: hidden;
          text-overflow: ellipsis;
          line-height: 26px;
          text-transform: capitalize;
        }
      }
    }

    .c_imges {
      display: inline-block;
      position: relative;
      z-index: 9;

      &::before {
        content: "";
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        background-image: url("../../images/user_back.png");
        background-repeat: no-repeat;
        width: 95px;
        z-index: -1;
        background-size: cover;
        height: 95px;
      }

      img {
        z-index: 5;
        width: 60px;
        height: 60px;
        border-radius: 50%;
        object-fit: cover;
      }
    }

    .follow_cards {
      display: flex;
      width: 100%;
      position: relative;
      padding: 10px 0 15px 0;
      margin: 0 0 1px 0;
      border-bottom: 1px solid #272c50;

      .foll_infor {
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
        border-right: 1px solid #383f73;
        position: relative;
        width: 50%;

        .foll_data {
          margin: 0 0 0 10px;
          text-align: left;

          h5 {
            margin: 0 0 3px 0;
          }

          span {
            font-size: 12px;
            text-transform: uppercase;
            color: #848ba4;
          }
        }

        &:last-child {
          border-right: 0px;
        }
      }
    }

    .follow_price {
      display: flex;
      width: 100%;
      position: relative;
      align-items: center;
      padding: 15px 0 15px 0;
      border-top: 1px solid #272c50;
      border-bottom: 1px solid #272c50;

      .f_price {
        width: 50%;
        position: relative;
        text-align: center;

        h4 {
          color: #3df5c1;
          font-weight: 600;
          font-size: 20px;
        }

        &.pri {
          h4 {
            color: #5167df;
          }
        }

        p {
          margin: 0;
        }
      }
    }
  }

  .cust_btns {
    border-top: 1px solid #272c50;
    margin: 1px 0 0 0;
    text-align: center;
    padding: 10px 0 10px 0px;

    .custom_btn {
      padding: 10px 30px !important;
      border-radius: 50px !important;
      letter-spacing: 0em;
      font-size: 14px !important;
      color: $textwhite  !important;
      background-image: $gradient2  !important;
    }
  }
}

.profil_section {
  .profile_img {
    width: 110px;
    height: 110px;
    position: relative;
    border-radius: 50%;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: auto;

    img {
      border-radius: 50%;
      width: 75px;
      height: 75px;
      object-fit: cover;
    }

    &::before {
      content: "";
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      background-image: url("../../../assets/images/user_back.png");
      background-repeat: no-repeat;
      width: 100%;
      z-index: -1;
      background-size: cover;
      height: 100%;
    }

    span {
      position: absolute;
      right: 5px;
      bottom: 5px;
      background-image: $gradient2;
      border: 5px solid $darktheme;
      width: 45px;
      height: 45px;
      border-radius: 50%;
      text-align: center;
      align-items: center;
      display: flex;
      justify-content: center;
      font-size: 14px;
    }
  }
}

.topinvester_section {
  .cust_section {
    margin: 0 0 30px 0;
    width: 100%;
  }
}

// .sideplayer_fix {
//   position: relative;
//   overflow-y: scroll;
//   width: auto !important;
//   background-color: transparent;
//   top: inherit;
//   left: inherit !important;
//   bottom: inherit;
//   padding: 0 5px 0px 0;
// }
.sideplayer_fix {
  position: absolute;
  /* height: 100vh; */
  width: 100%;
  background-color: transparent;
  top: inherit;
  left: inherit !important;
  bottom: inherit;
  padding: 0 5px 0px 0;
  overflow: hidden;
  width: 100%;
}

.sideplayer_section {
  z-index: 0;
  position: relative;
}

// Sidepanel Section End

// Banner-title Section Start
.banner_title {
  position: relative;
  background-image: url("https://banner-assets-categ.s3.eu-west-3.amazonaws.com/top_collectors.jpg");
  background-repeat: no-repeat;
  width: 100%;
  background-size: cover;
  background-position: center;
  padding: 70px 70px 70px 70px;
  min-height: 260px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  border-radius: 10px;
  margin: 0 0 60px 0;

  h3 {
    margin: 0 0 0 0;
    color: #fff;
    text-transform: uppercase;
    font-weight: 500;
    font-size: 36px;
  }
}

// Banner-title Section End

// My Profile Section Start
.myprofile_title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.myprofile_text {
  .profile_data {
    display: flex;
    align-items: center;
    width: 100%;
    margin: 0 0 15px 0;

    label {
      font-size: 18px;
      margin: 0 0 0 0;
      color: $textwhite;
      width: 150px;
      font-weight: 500;
      line-height: 20px;
    }

    h4 {
      font-size: 18px;
      font-weight: 300;
      margin: 0 0 0 0;

      font-weight: 400;
    }

    .form-control {
      min-width: 160px;
      width: auto;
      background-color: #343a6a;
      color: $textwhite;
      border: 0;
    }
  }
}

// My Profile Section End

// Model Section Start
.model_btns {
  &.centered {
    text-align: center;
  }

  .MuiChip-root {
    max-width: 280px;
    margin: auto;
  }

  padding: 0 20px;
  display: block;

  .custom_btn {
    padding: 12px 20px 12px 20px !important;
    margin: 0 20px 0px 0;

    &:last-child {
      margin: 0;
    }
  }

  .MuiButtonBase-root {
    background-image: $gradient  !important;
    padding: 10px 25px !important;
    font-size: 14px !important;
    line-height: 20px !important;
    text-transform: capitalize;
    border: 0 !important;
    color: $textwhite  !important;
    border-radius: 30px !important;
    // font-family: $secondary-font !important;
    font-weight: 600 !important;
    background-size: 200% auto !important;
    display: flex !important;
    align-items: center;
    transition: 0.4s all ease !important;
    text-align: center !important;
    height: auto !important;

    &:hover {
      transition: 0.5s all ease !important;
      background-position: right center !important;
    }
  }
}

.modal.show .modal-dialog {
  padding: 25px 0 0 0 !important;
}

.custom_model {
  .close_button {
    position: absolute;
    right: 0;
    min-width: auto;
    top: -50px;
    border-radius: 50%;
    background-color: transparent;
    background-image: $gradient2;
    border: 0;
    line-height: 21px;
  }

  .modal-content {
    border-radius: 20px;
  }

  .modal-body {
    background-color: $themecolor1;
    color: $textwhite;
    border-radius: 10px;
  }

  .model_title {
    text-align: center;
    position: relative;
    width: 100%;
    padding: 10px 0px 20px 0;
    margin: 0 0 20px 0;
    border-bottom: 1px solid #4f5586;

    h4 {
      margin: 0 0 0 0;
      font-size: 20px;
      font-weight: 500;
    }
  }

  .model_btns {
    display: flex;
    flex-wrap: wrap;

    .custom_btn {
      width: 100%;
      margin: 0 0 20px 0;
    }
  }

  .model_container {
    p {
      font-size: 14px;
      line-height: 22px;
      font-weight: 300;
      padding: 0 0 0 0;
      margin: 0 0 20px 0;
      text-align: center;
    }
  }
}

// Model Section End

// Follower Card Start
.follow_title {
  background-color: $themecolor1;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px 25px 20px 25px;
  border-radius: 10px;
  margin: 10px 0 30px 0;

  .custom_btn {
    padding: 12px 25px 12px 25px !important;
    border-radius: 10px !important;
    font-size: 14px !important;
    font-weight: 600 !important;
    border: 1px solid #2a3370 !important;
    background-image: none !important;
    color: #5e6dac !important;
    background-color: transparent !important;

    &:hover {
      color: #ffffff !important;
      background-image: $gradient  !important;
    }

    img {
      margin: 0 10px 0 0;
    }
  }
}

.search_input {
  width: 35%;

  input {
    width: auto;
    flex: inherit;
    min-width: 300px;
    background-color: #393f74;
    border: 0;
    height: 45px;
    border-radius: 5px !important;
  }

  button {
    position: absolute !important;
    right: 0;
    border: 0;
    z-index: 10 !important;
    padding: 10px 15px 10px 15px;
    height: 45px;

    &:hover {
      background-color: transparent;
      color: #ffffff;
    }
  }
}

.follower_card {
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  padding: 40px 20px 40px 30px;
  border: 1px solid #3c447f;
  border-radius: 10px;
  margin: 0 0 30px 0;

  .follow_img {
    width: 55px;
    height: 55px;
    border-radius: 50%;
    position: relative;

    &::before {
      content: "";
      position: absolute;
      left: 49%;
      top: 49%;
      transform: translate(-50%, -50%);
      background-image: url("../../images/user_back.png");
      background-repeat: no-repeat;
      width: 80px;
      height: 80px;
      z-index: -1;
      background-size: cover;
    }

    img {
      width: 55px;
      height: 55px;
      border-radius: 50%;
      object-fit: cover;
    }

    span {
      position: absolute;
      right: -15px;
      bottom: -10px;
      background-image: $gradient2;
      border: 3px solid $darktheme;
      width: 30px;
      height: 30px;
      border-radius: 50%;
      text-align: center;
      align-items: center;
      display: flex;
      justify-content: center;
      font-size: 12px;
    }
  }

  .follow_text {
    display: flex;
    align-items: center;
    width: calc(100% - 80px);
    margin: 0 0 0 20px;
    justify-content: space-between;

    .follow_name {
      h4 {
        font-size: 14px;
        color: $subtitle;
        margin: 0 0 5px 0;
      }

      span {
        margin: 0 0 0 0;
        color: #5e6dac;
      }
    }

    .sportf_icons {
      .profile_info {
        display: flex;
        align-items: center;

        .footl_icon {
          width: 30px;
          height: 30px;
          border: 1px solid #4a4972;
          border-radius: 50%;
          align-items: center;
          justify-content: center;
          display: flex;
          margin: 0 5px 0 0;

          img {
            width: 15px;
          }

          &:last-child {
            margin: 0 0 0 0;
          }
        }
      }
    }

    .follos_btns {
      .custom_btn {
        padding: 5px 17px 5px 17px !important;
        border-radius: 50px !important;
        font-size: 14px !important;
        font-weight: 600 !important;
        border: 1px solid #2a3370 !important;
        background-image: none !important;
        color: #5e6dac !important;
        background-color: transparent !important;

        &:hover {
          color: #ffffff !important;
          background-image: $gradient  !important;
        }
      }
    }
  }
}

// Follower Card End

// SideMenu start
.sidemenu_dropdown {
  text-align: center;
  margin-bottom: 0px;
  margin-top: 20px;
  position: relative;

  i {
    position: absolute;
    left: 30%;
    top: 50%;
    color: #d4d9fb9c;
    transform: translate(-50%, -50%);
  }

  .cust_dropdown {
    margin: 0 auto;
    border: 1px solid #424177;
    font-size: 14px;
    color: #d4d9fb9c;
    padding: 0;
    width: 0%;
    min-width: 160px;
    min-height: 40px;
  }
}

// SideMenu End

// Social Icon Start
.social-button {
  display: flex;
  text-transform: uppercase;
  align-items: center;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  font-weight: bold;
  font-size: 13px;
  padding: 10px;
  transition: 0.5s;

  &:hover {
    background-color: #fff;
    font-weight: bold;
  }

  .social-icon {
    width: 30px;

    img {
      width: 100%;
    }
  }

  p {
    width: 100%;
    margin: 0;
    color: white;
  }
}

.twitter {
  background-color: #55aced;
  font-weight: bold;
  text-decoration: none;
  border-radius: 6px;
  font-size: 14px;

  &:hover {
    p {
      color: #55aced;
    }
  }
}

.google {
  background-color: white;
  font-weight: bold;
  text-decoration: none;
  border-radius: 6px;
  font-size: 13px;

  p {
    color: black;
  }

  &:hover {
    p {
      color: #e34134;
    }
  }
}

.facebook {
  background-color: #1b76f2;
  font-weight: bold;
  font-size: 13px;
  border-radius: 6px;

  &:hover {
    p {
      color: #1b76f2;
    }
  }
}

// Social Icon End

// Admin-Table Structure Start
.table_section {
  margin: 30px 0 30px 0;

  .table {
    color: $textwhite;

    tbody {
      tr {
        background-color: #353471;

        td {
          border: 0px;
        }
      }
    }
  }
}

// Admin-Table Structure ENd

// Chart Section Start
.chart_section {
  margin: 35px 0 0 0 !important;
  padding: 40px 20px 0px 20px !important;
}

.chart_custsec {
  .char_dropdown {
    .MuiInputBase-root {
      display: flex;
      align-items: center;
      width: 150px;
      padding: 10px 10px 10px 10px;

      .MuiSelect-select {
        padding: 0 !important;
      }
    }
  }
}

// Chart Section End
.chart_custsec {
  .apexcharts-tooltip.apexcharts-theme-light {
    border: 1px solid #25294a !important;
    background: rgba(37, 41, 74, 0.9) !important;
  }

  .apexcharts-tooltip.apexcharts-theme-light .apexcharts-tooltip-title {
    background: #25294a !important;
    border-bottom: 1px solid #ddd !important;
  }
}

.my-portfolio {
  position: relative;
  margin: auto;
  max-width: 1100px;
  margin-top: 0;
}

.myportfolio_section {
  position: relative;

  .topInvestorContainer {
    overflow: hidden;
    overflow-y: scroll;
    @include scrollbar;
  }
}

.spline_chart {
  margin: 0 0 0 0;
  position: relative;

  .chart_cards {
    position: relative;
    background-color: #3d447a;
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 5px 5px 10px 0px rgba(32, 36, 66, 0.5);

    .chart_component {
      display: block;
      margin-top: -7px;
    }

    .chart_label {
      position: relative;
      padding: 80px 20px 20px 20px;
      text-align: center;
      width: 100%;

      .chart_name {
        display: block;
        color: #ffffff;
        font-size: 12px;
        font-weight: 100;
        margin: 10px 0 4px 0;
      }

      .chart_subname {
        margin: 0 auto;
        text-align: center;

        &.positive {
          color: #3df5c1;
        }

        &.negative {
          color: #fd7667;
        }
      }

      .chart_amount {
        color: #ffc600;
        margin-top: 20px;
        font-weight: 700;
        letter-spacing: 0.03em;
        line-height: 24px;
        font-size: 26px;
        // font-family: $secondary-font;
      }
    }
  }
}

.myplayers_table {
  position: relative;
  margin: 0 0 50px 0;
}


// ===== Main Section SCSS End =====

// ===== Table Section One Start =====
.table_section_one.MuiPaper-elevation1 {
  box-shadow: none;
  background-color: #25294a;

  .tables {
    border-collapse: separate;
    border-spacing: 0 15px;

    thead {
      tr {
        background: #343a6a;
        border-radius: 5px;

        th {
          color: #ffffff;
          font-weight: 500 !important;
          line-height: 20px;
          font-size: 18px;
          border-bottom: none;
          padding: 20px 20px 20px 20px;

          // font-family: $primary-font;
          &:first-child {
            text-align: left;
            border-top-left-radius: 5px;
            border-bottom-left-radius: 5px;
          }

          &:last-child {
            border-top-right-radius: 5px;
            border-bottom-right-radius: 5px;
          }
        }
      }
    }

    tbody {
      tr {
        background-color: #353471;
        margin: 10px 0px;

        .tables_images {
          display: flex;
          align-items: center;
        }

        .playerImage {
          position: relative;

          .MuiAvatar-root {
            width: 60px;
            height: 60px;

            img {
              width: 60px;
              height: 60px;
              object-fit: cover;
            }
          }

          // .gameDetail{
          //     background: linear-gradient(-45deg, #f4307f 0%, #ff7c6d 100%);
          //     border-radius: 8px;
          //     color: #ffffff;
          //     position: absolute;
          //     line-height: 17px;
          //     font-weight: 600;
          //     padding: 0px 8px;
          //     text-align: center;
          //     top: 31px;
          // }
          &::before {
            content: "";
            position: absolute;
            width: 65px;
            height: 65px;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            //background-image: linear-gradient(#ff7c6e, #f62b7b);
            border-radius: 50%;
          }
        }

        .player-details {
          margin-left: 10px;
          margin-top: 0;

          span {
            display: block;
          }

          .playerName {
            color: #ffffff;
            font-weight: bold;
            line-height: 18px;
            font-size: 18px;
          }

          .playerTeamDetails,
          .playerSubtitle {
            color: #909092;
            font-size: $subtitle-fontsize;
            // font-family: $secondary-font;
            text-align: left;
          }

          .playerBoost {
            font-weight: 400;
            font-size: $subtitle-fontsize;
            color: #7180E3;
          }
        }

        td {
          color: #ffffff;
          border-bottom: none;
          font-weight: bold;
          font-size: 16px;
          letter-spacing: 0.03em;
          // font-family: "Rubik", sans-serif;
          padding: 13px 20px 13px 20px;

          &:first-child {
            border-top-left-radius: 5px;
            border-bottom-left-radius: 5px;
          }

          &:last-child {
            border-top-right-radius: 5px;
            border-bottom-right-radius: 5px;
          }
        }

        .table_price {
          p {
            margin: 0 0 0 0;
            color: #18d29c;
            font-size: 16px;
            letter-spacing: 0.03em;
          }
        }

        .greenTxt {
          color: #18d29c;
          font-weight: bold;
        }

        .redTxt {
          color: #ff7700 !important;
          font-weight: bold;
        }

        .primaryCta {
          Button {
            border-radius: 18px;
            background-color: rgba(128, 55, 255, 0);
            border: 1px solid rgba(190, 174, 255, 0.3);
            color: #ffffff;
            text-transform: uppercase;
            font-weight: 700;
            font-size: 14px;
            // font-family: "Josefin Sans", sans-serif;
            padding-top: 0px !important;
            padding: 5px 16px 0 16px;
            line-height: 14px;
            height: 35px;
            margin-right: 5px;
            display: flex;
            align-items: center;
            min-width: 70px;
            justify-content: center;
            cursor: pointer;
          }
        }
      }
    }
  }
}

.buttons-watch-list {
  display: inline-flex;
  align-items: center;
  justify-content: flex-end;
  grid-column-gap: 20px;
  column-gap: 10px;
}

// ===== Table Section One End =====

// ====== Empty list placeholder Start ======
.empty-list-placeholder {
  color: white;
  background-color: #353471;
  text-align: center;
  padding: 20px;
  width: 100%;
  border-radius: 5px;
  box-sizing: border-box;

  h3 {
    margin-bottom: 15px;
    font-weight: 500;
    font-family: 'Plus Jakarta Display';
    color: #222222;
    font-size: 15px;
    text-transform: uppercase;
  }
}

.emptylist_buttons {
  width: 90%;
  max-width: 350px;
  margin: 20px auto 0;
}

// ====== Empty list placeholder End ======

.MuiFormControl-root {
  border-radius: 7px !important;
}

// ====== Main-Table Concept Start
.assets-table {
  .flex-table {
    display: inline-flex;
    align-items: center;
  }

  .available-soon,
  .subscribed {
    text-transform: none;
    font-weight: normal;
    margin-bottom: 0;
    cursor: initial;
    text-shadow: 2px 2px 10px #00000077;
    margin-left: 8px;

    @media (max-width: 600px) {
      font-size: 10px;
    }
  }

  .button-flex {
    display: inline-flex;
    align-items: center;
    justify-content: flex-end;
    column-gap: 20px;
    padding-right: 0px;

    .button {
      width: 90%;
      margin: auto;
      cursor: pointer;
    }
  }

  .table_section_one.MuiPaper-elevation1 {
    box-shadow: none;
    background-color: #25294a;

    .tables {
      border-collapse: separate;
      border-spacing: 0 15px;
      background: #26294a;

      thead {
        tr {
          background: #343a6a;
          border-radius: 5px;

          th {
            color: #ffffff;
            font-weight: 500 !important;
            line-height: 20px;
            font-size: 18px;
            // font-family: $primary-font;
            padding: 20px 20px 20px 20px;
            border-bottom: none;

            &:first-child {
              text-align: left;
              border-top-left-radius: 5px;
              border-bottom-left-radius: 5px;
            }

            &:last-child {
              border-top-right-radius: 5px;
              border-bottom-right-radius: 5px;
            }
          }
        }
      }

      tbody {
        tr {
          background-color: #353471;
          margin: 10px 0px;

          .playerImage {
            position: relative;

            .MuiAvatar-root {
              width: 60px;
              height: 60px;

              img {
                width: 60px;
                height: 60px;
                object-fit: cover;
              }
            }

            // .gameDetail {
            //     background: linear-gradient(-45deg, #f4307f 0%, #ff7c6d 100%);
            //     border-radius: 8px;
            //     color: #ffffff;
            //     position: absolute;
            //     font-size: $extra-small-font;
            //     line-height: 17px;
            //     font-weight: 600;
            //     padding: 0px 8px;
            //     text-align: center;
            //     top: 31px;
            // }
            &::before {
              content: "";
              position: absolute;
              width: 65px;
              height: 65px;
              left: 50%;
              top: 50%;
              transform: translate(-50%, -50%);
              //background-image: linear-gradient(#ff7c6e, #f62b7b);
              border-radius: 50%;
            }
          }

          .player-details {
            margin-left: 20px;
            margin-top: 0;

            span {
              display: block;
            }

            .asset-league{
              display: inline;
            }

            .playerName {
              color: #ffffff;
              font-weight: bold;
              line-height: 18px;
              font-size: 18px;
            }

            .playerTeamDetails {
              color: #ffc600;
              font-weight: 400;
              font-size: 12px;
              // font-family: $secondary-font;
            }
          }

          .price_stock {
            position: relative;
            display: flex;
            flex-wrap: wrap;

            .ps_price {
              width: 100%;
              display: block;
              color: #ffffff;
              font-weight: 700;
              line-height: 18px;
              font-size: 18px;
              margin: 0 0 7px 0;
            }

            .ps_stock {
              text-shadow: 2px 2px 15px #000000bb;
              width: 100%;
              display: block;
              margin: 0 0 0 0;
              color: #3df5c1;
              font-size: 10px;
              letter-spacing: 0.03em;

              @media(min-width:$min-medium-device) {
                font-size: 12px;
              }
            }
          }

          td {
            color: #ffffff;
            border-bottom: none;
            font-weight: bold;
            font-size: 16px;
            letter-spacing: 0.03em;
            // font-family: "Rubik", sans-serif;
            padding: 13px 20px 13px 20px;

            @media (max-width: 1500px) {
              padding: 13px 15px;
            }


            &:first-child {
              border-top-left-radius: 5px;
              border-bottom-left-radius: 5px;
            }

            tbody {
              tr:hover {
                background: linear-gradient(to right, #912c6b -30%, transparent 120%) !important;
              }

              tr {
                background-color: #353471;
                margin: 10px 0px;

                .playerImage {
                  position: relative;

                  .MuiAvatar-root {
                    width: 60px;
                    height: 60px;

                    img {
                      width: 60px;
                      height: 60px;
                      object-fit: cover;
                    }
                  }

                  // .gameDetail {
                  //     background: linear-gradient(-45deg, #f4307f 0%, #ff7c6d 100%);
                  //     border-radius: 8px;
                  //     color: #ffffff;
                  //     position: absolute;
                  //     font-size: $extra-small-font;
                  //     line-height: 17px;
                  //     font-weight: 600;
                  //     padding: 0px 8px;
                  //     text-align: center;
                  //     top: 31px;
                  // }
                  &::before {
                    content: "";
                    position: absolute;
                    width: 65px;
                    height: 65px;
                    left: 50%;
                    top: 50%;
                    transform: translate(-50%, -50%);
                    //background-image: linear-gradient(#ff5500, #d8b92a);
                    //background-image: linear-gradient(#ff7c6e, #f62b7b);
                    border-radius: 50%;
                  }
                }

                .player-details {
                  margin-left: 20px;
                  margin-top: 0;

                  span {
                    display: block;
                  }

                  .playerName {
                    color: #ffffff;
                    font-weight: 600;
                    line-height: 18px;
                    font-size: 18px;
                  }

                  .playerTeamDetails {
                    color: #ffc600;
                    font-weight: 400;
                    font-size: 12px;
                    // font-family: $secondary-font;
                  }
                }

                .price_stock {
                  position: relative;
                  display: flex;
                  flex-wrap: wrap;

                  .ps_price {
                    width: 100%;
                    display: block;
                    color: #ffffff;
                    font-weight: 700;
                    line-height: 18px;
                    font-size: 18px;
                    margin: 0 0 7px 0;
                  }

                  .ps_stock {
                    text-shadow: 2px 2px 15px #000000bb;
                    width: 100%;
                    display: block;
                    margin: 0 0 0 0;
                    color: #3df5c1;
                    font-size: 14px;
                    letter-spacing: 0.03em;
                  }
                }

                td {
                  color: #ffffff;
                  border-bottom: none;
                  font-weight: bold;
                  font-size: 16px;
                  letter-spacing: 0.03em;
                  // font-family: "Rubik", sans-serif;
                  padding: 13px 20px 13px 20px;

                  @media (max-width: 1500px) {
                    padding: 13px 15px;
                  }

                  &:nth-child(2) {
                    // font-family: "Open Sans", sans-serif;
                    color: #3df5c1;
                  }

                  &:first-child {
                    border-top-left-radius: 5px;
                    border-bottom-left-radius: 5px;
                  }

                  &:last-child {
                    border-top-right-radius: 5px;
                    border-bottom-right-radius: 5px;
                  }
                }

                .table_price {
                  p {
                    margin: 0 0 0 0;
                    color: #3df5c1;
                    font-size: 16px;
                    letter-spacing: 0.03em;
                  }
                }

                .greenTxt {
                  color: #18d29c;
                  font-weight: bold;
                  text-align: center;
                }

                .primaryCta {
                  Button {
                    border-radius: 18px;
                    background-color: rgba(128, 55, 255, 0);
                    border: 1px solid rgba(190, 174, 255, 0.3);
                    color: #ffffff;
                    text-transform: uppercase;
                    font-weight: 700;
                    font-size: 14px;
                    font-family: "Josefin Sans", sans-serif;
                    padding: 5px 16px 0 16px;
                    line-height: 14px;
                    height: 35px;
                    margin-right: 5px;
                    display: flex;
                    align-items: center;
                    min-width: 70px;
                    justify-content: center;
                  }

                  &:last-child {
                    border-top-right-radius: 5px;
                    border-bottom-right-radius: 5px;
                  }
                }
              }
            }
          }

          .table_price {
            p {
              margin: 0 0 0 0;
              color: #3df5c1;
              font-size: 16px;
              letter-spacing: 0.03em;
            }
          }

          .greenTxt {
            color: #18d29c;
            font-weight: bold;
          }

          .primaryCta {
            Button {
              border-radius: 18px;
              background-color: rgba(128, 55, 255, 0);
              border: 1px solid rgba(190, 174, 255, 0.3);
              color: #ffffff;
              text-transform: uppercase;
              font-weight: 700;
              font-size: 14px;
              // font-family: "Josefin Sans", sans-serif;
              padding: 5px 16px 0 16px;
              line-height: 14px;
              height: 35px;
              margin-right: 5px;
              display: flex;
              align-items: center;
              min-width: 70px;
              justify-content: center;
            }
          }

          .asset-icon-loader-or-timer-wrapper {
            @include display(flex);
            @include justify-content(flex-end);
            @include align-items(center);

            @media (max-width: 600px) {
              justify-content: flex-end;
            }

            .icon {
              height: $notification_icon_size;
              width: $notification_icon_size;
              margin-left: 8px;
              transition: all 0.5s ease;

              @media (max-width: 600px) {
                height: $notification_icon_size - 16;
                width: $notification_icon_size - 16;
              }

              @media (min-width: $min-small-device) {
                height: $notification_icon_size - 18;
                width: $notification_icon_size - 18;
              }

              &:hover {
                cursor: pointer;
                transform: scale(1.1);
              }
            }

            .icon.disabled {
              color: $medium-green;
              opacity: 0.6;

              &:hover {
                @include animation("ring 5s 3");
              }
            }

            .icon.enabled {
              // color: rgba(252, 196, 7, .7);
              color: $medium-green;
            }
          }
        }
      }
    }
  }
}

// ====== Main-Table Concept End

// ====== Round TableCust Start
.round_table {
  .table_section_one {
    &.MuiPaper-elevation1 {
      .tables {
        tbody {
          tr {
            td {
              &:first-child {
                border-top-left-radius: 100px;
                border-bottom-left-radius: 100px ;
                padding-left: 30px;
              }

              &:last-child {
                border-top-right-radius: 100px;
                border-bottom-right-radius: 100px;
                padding-right: 10px;
              }
            }
          }
        }
      }
    }
  }
}

// ====== Round TableCust End

// ===== Scroll With Table Start
.table_cust_scroll {
  /*max-height: 530px;*/
  overflow-y: auto;
  padding: 0 5px 0 0;

  &::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    border-radius: 10px;
    background-color: #d4d9fb00;
  }

  &::-webkit-scrollbar {
    width: 9px;
    background-color: #d4d9fb00;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: #d4d9fb7e;
  }
}

// ===== Scroll With Table End

// ===== Affiliate Page Start =====

.referral-copy-link {
  border-radius: 100px;
  background-color: #5a4bcc;
  padding: 20px 20px 20px 20px;
  text-align: center;
  display: inline-block;
  margin: -90px 0px 20px 0px;
  z-index: 0;
  top: -15px;
  position: relative;
  width: 80%;

  .referral-link-txt {
    border-radius: 100px;
    background-color: #4c3eb9;
    border: 1px solid #7b6fd6;
    padding: 5px 5px 5px 5px;
    color: #ffffff;
    display: flex;
    align-items: center;
    position: relative;

    .copy-link-img {
      width: 45px;
      height: 45px;
      text-align: center;
      line-height: 48px;
      font-size: 19px;
      border-radius: 50%;
      background-color: #5351fb;
      margin: 0 15px 0 0;
    }

    .cust_linktext {
      h3 {
        margin: 0 0 0 0;
        font-size: 18px;
        color: white;

        // font-family: $secondary-font;
        span {
          margin: 0px 0 0 15px;
          font-size: 16px;
          color: #00cca2;
          display: inline-block;
        }
      }
    }
  }

  button {
    background: linear-gradient(90deg, #fe7967 0%, #f62b7b 100%);
    border: none;
    width: auto;
    color: #fff;
    // font-family: $secondary-font;
    text-transform: uppercase;
    transition: 0.2s;
    border-radius: 40px;
    padding-right: 30px;
    padding-left: 30px;
    line-height: 35px;
    font-size: 16px;
    transition: 0.2s all ease;
    font-weight: 600;
    position: absolute;
    box-shadow: none;
    right: 5px;

    &:hover {
      transition: 0.2s all ease;
      background: linear-gradient(180deg, #fe7967 0%, #f62b7b 100%);
    }
  }
}

// ===== Affiliate Page Start =====

// ===== Rules Section Start =====

.rules_titlesec {
  position: relative;
  border-radius: 5px 5px 0px 0px;
  overflow: hidden;

  img {
    width: 100%;
  }

  h3 {
    position: absolute;
    top: 20%;
    left: 50%;
    transform: translate(-50%, -50%);
    margin: 0 0 0 0;
    color: #202442;
    font-weight: 500;
    font-size: 34px;
    letter-spacing: 0.05em;
  }
}

.rules_tabs {
  margin: 0 0 60px 0;
  background-color: #202442;
  border-radius: 0px 0px 5px 5px;
  overflow: hidden;
  padding: 25px 0 15px 0;

  .MuiTabs-scroller {
    overflow: hidden;
    text-align: center;
    justify-content: center;
    display: flex;
    width: 100%;
  }

  .MuiTabs-indicator {
    display: none;
  }

  .MuiButtonBase-root {
    .MuiTab-wrapper {
      width: 100%;
      display: inline-flex;
      align-items: center;
      flex-direction: column;
      justify-content: center;

      font-size: 16px;
      font-weight: 600;
    }

    &.Mui-selected {
      .MuiTab-wrapper {
        color: #f5317f;
      }
    }
  }
}

.tabpanel_section {
  position: relative;
  padding: 0px 70px;

  .MuiBox-root {
    padding: 0;
  }
}

// ===== Rules Section End =====

.close_background {
  position: fixed;
  background-color: transparent;
  width: 100%;
  height: 100vh;
  top: 0;
  right: 0;
  display: none;

  &.active {
    display: block;
  }
}

.center-text {
  text-align: center;
}

.user-perks-panel {
  .no-perks-container {
    .no-perks {
      p {
        margin: 0;
      }
    }
  }
}

.become_vip_text {
  position: relative;
  min-height: calc(100vh - 300px);
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  color: #ffffff;
  display: flex;
  flex-direction: column;
}

.become_vip_text p {
  text-align: center;
  padding: 0 30px;
  font-weight: bold;
}

.emptylist_buttons {
  .primary-button {
    font-size: 18px;
    padding: 15px 0 15px 0px;
    font-weight: 500;
    letter-spacing: 0.02em;
  }
}

.container-text-success {
  .message-text-success {
    margin: 0;
  }
}

.container-init-payment .container-bouton-shares .btn-init-payment {
  margin: 0;
}

// Dark/Light Switch Start
.dark_light_switch {
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 30px 0 0 0;

  .button-cover {
    height: 100px;
    margin: 20px;
    background-color: #fff;
    box-shadow: 0 10px 20px -8px #c5d6d6;
    border-radius: 4px;

    &:before {
      counter-increment: button-counter;
      content: counter(button-counter);
      position: absolute;
      right: 0;
      bottom: 0;
      color: #d7e3e3;
      font-size: 12px;
      line-height: 1;
      padding: 5px;
    }

    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }

  .knobs,
  .layer {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }

  .button {
    position: relative;
    top: 50%;
    width: 100px;
    height: 40px;
    overflow: hidden;
    box-shadow: 0px 7px 20px 0px rgba(0, 0, 0, 0.2);

    &.r {
      border-radius: 100px;

      .layer {
        border-radius: 100px;
      }
    }

    &.b2 {
      border-radius: 2px;
    }
  }

  .checkbox {
    position: relative;
    width: 100%;
    height: 100%;
    padding: 0;
    margin: 0;
    opacity: 0;
    cursor: pointer;
    z-index: 3;
  }

  .knobs {
    z-index: 2;
  }

  .layer {
    width: 100%;
    background-color: #ffffff;
    transition: 0.3s ease all;
    z-index: 1;
    border-radius: 10px;
  }

  #button-11 {
    overflow: visible;

    .knobs {
      perspective: 70px;

      &:before,
      &:after,
      span {
        position: absolute;
        top: 5px;
        border-radius: 2px;
      }

      &:before,
      &:after {
        width: 20px;
        height: 10px;
        color: #4e4e4e;
        font-size: 10px;
        font-weight: bold;
        text-align: center;
        line-height: 1;
        padding: 9px 4px;
      }

      &:before {
        content: "Dark";
        left: 7px;
        top: 3px;
        font-size: 14px;
        font-weight: 500;
        letter-spacing: 0.01em;
      }

      &:after {
        content: "Light";
        right: 27px;
        top: 3px;
        font-size: 14px;
        font-weight: 500;
        letter-spacing: 0.01em;
      }

      span {
        right: 4px;
        width: 45px;
        height: 30px;
        background-color: #202442;
        border-radius: 8px;
        transform: rotateY(0);
        transform-origin: 0% 50%;
        transition: 0.6s ease all;
        z-index: 1;
      }
    }

    .checkbox:checked {
      +.knobs span {
        transform: rotateY(-180deg);
        background-image: linear-gradient(125deg, #7180E3 20%, #9371ef 80%);
      }

      ~.layer {
        background-color: rgb(255, 255, 255) !important;
      }
    }
  }
}

// Dark/Light Switch End

.container-init-payment .container-bouton-shares .btn-init-payment {
  background: linear-gradient(90deg, #fe7967 0%, #f62b7b 100%);
  border: none;
  padding: 10px;
  padding-left: 15px;
  padding-right: 15px;
  border-radius: 10px;
  width: 100%;
  color: #fff;
  text-transform: uppercase;
}

.perk-obtained-image-asset {
  border-radius: 100px;
  width: 90px;
  height: 90px;
  background-size: cover;
  margin: auto;
  margin-bottom: 10px;
  background-position: center center;
}

.invert-color {
  filter: invert(1);
}

.link-social-network {
  img {
    width: 40px;
  }
}

.link-container {
  display: flex;
  justify-content: space-between;
  margin-top: 25px;

}

.content-modal {
  z-index: 1500 !important;
}