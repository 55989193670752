.bottom-tab-bar {
    a {
        height: 50%;
    }

    .extrafield {
        font-size: 10px;
        display: flex;
        color: #5C76E4;
        align-items: center;
        height: 10px;
        margin-top: 2px;

        svg {
            filter: invert(40%) sepia(89%) saturate(1352%) hue-rotate(212deg) brightness(94%) contrast(90%);
            transform: scale(0.7);
            width: 20px;
        }
    }
}