@import "../../style/variable";
@import "style/size.scss";
@import 'style/colors.scss';
@import 'style/font.scss';

.affiliate-overview-page {
  margin-top: 70px;
  padding: 0px 10px 100px;

  @media (min-width: $min-extra-large-device) {
    padding-bottom: 0px;
  }



  .affiliate-top-section {
    padding: 10px;
    border-radius: 18px;
    background: linear-gradient(343deg, #DDE2FF80, #F7F8FF);

    .affiliate-banner {
      display: block;
      width: 100%;
      border-radius: 16px;
      margin: 0px auto 20px;
    }

    .affiliate-share-section {
      border-radius: 11px;
      padding: 13px;
      filter: drop-shadow(0px 3px 6px #15182B1A);
      backdrop-filter: blur(35px) opacity(0.75);

      .affiliation_coupon {
        .general-terms {
          margin-top: 20px;

          .cgu-label {
            color: $tertiary-grey;
            @include fontWeightSize(medium, 13px);
          }
        }
      }
    }
  }

  .knowMoreButtonContainer {
    margin: 5% auto 7%;
    width: 43%;

    .primary-button {
      font-weight: 800;
      font-size: 15px;
      padding: 3px;
      border-radius: 25px;
    }
  }

  .referral-section {
    @media (min-width: $min-medium-device) {
      display: flex;
    }

    .referral-card {
      color: $tertiary-grey;
      border-radius: 11px;
      padding: 24px;
      margin-bottom: 2%;
      width: 100%;
      display: flex;
      align-items: center;

      &:not(:last-child) {
        @media (min-width: $min-medium-device) {
          margin-right: 2%;
        }
      }

      &.blue {
        background: linear-gradient(284deg, rgba(113, 128, 227, 0.23), rgba(177, 184, 232, 0.23));
      }

      &.orange {
        background: linear-gradient(180deg, rgba(252, 199, 60, 0.23), rgba(240, 158, 54, 0.23));

        svg path {
          fill: #E2AD37;
        }
      }

      .referral-card-icon {
        margin-right: 20px;

        svg {
          width: 45px;
          height: 45px;
        }
      }

      .referral-card-info {
        h4 {
          color: $tertiary-grey;
          opacity: 0.65;
          @include fontWeightSize(medium, 14px);
          margin: 0;
        }

        span {
          color: $tertiary-grey;
          font-size: 26px;
          font-weight: 800;
        }
      }
    }
  }

  .referral-friend-section {
    margin-top: 35px;
    margin-bottom: 10px;

    .referral-friend-title {
      color: $tertiary-grey;
      font-size: 24px;
      font-weight: 600;
      margin-bottom: 5%;
    }

    .friend-card {
      color: $tertiary-grey;
      border-radius: 12px;
      background: $white;
      filter: drop-shadow(0px 3px 6px #15182B12);
      display: flex;
      align-items: center;
      padding: 18px 12px;
      margin-bottom: 6px;

      .friend-avatar {
        border: 3px solid #FFD66780;
        border-radius: 50%;
        height: 40px;
        width: 40px;
        margin-right: 20px;
      }

      .friend-details {
        display: flex;
        justify-content: space-between;
        width: 100%;
        align-items: center;

        .friend-name {
          font-weight: 600;
          margin: 0;
          width: 40%;

          @media (min-width: $min-large-mobile) {
            width: auto;
          }
        }

        .friend-amount-block {
          font-size: 9px;
          font-weight: 400;
          text-align: right;
          text-transform: uppercase;
        }
      }
    }
  }

  .container-affiliate {
    width: 100%;
    margin: auto;
  }


  .MuiPaper-rounded {
    border-radius: 15px;
  }

  .MuiPaper-root {
    background: transparent;
    box-shadow: none;
  }
}

.container-fluid {
  max-width: 1100px;
  width: 100%;

  .row {
    .col-lg-12 {
      padding: 0;
      margin: 0;
    }
  }
}