@import 'style/colors.scss';
@import 'style/font.scss';
@import '../../../../assets/scss/components/mixins';

$keyboard-height: 350px;

#mainbody:has(.buy-asset-slider) {
    overflow-y: hidden;
}

.buy-asset-slider {
    @include plus-jakarta-font;
    @include scrollbar;
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    background: $app-background;
    z-index: 20;
    padding: 37px 16px 0px 16px;
    overflow-y: auto;
    overflow-x: hidden;
    color: $tertiary-grey;
    padding-top: 60px;

    &.is-iframe-landing {
        padding: 15px 15px 15px 15px;
    }

    @media (max-height:767px) {
        padding: 10px 16px 0;
    }

    .buy-asset-wrapper {
        position: relative;
        min-height: calc(100vh - 45px);
        padding-bottom: 70px;

        &.buy-asset-wrapper_ios {
            min-height: calc(100vh - 115px);
        }

        .all-token-information-container {
            background: none;
            padding: 0;
            margin: 0;
            min-height: auto;
            justify-content: center;
            display: flex;

            .info {
                display: flex;
                justify-content: center;

                &:not(:last-child)::after {
                    content: none
                }

                .icon {
                    grid-area: span 2/ span 1;
                }

                .label {
                    margin: 0 auto;
                }

                &.token-price .icon {
                    height: 28px;
                    width: 22px;
                    justify-self: end;
                }

                .value {
                    margin: 0 auto;
                }

                &.token-available {
                    .icon svg {
                        height: 34px;
                        width: 31px;
                    }
                }
            }
        }
    }

    .arrow-back {
        color: black;
        position: absolute;
        top: 20px;
        left: 0px;
        cursor: pointer;
        z-index: 1;

        svg {
            height: 48px;
            width: 36px;
        }
    }

    .container-button-continue {
        text-align: center;
        position: relative;

        .prePrivateSale-warning {
            position: absolute;
            margin: 5px auto 0px;
            left: 0;
            right: 0;
            background: linear-gradient(90deg, #ed213a, #93291e);
            background-clip: text;
            -webkit-text-fill-color: transparent;
        }
    }

    .buy-asset-continue-button {
        width: 100%;
        margin-top: 20px;
        @include fontWeightSize(extra-bold, 15px);
        border-radius: 11px;

        &.green-btn {
            background: $medium-green;

            &:disabled {
                background: $disabled-medium-green;
                cursor: not-allowed;
            }
        }

        &.red-btn {
            background: $medium-red;

            &:disabled {
                background: $disabled-medium-red;
                cursor: not-allowed;
            }
        }
    }

    .keyboard-section {
        max-height: $keyboard-height;
        position: fixed;
        width: 100%;
        left: 0;
        right: 0;
        bottom: 1vh;
        background: $bg-virtual-keyboard;
        box-shadow: 0px 5px 30px #15182B59;
        padding: 16px;

        &.hide-bg {
            background-color: transparent;
            box-shadow: none;
        }
    }

    &.slide-out,
    &.slide-in {
        position: absolute;
    }

    &.stable {
        @media (min-width:1200px) {
            left: 325px;
            width: 650px;
            border-left: 1.5px solid #33395A25;
            border-right: 1.5px solid #33395A25;
        }

        @media (min-width:1300px) {
            left: calc((100vw - 650px)/2)
        }
    }
}