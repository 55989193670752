@import url('https://fonts.cdnfonts.com/css/radwave-demo');
@import 'style/size.scss';
@import 'style/colors.scss';

.tournament-league-card {
    font-family: 'Plus Jakarta Display';
    background-size: 50%;
    background-repeat: no-repeat;
    padding: 5px 0 0 0;
    color: white;
    text-align: center;
    border-radius: 15px;
    margin-top: 15px;

    .league-access-container {
        margin-top: -25px;

        .title-container {
            margin-top: 25px;
        }
    }

    .tournament-information {
        padding: 10px;
        border-radius: 15px;
        position: relative;
        height: 135px;
        box-shadow: 0 14px 28px #00000026, 0 10px 10px #00000000;

        .league-name-background {
            position: absolute;
            top: 50%;
            background-color: #ffffff29;
            background-size: 100%;
            background-repeat: repeat;
            -webkit-background-clip: text;
            background-clip: text;
            -webkit-text-fill-color: transparent;
            -moz-text-fill-color: transparent;
            font-size: 62pt;
            font-family: 'Radwave Demo', sans-serif;
            left: 50%;
            transform: translate(-50%, -50%);

            &::before {
                content: attr(data-content);
                font-family: 'Radwave Demo', sans-serif;
            }
        }

        .leagueIcon {
            margin: 2px auto 15px;

            div {
                height: 35px;
                width: 35px;
                margin: 0px auto;

                svg {
                    height: 100%;
                    width: 100%;
                }
            }
        }

        .title {
            font-family: 'Radwave Demo', sans-serif;
            -webkit-text-stroke: 1px white;
            font-size: 33px;
            color: transparent;
        }

    }

    &:first-child {
        margin-top: -20px;
    }

    .tournament-league-card-sub {
        font-family: 'Plus Jakarta Display';
        border-radius: 15px;
        background-color: #EBECF6;
        text-align: center;
        line-height: 12px;
        padding: 8px 0;
        border-bottom-right-radius: 0;
        border-bottom-left-radius: 0;
        margin-top: 10px;

        p {
            line-height: 12px;
            margin: 10px;
            padding: 0;
        }

        .annual-rewards {
            &:last-child {
                opacity: 0.6;
                color: $steel-grey;
            }
        }
    }

    .purple {
        font-weight: 500 !important;
        opacity: 0.9 !important;
        color: #A460C1 !important;
    }

    .vertical-center {
        display: flex;
        align-items: center;
        justify-content: center;
    }
}