@import "style/size.scss";
@import "style/colors.scss";

    .wallet-side-pane {
        background: rgba(21, 24, 43, 0.75);
        backdrop-filter: blur(30px);
        width: 500px !important;

        .wallet-container {
            padding: 20px 25px;

            .close-wallet {
                top: 10px;
            }
        }
    }

    .customize-menu{
        display: flex;
        gap: 7px;
        
        .button-menu{
            background: $bg-light-violet;
            border-radius: 9px;
            color: $primary-button-color;
        }
    }