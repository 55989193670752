.panel {
    background-color: #2a295b;
    border-radius: 10px;
    margin: 0 0 20px 0;
    border: 1px solid transparent;
    -webkit-transition: 0.3s all ease;
    transition: 0.3s all ease;
  
    &:hover {
      border: 1px solid #444293;
    }
  
    &:last-child {
      margin-bottom: 0;
    }
  }
  
  .panel__label {
    position: relative;
    display: block;
    width: 100%;
    background: none;
    border: none;
    text-align: left;
    padding: 20px 60px 20px 85px;
    transition: color 0.2s linear;
    font-size: 22px;
    line-height: 1.5;
    color: #dbe0fa;
    font-weight: 600;
    font-family: "Open Sans", sans-serif;
    display: flex;
    align-items: center;
    cursor: pointer;
  
    img {
      padding-right: 20px;
    }
  
    &:focus {
      outline: none;
    }
  
    &:before {
      content: "";
      position: absolute;
      right: 25px;
      top: 30px;
      background-image: url(/img/accordion-arrow.svg);
      width: 17px;
      height: 17px;
      transform: rotate(180deg);
      transition: all 0.5s;
      background-repeat: no-repeat;
    }
    &:after{
        content: "";
        position: absolute;
        top: 15px;
        left: 30px;
        background-image: url(/img/faq-icon.png);
        width: 40px;
        height: 40px;
        background-repeat: no-repeat;
        background-size: contain;
    }

  }

  .panel[aria-expanded="true"] {
    .panel__content {
      opacity: 1;
    }
  
    .panel__label {
      color: #dbe0fa;
  
      &:before {
        transform: rotate(270deg);
      }
    }
  }
  
  .panel__inner {
    overflow: hidden;
    will-change: height;
    transition: height 0.4s cubic-bezier(0.65, 0.05, 0.36, 1);
  
    p {
      padding: 20px;
      border-top: 1px solid #444293;
      color: #dbe0fa;
      font-size: 18px;
      font-weight: 300;
      line-height: 26px;
      margin: 0;
    }
  }
  
  .panel__content {
    margin: 5px 25px 25px;
    font-size: 14px;
    opacity: 0;
    transition: opacity 0.3s linear 0.18s;
  }