@import "style/colors.scss";
@import "style/font.scss";

.token-yield {
    &.icon-as-banner {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        border-radius: 11px;
        padding: 10px;
        background-color: $yield-icon-bg;
        color: $fade-violet;

        .icon {
            height: 33px;
            width: 33px;

            svg {
                height: 100%;
                width: 100%;

                path {
                    fill: $fade-violet;
                }
            }
        }

        .value {
            @include fontWeightSize(bold, 16px);
        }
    }
}