@import 'style/colors.scss';
@import 'style/size.scss';
@import 'style/font.scss';


.asset-sell-wrapper {
    @include plus-jakarta-font;
    position: absolute;
    bottom: 10px;
    left: 0;
    width: calc(100% - 20px);
    margin: 0px 10px 0px 10px;

    @media (min-width: $desktop-min) {
        transform: translateX(-50%);
        left: 50%;
        width: 620px;
        margin: 0px;
    }

    .asset-market-sell {
        cursor: pointer;
        display: flex;
        justify-content: space-between;
        padding: 28px 25px;
        border-radius: 14px;
        background-color: $app-background;

        .asset-market-sell-info-wrapper {
            text-align: left;
            display: flex;

            .avatar {
                position: relative;
                min-width: 56px;
                width: 70px;
                height: 70px;
                border-radius: 10px;
                border: solid 3px transparent;

                @media (max-width: $mobile) {
                    width: 60px;
                    height: 60px;
                }

                .avatar-bg,
                .avatar-icon,
                .overlay-filter {
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    border-radius: 10px;
                }

                .avatar-bg {
                    object-fit: cover;
                    object-position: top left;
                }

                .avatar-icon {
                    object-fit: cover;
                    object-position: top;
                }

                .overlay-filter {
                    background: $assetsTableRow-overlay-bgColor;
                }
            }

            .asset-market-sell-info {
                margin-left: 15px;

                span {
                    text-transform: uppercase;
                    @include fontWeightSize(medium, 13px);
                    color: $tertiary-violet ;
                }

                .title {
                    @include fontWeightSize(bold, 15px);
                    margin-top: 5px;
                    color: $tertiary-grey;
                }

                .info {
                    margin-top: 5px;
                    font-size: 13px;
                    color: #595B6A;
                }

                .price-wrapper {
                    .title {
                        @include fontWeightSize(medium, 13px);
                        color: $light-grey;
                    }

                    .price {
                        .current-value {
                            color: $tertiary-grey;
                            @include fontWeightSize(bold, 20px)
                        }
                    }

                    .price-deviation {
                        @include fontWeightSize(bold, 14px);
                        margin-left: 5px;
                        color: $fade-violet;
                    }
                }
            }
        }

        .asset-market-sell-button {
            height: 40px;

            .button-icon {
                width: 40px;
            }
        }
    }

    .asset-liquidity-pool-sell {
        cursor: pointer;
        display: flex;
        margin-top: 10px;
        padding: 28px 25px;
        border-radius: 14px;
        text-align: left;
        justify-content: space-between;
        background-color: $app-background;

        .asset-liquidity-pool-info {
            .title {
                @include fontWeightSize(bold, 15px);
                margin-top: 5px;
                color: $tertiary-grey;

                .instant-sell-icon {
                    margin-left: 5px;

                    img {
                        width: 15px;
                        height: 15px;
                    }
                }
            }

            .info {
                margin-top: 5px;
                font-size: 13px;
                color: #595B6A;
            }

            .price-wrapper {
                .title {
                    @include fontWeightSize(medium, 13px);
                    color: $light-grey;
                }

                .price {
                    color: $tertiary-grey;
                    @include fontWeightSize(bold, 20px)
                }
            }
        }

        .asset-liquidity-pool-sell-button {
            cursor: pointer;
            height: 40px;

            .button-icon {
                width: 40px;
            }
        }
    }

    .asset-liquidity-sell-disable, .asset-market-sell-disable {
        margin-top: 10px;
        padding: 15px;
        border-radius: 14px;
        text-align: center;
        background-color: #EAEAED;

        .title {
            @include fontWeightSize(bold, 15px);
            margin-top: 5px;
            color: #858793;
        }

        .info {
            margin-top: 10px;
            color: #858793;

            .instant-sell-icon {
                margin-left: 5px;

                img {
                    width: 15px;
                    height: 15px;
                }
            }
        }
    }
}