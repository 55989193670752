.back-arrow-modal {
    color: black;
    position: absolute;
    top: 10px;
    left: 10px;
    cursor: pointer;
    z-index: 1;

    svg {
        height: 48px;
        width: 36px;
    }
}