.txn-buy-asset-header {

    .pack-vip-container {
        margin-bottom: 8px;
        width: 100%;
    }
    .transaction-buy-asset-header_title {
        display: flex;
        justify-content: space-between;
        position: relative;
        margin-bottom: 8px;

        .drop-down-menu {
            align-self: center;
            flex-basis: 200px;
            padding: 8px 10px 8px 12px;
            position: static;


            .drop-down-menu_label {
                font-weight: bold;
            }

            .menu {
                margin: 0px;
                position: absolute;
                left: auto;
                right: 0;
                top: 100%;
                width: 100%;
                height: fit-content;
            }
        }
    }
}
