@mixin plus-jakarta-bold-font {
    @include plus-jakarta-font;
    font-weight: bold;
}

@mixin plus-jakarta-medium-font {
    @include plus-jakarta-font;
    font-weight: 500;
}
@mixin plus-jakarta-font {
    font-family: 'Plus Jakarta Sans', sans-serif;
}

$font-weights: (
    "light": 100,
    "regular": 400, 
    "medium": 500, 
    "bold": 700,
    "extra-bold": 800,
    "ultra-bold": 900,
);

@mixin fontWeightSize($weight, $size: -1){
    font-weight: map-get($font-weights, $weight);
    @if $size != -1 {
        font-size: $size;
    }
}

// Font reset base classes
.MuiDialog-root,
.MuiAlert-root
{
    @include plus-jakarta-font;
}