// ==========================
// ========================== Light Demo Start ========================== //
// ==========================
@import '../../style/colors.scss';
@import '../../style/size.scss';
@import '../../style/font.scss';

.light_demo {
    background-color: #ffffff;

    .effect_round {
        width: 450px;
        height: 450px;
        background-color: rgba(255, 255, 255, 0.05);
        border-radius: 50%;
        z-index: 0;
        box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.05);
        position: relative;
        display: block;

        &::before {
            content: '';
            position: absolute;
            background-color: rgba(255, 255, 255, 0.05);
            width: 320px;
            height: 320px;
            border-radius: 50%;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.05);
        }

        &::after {
            content: '';
            position: absolute;
            background-color: rgba(255, 255, 255, 0.05);
            width: 170px;
            height: 170px;
            border-radius: 50%;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.05);
        }
    }

    // Round Effect End
    .app-navbar-notch {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        z-index: 99;
        height: env(safe-area-inset-top);
        background: white;
    }

    .sidemenu_section {
        padding-top: constant(safe-area-inset-top);
        /* iOS 11.0 */
        padding-top: env(safe-area-inset-top);
        /* > iOS 11.0 */
        background-repeat: no-repeat;
        background-size: contain;
        background-position: top center;
        box-shadow: 5px 0px 15px 0px rgba(0, 0, 0, 0.15);

        @media (min-width: $min-extra-large-device) {
                box-shadow: none;
            }
        .effect_round {
            position: absolute;
            top: -180px;
            left: -170px;
        }
    }

    // Side Menu Start
    .sidemenu_links_sec {
        .sidemenu_widget {

            a {
                svg {
                    height: 20px;
                    width: 20px;

                    path {
                        fill: $tertiary-grey
                    }
                }

                &.active {
                    color: $menu-active-color;
                    background: $menu-active-background;

                    .tournament_svg {
                        path {
                            fill: $menu-active-color;
                            stroke: $menu-active-color;
                        }
                    }

                    svg path {
                        fill: $menu-active-color
                    }

                    &:before {
                        background-color: $menu-active-background;
                    }
                }

                &:hover {
                    background: $menu-active-background;
                    color: $menu-active-color;

                    svg path {
                        fill: $menu-active-color
                    }

                    &:before {
                        background-color: $menu-active-background;
                    }
                }
            }
        }

        .sidemenu_trading {
            a {
                color: #d7d8ff;
                background-image: linear-gradient(to right, rgba(255, 255, 255, 0.1) -20%, transparent 80%);

                .linkside_logo {
                    span {
                        color: #d7d8ff;
                    }
                }

                &:hover {
                    background-image: linear-gradient(to right, rgba(255, 255, 255, 0.3) -30%, transparent 85%);
                    color: #ffffff;

                    &:before {
                        background-color: #ffffff;
                    }

                    .linkside_logo {
                        span {
                            color: #ffffff;
                        }
                    }
                }

                &.active {
                    background-image: linear-gradient(to right, rgba(255, 255, 255, 0.3) -30%, transparent 85%);
                    color: #ffffff;

                    &:before {
                        background-color: #ffffff;
                    }

                    .linkside_logo {
                        span {
                            color: #ffffff;
                        }
                    }
                }
            }
        }
    }

    .sidemenu_dropdown {
        .cust_dropdown {
            border: 1px solid #ffffff;
            background-color: #ffffff;
            font-size: 16px;
            color: #353471;
        }

        i {
            left: 32%;
            color: #353471;
            font-size: 16px;
        }
    }

    // Side Menu End

    // Main Section Start
    .main_section {
        position: relative;
        background-repeat: no-repeat;
        background-size: contain;
        background-position: top center;
        background-attachment: fixed;
        right: 0;
        /*&:before {
            content: "";
            position: absolute;
            background-image: url('../../assets/images/main_background.png');
            background-repeat: no-repeat;
            background-size: auto;
            background-position: top right;
            width: 100%;
            height: 270px;
            filter: grayscale(100%);
            opacity: 0.5;
            right: 0;
            z-index: -5;
        }
        &::after {
            content: '';
            position: absolute;
            background-image: url('../../assets/images/main_background2.png');
            background-repeat: no-repeat;
            background-size: 100%;
            width: 630px;
            height: 240px;
            opacity: 0.5;
            right: 0;
            bottom: 0px;
            transform: inherit;
            z-index: -5;
            filter: blur(4px);
        }*/
    }

    .section_title {
        h3 {
            color: #909092;
        }
    }

    .spline_chart {
        .chart_cards {
            border-radius: 15px;
            background-color: #ffffff;
            overflow: hidden;
            position: relative;
            box-shadow: 0px 5px 20px 0px rgba(32, 36, 66, 0.1);

            .chart_label {
                .chart_name {
                    color: #909092;
                }

                .chart_amount {
                    color: #222222;
                    letter-spacing: 0.01em;
                    font-size: 26px;
                    line-height: 23px;
                }
            }

            &:hover {
                &::before {
                    transition: 0.3s all ease;
                    background-position: 90px -20px;
                }
            }
        }
    }

    .atitle_sec {
        h3 {
            color: #909092;
        }
    }

    // Main Section End

    // Header Section Start
    .header_sec {
        .user_header {
            .header_links {
                .portfolio_links {
                    color: #343a6a;
                    padding: 0;

                    .percentage-number-wallet {
                        color: #18d29c;
                        font-size: 16px;
                    }
                }

                &.profil_icon {
                    margin: 0;

                    &.a_icon {
                        svg {
                            fill: #25294a;
                        }
                    }
                }
            }
        }

        .header_links {
            p {
                color: #222222;
                padding: 0;
                border-radius: 40px;
                font-size: 18px;
                display: flex;
            }
        }
    }

    // Header Section End

    // SidePlayer Section Start
    .sideplayer_section {
        .sideplayer_fix {
            background-color: transparent;
            background: transparent;
            overflow: visible;
        }
    }

    .cust_section {
        background-color: #ffffff;
        box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.07);
        transition: 0.3s all ease;

        &:hover {
            transition: 0.3s all ease;
            box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.15);
        }

        .cards_info {
            .cards_titles {
                .cards_name {
                    h4 {
                        color: #222222;
                    }
                }
            }

            .follow_cards {
                border-bottom: 1px solid #dddddd;
            }

            .follow_price {
                border-top: 1px solid #dddddd;
                border-bottom: 0;

                .f_price {
                    h4 {
                        color: #18d29c;
                    }

                    &.pri {
                        h4 {
                            color: #5f4fda;
                        }

                        border-left: 1px solid #dddddd;
                    }

                    p {
                        margin: 0;
                        color: #777777;
                    }
                }
            }

            .cards_imgs {
                background-image: url('../images/trendex_plaquettes.png');
            }
        }

        .profil_section {
            .profile_img {
                &::before {
                    background-image: url('../../assets/images/user_back_new.png');
                    width: 97px;
                    height: 97px;
                }

                &::after {
                    content: '';
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    background-color: rgba(255, 255, 255, 0.65);
                    border-radius: 50%;
                    width: 110px;
                    height: 110px;
                    z-index: -2;
                }
            }
        }
    }

    // SidePlayer Section End

    // Footer Start
    .footer_section {
        .footer_nav {
            ul {
                li {
                    .f_links {
                        color: #222222 !important;
                        font-weight: 500;

                        a {
                            color: #222222 !important;
                            font-weight: 500;

                            &:hover {
                                color: #7180e3 !important;
                            }
                        }

                        &:hover {
                            color: #7180e3 !important;
                        }
                    }
                }
            }

            .android_app {
                .app_links {
                    img {
                        filter: brightness(0.5);

                        &:hover {
                            filter: brightness(1);
                        }
                    }
                }
            }
        }
    }

    .copyrights {
        h4 {
            @include fontWeightSize(medium, 14px);
            color: $footer-text-color;
        }

        .copyrights_social {
            .icon-socials {
                display: inline-flex;

                a {
                    line-height: 0;
                    padding: 10px;
                    color: $become-vip-text-color;

                    &:hover {
                        color: $primary-button-color
                    }

                    svg {
                        font-size: 30px;
                    }
                }
            }
        }
    }

    // Footer End

    // Table Design Start
    .assets-table {
        .table_section_one {
            &.MuiPaper-elevation1 {
                background-color: #ffffff;

                .tables {
                    background-color: #ffffff;
                    padding: 0 0;
                    // box-shadow: 0px 3px 5px 0px rgba(0, 0, 0, 0.1);

                    tbody {
                        tr {
                            background-color: #ffffff;
                            margin: 10px 0px;
                            border-radius: 15px;

                            .price_stock {
                                .ps_stock {
                                    text-shadow: none;
                                    color: #53D081;
                                    margin: 4px 0px 4px 0px;
                                }

                                .ps_price {
                                    color: #222222;
                                }
                            }

                            .player-details {
                                .playerName {
                                    color: #222222;
                                }
                            }

                            td {
                                &.td-tdxAcademy {
                                    padding-left: 0;
                                    padding-right: 0;
                                }
                            }
                        }
                    }
                }
            }

            .subClamp {
                height: 15px;
            }
        }

        .table_section_one.MuiPaper-elevation1 .tables tbody tr .player-details .playerTeamDetails,
        .table_section_one.MuiPaper-elevation1 .tables tbody tr .player-details .playerSubtitle {
            color: #15182B;
        }
    }

    // Table Design End

    // Myplayers Table Start
    .empty-list-placeholder {
        color: #222;
        background-color: #ffffff;
        box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.1);
        padding: 20px;
        width: calc(100% - 20px);
        border-radius: 15px;
        overflow: hidden;
        position: relative;
        margin: 0 auto;
        z-index: 0;

        h3 {
            margin: 0;
        }

        .effect_round {
            background-image: linear-gradient(125deg, #7180e3 20%, #9371ef 80%);
            position: absolute;
            z-index: -1;
            top: 40px;
            left: -140px;
            opacity: 0.7;
            filter: blur(3px);
            width: 330px;
            height: 330px;
            box-shadow: 0px 0px 35px 0px rgba(101, 84, 231, 0.4);

            &:before {
                width: 220px;
                height: 220px;
            }

            &:after {
                width: 110px;
                height: 110px;
            }
        }
    }

    .myplayers_table {
        .table_section_one {
            &.MuiPaper-elevation1 {
                padding: 0 10px;
                background-color: white;
            }
        }
    }

    .table_section_one {
        &.MuiPaper-elevation1 {
            background-color: #ffffff;
            // padding: 0 10px;

            .tables {
                border-collapse: separate;
                border-spacing: 0 15px;
                background-color: white;

                thead {
                    tr {
                        background: #ffffff;
                        border-radius: 5px;
                        margin: 10px 0px;
                        box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.1);
                        border-radius: 15px;

                        th {
                            color: #909092;

                            &:first-child {
                                border-top-left-radius: 15px;
                                border-bottom-left-radius: 15px;
                            }

                            &:last-child {
                                border-top-right-radius: 15px;
                                border-bottom-right-radius: 15px;
                            }
                        }
                    }
                }

                tbody {
                    tr {
                        background: #ffffff;
                        border-radius: 5px;
                        margin: 10px 0px;
                        border-radius: 15px;

                        td {
                            &:first-child {
                                border-top-left-radius: 15px;
                                border-bottom-left-radius: 15px;
                            }

                            &:last-child {
                                border-top-right-radius: 15px;
                                border-bottom-right-radius: 15px;
                                width: 30%;

                                @media (max-width: $mobile) {
                                    width: 40%;
                                }
                            }
                        }

                        td:nth-child(2) {

                            @media (max-width: $mobile) {
                                max-width: 134px;
                            }
                        }
                    }
                }
            }
        }
    }

    .table_section_one.MuiPaper-elevation1 .tables tbody tr {
        background-color: #ffffff;
        margin: 10px 0px;
    }

    .table_section_one {
        .small_timer {
            .small_title-countdown {
                color: #909092;
                font-weight: 600;
                text-shadow: none;
                margin-left: 0;
                font-size: 16px;
                margin-bottom: 5px;
            }
        }
    }

    .small_timer {
        .small_timer-container {
            color: #00b984;

            .small_time {
                .small_time__number {
                    font-size: 16px;
                    margin-right: 4px;
                    font-weight: 600;
                    color: #00b984;
                }

                .small_time__text {
                    color: #000000;
                }
            }
        }
    }

    .assets-table {

        .available-soon,
        .subscribed {
            text-shadow: none;
            margin-left: 8px;
            font-weight: 600;
        }
    }

    .assets-table .table_section_one.MuiPaper-elevation1 .tables tbody tr .asset-icon-loader-or-timer-wrapper .icon.disabled {
        color: $medium-green;
        opacity: 0.6;
    }

    .round_table {
        .MuiTabs-flexContainer {
            .MuiButtonBase-root {
                opacity: 1;

                .MuiTab-wrapper {
                    color: #15182b;
                    opacity: 0.5;
                    border-radius: 20px;
                    min-height: 60px;
                    transition: 0.5s;
                    font-weight: 600;
                    margin-right: 1.43em;
                }

                &.Mui-selected {
                    .MuiTab-wrapper {
                        color: $violet;
                        opacity: 1;
                        font-weight: 600;
                    }
                }
            }
        }
    }

    // Myplayers Table End

    // Search Panel Start
    .assets-category-page {
        .search-players {
            background-color: #eeeeee;
        }
    }

    .search-bar {
        .input-search {
            background-color: #ffffff;
            color: #222222;
        }

        .search-container {
            .search-button-container {
                .primary-button {
                    background: #7180e3;
                }
            }
        }
    }

    // Search panel End

    // Button Start
    /*.primary-button {
        background: linear-gradient(125deg, #7180E3 20%, #9371ef 80%);
        &:hover {
            transition: 0.2s all ease;
            background: linear-gradient(190deg, #7180E3 20%, #9371ef 80%);
        }
    }*/
    // Button End

    // Side Titel Start
    .side_title {
        position: relative;
        background: linear-gradient(125deg, #7180e3 20%, #9371ef 80%);
        overflow: hidden;

        &::before {
            content: '';
            position: absolute;
            left: -50px;
            top: -100px;
            width: 150px;
            height: 150px;
            background-color: rgba(255, 255, 255, 0.2);
            border-radius: 50%;
            z-index: 0;
        }
    }

    // Side Titel End

    .user-perks-panel {
        .no-perks-container {
            .no-perks {
                border: 2px dashed #dddddd;
                background: #ffffff;

                p {
                    margin: 0;
                    color: #909092;
                    font-weight: 500;
                    line-height: 30px;
                }
            }
        }
    }

    // About Card Start
    .about_card {
        background-color: #ffffff;
        box-shadow: 0px 20px 20px 0px rgba(32, 36, 66, 0.1);

        .card_follower {
            .follower_section {
                svg {
                    fill: #212540;
                }

                .cardfollo_sec {
                    h4 {
                        color: #222222;
                    }

                    p {
                        color: #777777;
                    }
                }
            }
        }

        .main_profile {
            .profileimg_info {
                .profile_img {
                    span {
                        background: linear-gradient(125deg, rgba(101, 84, 231, 1) 20%, rgba(147, 113, 239, 1) 80%);
                        background-color: #ffffff;
                        border: 5px solid #ffffff;
                    }

                    &::before {
                        background-image: url('../../assets/images/user_back_new.png');
                        width: 145px;
                        height: 145px;
                    }

                    &::after {
                        content: '';
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        transform: translate(-50%, -50%);
                        background-color: rgba(255, 255, 255, 0.65);
                        border-radius: 50%;
                        width: 160px;
                        height: 160px;
                        z-index: -2;
                    }
                }
            }
        }
    }

    .atab_section {
        .tab_header {
            box-shadow: 0px 15px 20px 0px rgba(32, 36, 66, 0.05);
            background-color: #ffffff;
            border-top: 1px solid #dddddd;

            .MuiButtonBase-root {
                .MuiTab-wrapper {
                    color: #212540;
                }
            }

            .MuiTabs-scroller {
                margin-bottom: 0px;
            }
        }

        .about-overview {}
    }

    .custinput_group {
        .form_label {
            color: #868895;
            font-weight: 500;
        }

        .MuiFormControl-root {
            width: 100%;
            background: rgba(255, 255, 255, 1) !important;
        }
    }

    .cust_dropdown {
        background-color: #ffffff;
        color: #222222 !important;
        border: 1px solid #c4c4c4;
    }

    .MuiFormControl-root {

        label,
        #outlined-basic-label {
            color: #222;
        }
    }

    .MuiInputBase-formControl,
    .MuiInputBase-formControl input {
        border: 0 !important;
        border-radius: 7px !important;
        background: white;
    }

    .PrivateTabIndicator-colorSecondary-17 {
        background: linear-gradient(125deg, rgba(101, 84, 231, 1) 20%, rgba(147, 113, 239, 1) 80%);
    }

    .model_btns {
        .MuiButtonBase-root {
            background-image: linear-gradient(125deg, rgba(101, 84, 231, 1) 20%, rgba(147, 113, 239, 1) 80%) !important;

            &:hover {
                transition: 0.2s all ease;
                background-image: linear-gradient(190deg, #7180e3 20%, #9371ef 80%) !important;
            }
        }
    }

    .about-page {
        .chart-overview {
            background: #ffffff;
            box-shadow: 0px 5px 20px 0px rgba(32, 36, 66, 0.1);
            margin-top: 20px;
        }

        .sidebar-income {
            .sub_heading {
                color: #777777;
                font-size: 16px;
            }
        }

        .chart-overview {
            .return-investment {
                span {
                    &.sub_heading {
                        color: #777777;
                        font-size: 16px;
                    }
                }
            }
        }

        .chart_custsec {
            border-right: 1px solid #dddddd;

            .card-tab {
                p {
                    color: #222222;
                }
            }
        }
    }

    // About Card End

    // Contact Us Start
    .contactus {
        .contact_form {
            background-color: #ffffff;
            box-shadow: 0px 5px 30px 0px rgba(32, 36, 66, 0.1);

            @media (max-width: 1100px) {
                margin-bottom: 75px;
            }

            .contact_title {
                h3 {
                    color: #909092;
                }
            }

            .form_section {
                .form-group {
                    .form-label {
                        color: #555555;
                    }

                    .form-control {
                        border: 1px solid #dddddd;
                        background-color: #ffffff;
                        color: #222222;
                    }
                }
            }
        }

        .title_sec {
            h3 {
                color: #909092;
            }

            h5 {
                color: #6f5be5;
                font-size: 16px;
                font-weight: 600;
            }
        }

        .contact_faqtitle {
            h5 {
                color: #909092;
            }
        }

        .contact_text {
            .context_sec {
                h4 {
                    color: #555555;
                }

                p {
                    font-weight: 400;
                    color: #555555;
                }
            }
        }
    }

    .custom_btn {
        background-image: linear-gradient(145deg, #6a58e4 25%, #9371ef 50%) !important;
        opacity: 1 !important;
        cursor: pointer;
    }

    .custom_btn_purple {
        background-image: $gradient-button-primary !important;
    }

    // Contact Us End

    // Rules Page Start
    .main_innerWrapper {
        background: transparent;
    }

    .rules_titlesec {
        height: 300px;
        overflow: hidden;
        border-radius: 15px;

        img {
            width: 100%;
            height: 300px;
            object-fit: cover;
        }
    }

    .faq-section {
        .category-item {
            h2 {
                color: #909092;
            }
        }
    }

    .dropdown-section {
        .panel {
            &:hover {
                background-color: #eeeeee;
            }

            .panel__label {
                color: #222222;
            }
        }
    }

    .accordion-section {
        .panel {
            background: linear-gradient(125deg, #7180e3 20%, #9371ef 80%);
            transition: 0.3s all ease;
            border: 0;
            border-radius: 20px;

            .panel__label {
                box-shadow: 0px 5px 15px 0px rgba(32, 36, 66, 0.1);
                border-radius: 15px;
                transition: 0.3s all ease;
                color: #555555;
                border-radius: 15px;
                background-color: #ffffff;

                &:after {
                    filter: invert(1);
                }

                &:before {
                    filter: invert(1);
                }
            }

            .panel__inner {
                p {
                    border: 0;
                    color: #ffffff;
                }
            }

            &:hover {
                transition: 0.3s all ease;
                border: 0;

                .panel__label {
                    transition: 0.3s all ease;
                    box-shadow: 0px 5px 15px 0px rgba(32, 36, 66, 0.2);
                }
            }
        }
    }

    // Rules Page Start


    // Asset-profil page Start
    .asset-profil-page {
        .full-container {
            .asset-profil-name {
                .visitor-counter {
                    background: #f2f2f4;
                }
            }
        }

        .asset-profil-header-share {
            display: none;
        }

        .asset-profil-header {
            padding: 0px 8px 8px 8px;

            .profil-banner {
                .about_card {
                    .asset-banner-footer {
                        .price-container {
                            .price-case {
                                .price {
                                    color: #909092;

                                    &.total-earnings-up {
                                        color: #18d29c;
                                    }
                                }
                            }
                        }
                    }

                    .social-container {
                        box-shadow: 0px 10px 20px 0px rgba(32, 36, 66, 0.1);
                        background-color: #ffffff;
                    }

                    .asset-banner-footer {
                        .description-container {
                            p {
                                color: #222222;
                            }
                        }
                    }
                }
            }
        }

        .graph-container {
            margin: 40px 0 0 0;
            border-radius: 20px;
            overflow: hidden;
            position: relative;
        }

        .assetprofil_con {
            .tokens-remaining {
                position: relative;
                height: 100%;
                background-color: #ffffff;
                border-radius: 25px;
                display: flex;
                overflow: hidden;
                align-items: center;
                padding: 50px 0;
                margin: 0 30px 0px 0;
                background: linear-gradient(125deg, rgba(101, 84, 231, 1) 20%, rgba(147, 113, 239, 1) 80%);
                box-shadow: 0px 10px 20px 0px rgba(32, 36, 66, 0.1);

                .effect_round {
                    position: absolute;
                    top: -230px;
                    left: -230px;
                }

                .only-tokens {
                    width: 80%;
                    position: relative;
                    padding: 30px 20px;
                    text-align: center;
                    border: 2px dashed #ffffff;
                    box-shadow: 0px 10px 50px 0px rgba(32, 36, 66, 0.45);
                    border-radius: 50px;
                    margin: 0 auto;

                    h4 {
                        &.title-asset {
                            color: #18d29c;
                            font-size: 24px;
                            font-weight: 600;
                            margin: 0 0 7px 0;
                        }
                    }

                    h2 {
                        &.title-asset {
                            color: #18d29c;
                            font-size: 25px;
                            margin: 0 0 10px 0;
                        }
                    }

                    h4 {
                        color: #ffffff;
                        font-weight: 500;
                    }
                }
            }
        }

        .container-init-payment {
            .container-bouton-shares {
                background: #fbfbfc;
                // box-shadow: 0px 5px 20px 0px rgba(32, 36, 66, 0.15);
                text-align: center;
                box-sizing: border-box;
                margin: auto;
                margin-top: -10px;

                .btn-init-payment {
                    &.buy-tokens {
                        border-radius: 10px;
                        padding: 10px 10px;
                        font-size: 16px;
                        letter-spacing: 0.04em;
                        font-weight: 500;
                        background: linear-gradient(125deg, #2feeb8 20%, #18d29c 80%);
                        color: white;
                        border-radius: 55px;
                        width: 120px;
                    }
                }

                .secondary-btn-init-payment {
                    border: 1px solid #424177;
                    padding: 10px;
                    padding-left: 20px;
                    padding-right: 20px;
                    border-radius: 10px;
                    color: #909092;
                    font-weight: 500;

                    &:hover {
                        background: linear-gradient(125deg, #7180e3 20%, #9371ef 80%);
                        border: 1px solid #7180e3;
                        padding: 10px;
                        padding-left: 20px;
                        padding-right: 20px;
                        border-radius: 10px;
                        color: #ffffff;
                        font-weight: 500;
                    }
                }

                .btn-init-payment {
                    &.activated {
                        background: linear-gradient(125deg, #7180e3 20%, #9371ef 80%);
                        border: 1px solid #7180e3;
                        padding: 10px;
                        padding-left: 20px;
                        padding-right: 20px;
                        border-radius: 10px;
                        color: #ffffff;
                        font-weight: 500;
                    }
                }
            }
        }
    }

    .countdown-ico-not-open {
        .title-countdown {
            color: #222222;
            font-size: 18px;
        }

        .timer-container {
            .chart {
                border: none;
                background: transparent;

                .time {
                    color: #222222;
                    font-size: 14px;
                }
            }
        }
    }

    // Asset-profil page End

    // Assets-Page payment Model Start

    .MuiDialog-paperFullWidth {
        .body-modal {
            padding: 15px;
        }
    }

    // .MuiDialog-paperFullWidth {
    //     margin: 15px;
    //     background: white !important;
    //     border-radius: 30px;
    //     width: calc(100% - 25px);
    //     .body-modal {
    //         box-sizing: border-box;
    //         background: #ffffff;
    //         width: 100%;
    //         height: 100%;
    //         color: #222222;
    //         padding: 20px;
    //         .pricing-page {
    //             background: #ffffff;
    //             .title-modal-payment {
    //                 color: #222222;
    //             }
    //             .currency-input {
    //                 color: #000;
    //                 font-size: 18px;
    //             }
    //             #outilned-basic {
    //                 width: 35%;
    //                 border-radius: 20px;
    //                 border: 1px solid #dddddd;
    //                 background: #3c3f9b;
    //                 padding: 5px;
    //                 font-size: 18px;
    //                 margin: 6px;
    //             }
    //             .result-tokens.asset-name {
    //                 margin-top: 0;
    //                 color: #18d29c;
    //                 font-size: 14px;
    //             }
    //             .result-tokens {
    //                 color: #18d29c;
    //             }
    //             .text-result-tokens {
    //                 border: 2px dashed;
    //                 background-color: white;
    //                 color: #7061e7;
    //                 box-shadow: 0px 5px 25px 0px rgba(0, 0, 0, 0.2);
    //             }
    //             .accordion-payment {
    //                 .MuiPaper-root {
    //                     &.MuiAccordion-root {
    //                         border-radius: 15px;
    //                         background: linear-gradient(125deg, #7180E3 20%, #9371ef 80%);
    //                         border: 0;
    //                     }
    //                 }
    //             }
    //         }
    //     }
    // }
    // Assets-Page payment Model End

    // Asset-profil Chart Start
    .apexcharts-canvas {
        &.apexchartsareaxdatetime {
            &.apexcharts-theme-light {
                svg {
                    background-color: #ffffff;
                }
            }
        }
    }

    // Asset-profil Chart End

    // Login Page Start
    .separate_or {
        padding: 0px 0;
        margin: 25px 0;

        span {
            color: #ffffff;
            background-color: #d2c2e6;
            border-radius: 7px;

            &::before {
                left: 0;
                top: 50%;
                transform: inherit;
                background-color: white;
            }

            p {
                margin: 0;
                display: inline-block;
                padding: 5px 10px;
                background-color: #ffffff;
                color: #909092;
                z-index: 10;
                position: relative;
                border-radius: 30px;
            }
        }
    }

    .auth_page {

        .auth_structure {
            width: 100%;
            height: 100%;
            overflow-x: hidden;
            overflow-y: auto;
            background-color: #F7F8FF;
            backdrop-filter: blur(42px) opacity(0.85);

            /*&::before {
                content: '';
                background-color: #ffffff;
                background: linear-gradient(125deg, #7180E3 20%, #9371ef 80%);
                border-radius: 50%;
                left: -90px;
                top: -95px;
                width: 280px;
                height: 280px;
                position: absolute;
                z-index: 0;
                box-shadow: 0px 5px 20px 0px rgba(32, 36, 66, 0.1);
                opacity: 0.7;
                filter: blur(3px);
            }*/

            .auth-form {
                .loginregi_form {
                    .forgot-password {
                        color: $tertiary-violet;

                        a {
                            color: $register-page-black-txt-color;
                            font-size: 16px;
                            font-weight: 500;
                        }
                    }

                    .custinput_group {
                        .MuiInputBase-formControl {
                            &:hover {
                                border: 0 !important;
                            }
                        }
                    }

                    .custinput_group {

                        .MuiFormControl-root {
                            background: transparent !important;
                        }
                    }

                    .MuiInputBase-formControl {
                        background-color: transparent;
                    }

                    .MuiFormHelperText-root.Mui-error {
                        color: #f44336;
                        background-color: transparent;
                        text-shadow: 0px 0px 10px white;
                        @include fontWeightSize(medium, 12px);
                        display: inline-block;
                        bottom: -22px;
                        padding: 0 2px;
                        border-radius: 5px;
                    }
                }
            }
        }

        .custinput_group {
            .form_label {
                color: #222222;
                font-size: 16px;
                font-weight: 500;
            }
        }

        .logreg_title {
            h3 {
                color: #ffffff;
                text-shadow: 0 2px 20px rgba(0, 0, 0, 0.2);
            }
        }

        .loginsocial_buttons {
            justify-content: center;
            column-gap: 20px;

            .custom_btn {
                border: 0px !important;
                margin: 0 10px 0 10px;
                background-image: linear-gradient(-123deg, #ffffff 25%, #ffffff 50%) !important;

                span {
                    color: #222222;
                    font-size: 16px;
                    font-weight: 600;
                }

                &:hover {
                    span {
                        color: #ffffff;
                    }
                }
            }
        }
    }

    .loginsocial_buttons {
        .custom_btn {
            &:hover {
                background-position: inherit !important;
                background-image: linear-gradient(-123deg, #6a58e4 25%, #9371ef 50%) !important;
            }
        }
    }

    .custom_btn_discreet {
        background: transparent !important;
        border: 1.5px solid #8e8e93 !important;
        color: #8e8e93 !important;
    }

    // Login Page End

    .form-control:focus {
        color: #202442;
        outline: 0;
        box-shadow: none;
    }

    .custinput_group {
        input {
            color: #222222;
        }
    }

    // Model Page Start
    .custom_model {
        .close_button {
            top: -40px;
        }

        .modal-body {
            background-color: #ffffff;
            color: #222222;
        }

        .model_container {
            p {
                font-size: 16px;
                line-height: 22px;
                font-weight: 400;
            }
        }
    }

    // Model Page End

    // Dark/Light Switch Start
    .dark_light_switch {
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 20px 0 0 0;

        .button-cover {
            height: 100px;
            margin: 20px;
            background-color: #fff;
            box-shadow: 0 10px 20px -8px #c5d6d6;
            border-radius: 4px;

            &:before {
                counter-increment: button-counter;
                content: counter(button-counter);
                position: absolute;
                right: 0;
                bottom: 0;
                color: #d7e3e3;
                font-size: 12px;
                line-height: 1;
                padding: 5px;
            }

            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
        }

        .knobs,
        .layer {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
        }

        .button {
            position: relative;
            top: 50%;
            width: 100px;
            height: 40px;
            overflow: hidden;
            box-shadow: 0px 7px 20px 0px rgba(0, 0, 0, 0.2);

            &.r {
                border-radius: 100px;

                .layer {
                    border-radius: 100px;
                }
            }

            &.b2 {
                border-radius: 2px;
            }
        }

        .checkbox {
            position: relative;
            width: 100%;
            height: 100%;
            padding: 0;
            margin: 0;
            opacity: 0;
            cursor: pointer;
            z-index: 3;
        }

        .knobs {
            z-index: 2;
        }

        .layer {
            width: 100%;
            background-color: #ffffff;
            transition: 0.3s ease all;
            z-index: 1;
            border-radius: 10px;
        }

        #button-11 {
            overflow: visible;

            .knobs {
                perspective: 70px;

                &:before,
                &:after,
                span {
                    position: absolute;
                    top: 5px;
                    border-radius: 2px;
                }

                &:before,
                &:after {
                    width: 20px;
                    height: 10px;
                    color: #4e4e4e;
                    font-size: 10px;
                    font-weight: bold;
                    text-align: center;
                    line-height: 1;
                    padding: 9px 4px;
                }

                &:before {
                    content: 'Dark';
                    left: 7px;
                    top: 3px;
                    font-size: 14px;
                    font-weight: 500;
                    letter-spacing: 0.01em;
                }

                &:after {
                    content: 'Light';
                    right: 27px;
                    top: 3px;
                    font-size: 14px;
                    font-weight: 500;
                    letter-spacing: 0.01em;
                    color: #7180e3;
                }

                span {
                    right: 4px;
                    width: 45px;
                    height: 30px;
                    background-color: #202442;
                    border-radius: 8px;
                    transform: rotateY(0);
                    transform-origin: 0% 50%;
                    transition: 0.6s ease all;
                    z-index: 1;
                }
            }

            .checkbox:checked {
                +.knobs span {
                    transform: rotateY(-180deg);
                    background-image: linear-gradient(125deg, #7180e3 20%, #9371ef 80%);
                }

                ~.layer {
                    background-color: rgb(255, 255, 255) !important;
                }
            }
        }
    }

    // Dark/Light Switch End

    .asset-profil-page {
        .graph-container {
            margin: 40px 0 0 0;
            border-radius: 20px;
            box-shadow: 0px 5px 20px 0px rgba(0, 0, 0, 0.1);

            .buy-asset-history-graph {
                flex-basis: 96%;
                text-align: center;
                background: #ffffff;
                height: 100%;
                position: relative;
                border-radius: 5px;
                box-shadow: 0px 20px 20px 0px #000000;
            }
        }
    }

    // Chart-Hover Effect Start
    .apexcharts-tooltip {
        &.apexcharts-theme-light {
            border: 1px solid #eeeeee;
            background: #ffffff;
            color: #25294a;

            .apexcharts-tooltip-title {
                background: #ffffff;
                color: #25294a;
                border-bottom: 1px solid #dddddd;
            }
        }
    }

    // Chart-Hover Effect End

    // Bottom Bar Start
    .bottom-tab-bar {
        background-color: rgba(255, 255, 255, 0.7);
        box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.1);

        a {
            color: #5b5b5b;
            font-size: 18px;
            //border-top: 4px solid #ffffff;
            border-top: none;

            &.active {
                @include fontWeightSize(bold, 14px);
                color: #7180e3;
            }
        }
    }

    // Bottom Bar End

    .contact_pages_e {
        .helpPageTabs {
            .MuiTab-root {
                background-color: #eeeeee;
                box-shadow: 0px 0px 0px 0px #000000;
                border-radius: 0px;

                .MuiTab-wrapper {
                    color: #000000;
                }
            }
        }
    }

    .become_vip_text {
        color: #000000;
    }

    .cgu-checkbox {
        border: 1px solid #000000;
    }

    .cgu-checkbox:checked::after {
        content: '\2714';
        font-size: 16px;
        position: absolute;
        top: -3px;
        left: 3px;
        color: #202442;
    }

    .header_sec {
        .user_header {
            .header_links {
                a {
                    p {
                        color: white;
                    }
                }
            }
        }
    }

    .buy_credits_t {
        svg {
            font-size: 18px;
            margin: 0px 1px 0 0;
        }
    }

    .container-text-success {
        .title-text-success {
            color: #222222;
        }

        .message-text-success {
            color: #222222;
            margin: 0;
        }
    }

    .perk-obtained-container {
        .container-profil-asset {
            background: #ffffff;
            box-shadow: 0px 0px 20px 0px rgba(32, 36, 66, 0.1);
        }

        .information-container {
            background: #ffffff;
            border-radius: 20px;
            box-shadow: 0px 5px 20px 0px rgba(32, 36, 66, 0.1);

            .no-perks {
                color: #222222;
            }
        }
    }

    .atab_section {
        .tab_header {
            .PrivateTabIndicator-colorSecondary-9 {
                background-color: #9371ef;
                box-shadow: 0px -2px 10px 0px #9371ef;
            }
        }
    }

    .myplayers_table {
        .atab_section .tab_header {
            box-shadow: 0px 5px 20px 0px rgba(32, 36, 66, 0.1);
            background-color: #ffffff;
            border-top: 0;
            border-radius: 10px;
            margin: 0;
        }
    }

    .myplayers_table .table_section_one.MuiPaper-elevation1 .tables tbody tr td {
        padding: 13px 0px;

        @media screen and (max-width: 800px) {
            &:before {
                color: #222222 !important;
            }
        }
    }

    .table_section_one {
        &.MuiPaper-elevation1 {
            .tables {
                tbody {
                    tr {
                        .player-details {
                            .playerName {
                                color: #222222;
                            }
                        }

                        td {
                            color: #222222;
                            padding: 13px 0px;
                        }

                        .primaryCta {
                            Button {
                                background: linear-gradient(125deg, #7180e3 20%, #9371ef 80%);
                                color: #ffffff;
                            }
                        }
                    }
                }
            }
        }
    }

    // Buy credits page
    .container-buy-credits {
        background: #e8ecf2;
        color: #222222;

        input {
            color: #222222;
            background: white;
        }

        .container-section-credits {
            .value-credits {
                color: #222222;
                background: white;
            }

            .price {
                background: white;
                color: #222222;
            }
        }
    }

    //Private sell
    .overview-private-sell {
        .header-private-sell {
            color: #222222;
        }

        h5.no-margin {
            color: #222222;
        }

        .line {
            background: #222222 !important;
        }

        .monitoring-private-sell h5 {
            color: #222222;
        }
    }


    //Forgot-password
    .forgot_password {
        .forgot_password_form {
            background-color: transparent;

            .forgot_password_title {
                h3 {
                    color: #222222;
                }
            }

            .form_section {
                .form-group {
                    .form-control {
                        border: 1px solid #dddddd;
                        background-color: #ffffff;
                        color: #222222;
                    }

                    .form-label {
                        font-size: 16px;
                        font-weight: 500;
                        color: #222222;
                    }
                }
            }
        }
    }

    .admin-asset-form-container {
        label {
            color: black;
        }
    }

    .credits-text {
        color: #222;
    }

    //Reset-password
    >.reset_password {
        background-color: white;
        border-radius: 10px;
        border: 1px solid #dbe0fa;

        .reset_password_form {
            background-color: white;
            margin: 0;

            .reset_password_title>h3 {
                color: #222222 !important;
            }

            .form_section {
                .form-group {
                    .form-control {
                        border: 1px solid #dddddd;
                        background-color: #ffffff;
                        color: #222222;
                    }

                    .form-label {
                        font-size: 16px;
                        font-weight: 500;
                        color: #222222;
                    }
                }

                .condition-password {
                    background-color: white;
                    color: #222222;
                    text-align: center;
                }

                .password-icon-toggle {
                    color: #222222;
                }
            }
        }
    }

    .container-payment {
        background: linear-gradient(125deg, #2feeb8 20%, #18d29c 80%);

        &.red {
            background: linear-gradient(90deg, #fe7967 0%, #f62b7b 100%);
        }

        .title-init-payment {
            font-size: 18px;
        }

        .bg-init-payment {
            .bg-input-init-payment {
                border: 0;
                border-radius: 15px;
                position: relative;
                height: 45px;

                span {
                    line-height: 45px;
                    font-size: 20px;
                }

                color: white;

                button {
                    border-radius: 50px;
                    background: #18d29c;
                    color: #fff;
                    border: none;
                    width: 35px;
                    height: 35px;
                    text-align: center;
                    position: absolute;
                    top: 5px;
                    // ios fix
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    // ios fix end
                    &.minus {
                        left: 5px;
                        -ms-touch-action: manipulation;
                        -webkit-touch-action: manipulation;
                        touch-action: manipulation;
                    }

                    &.add {
                        right: 5px;
                        -ms-touch-action: manipulation;
                        -webkit-touch-action: manipulation;
                        touch-action: manipulation;
                    }

                    .red & {
                        background: #f62b7b;
                    }
                }

                input {
                    border-radius: 10px;
                    border: 1px solid #eeeeee;
                }
            }

            .container-invoice {
                margin-top: 40px;

                .container-first-total {
                    p {
                        font-size: 18px;
                    }
                }

                .privilege-container {
                    .privilege-container-content {
                        border-radius: 20px;
                        background-color: #ffffff;
                        box-shadow: 0px 5px 20px 0px rgba(32, 36, 66, 0.25);

                        .title-label-privilege {
                            color: #222222;
                        }

                        .subtitle-label-privilege {
                            color: #222222;
                        }

                        .MuiTable-root {
                            .privilege-row {
                                .accordion-resell {
                                    background: transparent;
                                    box-shadow: 0px 3px 10px 0px rgba(0, 0, 0, 0.1);
                                    border-radius: 10px;
                                    transition: 0.3s all ease;

                                    &:hover {
                                        transition: 0.3s all ease;
                                        box-shadow: 0px 3px 10px 0px rgba(0, 0, 0, 0.15);
                                    }
                                }

                                .asset-description {
                                    color: #777777;
                                }

                                .MuiAccordionSummary-root.Mui-expanded {
                                    background-color: #906fee;
                                    border-radius: 15px;

                                    .privilege-row-content {
                                        color: white;
                                    }
                                }
                            }

                            .privilege-row-enough {
                                .accordion-resell {
                                    background: transparent;
                                    box-shadow: 0px 3px 10px 0px rgba(0, 0, 0, 0.1);
                                    border-radius: 10px;
                                    transition: 0.3s all ease;

                                    &:hover {
                                        transition: 0.3s all ease;
                                        box-shadow: 0px 3px 10px 0px rgba(0, 0, 0, 0.15);
                                    }
                                }

                                .MuiAccordionSummary-content {
                                    .privilege-row-content {
                                        color: #18d29c;
                                    }
                                }

                                .asset-description {
                                    color: #777777;
                                }

                                .MuiAccordionSummary-root.Mui-expanded {
                                    background-color: #906fee;
                                    border-radius: 15px;

                                    .privilege-row-content {
                                        color: white;
                                    }
                                }
                            }
                        }
                    }
                }
            }

            .bg-btn-init-paymeny {
                button {
                    &.btn-init-payment {
                        &.change-method-payment {
                            color: #ffffff;
                            font-weight: 500;
                            font-size: 14px;
                        }
                    }
                }
            }
        }
    }

    .cta_primary {
        background: linear-gradient(125deg, #7180e3 20%, #9371ef 80%);
    }

    .instructions_detail_title {
        color: #222222;
    }

    .perk-obtained-container {
        .information-container {
            .privilege-row-enough {
                .accordion-resell {
                    .MuiButtonBase-root {
                        .MuiAccordionSummary-content {
                            .privilege-row-content {
                                .privilege-row-label {
                                    color: #7180e3;
                                }

                                .privilege-row-first-label {
                                    font-size: 18px;
                                    color: #3df5c1;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    .user-perks-panel {
        .perks-panel-title {
            color: #909092;
        }
    }
}