@import 'style/font.scss';
@import 'style/colors.scss';
@import "../../../assets//scss//components/mixins";

@mixin flex-with-alignCenter {
    @include display(flex);
    align-items: center;
}

.instant-liquidity-chip {
    color: $tertiary-grey;
    @include flex-with-alignCenter;
    justify-content: center;
    align-items: center;
    gap: 9px;

    .instant-liquidity__img {
        @include flex-with-alignCenter;

        img {
            height: 19px;
        }
    }


    .instant-liquidity__title {
        display: flex;
        align-items: center;
        @include fontWeightSize(medium, 12px);
        opacity: 0.53;
        margin: 0;
        text-align: center;

        .info-icon {
            margin-left: 4px;
            width: 16px;
            cursor: pointer;
        }
    }

    .launch-mode-lp {
        color: #935d00;
        font-weight: bold;
    }

    .instant-liquidity__gridView {
        img {
            width: 20px;
            height: 20px;
        }

    }
}