@import 'style/size.scss';

.flex {
    display: flex;
}

.flex-center {
    display: flex;
    align-items: center;
}

.score-booster-container {
    margin: auto;
    padding: 12px 8px;
    border-radius: 16px;
    justify-content: space-between;
    column-gap: 0px;
    width: 100%;

    .upgrade-arrow {
        img {
            height: 24px;
            margin-left: 5px;
        }
    }

    &.green {
        background: linear-gradient(101deg, rgba(103, 198, 117, 1) 0%, rgba(143, 225, 161, 1) 80%);
        cursor: pointer;
    }

    &.grey {
        pointer-events: none;
        background: #F1F1F9;
        color: #868895;
    }


    .booster-points {
        font-weight: bold;
        text-transform: uppercase;
    }

    .left-part {
        display: flex;
        align-items: center;
        color: #fff;

        &.grey-text {
            color: #868895;
        }
    }

    .booster-points-value {
        font-size: 24px;
        margin-right: 2px;

        @media (max-width: $mobile) {
            font-size: 16px;
        }
    }

    .boost-detail-container {
        padding: 8px;
        background: white;
        border-radius: 8px;

        span,
        i {
            color: #87DB98;
        }

        .transparent {
            opacity: 0.63;
        }

        .score {
            font-weight: bolder;
            font-size: 16px;
            margin-right: 2px;
            margin-left: 2px;
        }

        i {
            font-size: 12px;
        }
    }

}