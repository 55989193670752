.message-modal-container {
    .icon {
        text-align: right;
        padding: 10px 10px 0px 0px;
        font-size: 30px;

        .native {
            font-size: 30px;
        }
    }
}
