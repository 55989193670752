@import 'style/colors.scss';
@import "../../../assets/scss/components/variable";

.bottom-tab-bar {
  display: none;
  padding: 3px;
  background-color: $bottom-bar-background !important;
  border: 1px solid white;
  position: fixed;
  margin: 0 auto;
  bottom: 40px;
  min-width: 350px;
  border-radius: 16px;
  margin-left: 50%;
  transform: translateX(-50%);
  z-index: 15;
  justify-content: space-around;
  opacity: 1;
  transition-property: opacity, visibility;
  transition-duration: $bottom-tab-fade-duration;
  transition-timing-function: ease-in-out;

  &.hide {
    display: none;
  }

  /* > iOS 11.0 */
  @media (max-width: 1200px) {
    display: flex;
  }

  &.fade {
    opacity: 0;
    visibility: hidden;
  }

  a {
    font-size: 14px;
    width: 100%;
    display: block;
    color: #f2f2f2;
    text-align: center;
    text-decoration: none;
    padding: 10px 5px;
    border-top: 4px solid #25294a;
    opacity: 0.8;

    p {
      color: $bottom-menu-color
    }

    svg path {
      fill: $bottom-menu-color
    }

    &.active {
      color: #ff7c6e;
      background-color: $menu-active-background;
      border-radius: 16px;
      opacity: 1;

      p {
        color: $menu-active-color
      }

      .tournament_svg {
        path {
          fill: $menu-active-color;
          stroke: $menu-active-color;
        }
      }

      svg path {
        fill: $menu-active-color
      }

    }
  }

  &.iOS>a {
    padding-bottom: 0;
  }

  p {
    margin-bottom: 0;
    font-size: 12px;
    margin-top: -3px;
  }

  @media (max-height: 550px) {
    display: none;
  }
}