@import "style/size.scss";

.kyc-result-dialog {
    padding: 20px;

    p {
        line-height: 14px;
    }

    p:first-child {
        text-align: center;
    }

    p:not(:first-child) {
        text-align: center;

        @media (max-width: $mobile) {
            text-align: left;
        }
    }

    .buttons-container {
        display: flex;
        justify-content: center;
        column-gap: 15px;
        align-items: stretch;
        margin-top: 15px;

        button {
            width: 100%;
            height: auto;
            min-height: 35px;
            line-height: inherit;
            font-size: 14px;
        }
    }

    &.confirmed {
        .buttons-container {
            button {
                width: auto;
            }
        }
    }
}