@import "../../../../style/colors.scss";

.red-button {
    background: $red-button-color;
    border: none;
    width: 100%;
    color: #fff;
    text-transform: uppercase;
    transition: 0.2s;
    border-radius: 11px;
    width: 100%;
    font-weight: bold;
    padding-right: 13px;
    padding-left: 13px;
    line-height: 35px;
    cursor: pointer;

    &:hover{
        transition: 0.2s all ease;
        opacity: 0.7;
    }

    &.sell-share-button{
        background: $sell-button-bgColor;
        color: $sell-button-TextColor;
    }

    &:disabled {
        cursor: not-allowed;
        opacity: 0.5;
    }
}
