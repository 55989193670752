@import 'style/colors.scss';
@import 'style/font.scss';

.edit-userProfile-container {
    .confirm-section {
        display: flex;
        justify-content: space-between;
        font-size: 16px;
        margin: 10px 0px;
        align-items: center;

        .cancel-btn {
            display: flex;
            align-items: center;
            @include fontWeightSize(medium);
        }
    }

    h2 {
        @include fontWeightSize(bold, 24px);
    }

    .profil_section {
        background: $medium-grey;
        border-radius: 14px;
        padding: 24px 0px;
        margin-bottom: 20px;

        .profile_img {
            width: 100px;
            height: 100px;

            &::before {
                background-image: none
            }

            .img-fluid {
                max-width: none;
                width: 100px;
                height: 100px;
                border: 2px solid #15182B33;
                box-shadow: 0px 3px 20px #15182B40;
            }
        }

        .change-profile {
            text-align: center;
            color: $tertiary-violet;
            @include fontWeightSize(bold);
            margin: 14px 0px 0px;

            label {
                display: inline;
                margin: 0px;
            }
        }
    }


    .model_btns {
        margin-bottom: 25px;
        padding: 0px;

        .container-verify-email {
            display: flex;
            align-items: center;
            background: #6677F51A;
            padding: 6px 20px 6px 6px;
            border-radius: 25px;
            width: fit-content;
            margin: auto;

            &.verified {
                background-color: #6677F50D;
            }
        }

        .icon-container {
            padding: 9px;
            border-radius: 50%;
            background-color: #6677f557;
            display: flex;
            align-items: center;
            margin-right: 10px;

            &.verified {
                background-color: #6677F51A;

                svg {
                    color: $tertiary-violet;
                }
            }

            svg {
                color: $white;
                width: 17px;
                height: 17px;
            }

        }

        .message {
            margin: 0;
            color: $tertiary-violet;
            @include fontWeightSize(bold, 13px);
        }

    }
}