@import 'style/_variable.scss';
@import 'style/colors.scss';
@import 'style/size.scss';
@import 'style/font.scss';

@mixin font-bold-italic {
    font-weight: bold;
    font-style: italic;
}

.asset-grid-box {
    position: relative;
    cursor: pointer;

    .grid-wrapper {
        position: relative;
        border-radius: 1.2em;
        overflow: visible;
        width: $grid-box-width;
        height: $grid-box-height;
        border: solid 3px transparent;

        @media (min-width: $min-medium-device) {
            width: calc(1.5 * 90px);
            height: calc(1.5 * #{$grid-box-height});
            border-width: 5px;
        }

        .grid-asset-bg-img,
        .grid-asset-avatar-img,
        .overlay-filter {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }

        .grid-asset-bg-img {
            border-radius: 1.2em;
        }



        .grid-asset-avatar-img {
            object-fit: cover;
            border-radius: 1.2em;
        }

        .overlay-filter {
            background: linear-gradient(to top, rgba(0, 0, 0, 0.85), rgba(0, 0, 0, 0.5) 15%, transparent 60%);
            border-radius: 1.2em;

            .token-content {
                position: absolute;
                left: 1px;
            }
        }

        .asset-score-container {
            z-index: 1;
            top: -0.3em;
            right: -5px;
            position: absolute;

            &:has(.asset-score:only-child) {
                top: 0.3em;
            }

            .asset-score {
                margin-bottom: 3px;

                @media (min-width: $min-medium-device) {
                    @include fontWeightSize(bold, 12px);
                    margin-bottom: 5px;
                    padding: 3px 7px;

                }

                .asset-score_icon {
                    @media (min-width: $min-medium-device) {
                        height: 12px;
                        width: 12px;
                    }
                }

                .asset-score_item {
                    width: 16px;

                    @media (min-width: $min-medium-device) {
                        width: 20px;
                    }
                }
            }
        }

        .grid-name {
            @include fontWeightSize(extra-bold, 12px);
            font-style: italic;
            text-transform: uppercase;
            position: absolute;
            left: 50%;
            top: 52%;
            transform: translate(-50%, -50%);
            width: 100%;
            height: 35%;
            line-height: 1em;
            overflow: hidden;
            text-overflow: ellipsis;
            display: flex;
            justify-content: center;
            align-items: flex-end;
            color: $base-color-hover ;
            padding: 0px 3px;
            text-align: center;
            word-break: break-word;

            @media (min-width: $min-medium-device) {
                font-size: 14px;
            }

            a {
                color: $base-color-hover ;
            }
        }

        .grid-currentValue {
            @include font-bold-italic;
            position: absolute;
            left: 50%;
            top: 79%;
            transform: translate(-50%, -50%);
            display: flex;
            align-items: center;
            color: $base-color-hover ;
            font-size: 13px;

            @media (min-width: $min-medium-device) {
                font-size: 14px;
            }

            .small-text_shareLabel {
                color: $base-color-hover ;
                font-size: 8px;
                font-style: italic;

                @media (min-width: $min-medium-device) {
                    font-size: 10px;
                }
            }
        }

        .grid-totalPriceChange {
            position: absolute;
            left: 50%;
            top: 91%;
            text-align: center;
            transform: translate(-50%, -50%);
            width: 100%;
            font-size: 8px;
            font-style: italic;
            font-weight: 600;

            @media (min-width: $min-medium-device) {
                font-size: calc(1.3*8px);
            }
        }

        .asset-icon-loader-or-timer-wrapper {
            position: absolute;
            transform: translate(-50%, -50%);
            left: 50%;
            top: 80%;
            color: $base-color-hover;
            width: 100px;
            @include font-bold-italic;


            .available-soon {
                color: $base-color-hover;
                line-height: 100%;
                font-size: 9px;
            }

            .timer {

                padding: 8px 6px 0;
                flex-flow: column;

                .small_timer {
                    .small_timer-container {
                        .small_time {
                            margin-right: 2px;

                            .small_time__number {
                                color: $base-color-hover;
                                font-size: 9px;
                                margin-right: 1px;

                                @media (min-width: $min-medium-device) {
                                    font-size: calc(1.3*9px);
                                }

                            }

                            .small_time__text {
                                color: $base-color-hover;
                                font-size: 8px;

                                @media (min-width: $min-medium-device) {
                                    font-size: calc(1.3*8px);
                                }

                            }
                        }
                    }
                }
            }

            svg {
                cursor: pointer;
            }
        }

        .greenTxt {
            color: $tdx-price-evolution-green;
        }

        .redTxt {
            color: $tdx-red;
        }
    }

    &.onboarding-v2 {
        .grid-wrapper {
            width: calc(90px - 5px);
    
            @media (min-width: $min-medium-device) {
                width: calc(1.5 * #{calc(90px - 5px)});
            }
        }

        .grid-name {
            top: 72%;
        }

        .overlay-filter,
        .grid-currentValue,
        .grid-totalPriceChange {
            display: none;
        }
    }
}