@import 'style/size.scss';
@import 'style/colors.scss';
@import 'style/layout.scss';

.common-range-container {
    width: 100%;
    height: 10px;
    position: relative;
    border-radius: 25px;
    background: rgba(102, 119, 245, 0.25);

    .range-bar {
        @include default-flex-box;
        left: 50%;
        height: 10px;
        position: absolute;
        border-radius: 25px;
        justify-content: space-between;
        background: rgba(102, 119, 245, 1);
        transform: translate(-50%, 0px);
    }

    .range-price-container {
        @include default-flex-box;
        position: absolute;
        transform: translate(-50%, -50%);
        left: 50%;
        width: 100%;
        text-align: center;

        .price-container {
            width: 100%;
            margin-bottom: 10px;
            flex-direction: column;
            @include default-flex-box;
            transform: translateY(-7px);

            p {
                height: 10px;
                font-size: 10px;
                color: $tertiary-grey;
            }

            .arrow-drop {
                font-size: 20px;
                color: $tertiary-violet;
            }
        }

        .label-start {
            margin-left: -6px;
            align-items: flex-start;
        }

        .label-center {
            align-items: center;
        }

        .label-end {
            margin-right: -6px;
            align-items: flex-end;
        }
    }
}