.start-private-sell-base {
    max-width: 600px;
    margin: 50px auto;
    text-align: center;

    h2 {
        font-size: 28px;
    }

    img {
        margin: 30px auto;
    }
}

.start-private-sell-light {
    color: black;
}

.start-private-sell-dark {
    color: white;
}
