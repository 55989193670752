@import "style/font.scss";
@import "style/colors.scss";

.buy-confirmation-view {
  margin-top: 16px;

    .confirm-payment-type {
        display: flex;
        flex-direction: column;
        gap: 8px;

        .warning-msg{
          color: $caution-yellow;
          @include fontWeightSize(regular,12px);
        }

        .payment-card-wrapper{
          padding: 16px;
        }
  }

  .asset-reward-debt-info {
    margin-top: 10px;
  }

  .hidden {
    display: none;
  }
}

.wallet-checkout-form {
  margin-top: 10px;
  border-radius: 16px;
  height: 50px;
  transition: max-height 200ms;
  cursor: pointer;
  position: relative;

  .checkout-with-link{
    position: absolute;
    left: 0;
    right: 0;
    top: 10px
  }
}