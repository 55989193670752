@import 'style/size.scss';

.asset-buySell-section {
    margin: 25px 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 18px;

    @media (max-width: $mobile) {
        margin:20px 0px 5px 0px;
    }

    &>button {
        width: auto;
    }

    @media (min-width: $min-medium-mobile) {
        flex-direction: row;
        gap: 8px;


        &>button {
            width: 50%;
        }
    }
}