@import 'style/colors.scss';
@import 'style/font.scss';
@import 'style/size.scss';

.txn-asset-body {

    &.slider-gap {
        margin-top: 10px;
    }

    .token-section {
        margin-bottom: 16px;

        .token-input-row {
            position: relative;
            margin-bottom: 12px;


            .arrow-circle {
                position: absolute;
                bottom: -23px;
                left: 50%;
                transform: translateX(-50%);
                padding: 12px;
                background-color: $white;
                border-radius: 50%;
                display: flex;
                align-items: center;

                .up-arrow {
                    display: flex;
                    width: 12px;
                    height: 12px;

                    &.revert-arrow {
                        transform: rotate(180deg);
                    }

                    svg {
                        height: 100%;
                        width: 100%;
                    }
                }
            }
        }

        .trade-detail-box {
            .input-field-tokens-value {

                .input-iframe-benzema {
                    @media (max-width:$mobile) {
                        width: 45px;
                    }
                }

                input {
                    width: 80px;
                    height: 40px;
                    outline: 2px solid #B7BAE3;
                    @include fontWeightSize(bold, 16px);
                    border-radius: 8px;
                    border: none;
                    text-align: right;
                    padding: 2px 8px;

                    @media(min-width: $min-small-mobile) {
                        width: 90px;
                    }
                }

                input[type=number]::-webkit-inner-spin-button,
                input[type=number]::-webkit-outer-spin-button {
                    -webkit-appearance: none;
                    margin: 0;
                }
            }

            .trade-detail {

                .trade-detail__fee,
                .trade-detail__price-impact {
                    @include fontWeightSize(bold, 10px);
                    color: $light-grey;
                }

                .trade-detail__fee {
                    margin-top: 6px;
                    word-break: break-word;
                }
            }

            .trade-detail_market-value {
                @include fontWeightSize(medium, 1rem);
                margin-bottom: 8px;
            }
        }

        .fee-caution {
            margin-top: 4px;
            text-align: right;
            color: $light-grey;
            @include fontWeightSize(regular, 12px);
        }
    }

    .button-increase-decrease {
        text-align: center;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        height: 25px;
        width: 25px;
        padding: 0px 0px 6px 0px;
        font-size: 20px;
        text-transform: uppercase;
    }

    .flex-with-button {
        column-gap: 5px;
    }

    .max-btn {
        text-align: center;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        height: 40px;
        width: 40px;
        padding: 0;
        font-size: 12px;
        text-transform: uppercase;

        &.max-on {
            background-color: $tertiary-violet;
            color: $white;
        }

    }

    .container-caution-tokens-detail {
        .input-tokens-caution {
            text-align: center;
            color: $error-red;
            top: 100%;
            width: 100%;

            svg {
                position: relative;
                top: 2px;
                height: 24px;
                width: 16px;
                margin-right: 5px;

                g {
                    fill: $error-red;
                }

                @media screen and (max-width: $mobile) {
                    display: none;
                    margin-right: 0px;
                    margin-bottom: 5px;
                }
            }

            p {
                font-size: 14px;
                display: flex;
                align-items: center;
                justify-content: center;
                margin-top: 5px;
                font-weight: 500;

                @media screen and (max-width: $mobile) {
                    font-size: 12px;
                }
            }

            @media screen and (max-width: $mobile) {
                margin-top: 5px;
                text-align: end;
            }
        }
    }

    .input-tokens-caution {
        text-align: center;
        color: $caution-yellow;
        top: 100%;
        width: 100%;

        svg {
            position: relative;
            top: 2px;
            height: 14px;
            width: 15px;

            g {
                fill: $caution-yellow;
            }
        }

        p {
            font-size: 13px;
        }
    }

    .detail-section {
        margin-top: 10px;

        .price-details .list-card .information_row_value {
            width: 100%;
        }
    }

    .confirm-transaction-total_timer {
        text-align: center;
        color: $light-grey;
        font-size: 12px;
    }


    .virtual-keyboard-container {
        width: 100%;
        max-width: 350px;
        margin: 0 auto;
    }
}