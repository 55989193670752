.asset-edit-page {
    margin-top: 70px;
    .content {
        width: 50%;
        margin: auto;
        .action-button {
            color: black;
        }
        .container-action-buttons {
            display: flex;
            flex-direction: row;
            justify-content: flex-end;
            .action-button {
                color: black;
                width: 20%;
                margin: 15px;
            }
        }
    }
}
