@import 'style/colors.scss';
@import 'style/font.scss';

.description-card {
    padding: 18px 12px 23px 20px;
    display: flex;
    border-radius: 12px;
    gap: 5px;
    margin-top: 18px;
    width: 100%;
    max-width: 400px;
    align-self: center;

    .reward-trophy-container {
        display: flex;
        align-items: center;

        .reward-icon {
            position: relative;
            height: 50px;

            svg {
                position: absolute;
                top: -8px;
                left: -6px;
                width: 24px;
                height: 21px;

                path {
                    fill: $white;
                }

            }

            img {
                width: inherit;
                height: inherit;
            }
        }
    }

    .plus-sign {
        padding-top: 5px;

        svg {
            font-size: 24px;
        }
    }

    .reward-description {
        display: flex;
        flex-direction: column;
        text-align: left;
        gap: 6px;

        .reward-amount {
            @include fontWeightSize(bold, 22px);
        }

        .reward-text {
            @include fontWeightSize(medium, 13px);
        }
    }
}