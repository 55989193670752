@import './components/mixins';
@import './components/variable';

// Large devices (desktops, less than 1600px)
@media (max-width: 1600px) {
}

// Large devices (desktops, less than 1440px)
@media (max-width: 1440px) {
    .about_card .card_follower {
        flex-wrap: wrap;
    }

    .about_card .card_follower .profile_info {
        margin: 0 0 20px 0;
    }

    .about_card .card_follower {
        padding: 20px 30px 20px 180px;
    }

    // .about_card .main_profile {
    //   padding: 60px 20px 0 20px;
    // }
    .about_card .main_profile .profileimg_info .profile_img::before {
        width: 145px;
        height: 145px;
    }

    .about_card .main_profile .profileimg_info .profile_img img {
        width: 95px;
        height: 95px;
    }

    .profil-icon .profile_img img {
        width: 90px;
        height: 90px;
    }

    .profil-icon .profile_img::before {
        width: 80%;
        height: 80%;
    }

    .cust_section .cards_info .cards_imgs {
        margin: 0px 0px 70px 0;
    }

    .cust_section .cards_info .follow_price {
        flex-wrap: wrap;
    }

    .cust_section .cards_info .follow_price .f_price {
        width: 100%;
    }

    .cust_section .cards_info .follow_price .f_price.pri {
        margin: 10px 0 0 0;
    }

    .tabpanel_section {
        padding: 0;
    }

    .faq-section {
        .category-item {
            h2 {
                font-size: 28px;
            }
        }
    }

    .panel__label {
        font-size: 20px;
        padding: 20px 60px 20px 75px;

        &:after {
            top: 19px;
            width: 30px;
            height: 30px;
        }

        &:before {
            top: 27px;
        }
    }

    .breadcrumbs {
        padding: 90px 40px;

        &.contactus_breadcrumbs {
            &::before {
                right: 80px;
                bottom: 30px;
                width: 400px;
            }
        }
    }

    .breadcrumbs_text h2 {
        font-size: 42px;
        line-height: 48px;
    }

    .contactus {
        padding: 50px 0 50px 0;

        .contact_form {
            padding: 40px 30px;
            border-radius: 10px;
            margin: -80px 0 0 0;
        }
    }

    .contact_sec {
        img {
            width: 60px;
        }
    }

    .contactus {
        .contact_text {
            .context_sec {
                h4 {
                    font-size: 18px;
                    margin: 0 0 5px 0;
                }

                p {
                    font-size: 14px;
                    line-height: 20px;
                }
            }
        }
    }

    .contactus {
        .title_sec {
            margin: 0 0 40px 0;

            h3 {
                font-size: 32px;
                margin: 0 0 10px 0;
            }
        }
    }
}

// Mid devices (desktops, less than 1366px)
@media (max-width: 1366px) {
    .col-xxl-12 {
        flex: 0 0 100%;
        max-width: 100%;
    }

    .hidden-medium {
        display: none;
    }

    .about_card .main_profile .profileimg_info .profile_name h3 {
        font-size: 26px;
        max-width: 400px;
    }

    .about_card .main_profile .profileimg_info .profile_name .country_icon {
        margin: 0 0 5px 0;
    }

    .about_card .main_profile .profileimg_info .profile_img {
        position: relative;
        margin: 0 10px 0 0;
    }

    .about_card .main_profile .profileimg_info {
        bottom: -80px;
    }

    .footer_section {
        padding: 90px 0 0 0;
    }

    .sidemenu_section {
        width: 315px;

        &.active {
            left: 0px;
        }
    }

    .main_section {
        width: calc(100% - 270px);
    }

    .side_title {
        flex-wrap: wrap;

        .view_btns {
            margin: 0px 0 0 0;
        }
    }

    .assets-category-page {
        .assets-category-container {
            height: 300px;

            .trading-name-container {
                .trading-name {
                    font-size: 50px;
                    margin: 0;
                }
            }
        }
    }

    .banner_title {
        padding: 60px 60px 60px 60px;
        min-height: 220px;
        margin: 0 0 40px 0;

        h3 {
            font-size: 34px;
        }
    }

    .referral-copy-link {
        width: 100%;
    }
}

// Large devices (desktops, less than 1199px)
@media (max-width: 1199px) {
    .sideplayer_section {
        margin: 30px 0 0 0;
    }

    .cust_section .cards_info .cards_titles {
        bottom: -20px;
    }

    .cust_section .cards_info .cards_imgs {
        margin: 0px 0px 30px 0;
    }

    .sideplayer_fix {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
    }

    .cust_section {
        width: 30.33%;
        margin: 0 10px 20px 10px;
    }
}

// Medium devices (tablets, less than 992px)
@media (max-width: 1200px) {
    .sidemenu_section {
        left: -$sideMenuWidth;
        transition: 0.15s all ease-in-out;

        &.active {
            left: 0px;
            transition: 0.15s all ease-in-out;
        }
    }

    .side_menulinks {
        .close_menusec {
            display: flex;
        }
    }

    .responsive_menu {
        display: flex;
    }

    .main_section {
        width: 100%;
    }

    .side_title .view_btns {
        margin: 0;
    }
}

// Medium devices (tablets, less than 992px)
@media (max-width: 991px) {
    .auth_page {
        .auth_structure {
            padding: 0px 0px 40px 0px;
            width: 576px;

            .container-logo {
                padding: 8px 35px;

                &:after {
                    padding: 42px 135px;
                }

                &:before {
                    padding: 48px 152px;
                }
            }
        }

        .logreg_title {
            margin: 0 0 25px 0;

            h3 {
                font-size: 32px;
            }
        }
    }

    .cust_section {
        width: 45.33%;
        margin: 0 15px 20px 15px;
    }

    .search_input button {
        min-width: auto;
    }

    .responsive_menu {
        padding: 0px 8px 0px 8px;
        height: 42px;
    }

    .header_sec {
        .search_panel {
            width: 300px;

            .MuiPaper-elevation1 {
                border-radius: 6px;
                height: 42px;
            }
        }

        .user_header {
            .header_links {
                a {
                    padding: 0px 12px 0px 12px;
                    height: 43px;

                    p {
                        font-size: 14px;
                    }
                }
            }
        }
    }

    // Footer Section Start
    .footer_section {
        padding: 50px 0 0 0;

        .footer_nav {
            padding: 0 0px 20px 0;

            ul {
                li {
                    display: block;

                    .f_links {
                        font-size: 14px;
                        margin: 0 0px 5px 0;
                        display: inline-block;
                    }
                }
            }

            .android_app {
                text-align: right;

                .app_links {
                    margin: 0 10px 0 0;

                    img {
                        width: 40%;
                    }
                }
            }
        }
    }

    .copyrights {
        padding: 20px 0;

        h4 {
            font-size: 14px;
        }
    }

    .assets-category-page {
        .assets-category-container {
            height: 250px;

            .trading-name-container {
                .trading-name {
                    font-size: 42px;
                    margin: 0;
                }
            }
        }
    }

    .assets-table {
        .button-flex {
            column-gap: 10px;
        }

        .table_section_one.MuiPaper-elevation1 {
            .tables {
                tbody {
                    tr {
                        .player-details {
                            .playerName {
                                line-height: 16px;
                                font-size: 16px;
                            }
                        }

                        td {
                            padding: 10px 10px 10px 10px;
                        }

                        .price_stock {
                            .ps_price {
                                line-height: 16px;
                                font-size: 16px;
                                margin: 0 0 5px 0;
                            }
                        }
                    }
                }
            }
        }
    }

    .banner_title {
        min-height: 180px;

        h3 {
            font-size: 30px;
        }
    }

    .referral-copy-link {
        padding: 10px 10px 10px 10px;
        margin: -90px 0px 20px 0px;
    }

    .affiliate_banner {
        margin-top: 30px;

        .affiliate_texts {
            .affiliate_img {
                height: 220px;
            }

            .banner-txt {
                .b_title {
                    margin: 20px 0 10px 0;

                    h1 {
                        font-size: 32px;
                    }

                    span {
                        margin: 0px 0 0 0;
                    }
                }
            }
        }
    }

    .panel__label {
        font-size: 18px;
        padding: 15px 50px 15px 65px;

        &:after {
            top: 15px;
            left: 25px;
            width: 26px;
            height: 26px;
        }

        &:before {
            top: 22px;
            width: 14px;
            height: 14px;
        }
    }

    .faq-section {
        .category-item {
            h2 {
                font-size: 26px;
                padding-bottom: 25px;
            }
        }
    }

    .breadcrumbs {
        &.contactus_breadcrumbs {
            &::before {
                right: 50px;
                bottom: 0px;
                width: 400px;
            }
        }
    }

    .breadcrumbs {
        padding: 70px 30px;
    }

    .breadcrumbs_text h2 {
        font-size: 40px;
        line-height: 42px;
        margin: 0;
    }

    .contactus {
        .contact_form {
            padding: 30px 30px;
            border-radius: 10px;
            margin: 0px 0 0 0;
        }
    }
}

// Small devices (landscape phones, less than 768px)
@media (max-width: 767px) {
    .hidden-mobile-landscape {
        display: none !important;
    }

    .auth_page {
        .logreg_title {
            margin: 0 0 20px 0;

            h3 {
                font-size: 28px;
                max-width: 360px;
            }
        }

        .auth_structure {
            padding: 55px 35px 35px 35px;
            width: 480px;

            .container-logo {
                padding: 8px 35px;
                border-radius: 20px;

                img {
                    width: 130px;
                }

                &:after {
                    padding: 35px 115px;
                    border-radius: 25px;
                }

                &:before {
                    padding: 42px 127px;
                    border-radius: 25px;
                }
            }
        }
    }

    .about_card .main_profile .profileimg_info {
        bottom: 0px;
        align-items: center;
    }

    .about_card .main_profile .profileimg_info .profile_img::before {
        width: 115px;
        height: 115px;
    }

    .about_card .main_profile .profileimg_info .profile_img img {
        width: 76px;
        height: 76px;
    }

    // .about_card .main_profile {
    //   padding: 40px 10px 0 10px;
    // }
    .about_card .card_follower {
        padding: 20px 20px 20px 20px;
        align-items: center;
    }

    .about_card .card_follower .profile_info {
        margin: 0;
    }

    .cust_section {
        width: 45%;
        margin: 0 10px 20px 10px;
    }

    .header_sec {
        flex-wrap: wrap;

        .search_panel {
            width: 100%;
            order: 3;
            margin: 0px 0 0 0;
        }

        .user_header {
            justify-content: flex-end;
            margin: 0;
            margin-left: auto;

            .header_links {
                &.profil_icon {
                    margin: 0;
                }
            }
        }
    }

    .section_title h3 {
        font-size: 22px;
    }

    .table_section_one.MuiPaper-elevation1 {
        .tables {
            thead {
                tr {
                    th {
                        font-size: 16px;
                    }

                    td {
                        padding: 10px 10px 10px 10px;
                    }
                }
            }

            tbody {
                tr {
                    .player-details {
                        margin-left: 10px;

                        .playerName {
                            font-size: 16px;
                            margin: 0 0 3px 0;
                        }
                    }
                }
            }
        }
    }

    .spline_chart {
        .chart_cards {
            margin: 0 0 20px 0;
        }

        .col-md-4 {
            &:last-child {
                .chart_cards {
                    margin: 0;
                }
            }
        }
    }

    .assets-category-page {
        .assets-category-container {
            height: 200px;

            .trading-name-container {
                .trading-name {
                    font-size: 34px;
                    margin: 0;
                }

                .trading {
                    font-size: 22px;
                }
            }
        }
    }

    //.table_cust_scroll {
    //max-height: 400px;
    //}

    .banner_title {
        min-height: 150px;
        padding: 60px 40px 60px 40px;

        h3 {
            font-size: 28px;
        }
    }

    .faq-section {
        .category-item {
            flex-wrap: wrap;

            .cat_img {
                margin: 0 0px 20px 0;

                img {
                    width: 120px;
                }
            }
        }
    }

    .main_innerWrapper {
        padding-bottom: 0;
    }

    .faq-section {
        .category-item {
            padding-bottom: 40px;
        }
    }

    .rules_titlesec h3 {
        font-size: 28px;
    }

    .breadcrumbs {
        &.contactus_breadcrumbs {
            &::before {
                opacity: 0.2;
            }
        }
    }

    .breadcrumbs {
        padding: 50px 10px;
    }

    .breadcrumbs_text h2 {
        font-size: 36px;
    }

    .contactus {
        padding: 30px 0 30px 0;
    }

    .contactus .title_sec h3 {
        font-size: 28px;
        margin: 0 0 5px 0;
        line-height: 32px;
    }

    .contactus .title_sec h5 {
        font-size: 15px;
    }

    .contactus .title_sec {
        margin: 0 0 20px 0;
    }

    .contactus .contact_faqtitle:before {
        left: -13px;
        top: -9px;
        width: 40px;
        height: 40px;
        z-index: -1;
    }

    .contactus .contact_faqtitle {
        margin: 0 0 30px 0;
    }

    .contactus .contact_text .contact_sec {
        margin: 0 0 20px 0;
    }

    .contactus .contact_text .context_sec {
        margin: 0 0 0 10px;
        width: 100%;
    }

    .contactus .contact_form {
        padding: 20px 20px;
    }

    .contactus .contact_form .contact_title h3 {
        margin: 0 0 25px 0;
        font-size: 20px;
    }

    .contactus .contact_form .form_section .form-group .form-control {
        height: 45px;
        padding: 0px 20px 0 20px;
    }

    .contactus .contact_form .form_section .form-check {
        @include align-items(flex-start);
    }
}

// Extra small devices (portrait phones, less than 576px)
@media (max-width: 576px) {
    .hidden-mobile {
        display: none !important;
    }

    .display-block-mobile {
        display: block;
    }

    .asset-banner-footer {
        .content-banner {
            padding: 20px 30px 20px 30px;
        }
    }

    .auth_page {
        .auth_structure {
            width: 380px;
            padding: 40px 30px 30px 30px;
        }
    }

    .separate_or {
        margin: 10px 0;
    }

    .custinput_group {
        input {
            min-height: 40px;
        }
    }

    .loginsocial_buttons {
        justify-content: center;
        display: initial;

        a {
            margin: 0;
            margin-top: 20px;

            @media (max-width: 320px) {
                margin-top: 10px;
            }
        }

        .custom_btn {
            width: 20%;
            padding: 10px 10px !important;
            font-size: 14px !important;
            margin: 0 5px;

            span {
                display: none;
            }

            img {
                width: 18px;
                height: 18px;
                margin: 0;
            }
        }
    }

    .custinput_group .form_label {
        font-size: 14px;
        margin: 0 0 5px 0;
    }

    .loginregi_form .forgot-password {
        font-size: 14px;
    }

    .custinput_group {
        margin: 0 0 15px 0;
    }

    .auth_structure {
        .container-logo {
            &:before {
                display: none;
            }
        }
    }

    .auth_page .logreg_title h3 {
        font-size: 25px;
        max-width: 360px;
    }

    .about_card .card_follower {
        padding: 10px 20px 10px 20px;
        align-items: center;
        justify-content: center;
    }

    .about_card .card_follower .profile_info {
        margin: 0 0 20px 0;
    }

    .about_card .main_profile .profileimg_info .profile_name .country_icon {
        justify-content: center;
    }

    .profile_name {
        width: 100%;
        justify-content: center;
        text-align: center;
        margin: 0 0 -10px 0;
    }

    .follower_card {
        flex-wrap: wrap;
    }

    .follower_card .follow_img {
        width: 100%;
    }

    .follower_card .follow_img {
        width: 100%;
        text-align: center;
    }

    .follower_card {
        padding: 20px 20px 20px 20px;
    }

    .follower_card .follow_img::before {
        left: 50%;
        top: 50%;
    }

    .follower_card .follow_img span {
        left: 50%;
        bottom: -25px;
        transform: translate(40%, -50%);
    }

    .follower_card .follow_text {
        width: 100%;
        margin: 20px 0 0 0px;
        flex-wrap: wrap;
    }

    .follow_name {
        width: 100%;
        text-align: center;
    }

    .search_input button {
        width: auto;
        min-width: auto;
    }

    .tab_header {
        .MuiTabs-flexContainer {
            display: flex;
            justify-content: center;

            .Mui-selected {
                border-bottom: 1px solid #f50057;
            }
        }

        .MuiTabs-indicator {
            display: none;
        }

        .PrivateTabIndicator-root-25 {
            display: none;
        }
    }

    .responsive_menu {
        padding: 0px 7px 0px 7px;
        height: 37px;

        svg {
            font-size: 24px;
        }
    }

    .header_sec {
        .user_header {
            .header_links {
                a {
                    padding: 0px 3px 0px 3px;
                    height: 37px;

                    p {
                        font-size: 12px;
                    }

                    svg {
                        font-size: 20px;
                    }
                }

                .portfolio_links {
                    svg {
                        font-size: 20px;
                        color: #97a8de;
                    }
                }
            }
        }

        .search_panel {
            .MuiPaper-elevation1 {
                border-radius: 6px;
                height: 38px;
            }
        }
    }

    .footer_section {
        .footer_nav {
            ul {
                li {
                    .f_links {
                        font-size: 12px;
                    }
                }
            }
        }
    }

    .sidemenu_links_sec {
        .container-side-menu-deconnexion {
            padding: 20px 0 20px 0;

            .btn-logout {
                font-size: 16px;
            }
        }

        .sidemenu_widget {
            a {
                padding: 0px 0 0 20px;
                font-size: 14px;

                svg {
                    font-size: 18px;
                }
            }
        }

        .sidemenu_trading {
            padding: 20px 0 20px 0px;

            .title-section-side-menu {
                font-size: 16px;
                padding: 0 0 0 30px;
            }

            a {
                padding: 0px 0 0 20px;

                .linkside_logo {
                    span {
                        font-size: 18px;
                        margin: 0 10px 0 0;
                    }
                }
            }
        }
    }

    .sidemenu_dropdown {
        margin-top: 20px;
    }

    .side_menulinks {
        .sidemenu_logo {
            margin: 0 0 5px 0;

            img {
                width: 91px;
            }
        }

        .profile_logosec {
            padding: 45px 10px 20px 10px;
        }

        .close_menusec {
            top: 45px;
            font-size: 16px;
        }
    }

    .emptylist_buttons {
        width: 60%;
    }

    .atitle_sec {
        margin: 20px 0 20px 0;

        h3 {
            font-size: 24px;
            margin: 0 0 15px 0;
        }
    }

    .side_title {
        padding: 10px 20px 10px 20px;

        .view_btns {
            margin: 0;
        }
    }

    .footer_section {
        padding: 30px 0 0 0;
    }

    .cust_section {
        .cards_info {
            .cards_titles {
                .cards_name {
                    h4 {
                        white-space: nowrap;
                        text-overflow: ellipsis;
                        overflow: hidden;
                        padding: 0 20px;
                        font-size: 16px;
                    }
                }
            }
        }
    }

    .atitle_sec {
        h3 {
            font-size: 20px;
            margin: 0 0 15px 0;
        }
    }

    .round_table {
        .table_section_one.MuiPaper-elevation1 {
            .tables {
                tbody {
                    tr {
                        td {
                            &:first-child {
                                padding-left: 10px;
                            }

                            &:last-child {
                                padding-right: 20px;
                            }
                        }
                    }
                }
            }
        }
    }

    .assets-table {
        .button-flex {
            column-gap: 10px;
        }

        .table_section_one.MuiPaper-elevation1 {
            .tables {
                tbody {
                    tr {
                        td {
                            padding: 10px 10px 10px 10px;
                        }

                        .price_stock {
                            .ps_price {
                                line-height: 14px;
                                font-size: 14px;
                                margin: 0 0 5px 0;
                            }

                            .ps_stock {
                                display: flex;
                                justify-content: center;
                            }
                        }

                        .primaryCta {
                            Button {
                                height: 30px;
                                font-size: 12px;
                                padding: 3px 16px 0 16px;
                                cursor: pointer;
                            }
                        }
                    }
                }
            }
        }
    }

    .cust_section {
        .cards_info {
            .follow_price {
                padding: 10px 0 10px 0;

                .f_price {
                    h4 {
                        font-size: 16px;
                        margin: 0 0 5px 0;
                    }
                }
            }

            .cards_titles {
                bottom: -10px;
                height: 70px;

                .cards_name {
                    margin: 0px 0px 0px 0;
                    bottom: -5px;
                }
            }

            .cards_imgs {
                margin: 0px 0px 0px 0;
            }
        }
    }

    .banner_title {
        min-height: 140px;
        padding: 50px 40px 50px 40px;

        h3 {
            font-size: 26px;
        }
    }

    .affiliate_banner {
        .affiliate_texts {
            .banner-txt {
                right: -16%;
            }
        }
    }

    .affiliate_banner {
        .affiliate_texts {
            .affiliate_img {
                height: 170px;
            }
        }
    }

    .referral-copy-link {
        border-radius: 15px;

        .referral-link-txt {
            border-radius: 10px;
            justify-content: center;
            flex-wrap: wrap;

            .copy-link-img {
                display: none;
            }

            .cust_linktext {
                h3 {
                    font-size: 14px;
                    margin: 5px 0 0 0;

                    span {
                        font-size: 14px;
                    }
                }
            }
        }
    }

    .referral-copy-link button {
        position: relative;
        right: 0;
        width: 100%;
        line-height: 25px;
        margin: 10px 0 0 0;
        border-radius: 7px;
        font-size: 14px;
    }

    .affiliate-overview-page {
        .col-lg-10 {
            padding: 0;
        }
    }

    .rules_titlesec h3 {
        font-size: 23px;
        width: 100%;
        text-align: center;
    }

    .rules_tabs {
        margin: 0 0 30px 0;
    }

    .faq-section .category-item .cat_img {
        margin: 0 0px 20px 0;
        width: 100%;
        text-align: center;
    }

    .faq-section .category-item .cat_img img {
        width: 90px;
    }

    .faq-section .category-item h2 {
        font-size: 22px;
        padding-bottom: 15px;
        text-align: center;
    }

    .contactus .contact_text .context_sec h4 {
        font-size: 16px;
    }

    .contact_sec img {
        width: 40px;
    }

    .contactus .contact_form .form_section .form_btn button {
        padding: 11px 20px !important;
        width: 100%;
    }

    .myplayers_table {
        margin: 0;
    }

    .container-btn-histories {
        padding-bottom: 30px;
    }

    .table_section_one.MuiPaper-elevation1 .tables thead tr th {
        font-size: 16px;
        padding: 10px 10px 10px 10px;
    }

    .spline_chart .chart_cards .chart_label {
        padding: 55px 15px 0px 15px;
    }
}

// Extra small devices (portrait phones, less than 420px)
@media (max-width: 480px) {
    .cust_section {
        width: 100%;
        margin: 0 0px 20px 0px;
    }

    .follower_card .follow_text {
        justify-content: center;
    }

    .sportf_icons {
        width: 100%;
        text-align: center;
        display: flex;
        justify-content: center;
        margin: 5px 0 15px 0px;
    }

    .tab_header {
        .MuiTabs-flexContainer {
            flex-wrap: wrap;
        }
    }

    .about_card .card_follower {
        flex-wrap: wrap;
    }

    .about_card .card_follower .profile_info {
        margin: 0 0 20px 0;
    }

    .about_card .card_follower {
        padding: 20px 30px 20px 180px;
    }

    .about_card .main_profile {
        // padding: 60px 20px 0 20px;
    }

    .about_card .main_profile .profileimg_info .profile_img::before {
        width: 145px;
        height: 145px;
    }

    .about_card .main_profile .profileimg_info .profile_img img {
        width: 95px;
        height: 95px;
    }

    .profil-icon .profile_img img {
        width: 90px;
        height: 90px;
    }

    .profil-icon .profile_img::before {
        width: 80%;
        height: 80%;
    }

    .cust_section .cards_info .cards_imgs {
        margin: 0px 0px 70px 0;
    }

    .cust_section .cards_info .follow_price {
        flex-wrap: wrap;
    }

    .cust_section .cards_info .follow_price .f_price {
        width: 100%;
    }

    .cust_section .cards_info .follow_price .f_price.pri {
        margin: 10px 0 0 0;
    }

    .tabpanel_section {
        padding: 0;
    }

    .faq-section {
        .category-item {
            h2 {
                font-size: 28px;
            }
        }
    }

    .panel__label {
        font-size: 20px;
        padding: 20px 60px 20px 75px;

        &:after {
            top: 19px;
            width: 30px;
            height: 30px;
        }

        &:before {
            top: 27px;
        }
    }

    .breadcrumbs {
        padding: 90px 40px;

        &.contactus_breadcrumbs {
            &::before {
                right: 80px;
                bottom: 30px;
                width: 400px;
            }
        }
    }

    .breadcrumbs_text h2 {
        font-size: 42px;
        line-height: 48px;
    }

    .contactus {
        padding: 50px 0 50px 0;

        .contact_form {
            padding: 40px 30px;
            border-radius: 10px;
            margin: -80px 0 0 0;
        }
    }

    .contact_sec {
        img {
            width: 60px;
        }
    }

    .contactus {
        .contact_text {
            .context_sec {
                h4 {
                    font-size: 18px;
                    margin: 0 0 5px 0;
                }

                p {
                    font-size: 14px;
                    line-height: 20px;
                }
            }
        }
    }

    .contactus {
        .title_sec {
            margin: 0 0 40px 0;

            h3 {
                font-size: 32px;
                margin: 0 0 10px 0;
            }
        }
    }
}

// Mid devices (desktops, less than 1366px)
@media (max-width: 1366px) {
    .col-xxl-12 {
        flex: 0 0 100%;
        max-width: 100%;
    }

    .hidden-medium {
        display: none;
    }

    .about_card .main_profile .profileimg_info .profile_name h3 {
        font-size: 26px;
        max-width: 400px;
    }

    .about_card .main_profile .profileimg_info .profile_name .country_icon {
        margin: 0 0 5px 0;
    }

    .about_card .main_profile .profileimg_info .profile_img {
        position: relative;
        margin: 0 10px 0 0;
    }

    .about_card .main_profile .profileimg_info {
        bottom: -80px;
    }

    .footer_section {
        padding: 90px 0 0 0;
    }

    .sidemenu_section {
        width: 315px;

        &.active {
            left: 0px;
        }
    }

    .main_section {
        width: calc(100% - 270px);
    }

    .side_title {
        flex-wrap: wrap;

        .view_btns {
            margin: 0px 0 0 0;
        }
    }

    .assets-category-page {
        .assets-category-container {
            height: 300px;

            .trading-name-container {
                .trading-name {
                    font-size: 50px;
                    margin: 0;
                }
            }
        }
    }

    .banner_title {
        padding: 60px 60px 60px 60px;
        min-height: 220px;
        margin: 0 0 40px 0;

        h3 {
            font-size: 34px;
        }
    }

    .referral-copy-link {
        width: 100%;
    }
}

// Large devices (desktops, less than 1199px)
@media (max-width: 1199px) {
    .sideplayer_section {
        margin: 30px 0 0 0;
    }

    .cust_section .cards_info .cards_titles {
        bottom: -20px;
    }

    .cust_section .cards_info .cards_imgs {
        margin: 0px 0px 30px 0;
    }

    .sideplayer_fix {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
    }

    .cust_section {
        width: 30.33%;
        margin: 0 10px 20px 10px;
    }
}

// Medium devices (tablets, less than 992px)
@media (max-width: 1200px) {
    .sidemenu_section {
        left: -$sideMenuWidth;
        transition: 0.15s all ease-in-out;

        &.active {
            left: 0px;
            transition: 0.15s all ease-in-out;
        }
    }

    .side_menulinks {
        .close_menusec {
            display: flex;
        }
    }

    .responsive_menu {
        display: flex;
    }

    .main_section {
        width: 100%;
    }

    .side_title .view_btns {
        margin: 0;
    }
}

// Medium devices (tablets, less than 992px)
@media (max-width: 991px) {
    .auth_page {
        padding: 0 0 0 0;

        .auth_structure {
            padding: 0px 0px 40px 0px;
            width: 576px;

            .container-logo {
                padding: 8px 35px;

                &:after {
                    padding: 42px 135px;
                }

                &:before {
                    padding: 48px 152px;
                }
            }
        }

        .logreg_title {
            margin: 0 0 25px 0;

            h3 {
                font-size: 32px;
            }
        }
    }

    .cust_section {
        width: 45.33%;
        margin: 0 15px 20px 15px;
    }

    .search_input button {
        min-width: auto;
    }

    .responsive_menu {
        padding: 0px 8px 0px 8px;
        height: 42px;
    }

    .header_sec {
        .search_panel {
            width: 300px;

            .MuiPaper-elevation1 {
                border-radius: 6px;
                height: 42px;
            }
        }

        .user_header {
            position: relative;

            .header_links {
                a {
                    padding: 0px 12px 0px 12px;
                    height: 43px;

                    p {
                        font-size: 14px;
                    }
                }
            }
        }
    }

    // Footer Section Start
    .footer_section {
        padding: 50px 0 0 0;

        .footer_nav {
            padding: 0 0px 20px 0;

            ul {
                li {
                    display: block;

                    .f_links {
                        font-size: 14px;
                        margin: 0 0px 5px 0;
                        display: inline-block;
                    }
                }
            }

            .android_app {
                text-align: right;

                .app_links {
                    margin: 0 10px 0 0;

                    img {
                        width: 40%;
                    }
                }
            }
        }
    }

    .copyrights {
        padding: 20px 0;

        h4 {
            font-size: 14px;
        }
    }

    .assets-category-page {
        .assets-category-container {
            height: 250px;

            .trading-name-container {
                .trading-name {
                    font-size: 42px;
                    margin: 0;
                }
            }
        }
    }

    .assets-table {
        .button-flex {
            column-gap: 10px;
        }

        .table_section_one.MuiPaper-elevation1 {
            .tables {
                tbody {
                    tr {
                        td {
                            padding: 10px 10px 10px 10px;
                        }

                        .price_stock {
                            .ps_price {
                                line-height: 16px;
                                font-size: 16px;
                                margin: 0 0 5px 0;
                            }
                        }
                    }
                }
            }
        }
    }

    .banner_title {
        min-height: 180px;

        h3 {
            font-size: 30px;
        }
    }

    .referral-copy-link {
        padding: 10px 10px 10px 10px;
        margin: -90px 0px 20px 0px;
    }

    .affiliate_banner {
        .affiliate_texts {
            .affiliate_img {
                height: 220px;
            }

            .banner-txt {
                .b_title {
                    margin: 20px 0 10px 0;

                    h1 {
                        font-size: 32px;
                    }

                    span {
                        margin: 0px 0 0 0;
                    }
                }
            }
        }
    }

    .panel__label {
        font-size: 18px;
        padding: 15px 50px 15px 65px;

        &:after {
            top: 15px;
            left: 25px;
            width: 26px;
            height: 26px;
        }

        &:before {
            top: 22px;
            width: 14px;
            height: 14px;
        }
    }

    .faq-section {
        .category-item {
            h2 {
                font-size: 26px;
                padding-bottom: 25px;
            }
        }
    }

    .breadcrumbs {
        &.contactus_breadcrumbs {
            &::before {
                right: 50px;
                bottom: 0px;
                width: 400px;
            }
        }
    }

    .breadcrumbs {
        padding: 70px 30px;
    }

    .breadcrumbs_text h2 {
        font-size: 40px;
        line-height: 42px;
        margin: 0;
    }

    .contactus {
        .contact_form {
            padding: 30px 30px;
            border-radius: 10px;
            margin: 0px 0 0 0;
        }
    }
}

// Small devices (landscape phones, less than 768px)
@media (max-width: 767px) {
    .hidden-mobile-landscape {
        display: none !important;
    }

    .auth_page {
        .logreg_title {
            margin: 0 0 20px 0;

            h3 {
                font-size: 28px;
                max-width: 360px;
            }
        }

        .auth_structure {
            padding: 0px 35px 35px 35px;
            width: 480px;

            .container-logo {
                padding: 8px 35px;
                border-radius: 20px;

                img {
                    width: 130px;
                }

                &:after {
                    padding: 35px 115px;
                    border-radius: 25px;
                }

                &:before {
                    padding: 42px 127px;
                    border-radius: 25px;
                }
            }
        }
    }

    .about_card .main_profile .profileimg_info {
        bottom: 0px;
        align-items: center;
    }

    .about_card .main_profile .profileimg_info .profile_img::before {
        width: 115px;
        height: 115px;
    }

    .about_card .main_profile .profileimg_info .profile_img img {
        width: 76px;
        height: 76px;
    }

    // .about_card .main_profile {
    //   padding: 40px 10px 0 10px;
    // }
    .about_card .card_follower {
        padding: 20px 20px 20px 20px;
        align-items: center;
    }

    .about_card .card_follower .profile_info {
        margin: 0;
    }

    .cust_section {
        width: 45%;
        margin: 0 10px 20px 10px;
    }

    .header_sec {
        flex-wrap: wrap;

        .search_panel {
            width: 100%;
            order: 3;
            margin: 0px 0 0 0;
        }

        .user_header {
            justify-content: flex-end;
            margin: 0;
            margin-left: auto;

            .header_links {
                &.profil_icon {
                    margin: 0;
                }
            }
        }
    }

    .section_title h3 {
        font-size: 22px;
    }

    .table_section_one.MuiPaper-elevation1 {
        .tables {
            thead {
                tr {
                    th {
                        font-size: 16px;
                    }

                    td {
                        padding: 10px 10px 10px 10px;
                    }
                }
            }

            tbody {
                tr {
                    .player-details {
                        margin-left: 10px;

                        .playerName {
                            font-size: 16px;
                            margin: 0 0 3px 0;
                        }
                    }
                }
            }
        }
    }

    .spline_chart {
        .chart_cards {
            margin: 0 0 0 0;
        }

        .col-md-4 {
            &:last-child {
                .chart_cards {
                    margin: 0;
                }
            }
        }
    }

    .assets-category-page {
        .assets-category-container {
            height: 200px;

            .trading-name-container {
                .trading-name {
                    font-size: 34px;
                    margin: 0;
                }

                .trading {
                    font-size: 22px;
                }
            }
        }
    }

    .table_cust_scroll {
        max-height: 400px;
    }

    .banner_title {
        min-height: 150px;
        padding: 60px 40px 60px 40px;

        h3 {
            font-size: 28px;
        }
    }

    .faq-section {
        .category-item {
            flex-wrap: wrap;

            .cat_img {
                margin: 0 0px 20px 0;

                img {
                    width: 120px;
                }
            }
        }
    }

    .main_innerWrapper {
        padding-bottom: 0;
    }

    .faq-section {
        .category-item {
            padding-bottom: 40px;
        }
    }

    .rules_titlesec h3 {
        font-size: 28px;
    }

    .breadcrumbs {
        &.contactus_breadcrumbs {
            &::before {
                opacity: 0.2;
            }
        }
    }

    .breadcrumbs {
        padding: 50px 10px;
    }

    .breadcrumbs_text h2 {
        font-size: 36px;
    }

    .contactus {
        padding: 30px 0 30px 0;
    }

    .contactus .title_sec h3 {
        font-size: 28px;
        margin: 0 0 5px 0;
        line-height: 32px;
    }

    .contactus .title_sec h5 {
        font-size: 15px;
    }

    .contactus .title_sec {
        margin: 0 0 20px 0;
    }

    .contactus .contact_faqtitle:before {
        left: -13px;
        top: -9px;
        width: 40px;
        height: 40px;
        z-index: -1;
    }

    .contactus .contact_faqtitle {
        margin: 0 0 30px 0;
    }

    .contactus .contact_text .contact_sec {
        margin: 0 0 20px 0;
    }

    .contactus .contact_text .context_sec {
        margin: 0 0 0 10px;
        width: 100%;
    }

    .contactus .contact_form {
        padding: 20px 20px;
    }

    .contactus .contact_form .contact_title h3 {
        margin: 0 0 25px 0;
        font-size: 20px;
    }

    .contactus .contact_form .form_section .form-group .form-control {
        height: 45px;
        padding: 0px 20px 0 20px;
    }

    .contactus .contact_form .form_section .form-check {
        @include align-items(flex-start);
    }
}

// Extra small devices (portrait phones, less than 576px)
@media (max-width: 576px) {
    .hidden-mobile {
        display: none !important;
    }

    .asset-banner-footer {
        .content-banner {
            padding: 20px 30px 20px 30px;
        }
    }

    .auth_page {
        .auth_structure {
            width: 380px;
            padding: 0px 30px 30px 30px;
        }
    }

    .separate_or {
        margin: 10px 0;
    }

    .custinput_group {
        input {
            min-height: 40px;
        }
    }

    .loginsocial_buttons {
        justify-content: center;

        .custom_btn {
            width: 20%;
            padding: 10px 10px !important;
            font-size: 14px !important;
            margin: 0 5px;

            span {
                display: none;
            }

            img {
                width: 18px;
                height: 18px;
                margin: 0;
            }
        }
    }

    .custinput_group .form_label {
        font-size: 14px;
        margin: 0 0 5px 0;
    }

    .loginregi_form .forgot-password {
        font-size: 14px;
    }

    .custinput_group {
        margin: 0 0 15px 0;
    }

    .auth_structure {
        .container-logo {
            &:before {
                display: none;
            }
        }
    }

    .auth_page .logreg_title h3 {
        font-size: 25px;
        max-width: 360px;
    }

    .about_card .card_follower {
        padding: 10px 20px 10px 20px;
        align-items: center;
        justify-content: center;
    }

    .about_card .card_follower .profile_info {
        margin: 0 0 20px 0;
    }

    .about_card .main_profile .profileimg_info .profile_name .country_icon {
        justify-content: center;
    }

    .profile_name {
        width: 100%;
        justify-content: center;
        text-align: center;
        margin: 0 0 -10px 0;
    }

    .follower_card {
        flex-wrap: wrap;
    }

    .follower_card .follow_img {
        width: 100%;
    }

    .follower_card .follow_img {
        width: 100%;
        text-align: center;
    }

    .follower_card {
        padding: 20px 20px 20px 20px;
    }

    .follower_card .follow_img::before {
        left: 50%;
        top: 50%;
    }

    .follower_card .follow_img span {
        left: 50%;
        bottom: -25px;
        transform: translate(40%, -50%);
    }

    .follower_card .follow_text {
        width: 100%;
        margin: 20px 0 0 0px;
        flex-wrap: wrap;
    }

    .follow_name {
        width: 100%;
        text-align: center;
    }

    .search_input button {
        width: auto;
        min-width: auto;
    }

    .tab_header {
        .MuiTabs-flexContainer {
            display: flex;
            justify-content: center;
        }

        .PrivateTabIndicator-root-25 {
            display: none;
        }
    }

    .responsive_menu {
        padding: 0px 7px 0px 7px;
        height: 37px;

        svg {
            font-size: 24px;
        }
    }

    .header_sec {
        .user_header {
            .header_links {
                a {
                    padding: 0px 3px 0px 3px;
                    height: 37px;

                    p {
                        font-size: 12px;
                    }

                    svg {
                        font-size: 20px;
                    }
                }

                .portfolio_links {
                    svg {
                        font-size: 20px;
                        color: #97a8de;
                    }
                }
            }
        }

        .search_panel {
            .MuiPaper-elevation1 {
                border-radius: 6px;
                height: 38px;
            }
        }
    }

    .footer_section {
        .footer_nav {
            ul {
                li {
                    .f_links {
                        font-size: 12px;
                    }
                }
            }
        }
    }

    .sideplayer_section {
        .sidemenu_section {
            width: auto;
        }
    }

    .sidemenu_section {
        left: -280px;
        width: 280px;

        &.active {
            left: 0px;
        }
    }

    .sidemenu_links_sec {
        .container-side-menu-deconnexion {
            padding: 0px 0 20px 0;

            .btn-logout {
                font-size: 16px;
            }
        }

        .sidemenu_widget {
            a {
                padding: 0px 0 0 20px;
                font-size: 14px;

                svg {
                    font-size: 18px;
                }
            }
        }

        .sidemenu_trading {
            padding: 20px 0 20px 0px;

            .title-section-side-menu {
                font-size: 16px;
                padding: 0 0 0 30px;
            }

            a {
                padding: 0px 0 0 20px;

                .linkside_logo {
                    span {
                        font-size: 18px;
                        margin: 0 10px 0 0;
                    }
                }
            }
        }
    }

    .sidemenu_dropdown {
        margin-top: 20px;
    }

    .side_menulinks {
        .sidemenu_logo {
            margin: 0 0 5px 0;

            img {
                width: 91px;
            }
        }

        .profile_logosec {
            padding: 45px 10px 20px 10px;
        }

        .close_menusec {
            top: 45px;
            font-size: 16px;
        }
    }

    .emptylist_buttons {
        width: 60%;
    }

    .atitle_sec {
        margin: 20px 0 20px 0;

        h3 {
            font-size: 24px;
            margin: 0 0 15px 0;
        }
    }

    .side_title {
        padding: 10px 20px 10px 20px;

        .view_btns {
            margin: 0;
        }
    }

    .footer_section {
        padding: 30px 0 0 0;
    }

    .cust_section {
        .cards_info {
            .cards_titles {
                .cards_name {
                    h4 {
                        white-space: nowrap;
                        text-overflow: ellipsis;
                        overflow: hidden;
                        padding: 0 20px;
                        font-size: 16px;
                    }
                }
            }
        }
    }

    .atitle_sec {
        h3 {
            font-size: 20px;
            margin: 0 0 15px 0;
        }
    }

    .round_table {
        .table_section_one.MuiPaper-elevation1 {
            .tables {
                tbody {
                    tr {
                        td {
                            &:first-child {
                                padding-left: 15px;
                            }

                            &:last-child {
                                padding-right: 15px;
                            }
                        }
                    }
                }
            }
        }
    }

    .assets-table {
        .button-flex {
            column-gap: 10px;
        }

        .table_section_one.MuiPaper-elevation1 {
            .tables {
                tbody {
                    tr {
                        td {
                            padding: 10px 10px 10px 10px;
                            margin-right: 0;

                            &:nth-child(2) {
                                text-align: right;

                                .greenTxt,
                                .redTxt {
                                    text-align: right;
                                }
                            }
                        }

                        .price_stock {
                            .ps_price {
                                line-height: 14px;
                                font-size: 16px;
                                margin: 0 0 4px 0;
                            }

                            .ps_stock {
                                display: inline-block;
                                text-align: right;
                            }
                        }

                        .primaryCta {
                            Button {
                                height: 30px;
                                font-size: 12px;
                                padding: 3px 16px 0 16px;
                                cursor: pointer;
                            }
                        }
                    }
                }
            }
        }
    }

    .cust_section {
        .cards_info {
            .follow_price {
                padding: 10px 0 10px 0;

                .f_price {
                    h4 {
                        font-size: 16px;
                        margin: 0 0 5px 0;
                    }
                }
            }

            .cards_titles {
                bottom: -10px;
                height: 70px;

                .cards_name {
                    margin: 0px 0px 0px 0;
                    bottom: -5px;
                }
            }

            .cards_imgs {
                margin: 0px 0px 0px 0;
            }
        }
    }

    .banner_title {
        min-height: 140px;
        padding: 50px 40px 50px 40px;

        h3 {
            font-size: 26px;
        }
    }

    .affiliate_banner {
        .affiliate_texts {
            .banner-txt {
                right: -16%;
            }
        }
    }

    .affiliate_banner {
        .affiliate_texts {
            .affiliate_img {
                height: 170px;
            }
        }
    }

    .referral-copy-link {
        border-radius: 15px;

        .referral-link-txt {
            border-radius: 10px;
            justify-content: center;
            flex-wrap: wrap;

            .copy-link-img {
                display: none;
            }

            .cust_linktext {
                h3 {
                    font-size: 14px;
                    margin: 5px 0 0 0;

                    span {
                        font-size: 14px;
                    }
                }
            }
        }
    }

    .referral-copy-link button {
        position: relative;
        right: 0;
        width: 100%;
        line-height: 25px;
        margin: 10px 0 0 0;
        border-radius: 7px;
        font-size: 14px;
    }

    .affiliate-overview-page {
        .col-lg-10 {
            padding: 0;
        }
    }

    .rules_titlesec h3 {
        font-size: 23px;
        width: 100%;
        text-align: center;
    }

    .rules_tabs {
        margin: 0 0 30px 0;
    }

    .faq-section .category-item .cat_img {
        margin: 0 0px 20px 0;
        width: 100%;
        text-align: center;
    }

    .faq-section .category-item .cat_img img {
        width: 90px;
    }

    .faq-section .category-item h2 {
        font-size: 22px;
        padding-bottom: 15px;
        text-align: center;
    }

    .contactus .contact_text .context_sec h4 {
        font-size: 16px;
    }

    .contact_sec img {
        width: 40px;
    }

    .contactus .contact_form .form_section .form_btn button {
        padding: 11px 20px !important;
        width: 100%;
    }

    .myplayers_table {
        margin: 0;
    }

    .container-btn-histories {
        padding-bottom: 30px;
    }

    .table_section_one.MuiPaper-elevation1 .tables thead tr th {
        font-size: 16px;
        padding: 10px 10px 10px 10px;
    }

    .spline_chart .chart_cards .chart_label {
        padding: 55px 15px 0px 15px;
    }

    .dark_light_switch {
        .button {
            width: 90px;
            height: 35px;
            box-shadow: 0px 7px 20px 0px rgba(0, 0, 0, 0.2);
        }

        #button-11 {
            .knobs {
                span {
                    width: 40px;
                    height: 26px;
                }

                &::before {
                    left: 6px;
                    top: 1px;
                    font-size: 13px;
                }

                &::after {
                    right: 23px;
                    top: 1px;
                    font-size: 13px;
                }
            }
        }
    }

    .header_sec {
        .user_header {
            .header_links {
                a {
                    padding: 0px 10px 0px 10px;
                    height: 37px;
                }

                .portfolio_links {
                    .percentage-number-wallet {
                        font-size: 14px;
                    }
                }
            }
        }
    }
}

// Extra small devices (portrait phones, less than 420px)
@media (max-width: 480px) {
    .cust_section {
        width: 100%;
        margin: 0 0px 20px 0px;
    }

    .follower_card .follow_text {
        justify-content: center;
    }

    .sportf_icons {
        width: 100%;
        text-align: center;
        display: flex;
        justify-content: center;
        margin: 5px 0 15px 0px;
    }

    .tab_header {
        .MuiTabs-flexContainer {
            flex-wrap: wrap;
        }

        .MuiTab-root {
            width: 100%;
        }
    }

    .about_card .main_profile .profileimg_info .profile_name h3 {
        font-size: 20px;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        max-width: 300px;
    }

    // .about_card .main_profile {
    //   padding: 20px 10px 0 10px;
    // }

    .footer_section .footer_nav {
        padding: 0 0px 20px 0;
        flex-wrap: wrap;
        width: 100%;
        justify-content: center;
    }

    .footer_section .footer_nav .android_app {
        text-align: center;
        margin: 5px 0 0 0;
    }

    .footer_nav {
        ul {
            text-align: center;
            width: 100%;
        }
    }

    .footer_section {
        padding: 30px 0 0 0;
    }

    .copyrights {
        padding: 10px 0;
    }

    .copyrights_social {
        justify-content: center;
        flex-wrap: wrap;
        margin: 10px 0 0 0;
    }

    .icon-socials {
        width: 100%;
        justify-content: center;
        display: flex;
        margin: 10px 0 0 0;

        a {
            svg {
                font-size: 18px;
            }
        }
    }

    .emptylist_buttons {
        width: 100%;
    }

    .custinput_group {
        input {
            font-size: 14px;
        }

        .form_label {
            font-size: 12px;
        }
    }

    .cust_dropdown {
        min-height: 35px;
        font-size: 14px;
        min-width: 180px;
    }

    .side_title {
        h3 {
            font-size: 16px;
        }
    }

    .profil_section {
        .profile_img {
            width: 80px;
            height: 80px;

            img {
                width: 55px;
                height: 55px;
            }
        }
    }

    .cust_section {
        .cust_btns {
            .custom_btn {
                padding: 7px 20px !important;
            }
        }
    }

    .assets-category-page {
        .assets-category-container {
            height: 190px;

            .trading-name-container {
                .trading-name {
                    font-size: 26px;
                    margin: 0;
                }

                .trading {
                    font-size: 20px;
                }
            }
        }
    }

    .assets-category-page {
        .search-players {
            margin: 10px auto 10px auto;
        }
    }

    .search-bar {
        .input-search {
            padding: 7px 6px 6px 20px;
        }

        .search-container {
            .search-button-container {
                .primary-button {
                    cursor: pointer;
                    padding: 0px 20px 0px 20px;
                }
            }
        }

        .search-button-container {
            right: 0px;
        }
    }

    .assets-table {
        .table_section_one.MuiPaper-elevation1 {
            .tables {
                tbody {
                    tr {
                        .player-details {
                            .playerName {
                                font-size: 13px;
                                margin: 0 0 2px 0;
                            }
                        }
                    }
                }
            }
        }
    }

    .banner_title {
        min-height: 140px;
        padding: 50px 40px 50px 40px;
        justify-content: center;

        h3 {
            font-size: 26px;
        }
    }

    .affiliate_banner {
        .affiliate_texts {
            .banner-txt {
                right: -23%;
            }
        }
    }

    .panel__label {
        font-size: 16px;
        padding: 15px 50px 15px 65px;
    }

    .panel__inner p {
        padding: 15px;
        font-size: 16px;
        line-height: 24px;
    }

    .sidemenu_dropdown {
        i {
            left: 30%;
        }
    }

    .breadcrumbs_text h2 {
        font-size: 30px;
    }

    .breadcrumbs {
        padding: 40px 10px;
    }

    .contactus .title_sec h3 {
        font-size: 26px;
    }

    .header_sec .search_panel .MuiInputBase-formControl {
        height: 40px;
        padding: 0;
    }

    .contactus {
        .col-lg-7 {
            padding: 0;
        }
    }

    .contactus {
        padding: 30px 0 0px 0;
    }

    .contactus .contact_form .form_section .form-group .form-label {
        font-size: 14px;
        margin: 0 0px 5px 0;
    }
}

// Extra small devices (portrait phones, less than 420px)
@media (max-width: 420px) {
    .dark_light_switch {
        .button {
            width: 60px;
            height: 30px;
            box-shadow: 0px 7px 20px 0px rgba(0, 0, 0, 0.2);
        }

        #button-11 {
            .knobs {
                span {
                    width: 26px;
                    height: 22px;
                }

                &::before {
                    content: 'D';
                    left: 6px;
                    top: -1px;
                    font-size: 13px;
                }

                &::after {
                    content: 'L';
                    right: 6px;
                    top: -1px;
                    font-size: 13px;
                }
            }
        }
    }

    .auth_page .logreg_title h3 {
        font-size: 20px;
    }

    .auth_page .auth_structure {
        padding: 70px 30px 30px 30px;

        @media (max-height: 715px) and (max-width: 400px) {
            height: 100%;
            top: 0px;
        }

        &::after {
            z-index: -1 !important;
        }
    }

    .auth_page .logreg_title {
        margin: 0 0 10px 0;
    }

    .profil-icon .profile_img {
        width: 90px;
        height: 90px;
    }

    .profil-icon .profile_img img {
        width: 50px;
        height: 50px;
    }

    .responsive_menu {
        margin: 0px 5px 0px 0px;
    }

    .header_sec {
        .user_header {
            .header_links {
                a {
                    margin: 0 0 0 5px;
                }
            }
        }
    }

    .copyrights {
        h4 {
            font-size: 12px;
        }
    }

    .sidemenu_section {
        left: -290px;
        width: 280px;

        &.active {
            left: 0px;
        }
    }

    .side_menulinks {
        .close_menusec {
            font-size: 16px;
            font-weight: 700;
        }

        .sidemenu_logo {
            img {
                width: 91px;
            }
        }

        .sidemenu_trading {
            .title-section-side-menu {
                font-size: 15px;
                padding: 0 0 0 20px;
                margin: 0 0 20px 0;
            }
        }
    }

    .about_card {
        .main_profile {
            .profileimg_info {
                .profile_img {
                    margin: 10px 0px 0 0;
                    width: 120px;
                    height: 120px;

                    &:before {
                        width: 95px;
                        height: 95px;
                    }

                    img {
                        width: 65px;
                        height: 65px;
                    }
                }
            }
        }
    }

    .tab_header {
        .MuiTab-root {
            min-height: 35px;
        }
    }

    .about_card {
        .card_follower {
            .profile_info {
                margin: 0 0 15px 0;
            }

            .follower_section {
                .cardfollo_sec {
                    margin: 0px 15px 0px 0;
                    padding: 0 15px 0 0;
                }
            }
        }
    }

    .custinput_group {
        margin: 0 0 10px 0;
        padding: 0;
    }

    .profile_name {
        margin: 0 0 -20px 0;
    }

    .user-information-form {
        .button-submit {
            padding: 0;
        }
    }

    .assets-category-page {
        .assets-category-container {
            height: 160px;
            margin-bottom: 3px;

            .trading-name-container {
                .trading-name {
                    font-size: 20px;
                    margin: 0;
                }

                .trading {
                    font-size: 18px;
                }
            }
        }
    }

    .assets-table {
        .table_section_one.MuiPaper-elevation1 {
            .tables {
                border-spacing: 0 5px;

                tbody {
                    tr {
                        td {
                            padding: 3px 5px 3px 5px;
                        }
                    }
                }
            }
        }
    }

    .assets-table .button-flex {
        column-gap: 0px;
    }

    .search-bar {
        .search-container {
            .search-button-container {
                right: 1px;

                .primary-button {
                    cursor: pointer;
                    padding: 0px 14px 0px 14px;
                }
            }
        }
    }

    .assets-category-page {
        .search-players {
            padding: 5px 10px 5px 10px;
        }
    }

    .banner_title {
        min-height: 110px;
        padding: 30px 20px 30px 20px;
        justify-content: center;
        margin: 0 0 25px 0;

        h3 {
            font-size: 22px;
        }
    }

    .affiliate_banner {
        .affiliate_texts {
            .affiliate_img {
                height: 150px;
                opacity: 0.2;
            }

            .banner-txt {
                right: inherit;
                left: 50%;
                width: 100%;

                .b_title {
                    width: 100%;

                    h1 {
                        font-size: 26px;
                        text-align: center;
                    }
                }

                .b_sumtitle {
                    h4 {
                        font-size: 14px;
                        text-align: center;
                    }

                    span {
                        text-align: center;
                        font-size: 14px;
                    }
                }
            }
        }
    }

    .affiliate_banner {
        .affiliate_texts {
            background-color: #7948d9;
        }
    }

    .panel__label {
        font-size: 14px;
        padding: 10px 50px 10px 55px;
    }

    .panel__label:after {
        top: 10px;
        left: 22px;
        width: 20px;
        height: 20px;
    }

    .panel__label:before {
        top: 14px;
        right: 16px;
        width: 14px;
        height: 14px;
    }

    .panel__inner p {
        padding: 10px;
        font-size: 14px;
    }

    .table_section_one.MuiPaper-elevation1 {
        .tables {
            tbody {
                tr {
                    td {
                        font-size: 14px !important;
                        font-weight: normal;
                        padding: 8px 0 8px 10px;
                    }

                    .player-details .playerName {
                        font-size: 14px;
                    }
                }
            }
        }
    }

    .assets-table .table_section_one.MuiPaper-elevation1 .tables tbody tr td {
        padding: 5px 0px;
        margin-bottom: 5px;
    }

    .sidemenu_links_sec .sidemenu_trading a,
    .sidemenu_links_sec .sidemenu_widget a {
        margin: 0;
    }

    .col,
    .col-1,
    .col-10,
    .col-11,
    .col-12,
    .col-2,
    .col-3,
    .col-4,
    .col-5,
    .col-6,
    .col-7,
    .col-8,
    .col-9,
    .col-auto,
    .col-lg,
    .col-lg-1,
    .col-lg-10,
    .col-lg-11,
    .col-lg-12,
    .col-lg-2,
    .col-lg-3,
    .col-lg-4,
    .col-lg-5,
    .col-lg-6,
    .col-lg-7,
    .col-lg-8,
    .col-lg-9,
    .col-lg-auto,
    .col-md,
    .col-md-1,
    .col-md-10,
    .col-md-11,
    .col-md-12,
    .col-md-2,
    .col-md-3,
    .col-md-4,
    .col-md-5,
    .col-md-6,
    .col-md-7,
    .col-md-8,
    .col-md-9,
    .col-md-auto,
    .col-sm,
    .col-sm-1,
    .col-sm-10,
    .col-sm-11,
    .col-sm-12,
    .col-sm-2,
    .col-sm-3,
    .col-sm-4,
    .col-sm-5,
    .col-sm-6,
    .col-sm-7,
    .col-sm-8,
    .col-sm-9,
    .col-sm-auto,
    .col-xl,
    .col-xl-1,
    .col-xl-10,
    .col-xl-11,
    .col-xl-12,
    .col-xl-2,
    .col-xl-3,
    .col-xl-4,
    .col-xl-5,
    .col-xl-6,
    .col-xl-7,
    .col-xl-8,
    .col-xl-9,
    .col-xl-auto {
        padding-right: 7px;
        padding-left: 7px;
    }
}

// Extra small devices (portrait phones, less than 360px)
@media (max-width: 360px) {
    .auth_page .auth_structure {
        width: 340px;
    }

    .auth_page .auth_structure .container-logo:after {
        padding: 30px 97px;
        border-radius: 20px;
    }

    .main_section {
        padding: 0 0px 0 0px;
    }

    .empty-list-placeholder {
        padding: 20px 10px;
    }
}
// Limiting main section width to 650px
.main_section {
  @media (min-width:1200px) {
    width: 650px;
    padding: 0px 16px;
    border-left: 1.5px solid #33395A25;
    border-right: 1.5px solid #33395A25;
  }
  
  &:has(.full-width-banner-img) {
    padding: 0px 0px;
    .auth_structure {
        // background: linear-gradient(180deg, #E1E4FC 0%, #E1E4FC 80%, #F7F8FF 85%) !important;
    }
  }

}

.sidemenu_section {
    @media (min-width: 1200px) {
        min-width: 325px;
        width: calc((100vw - 650px) / 2);
        display: flex;
        justify-content: flex-end;
        z-index: 1;

        .side_menulinks {
            width: 315px;
        }
    }
}

.admin-mode .sidemenu_section {
    @media (min-width: 1200px) {
        width: 325px;
    }
}

.admin-mode .main_section {
    @media (min-width: 1200px) {
        min-width: 325px;
        width: calc(100vw - 325px);
    }
}
