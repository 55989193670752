@import 'style/colors.scss';
@import 'style/font.scss';
@import '../../../../assets/scss/components/mixins';

.buy-asset-slider-halfScreen {
    width: 650px;
    box-sizing: border-box;
    @include plus-jakarta-font;
    position: fixed;
    bottom: 0px;
    left: calc((100vw - 650px)/2);
    max-height: 100%;
    background: $app-background;
    z-index: 20;
    padding: 37px 16px 0px 16px;
    overflow-y: auto;
    overflow-x: hidden;
    color: $tertiary-grey;
    border: none;
    border-top: 2px solid #C7CEFB;
    box-shadow: 0px -2px 6px #00000029;

    .buy-asset-wrapper {
        position: relative;
        padding-bottom: 70px;

        .all-token-information-container {
            background: none;
            padding: 0;
            margin: 0;
            padding-top: 10px;
            min-height: auto;
            justify-content: center;

            .info {
                justify-content: flex-start;
                width: auto;
                display: grid;
                grid-template-columns: 30px 112px;
                grid-template-rows: 15px 21px;

                &:not(:last-child)::after {
                    content: none
                }

                .icon {
                    grid-area: span 2/ span 1;
                }

                .label {
                    margin: 0 auto;
                }

                &.token-price .icon {
                    height: 28px;
                    width: 22px;
                    justify-self: end;
                }

                .value {
                    margin: 0 auto;
                }

                &.token-available {
                    .icon svg {
                        height: 34px;
                        width: 31px;
                    }
                }
            }
        }
    }

    .arrow-back {
        color: black;
        position: absolute;
        top: 20px;
        left: 0px;
        cursor: pointer;
        z-index: 1;

        svg {
            height: 48px;
            width: 36px;
        }
    }

    .container-button-continue {
        text-align: center;
        position: relative;

        .prePrivateSale-warning {
            position: absolute;
            margin: 5px auto 0px;
            left: 0; 
            right: 0; 
            background: linear-gradient(90deg, #ed213a, #93291e);
            background-clip: text;
            -webkit-text-fill-color: transparent;
        }
    }

    .buy-asset-continue-button {
        width: 100%;
        max-width: 350px;
        margin-top: 20px;
        border-radius: 11px;

        &.green-btn{
            background: $medium-green;

            &:disabled{
                background: $disabled-medium-green;
                    cursor: not-allowed;
            }
        }

        &.red-btn{
            background: $medium-red;

            &:disabled{
                background:$disabled-medium-red;
                    cursor: not-allowed;
            }
        }
    }


}