@import 'style/colors.scss';
@import 'style/size.scss';

.green-button {
    background: $green-button-color;
    position: relative;
    border: none;
    width: 100%;
    color: #fff;
    text-transform: uppercase;
    transition: 0.2s;
    border-radius: 11px;
    font-weight: bold;
    padding-right: 13px;
    padding-left: 13px;
    line-height: 35px;
    cursor: pointer;
    overflow:hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    
    .subtitle {
        font-weight: 100;
    }
    
    &:hover{
        transition: 0.2s all ease;
        opacity: 0.7;
    }
    &:disabled{
        cursor: not-allowed;
        opacity: 0.5;
    }

    &.btn-shadow{
        box-shadow: 0px 3px 30px $green-button-shadow-color;
    }

    .green-button__icon {
        margin-right: 5px;
        display: flex;
        align-items: center;
    }
}

.green-button-gradient {
    line-height: 1.75;
    overflow:hidden;
    border-radius: 25px;
    padding: 5px 10px 5px 10px;
    position: relative;
    box-sizing: border-box;
    width: 100%;
    font-weight: 500;
    font-size: 13px;
    border: 1px solid transparent;
    -webkit-transition: background-color 0.25s ease-out, color 0.25s ease-out;
    transition: background-color 0.25s ease-out, color 0.25s ease-out;
    -webkit-appearance: none;
    text-align: center;
    cursor: pointer;
    background-color: #6e7f91;
    color: #fefefe;
    text-decoration: none;
    border: 0;
    text-shadow: 0 0 5px rgba(0, 0, 0, 0.25);
    background: #32c768;
    background-image: linear-gradient(to right, #32c768, #75e69f, #75e69f, #75e69f);
    background-size: 300% 100%;
    -moz-transition: all 0.4s ease-in-out;
    -o-transition: all 0.4s ease-in-out;
    -webkit-transition: all 0.4s ease-in-out;
    transition: all 0.4s ease-in-out;

    &:hover {
        background-position: 100% 0;
        -moz-transition: all 0.4s ease-in-out;
        -o-transition: all 0.4s ease-in-out;
        -webkit-transition: all 0.4s ease-in-out;
        transition: all 0.4s ease-in-out;
    }

    @media (max-width: $mobile) {
        font-size: 12px;
    }
}

.uppercase {
    font-size: 14px;
    text-transform: uppercase;
}
