.score-booster-detail-block {
    margin-top: 20px;
    padding-top: 20px;
    display: flex;
    flex-direction: column;

    h2 {
        font-weight: 700;
        font-size: 18px;
        margin-top: 15px;
        color: black;
    }
}