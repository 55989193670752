.tournament-level-button {

    &.MuiButton-text {
        padding: 0;
    }

    &.MuiButton-root {
        padding: 0;
        border-radius: 9px;
    }

    .MuiButton-label {
        height: 100%;
    }

}