// === Light-Style Responsive Start

@import "./components/mixins";

// Medium devices (tablets, less than 992px)
@media (max-width: 1100px) {
    .responsive_menu {
        padding: 0px 12px 0px 12px;
        height: 45px;
    }

    .light_demo {

        .side_menulinks {
            .close_menusec {
                color: #ffffff;
                top: 45px;
            }
        }
    }
}

// Large devices (desktops, less than 1024px)
@media (max-width: 1024px) {
    .light_demo {
        .asset-profil-page {
            .assetprofil_con {
                .tokens-remaining {
                    margin: 0 0px 30px 0;
                }
            }
        }
    }
    .container-init-payment {
        .container-bouton-shares {
            width: 100%;
            .container-payment {
                .bg-init-payment {
                    .container-invoice {
                        .container-first-total {
                            padding-left: 0px;
                            padding-right: 0px;
                        }
                    }
                }
            }
        }
    }
}

// Medium devices (tablets, less than 992px)
@media (max-width: 991px) {
    .responsive_menu {
        padding: 0px 10px 0px 10px;
        height: 42px;
    }

    .light_demo {
        .about_card {
            .main_profile {
                .profileimg_info {
                    .profile_img {
                        &::after {
                            width: 130px;
                            height: 130px;
                        }
                        &::before {
                            width: 115px;
                            height: 115px;
                        }
                    }
                }
            }
        }
    }
}

// Medium devices (tablets, less than 700px for Bottom Buttons)
@media (max-width: 700px) {
    .main_section {
        width: 100%;
    }

    .light_demo {
        .empty-list-placeholder {
            .effect_round {
                opacity: 0.25;
                filter: blur(2px);
            }
        }
    }
}

// Small devices (landscape phones, less than 768px)
@media (max-width: 767px) {
    .light_demo {
        .auth_page {
            .auth_structure {
                &::before {
                    opacity: 0.4;
                }
                &::after {
                    opacity: 0.6;
                }
            }
        }
    }

    .light_demo {
        .empty-list-placeholder {
            .effect_round {
                top: 60px;
                left: -170px;
            }
        }
    }
    .myplayers_table {
        margin: 0 0 30px 0;
    }
    .spline_chart {
        margin: 0 0 0 0;
    }
    .container-btn-histories {
        padding-bottom: 20px;
    }
    .footer_section {
        padding: 20px 0 0 0;
    }
    .responsive_menu {
        padding: 0px 10px 0px 10px;
        height: 42px;
    }
    .light_demo {
        .about_card {
            .main_profile {
                .profileimg_info {
                    .profile_img {
                        &::before {
                            width: 95px;
                            height: 95px;
                        }
                        &::after {
                            width: 110px;
                            height: 110px;
                        }
                    }
                }
            }
        }
    }
}

// Medium devices (tablets, less than 700px for Bottom Buttons)
@media (max-width: 700px) {
    .main_section {
        width: 100%;
    }
}

// Extra small devices (portrait phones, less than 576px)
@media (max-width: 576px) {
    .about_card {
        .main_profile {
            .profileimg_info {
                .profile_img {
                    span {
                        right: 15px;
                        bottom: 25px;
                        width: 40px;
                        height: 40px;
                        font-size: 5px;
                    }
                }
                .profile_name {
                    h3 {
                        font-size: 26px;
                        max-width: 100%;
                    }
                }
            }
        }
        .card_follower {
            .follower_section {
                .cardfollo_sec {
                    .cardfollo_name {
                        p {
                            font-size: 12px;
                        }
                    }
                }
            }
        }
    }

    .light_demo {
        .tab_header {
            .MuiTabs-flexContainer {
                .Mui-selected {
                    border-bottom: 1px solid #7180E3;
                }
            }
        }
        .header_sec {
            .user_header {
                .header_links {
                    a {
                        padding: 0px 15px 0px 15px;
                        height: 37px;
                    }
                    .portfolio_links {
                        .percentage-number-wallet {
                            font-size: 12px;
                        }
                    }
                }
            }
        }
        .dark_light_switch {
            .button {
                width: 90px;
                height: 35px;
                box-shadow: 0px 7px 20px 0px rgba(0, 0, 0, 0.2);
            }
            #button-11 {
                .knobs {
                    span {
                        width: 40px;
                        height: 26px;
                    }
                    &::before {
                        left: 6px;
                        top: 1px;
                        font-size: 13px;
                    }
                    &::after {
                        right: 23px;
                        top: 1px;
                        font-size: 13px;
                    }
                }
            }
        }
    }
    .responsive_menu {
        padding: 0px 8px 0px 9px;
        height: 37px;
    }
    .atitle_sec h3 {
        margin: 0 0 0px 0;
    }

    .light_demo {
        .auth_page {
            .auth_structure {
                .auth-form {
                    .loginregi_form {
                        .custinput_group {
                            .form_label {
                                font-size: 14px;
                                margin: 0 0 10px 0;
                            }
                        }
                    }
                }
            }
        }
    }
    .auth_page {
        .auth_structure {
            .container-logo {
                margin: 0 auto 0px auto !important;
                img {
                    width: 140px !important;
                }
            }
        }
        .logreg_title {
            margin: 0 0 15px 0;
        }
    }

    .emptylist_buttons {
        width: 100%;
        max-width: 330px;
    }

    .light_demo {
        .empty-list-placeholder {
            width: calc(100% - 0px);
        }
    }
    .light_demo {
        .side_menulinks {
            .close_menusec {
                top: 45px;
            }
        }
        .header_sec {
            .user_header {
                .header_links {
                    .portfolio_links {
                        svg {
                            color: #25294a;
                        }
                    }
                }
            }
        }
    }
    .light_demo {
        .main_section::after {
            filter: blur(5px);
        }
    }

    // Ressponsive Col For Mobile
    .responsive_col {
        padding: 0;
    }
    // Ressponsive Col For Mobile
}

// Extra small devices (portrait phones, less than 420px)
@media (max-width: 480px) {
    .spline_chart {
        .col-md-12 {
            padding-right: 15px;
            padding-left: 15px;
        }
    }

    .light_demo {
        .asset-profil-page {
            .container-init-payment {
                .container-bouton-shares {
                    .container-payment {
                        .bg-init-payment {
                            .container-invoice {
                                .container-first-total {
                                    p {
                                        font-size: 14px;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        .separate_or {
            margin: 13px 0;
        }
        .sidemenu_dropdown i {
            left: 30%;
            font-size: 16px;
            .cust_dropdown {
                font-size: 14px;
            }
        }
    }
    .light_demo {
        .auth_page {
            .custinput_group {
                .form_label {
                    font-size: 14px;
                }
            }
            .auth_structure .auth-form .loginregi_form .forgot-password a {
                font-size: 14px;
            }
        }
    }
}

// Extra small devices (portrait phones, less than 420px)
@media (max-width: 420px) {
    .light_demo {
        .dark_light_switch {
            .button {
                width: 60px;
                height: 30px;
                box-shadow: 0px 7px 20px 0px rgba(0, 0, 0, 0.2);
            }
            #button-11 {
                .knobs {
                    span {
                        width: 26px;
                        height: 22px;
                    }
                    &::before {
                        content: "D";
                        left: 6px;
                        top: -1px;
                        font-size: 13px;
                    }
                    &::after {
                        content: "L";
                        right: 6px;
                        top: -1px;
                        font-size: 13px;
                    }
                }
            }
        }
        .header_sec {
            .user_header {
                .header_links {
                    a {
                        margin: 0 0 0 5px;
                    }
                }
            }
        }

        .about_card {
            .main_profile {
                .profileimg_info {
                    .profile_img {
                        &::after {
                            width: 90px;
                            height: 90px;
                        }
                        &::before {
                            width: 82px;
                            height: 82px;
                        }
                        span {
                            right: 7px;
                            bottom: 21px;
                            width: 33px;
                            height: 33px;
                            border: 3px solid #ffffff;
                            .pencil {
                                svg {
                                    width: 17px;
                                }
                            }
                        }
                    }
                }
            }
        }

        .cust_section {
            .profil_section {
                .profile_img {
                    &::before {
                        width: 75px;
                        height: 75px;
                    }
                    &::after {
                        width: 85px;
                        height: 85px;
                    }
                }
            }
        }
    }

    .auth_page {
        .auth_structure {
            padding: 0px 16px 30px 16px;
        }
    }
    .light_demo {
        .separate_or {
            span {
                font-size: 12px;
            }
        }
    }
    .loginregi_form {
        .forgot-password {
            margin: 10px 0 0px 0;
        }
    }
    .light_demo {
        .custom_btn_discreet {
            padding: 13px 15px !important;
        }
    }

    .emptylist_buttons {
        width: 100%;
        max-width: 280px;
        .primary-button {
            font-size: 16px;
            padding: 10px 0 10px 0px;
            font-weight: 500;
            letter-spacing: 0.02em;
        }
    }
}

// Extra small devices (portrait phones, less than 360px)
@media (max-width: 360px) {
    .header_sec {
        .user_header {
            .header_links {
                a {
                    svg {
                        font-size: 16px;
                    }
                }
            }
        }
    }
    .responsive_menu {
        padding: 0px 6px 0px 6px;
    }
}

// === Light-Style Responsive End
