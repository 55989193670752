@import 'style/colors.scss';
@import 'style/size.scss';
@import 'style/font.scss';

.sidemenu_section {
    background: $app-background;
    overscroll-behavior: contain;

    .disabled {
        pointer-events: none;
        opacity: 0.5;
    }

    .side_menulinks {

        display: flex;
        flex-direction: column;
        min-height: 100vh;
        background: $app-background;
        padding: 0px 14px 0px 7px;

        @media(min-width:1200px) {
            padding: 0px 24px 0px 0px;
        }

        .sidemenu_logo {
            img {
                position: relative;
                left: 50px;
                top: 5px;

                @media (min-width:1200px) {
                    left: 0px;
                    top: 0px;
                }
            }
        }

        .sidemenu_widget {
            @include plus-jakarta-font;
            display: flex;
            flex-direction: column;
            gap: 7px;

            a {
                color: $tertiary-grey;
                @include fontWeightSize(medium, 15px);
                padding: 12.5px 0 12.5px 20px;
                border-radius: 9px;
            }

            a.primary-menu-item {
                @include fontWeightSize(medium, 16px);
                background: #EDEEF780;
                padding-top: 20px;
                padding-bottom: 20px;
                border-radius: 32px;
                margin-bottom: -2px;


                &.primary-menu-item_end {
                    margin-bottom: 20px;
                }

                &:hover,
                &.active {
                    font-weight: bold;
                    background: #6677F51F;
                }

                .extrafield {
                    color: #5C76E4;
                    display: flex;
                    font-size: 12px;

                    svg {
                        margin-left: 70px;
                        filter: invert(40%) sepia(89%) saturate(1352%) hue-rotate(212deg) brightness(94%) contrast(90%);
                        margin-right: 0;
                        transform: scale(0.8);
                    }
                }
            }

            a.link-gold,
            a.link-gold.active,
            a.link-gold:hover {
                background: linear-gradient(283deg, #FFD66740, #E0A04540, #E0A04540, #FFD66740);
                color: #AD782C;
                font-size: 12px;
                line-height: 20px;
                padding-top: 12.5px;
                padding-bottom: 12.5px;
                font-style: italic;

                svg path {
                    fill: #AD782C;
                }

                .link-gold-text::first-line {
                    font-size: 14px;
                    font-style: normal;
                }
            }
        }

        .sidemenu_dropdown {
            margin-bottom: 20px;
        }

        .icon-side-bar {
            height: auto;
            width: 18px;
            margin-right: 10px;
        }

        .legal_links {
            @include plus-jakarta-font;
            color: $tertiary-grey;
            margin-bottom: 40px;
            opacity: 0.7;
            margin-top: 10px;
            padding-bottom: 28px;

            .legal_links-title {
                margin-left: 20px;
                @include fontWeightSize(medium, 12px);
                text-transform: uppercase;
                cursor: pointer;

                svg {
                    position: relative;
                    top: 4px;
                    left: 6px;
                    height: 16px;
                    width: 16px;
                    transition: transform 0.2s ease-in;
                }

                .rotate {
                    transform: rotate(-180deg);
                    transition: transform 0.2s ease-in;
                }
            }

            .legal_links-container {
                @include fontWeightSize(medium, 12px);
                margin-left: 40px;
                margin-top: 20px;
                display: flex;
                flex-direction: column;
                color: $tertiary-grey;
                max-height: 0px;
                transition: max-height 0.5s ease-in-out;
                overflow: hidden;

                a {
                    color: $tertiary-grey
                }

                &.show {
                    max-height: 500px;
                }
            }
        }

        .close_menusec svg {
            height: 30px;
            width: 30px;

            path {
                stroke: $tertiary-grey;
                fill: $tertiary-grey;
                stroke-width: 1px;
            }


        }

        .container-side-menu-deconnexion {
            .btn-logout {
                font-size: 14px;
                font-weight: 500;
                display: flex;
                padding-left: 23px;
                opacity: 0.8;
                align-items: center;
                color: $tertiary-grey;

                svg {
                    padding-right: 5px;
                    margin-right: 5px;
                }
            }

            .discord-logo {
                margin-bottom: 20px;
                text-align: center;

                .discord-svg {
                    margin-right: 8px;
                }

                .discord-btn {
                    width: fit-content;
                    padding-left: 5px;
                    padding-right: 5px;
                    cursor: pointer;
                }
            }

        }
    }
}