.common-bottomsheet {
    --rsbs-backdrop-bg: none;
    --rsbs-handle-bg: #FFFFFF40;

    [data-rsbs-overlay] {
        height: auto;
        background: rgba(21, 24, 43, 0.75);
        backdrop-filter: blur(30px);
        z-index: 99;
        top: 32px;
        border-radius:12px 12px 0px 0px;
    }

        [data-rsbs-content] {
            overflow-y: auto;
        }
}
