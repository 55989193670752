.icon-stepper {
    width: 100%;
    text-align: center;
    height: 100%;
    display: flex;
    align-items: center;
    font-size: 21px;
}

.icon-stepper-light {
    color: black;
}

.icon-stepper-dark {
    color: white;
}
