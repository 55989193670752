@import 'style/colors.scss';
@import 'style/font.scss';

.score-booster-modal {
    color: $score-booster-text-color;
    text-align: center;
    padding: 0px 20px 20px 20px;

    .score-booster__title,
    .score-booster__area-title {
        @include fontWeightSize(bold, 17px);
        margin-bottom: 10px;
    }

    .score-booster__msg {
        @include fontWeightSize(medium, 14px);
    }

    .score-booster__area {
        .score-booster__area-btn {
            .score-booster-detail-block {
                margin-top: 0px;
                padding-top: 0px;
            }
        }
    }
}