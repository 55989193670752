@import 'style/font.scss';
@import 'style/size.scss';
@import 'style/colors.scss';

.watchNow-play-btn {
    display: inline-flex;
    align-items: center;
    color: $white;
    background-color: $top-50-banner-playBtn-bgColor;
    border-radius: 25px;
    padding: 5px 13px 5px 5px;

    &.left-padding{
        padding-left: 13px;
    }

    .play-button {
        margin-right: 5px;
        width: 22px;
        height: 22px;
        transition: opacity 0.3s ease-in-out;
        cursor: pointer;
        opacity: .9;

        @media(min-width :$min-medium-device ) {
            width: 27px;
            height: 27px;
        }
    }

    .playBtn-text {
        font-style: italic;
        @include fontWeightSize(bold, 12px);
        text-transform: uppercase;

        @media(min-width :$min-medium-device ) {
            font-size: 17px;
        }
    }
}
