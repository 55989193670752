@import 'style/font.scss';
@import 'style/colors.scss';

.asset-trend-score {
    display: flex;
    flex-direction: column;
    gap: 20px;

    .score-header {
        display: flex;
        flex-direction: column;
        gap: 16px;

        .title {
            display: flex;
            align-items: center;

            line-height: 21px;
            font-size: 16px;
            @include plus-jakarta-bold-font;
        }

        .content {
            padding: 18px;
            border-radius: 16px;
            position: relative;
            overflow: hidden;
            .icon-background {
                position: absolute;
                right: 15px;
                top: 20px;
                width: 180px;
                height: 180px;
                svg {
                    width: 180px;
                    height: 180px;
                }
            }

            .score {
                @include plus-jakarta-bold-font;
                font-size: 40px;
            }

            .status {
                margin-bottom: 10px;

                .indicator {
                    display: inline-block;
                    width: 10px;
                    height: 10px;
                    border-radius: 50%;
                    background-color: black;
                    margin-right: 6px;
                }

                .text {
                    font-size: 14px;
                    line-height: 15px;
                }
            }

            .description {
                color: #838592;
                white-space: pre-wrap;
            }
        }
    }
}