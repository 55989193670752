@import "style/font.scss";
@import "style/colors.scss";

.not-enough-message {
    margin-top: 15px;
    display: none;
}

.not-enough-message-displayed {
    margin-top: 15px;
    display: block;
    color: red;
}

.condition-password {
    background-color: #3f3a9b;
    border-radius: 7px;
    padding: 10px;

    .field-list-password-condition {
        display: flex;
        flex-direction: column;

        li {
            font-size: 14px;
            padding-top: 15px;
        }
    }
}

.container-send-login {
    .Mui-disabled {
        opacity: 0.3;
    }
}

.condition-password-hidden {
    display: none;
}

.icon-cursor {
    cursor: pointer;
}

.loginregi_form {
    .Mui-error {
        padding: 0px;
    }

    .label-checkbox.checkbox-send-email {
        display: flex;
        align-items: center;
    }

    .checkbox.checkbox-send-email {
        cursor: pointer;
    }

    .checkbox-send-email {
        color: #6677F5;
        font-size: 14px;
        margin-bottom: 0;
    }

    .fields-container {
        margin-bottom: 10px;

        @media (max-height: 732px) and (max-width: 420px) {
            margin-top: 10px;
        }

        .field-container {

            &.custinput_group:has(.valid-message) {
                margin-bottom: 0px;
            }

            &.custinput_group {
                margin-bottom: 0px;

                &:nth-child(2) {
                    margin-top: 5px;
                }
            }

            .register-input-field {
                background-color: transparent;
            }

            .register-input-field.authentification-form {
                position: relative;
                border: none;
                width: 100%;
                border-radius: 0px;
                border-bottom: 1px solid #222;
                transition: border-bottom-color 0.3s ease;

                &:focus {
                    outline: none;
                    border-bottom-color: #6677f5;

                    &::before {
                        content: '';
                        position: absolute;
                        left: 50%;
                        bottom: 0;
                        width: 0;
                        height: 1px;
                        background-color: #6677F5;
                        transition: width 0.3s ease, left 0.3 ease;
                    }
                }
            }



            input {
                padding: 0px 0px;
                background-color: transparent;
            }

            input::placeholder {
                color: #15182BCC;
                opacity: 1;
            }

            input[type=text]:focus {
                border-color: #53D081;
            }

            p {
                .icon {
                    margin-right: 2px;
                }
            }

            .valid-message {
                @include fontWeightSize(medium, 12px);
                color: #53D081;
                padding: 0 2px;
                margin-top: 3px;
            }
        }
    }

    .checkbox-container {
        @media (max-width: 700px) {
            margin-bottom: 20px;
        }

        label.label-checkbox.checkbox-send-email {
            cursor: pointer;
            input.checkbox.checkbox-send-email {
                margin-right: 5px;
            }
        }
    }

    .forgot-password {
        color: $tertiary-violet;
        @include fontWeightSize(bold, 14px);
    }
}

.error {
    @include fontWeightSize(medium, 12px);
    color: #f44336;
    background-color: transparent;
    text-shadow: 0px 0px 10px white;
    display: inline-block;
    bottom: -22px;
    padding: 0 10px;
    border-radius: 5px;
    width: 100%;
}