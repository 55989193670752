@import "style/font.scss";

#mainbody:has(.slide-pane__overlay){
    overflow-y: hidden;
}

.slide-pane__overlay {
    z-index: 99;
    @include plus-jakarta-font;
    @include fontWeightSize(medium);

    .slide-pane__content {
        padding: 0;
    }
}