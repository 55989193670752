@import 'style/size.scss';
@import 'style/colors.scss';
@import 'style/font.scss';
@import "assets/scss/custom_mixins.scss";

.asset-score {
    @include flex-align-center;
    padding: 1.5px 5px;
    border-radius: 6px;
    color: $base-color-hover;
    @include fontWeightSize(bold, 10px);
    gap: 5px;
    cursor: pointer;

    .asset-score_icon,
    .asset-score_boosted {
        display: inline-flex;
        align-items: center;
        width: 10px;
        height: 10px;

        svg {
            height: 100%;
            width: 100%;
            fill: $base-color-hover;
        }
    }

    .asset-score_item {
        @include flex-align-center;
        justify-content: center;
    }
}