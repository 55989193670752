@import "style/colors.scss";
@import "style/font.scss";

$section-credit-bgColor : #15182B80;

.score-boost-checkout {
    color: $tertiary-grey;
    text-align: center;
    margin-top: 25px;
    padding: 0 16px;


    .score-boost-checkout__title {
        @include fontWeightSize(bold, 20px);
    }

    .score-boost-checkout__section {
        margin: 30px 0px 16px;
        padding: 23px;
        display: flex;
        justify-content: space-between;
        text-align: left;

        &.payment{
            border-radius: 20px;
            border: 2px solid $tertiary-violet;
            background-color: $score-booster-amount-bg;

            .section__right {
                @include fontWeightSize(bold, 20px);
            }
        }

        &.credit{
            border-radius: 12px;
            background: $medium-grey;
            padding: 20px;
            color: $section-credit-bgColor;
        }

        .section__left,
        .section__right {
            display: flex;
            flex-direction: column;
            justify-content: center;
        }

        .left__title {
            @include fontWeightSize(bold, 16px);
        }

        .left__subTitle {
            @include fontWeightSize(medium, 12px);
            color: $score-booster-subtitle-text-color;

        }

    }

    .confirm-payment-type {
        display: flex;
        flex-direction: column;
        gap: 8px;

        .warning-msg{
            text-align: left;
            color: $caution-yellow;
            @include fontWeightSize(regular,12px);
          }
    }

    .confirmation-boost-btn {
        position: absolute;
        left: 50%;
        -webkit-transform: translateX(-50%);
        transform: translateX(-50%);
        max-width: 350px;
        bottom: 35px;
        border-radius: 11px;
        text-transform: capitalize;
    }

    .stripe-element {
        text-align: left;
    }
}