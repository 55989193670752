@import "style/font.scss";
@import "style/colors.scss";

.credit-card-wrapper {
    .green-button-gradient {
        display: none;
    }

    .trendex-checkout-form {
        //     background: #EBECF6CC;
        //     border-radius: 12px;
        //     padding: 0px;

        //     #payment-form {
        //         background: $app-background;
        //         padding: 28px 21px 19px 21px;
        //         border-radius: 8px;
        //         box-shadow: #15182B29 0px 3px 6px;
        //     }

        //     .stripe-element{
        //         justify-content: flex-start;
        //         column-gap: 22px;

        //         .default-stripe-background {
        //             border-bottom: 1px solid #15182B26;
        //             border-radius: 0px;
        //             background: none;
        //             color: #15182BCC;
        //             padding: 0px 8px 8px 0px;
        //         }

        //         .stripe-card-number {
        //             &::before {
        //                 font-family: sans-serif;
        //                 color: #15182BCC;
        //                 font-size: 14px;
        //                 width: 120px;
        //             }

        //             .MuiSvgIcon-root {
        //                 display: none;
        //             }
        //         }
        //     }

        //     .card-information {
        //         margin-bottom: 20px;
        //     }

        .MuiPaper-rounded {
            background: none;

            .MuiAccordionDetails-root {
                padding: 8px 0px 16px;
            }

        }
    }

    .accordion-container {
        .MuiAccordionSummary-root {
            padding: 0px;
            margin-left: 32px;

            .override-accordion-summary.accordion {
                justify-content: flex-start;

                .accordion-title {
                    @include plus-jakarta-bold-font;
                    font-size: 13px;
                    text-decoration: underline;
                    color: $primary-button-color;
                }
            }

            &.Mui-expanded {
                display: none;
            }
        }
    }
}