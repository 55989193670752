@import "style/colors.scss";

.yield-information-container {
    padding: 4px;

    .heading {
        font-size: 20px;
        font-weight: 700;
    }

    .range {
        margin-top: 15px;
        border-radius: 11px;
        padding: 50px 18px 30px 18px;
        background-color: $white-list-bgColor;
    }

    .content {
        margin-top: 15px;

        p {
            opacity: 0.65;
            font-size: 13px;
            text-align: left;
            color: $tertiary-grey;
        }

        :last-child {
            margin: 0px;
        }
    }

}