.private-sell-confirm {
    text-align: center;
    img {
        width: 20%;
        padding: 13px;
    }
    .content {
        .first-text {
            font-weight: 500;
            b {
                color: #fc636d;
            }
        }
        .description-modal {
            line-height: 22px;
            b {
                color: #fc636d;
            }
        }
        .text-informations {
            text-transform: uppercase;
            font-size: 10px;
            font-weight: 700;
            color: #9ad880;
        }
    }
    .information {
        margin-top: 25px;
        p {
            line-height: 0px;
            font-weight: bold;
            font-size: 12px;
        }
    }
}

.private-sell-confirm-dark {
    color: white;
}

.private-sell-confirm-light {
    color: black;
}
