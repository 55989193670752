// Base style
.stepper-generic {
    .stepper-root {
        background: transparent;
    }

    .content-stepper {
        margin-top: 8px;
        border-left: 1px solid #bdbdbd;
        margin-left: 0;
        padding-left: 20px;
        padding-right: 0px;
    }

    .shared-label-style {
        display: flex;
        align-items: center;
        margin-bottom: 0;
        text-transform: uppercase;
    }
}

// Theme
.stepper-generic-dark {
    .shared-label-style {
        color: white;
    }
}

.stepper-generic-light {
    .shared-label-style {
        color: black;
    }
}
