@import "style/colors.scss";

.share-profile-referral-container {
    background-color: #8187D01A;
    padding: 24px 15px 15px 15px;
    border-radius: 11px;

    .referral-info-data {
        border: 1px solid #C6C7CF;
        border-radius: 8px;
        padding: 9px;
        font-size: 11px;
        font-weight: 600;
        color: $fade-violet;
        margin-top: 16px;


        .refer-info {
            display: flex;
            justify-content: space-between;
            padding: 5px 0px;

            &:not(:last-child) {
                border-bottom: 1px solid #15182B1A;
            }

            .referInfo-label {
                display: flex;
                align-items: center;

                svg {
                    margin-right: 10px;
                }
            }

            svg {
                width: 20px;
                width: 20px;
            }
        }
    }
}