@import "style/colors.scss";

.banner-slide {
    position: relative;
    width: 100%;
    height: 100%;

    .overlay-banner {
        color: $white;
        background-size: cover;
        background-position: top center;
        width: 100%;
        height: 100%;
        position: absolute;
        z-index: 1;
        left: 0;
        transition: opacity 0.3s ease-in-out;
        background-position-y: center;
    }

    // .youtube-video {
    //     display: none;
    // }

    &.video-visible {
        .overlay-banner {
            opacity: 0;
            pointer-events: none;
        }

        .youtube-video {
            height: 100%;
            display: block;
        }
    }

    .circle-play-button {
        position: relative;
        transform: translate(-50%, -50%);
        top: 50%;
        left: 50%;
        height: 80px;
        width: 80px;
        filter: drop-shadow(0px 5px 30px #15182B59);

        svg {
            width: 100%;
            height: 100%;
            #Ellipse_357{
                opacity: 0.5;
                stroke: #ffffff;
                stroke-width: 3px;
                fill: #d2b145;
            }
        }
    }
}