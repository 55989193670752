@import "style/colors.scss";
@import "style/font.scss";

.payment-card-wrapper {
    background: #EBECF6CC;
    border-radius: 16px;
    max-height: 80px;
    transition: max-height 200ms;
    cursor: pointer;
    padding: 23px 16px;

    &.expanded {
        max-height: 500px;
    }

    .payment-card {
        display: flex;
        justify-content: space-between;
        align-items: center;

        &.disabled {
            opacity: 0.4;
            cursor: not-allowed;
        }

        .payment-card-left {
            @include plus-jakarta-bold-font;
            display: flex;
            gap: 12px;
            font-size: 13px;
            align-items: center;
        }

        .payment-card-right {
            font-size: 12px;
            opacity: 0.5;

            svg {
                width: 30px;
                height: 22px;
            }

            .credit-info-wrapper {
                @include fontWeightSize(medium, 12px);

                .info {
                    display: flex;
                    align-items: center;

                    span {
                        margin-right: 5px;
                    }

                    svg {
                        width: 15px;
                        height: 15px;
                    }
                }
            }
        }

        .credit-info-wrapper {
            @include fontWeightSize(medium, 12px);

            .info {
                display: flex;
                align-items: center;

                span {
                    margin-right: 5px;
                }

                svg {
                    width: 15px;
                    height: 15px;
                }
            }
        }

        &.active {
            .payment-card-left {
                color: $tertiary-violet
            }

            .payment-card-right {
                color: #8187D0;
                opacity: 1;

                svg path {
                    fill: $tertiary-violet;
                }
            }
        }
    }

    .payment-subcomponent {
        margin-top: 16px;
    }

    .no-margin-top {
        margin-top: 0;
    }
}