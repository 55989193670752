@import './style/colors.scss';
@import './style/size.scss';
@import './style/layout.scss';
@import './style/whatsapp.scss';
@import url('https://fonts.googleapis.com/css2?family=Plus+Jakarta+Sans:ital,wght@0,400;0,500;0,600;0,700;0,800;1,400;1,500;1,600;1,700;1,800&display=swap');

.skeleton {
    @keyframes skeleton-loading {
        0% {
            background-color: rgba(0, 0, 0, 0.1);
        }

        100% {
            background-color: rgba(0, 0, 0, 0.2)
        }
    }

    opacity: 0.7;
    animation: skeleton-loading 1s linear infinite alternate;
}

.no-margin {
    margin: 0;
}

.white-color {
    color: white;
}

.hover {
    cursor: pointer;
}

.disabled-cursor {
    cursor: not-allowed;
    opacity: 0.6;
}

.padding {
    padding: 30px;
}

.bold {
    font-weight: bold;
}

.black-color {
    color: black;
}

.flex {
    @include default-flex-box;
}

.tdx-light-green-color {
    color: $tdx-light-green;
}

.center {
    text-align: center;
}

.tdx-rose {
    color: #F17C83;
}

.tdx-red-color {
    color: $tdx-red;
}

.full-width {
    width: 100%;
}

.tdx-violet {
    color: $violet;
}

.tdx-red-background {
    background: $tdx-red;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
span,
div {
    user-select: none;
}

.dashed-border-dropzone {
    border: dashed 2px $dropzone-border-color;
    animation: fadeOutToFadeInBorderColor 1s linear 0.5s infinite alternate;

    @keyframes fadeOutToFadeInBorderColor {
        from {
            border-color: $dropzone-border-color;
        }

        to {
            border-color: transparent;
        }
    }
}

.text-effect-opacity {
    animation: fadeOutToFadeInOpacity 1s linear 0.5s infinite alternate;
    opacity: 1;

    @keyframes fadeOutToFadeInOpacity {
        from {
            opacity: 1;
        }

        to {
            opacity: 0;
        }
    }
}

.opacity {
    opacity: 0.5;
}

.sheen-effect {
    &:after {
        content: '';
        position: absolute;
        top: -50%;
        right: -50%;
        bottom: -50%;
        left: -50%;
        background: linear-gradient(to bottom, rgba(229, 172, 142, 0), rgba(255, 255, 255, 0.5) 50%, rgba(229, 172, 142, 0));
        transform: rotateZ(60deg) translate(-5em, 7.5em);
        animation: sheen 2.5s infinite;
    }
}

@keyframes sheen {
    100% {
        transform: rotateZ(60deg) translate(1em, -10em);
    }
}