/*-----------------------------------------------------------------------------------

      Template Name:
      Template URI:
      Description:
      Author:
      Author URI:

----------------------------------------------------------------------------------- */
/* 1. General CSS
     1.1. Button CSS
     1.2. Title CSS
     1.3. Brand logo CSS */

/*=====================
   1.General CSS start
  =====================*/

body {
    font-family: "Plus Jakarta Display" !important;
    position: relative;
    background-color: $themecolor2;
    color: $textwhite;
    font-size: 14px;
    margin: 0;
    padding: 0;
}

:root {
    font-family: "Plus Jakarta Display" !important;
}

h1 {
    font-size: 30px;
    font-weight: 500;
    text-transform: uppercase;
}

h2 {
    font-size: 22px;
    font-weight: 500;
}

h3 {
    font-size: 20px;
    font-weight: 400;
}

h4 {
    font-size: 18px;
    font-weight: bold;
}

h5 {
    font-size: 16px;
    font-weight: 400;
}

h6 {
    font-size: 14px;
    font-weight: 400;
}

ul {
    padding-left: 0;
    margin-bottom: 0;
}

li {
    display: inline-block;
}

p {
    font-size: 14px;

    line-height: 1;
}

a {
    transition: 0.5s ease;
    &:hover {
        text-decoration: none;
        transition: 0.5s ease;
    }
    &:focus {
        outline: none;
    }
}

button:focus {
    outline: none;
}

// input {
//     color: $textwhite;
// }

.form-control:focus {
    color: $textwhite;
    background-color: #202442;
    border-color: #80bdff;
    outline: 0;
    box-shadow: none;
}

::placeholder {
    font-size: 14px;
}

.main_container {
    max-width: 100%;
    margin: auto;
    @media (max-width: 376px) {
        padding-bottom: 50px;
    }
}

@media (min-width: 1400px) {
    .container {
        max-width: 1140px;
    }
}

// Title Section Start
.section_title {
    position: relative;
    width: 100%;
    margin: 0 0 10px 0;
    padding: 0 10px;
    h3 {
        display: block;
        font-size: 26px;
        font-weight: 700;
        color: #ffffff;
        margin: 0;
        // font-family: $secondary-font;
    }
}
// Title Section End

// Dropdown Start
.cust_dropdown {
    min-width: 100%;
    background-color: #343a6a;
    color: #ffffff;
    border: 0;
    min-height: 45px;
    border-radius: 7px;
}
// Dropdown End

// Button Start

.custom_btn {
    background-image: $gradient !important;
    padding: 15px 20px !important;
    font-size: 14px !important;
    line-height: 20px !important;
    /*text-transform: capitalize !important;*/
    border: 0 !important;
    color: $textwhite !important;
    border-radius: 40px;
    // font-family: $secondary-font !important;
    font-weight: 600 !important;
    background-size: 200% auto !important;
    display: inline-block !important;
    transition: 0.4s all ease !important;
    text-align: center !important;
    &:hover {
        transition: 0.5s all ease !important;
        background-position: right center !important;
    }
}

.custom_btn_purple {
    background-image: $gradient2 !important;
}

.custom_btn_green {
    background-image: $gradient4 !important;
}

.custom_btn_discreet {
    background: transparent !important;
    border: 1.5px solid #fffa !important;
    color: #fffa !important;
}

.custom_btn_sm_rounded {
    // background-image: $gradient !important;
    padding: 6px 20px !important;
    font-size: 14px !important;
    line-height: 20px !important;
    text-transform: capitalize !important;
    border: 1px solid #524f8e !important;
    color: $textwhite !important;
    border-radius: 23px !important;
    // font-family: $secondary-font !important;
    font-weight: 600 !important;
    background-size: 200% auto !important;
    transition: 0.4s all ease !important;
    &:hover {
        transition: 0.5s all ease !important;
        background-image: $gradient !important;
    }
}

// Button End

// Chart-JS Theme Chanes Start
.apexcharts-tooltip {
    &.apexcharts-theme-light {
        border: 1px solid #232848;
        background: rgba(37, 41, 74, 1);
        color: white;
        .apexcharts-tooltip-title {
            background: #25294a;
            color: #ffffff;
            border-bottom: 1px solid #ddd;
        }
    }
}
// Chart-JS Theme Chanes End

// CSS utils Start
.display-block-mobile {
    display: none;
}

.cursor-pointer{
    cursor: pointer;
}
// CSS utils End
