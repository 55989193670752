


// @font-face {
//     font-family: PlusJakartaSans;
//     src: url(../../jakarts-fonts/PlusJakartaSans-Bold.woff);
//     src: url(../../jakarts-fonts/PlusJakartaSans-ExtraBold.woff);
//     src: url(../../jakarts-fonts/PlusJakartaSans-ExtraLight.woff);
//     src: url(../../jakarts-fonts/PlusJakartaSans-Light.woff);
//     src: url(../../jakarts-fonts/PlusJakartaSans-Medium.woff);
//     src: url(../../jakarts-fonts/PlusJakartaSans-Regular.woff);
// }

$primary-font: 'Rubik', sans-serif;
$secondary-font: 'Open Sans', sans-serif;

$font-ultralight: 100;
$font-extralight: 200;
$font-light: 300;
$font-regular: 400;
$font-medium: 500;
$font-semibold: 600;
$font-bold: 700;
$font-extrabold: 800;
$font-black: 900;

// color variables //
$themecolor1: #2a295b;
$themecolor2: #25294a;
$themecolor3: #2d325a;
$darktheme: #202442;
$themelight: #ff7c6e;
$gradient: linear-gradient(145deg, #ff7c6e 25%, #f5317f 50%);
$gradient2: linear-gradient(90deg, rgba(215,61,245,1) 0%, rgba(143,61,245,1) 100%); 
$gradient3: linear-gradient(90deg, #65cff3 0%, #66a6ff 100%);
$gradient4: linear-gradient(90deg, #27a346 0%, #1fa005 100%); 

$colorwhite: #ffffff;
$colorblack: #000000;

$bgcolor1: #004879;
$bgcolor2: #00bdda;
 
$green: #3df5c1; 
$golden: #ffc600;

$textdark: #909092;
$textcolor: #dbe0fa;
$subtitle: #f5f7ff;
$textwhite: #ffffff;

$border: rgba(255, 255, 255, 0.3);
$aborder: #4a4972;

// responsive screen variables //
$screen1800: 1800px;
$screen1600: 1600px;
$screen1440: 1440px;
$screen1366: 1366px;
$screen1199: 1199px;
$screen991: 991px;
$screen767: 767px;
$screen575: 575px;
$screen480: 480px;
$screen420: 420px;
$screen350: 350px;

$minscreen1430: 1430px;
$minscreen1600: 1600px;
$minscreen1800: 1800px;

$notification_icon_size: 40px;

$subtitle-fontsize: 12px;
$subtitle-lines-to-show: 2;

$name-fontsize: 18px;
$name-lines-to-show: 2;
$sideMenuWidth: 325px;

// animation variables //
$asset-view-slide-up-duration: 720ms;
$asset-view-slide-down-duration: 450ms;
$bottom-tab-fade-duration: 600ms;
$asset-txn-sideSlide-duration: 200ms;