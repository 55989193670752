@import "style/colors.scss";
@import "style/font.scss";

.mixed-chart-score {
    width: 95%;
    margin: 12px 0 0;

    // apexchart style start
    .apexcharts-tooltip.apexcharts-theme-light {
        border: none;
        box-shadow: none;
        color: $medium-green;
        font-size: 16px;
        @include plus-jakarta-medium-font;
    }

    .apexcharts-xaxistooltip-bottom {
        display: none;

        &.apexcharts-active {
            display: inline;
            border: none;
            background: none;
            color: $medium-green;
            opacity: 0.8;
        }
    }

    .apexcharts-xaxis line {
        display: none;
    }

    .apexcharts-svg {
        .apexcharts-xcrosshairs.apexcharts-active {
            stroke: $medium-green;
            stroke-width: 2;
            stroke-dasharray: none;
        }

        .apexcharts-xaxis line {
            display: none;
        }
    }

    .mixed-chart_range {
        width: 100%;
        color: $tertiary-grey;
        display: flex;
        justify-content: space-between;
        font-size: 18px;

        span {
            cursor: pointer;
            color: $tertiary-grey;
            opacity: 0.5;

            &:hover {
                color: $tertiary-violet
            }
        }

        .active {
            opacity: 1;
            color: $tertiary-violet;
            @include plus-jakarta-bold-font;
        }
    }
}