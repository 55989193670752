@import 'style/colors.scss';
@import 'style/font.scss';
@import '../../../assets/scss/components/mixins';

.valid-identity {
    background: linear-gradient(180deg, $app-background, #FFFFFF);

    .identity_content {
        .valid-identity-body {
            @include scrollbar;
            padding: 15px 12px 12px 12px;
            max-height: calc(100vh - 160px);
            overflow-y: auto;
            overflow-x: hidden;

            .model_container {
                border-radius: 12px;
                padding-top: 12px;

                .model_btns {
                    padding: 0px;
                }

                .model_title {
                    padding: 0px;
                    border-bottom: none;
                    margin-bottom: 12px;

                    h4 {
                        @include fontWeightSize(bold, 12px);
                    }

                    svg {
                        font-size: 2rem;
                        position: relative;
                        top: 10px;
                    }

                    &.clickable {
                        color: $primary-button-color;
                        cursor: pointer;
                        position: relative;
                        bottom: 15px;
                    }
                }

                .sub-title {
                    @include fontWeightSize(bold, 15px);
                    color: $tertiary-grey;
                    text-align: center;
                }

                .identity-number-label {
                    @include fontWeightSize(medium, 12px);
                    color: #868895;
                    margin-bottom: 5px;
                }

                .id-number-field {
                    @include fontWeightSize(medium, 14px);
                    color: $tertiary-grey;
                    padding: 14px;
                    background: none;
                    border: 1px solid #D2D4E0;
                    border-radius: 6px;
                }

                .container-identity-img {
                    margin-top: 18px;

                    img {
                        height: 200px;
                        max-width: 313px;
                        margin-left: 50%;
                        transform: translateX(-50%);
                        border-radius: 12px;
                    }
                }

                .upload-images {
                    display: flex;
                    flex-direction: column;
                    gap: 8px;

                    .form-group {
                        margin-bottom: 0;
                    }

                    .label-identity-upload {
                        display: flex;
                        justify-content: space-between;
                        padding: 25px;
                        border-radius: 11px;
                        background: linear-gradient(283deg, rgba(113, 128, 227, 0.23), rgba(177, 184, 232, 0.23));

                        .identity-upload_data {
                            @include fontWeightSize(medium, 13px);
                            display: flex;
                            flex-direction: column;
                            text-align: right;

                            .upload-picture {
                                color: $primary-button-color;
                            }

                            .file-attached {
                                color: #868895;
                            }
                        }

                        .identity-upload_label {
                            @include fontWeightSize(bold, 13px);
                            color: #5F6273;
                            display: flex;
                            align-items: center;
                            gap: 14px;
                        }
                    }
                }


                .submit-identity {
                    max-width: 500px;
                }

                .ChooseIdTypeContainer {
                    width: 100%;

                    .choose-identity-title {
                        @include fontWeightSize(bold, 15px);
                        text-align: center;
                        margin-bottom: 12px;
                    }

                    .choose-identity {
                        @include fontWeightSize(medium, 14px);
                        color: #565A74;
                        margin-bottom: 29px;
                    }

                    .option_btn {
                        margin-bottom: 8px;
                        width: 100%;
                        padding: 0px;
                        height: 67px;
                        position: relative;
                        background-color: $primary-button-color;
                        border-radius: 12px;
                        color: #ffffff;
                        cursor: pointer;

                        &:hover {
                            background-color: $primary-button-hover-color;
                        }

                        p {
                            @include fontWeightSize(extra-bold, 15px);
                            text-align: center;
                            margin: 0px;
                            position: absolute;
                            top: 50%;
                            left: 50%;
                            transform: translate(-50%, -50%);
                        }
                    }
                }
            }
        }
    }
}