@import 'style/colors.scss';
@import 'style/font.scss';

.lover-banner{
    display: flex;
    align-items: center;
    border-radius: 20px;
    padding:16px 16px 16px 0;
    margin-bottom: 20px;
    background: linear-gradient(257deg, #F0F1FA, #EFD9E920,#ED8FB5);

    .banner-icon{
        display: flex;
        align-items: center;

        svg{
            height: 78px;
            width: 66px;
        }
    }
    .banner-info{
        color: #A261C3;

        .banner-title{
            @include fontWeightSize(bold, 13px);
            margin-bottom: 2px;
            display: flex;
            align-items: center;
            justify-content: space-between;

            .title-tooltip{
                svg{
                    color: #A261C3;
                    width: 14px;
                    height: 14px;
                }
            }
        }

        .banner-detail{
            @include fontWeightSize(regular, 13px);
        }

    }
}