.check-icon {
    // Sample container
    .main-container-success {
        width: 100%;
        display: flex;
        flex-flow: column;
        justify-content: center;
        align-items: center;
    }

    // Main CSS
    .check-container-success {
        width: 7.75rem;
        height: 10.2rem;
        margin: auto;
        display: flex;
        flex-flow: column;
        align-items: center;
        justify-content: space-between;

        .check-background-success {
            width: 100%;
            height: calc(100% - 1.25rem);
            background: linear-gradient(90deg, #fe7967 0%, #f62b7b 100%);
            box-shadow: 0px 0px 0px 65px rgba(255, 255, 255, 0.25) inset,
                0px 0px 0px 65px rgba(255, 255, 255, 0.25) inset;
            transform: scale(0.84);
            border-radius: 50%;
            animation: animateContainer 0.25s ease-out forwards 0.25s;
            display: flex;
            align-items: center;
            justify-content: center;
            opacity: 0;

            svg {
                width: 65%;
                transform: translateY(0.25rem);
                stroke-dasharray: 80;
                stroke-dashoffset: 80;
                animation: animateCheck 0.35s forwards 0.35s ease-out;
            }
        }

        .check-shadow-success {
            bottom: calc(-15% - 5px);
            left: 0;
            border-radius: 50%;
            background: radial-gradient(closest-side, #fe7967, transparent);
            animation: animateShadow 0.35s ease-out forwards 0.3s;
        }
    }
    .container-text-success {
        .title-text-success {
            font-size: 27px;
            text-transform: uppercase;
            letter-spacing: 0.6px;
            color: #fff;
        }
        .message-text-success {
            color: #fff;
        }
    }
}

@keyframes animateContainer {
    0% {
        opacity: 0;
        transform: scale(0);
        box-shadow: 0px 0px 0px 65px rgba(255, 255, 255, 0.25) inset, 0px 0px 0px 65px rgba(255, 255, 255, 0.25) inset;
    }
    25% {
        opacity: 1;
        transform: scale(0.9);
        box-shadow: 0px 0px 0px 65px rgba(255, 255, 255, 0.25) inset, 0px 0px 0px 65px rgba(255, 255, 255, 0.25) inset;
    }
    43.75% {
        transform: scale(1.15);
        box-shadow: 0px 0px 0px 43.334px rgba(255, 255, 255, 0.25) inset,
            0px 0px 0px 65px rgba(255, 255, 255, 0.25) inset;
    }
    62.5% {
        transform: scale(1);
        box-shadow: 0px 0px 0px 0px rgba(255, 255, 255, 0.25) inset,
            0px 0px 0px 21.667px rgba(255, 255, 255, 0.25) inset;
    }
    81.25% {
        box-shadow: 0px 0px 0px 0px rgba(255, 255, 255, 0.25) inset, 0px 0px 0px 0px rgba(255, 255, 255, 0.25) inset;
    }
    100% {
        opacity: 1;
        box-shadow: 0px 0px 0px 0px rgba(255, 255, 255, 0.25) inset, 0px 0px 0px 0px rgba(255, 255, 255, 0.25) inset;
    }
}

@keyframes animateCheck {
    from {
        stroke-dashoffset: 80;
    }
    to {
        stroke-dashoffset: 0;
    }
}

@keyframes animateShadow {
    0% {
        opacity: 0;
        width: 100%;
        height: 15%;
    }
    25% {
        opacity: 0.25;
    }
    43.75% {
        width: 40%;
        height: 7%;
        opacity: 0.35;
    }
    100% {
        width: 85%;
        height: 15%;
        opacity: 0.25;
    }
}
