@import 'style/size.scss';

.instructions_container {
    width: 60%;
    margin: 30px auto auto auto;

    @media (max-width: $mobile) {
        width: 90%;
    }
}

.instructions_detail_container {
    text-align: left;

    p {
        margin-bottom: 0;
    }

    .instructions_detail_title {
        font-weight: 600;
        font-size: 20px;
        margin: 0;
        font-family: 'Open Sans', sans-serif;
        padding: 20px 20px 0 20px;
        text-align: left;

        @media (max-width: 411px) {
            font-size: 16px;
        }
    }

    .instructions_detail_paragraph_wrapper {
        padding: 20px 0;
        color: #928f95;

        .instructions_detail_paragraph {
            font-size: 15px;
            padding-left: 20px;
            margin-bottom: 10px;
            line-height: 1.5;
        }
    }

    .close-instruction-modal-container {
        text-align: center;

        .instruction-close-modal-button {
            margin-top: 10px !important;
            margin: auto;
            width: 70%;
        }
    }
}

.instructions_custom_padding {
    @media (min-width: 450px) {
        padding: 30px;
    }
}
