@import 'style/colors.scss';
@import 'style/font.scss';
@import 'style/size.scss';

@mixin color-border-with-shadow($border-color:#6677F580) {
    border: 1.5px solid $border-color;
    box-shadow: 0px 2px 12px #6677F533;
}

.user-information-form {
    .red {
        color: $medium-red;
        font-weight: bold;
    }

    .infobox {
        color: $tertiary-violet;
        font-size: 11px;
        font-weight: bold;
    }
    #user-information-form {
        margin-top: 10px;
    }
    .custinput_group:has(input:focus) .form_label,
    .custinput_group:has(select:focus) .form_label  {
        color: $tertiary-violet;
    }

    .checkbox-container {
        padding-left: 7px;
        padding-right: 7px;
        .checkbox-send-sms {
            color: #6677F5;
            font-size: 14px;
        }
    }

    .form_label{
        @include plus-jakarta-font;
        font-size: 12px;
        color: #868895;
    }

    select{
        height: 45px;
        color: $tertiary-grey;
    }

    input:focus,
    select:focus{
        @include color-border-with-shadow;
    }

    input:disabled,
    select:disabled {
        background-color: #EBECF680;
        pointer-events: none;
        opacity: 0.8;
    }

    select.select-language{
        border-radius: 6px;
        border: 1px solid #D2D4E0;
        width: 100%;
        padding:11px 14px;
        background: $white;
    }

    select:focus-visible{
        outline:none;
    }

    select:hover{
        @include color-border-with-shadow;
        cursor: pointer;
    }

    .email-not-verified{
        color : #E0AD22;
        @include fontWeightSize(medium, 12px);

        svg{
            margin-right: 2px;
            width: 10px;
        }
    }

    .react-tel-input {
        input {
            width: 100%;
            background: #393f74;
            border-color: rgba(99, 66, 255, 0.5);
            &.invalid-number {
                background-color: #393f74;
            }
        }

        .flag-dropdown {
            background-color: #393f74;
            border-color: rgba(99, 66, 255, 0.5);
        }

        .country-list {
            background: rgb(52, 59, 106);
        }

        .highlight {
            color: #1b1e21;
        }

        .light_demo & {
            input {
                border-color: #cccccc;
                width: 100%;
                background: rgb(255, 255, 255);

                &:hover{
                    @include color-border-with-shadow;
                }

                &:focus{
                    @include color-border-with-shadow;
                 }

                &.invalid-number {
                    background-color: #FAF0F0;
                }
            }

            .country-list {
                background: rgb(255, 255, 255);
            }

            .flag-dropdown {
                background-color: rgb(255, 255, 255);
                border-color: #cccccc;

                &:hover{
                    @include color-border-with-shadow;
                }

                &:focus{
                   @include color-border-with-shadow;
                }
            }

            .highlight {
                color: #1b1e21;
            }
        }
    }

    .light_demo & .country-name {
        color: #1b1e21;
    }


    .date-dob{
        border-radius: 6px;
        border: 1px solid #D2D4E0;

        .MuiInputBase-input:hover{
            @include color-border-with-shadow;
        }
    }

    .confirm-block{
        width: 100%;
        display: flex;
        justify-content: center;
        margin: 20px 0px;
        margin-top: 15px;

        .button-submit, .button-cancel{
            width: 170px;
            padding:0px 15px ;
    
            button{
                height: 45px;
                padding-top: 5px;
                padding-bottom: 5px;
                @include fontWeightSize("bold",15px)
            }
        }
    }

    .MuiInputBase-root:hover{
        .MuiOutlinedInput-notchedOutline {
            @include color-border-with-shadow;
        }
    }   

    .MuiInputBase-root.Mui-disabled {
        pointer-events: none;
    }

    .MuiOutlinedInput-root.Mui-focused {
        .MuiOutlinedInput-notchedOutline {
            @include color-border-with-shadow;
        }
    }

    .MuiFormHelperText-root.Mui-error{
        background:#F7F8FF;
        @include fontWeightSize(medium);
    }
}