@import "../../../../style/colors.scss";
@import "../../../../style/font.scss";

.secondary-button-base {
    @include plus-jakarta-font;
    @include fontWeightSize(bold, 14px);
    border-radius: 18px;
    background: none;
    padding: 0px 16px 0 16px;
    line-height: 14px;
    height: 35px;
    cursor: pointer;
    color: inherit;
}

.secondary-button-light {
    border: solid 2px #7180e3 !important;
    box-shadow: none !important;
    background: white !important;
    border-radius: 25px;
    padding: 5px;
    box-sizing: border-box !important;
    width: 100%;
    font-size: 11px;
    color: #7180e3 !important;
    min-width: 64px;

    &.full {
        background: #8869ed;
        color: white;
    }
}

.secondary-button-dark {
    border: 1px solid #ffffff;
    color: #ffffff;
}