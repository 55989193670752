.admin-identity-footer {
    z-index: 99;
    .content-banner {
        display: flex;
        align-items: center;
        column-gap: 30px;
        justify-content: space-between;
        width: 100%;
        z-index: 5;
        padding: 20px 30px 20px 220px;
        p{
            color: #222;
            background-color: white;
            padding: 8px;
        }
    }
}
