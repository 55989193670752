@mixin change-svgColor($color) {
    svg {
        path {
            fill: $color;
        }
    }
}

@mixin flex-align-center {
    display: flex;
    align-items: center;
}

@mixin scoreLabelIconSize($height, $width, $color){
    height: $height;
    width: $width;
    fill: $color;
}