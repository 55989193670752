@import 'style/font.scss';
@import 'style/size.scss';
@import 'style/colors.scss';

.trendex-academy {
    color: $tertiary-grey;
    padding-bottom: 12px;
    text-align: center;

    @media(min-width: $min-extra-large-device) {
        text-align: left;
    }

    .academy-title {
        text-align: left;
        @include fontWeightSize(bold, 18px);

        @media(min-width: $min-extra-large-device) {
            position: absolute;
            top: 110px;
        }
    }

    .academy-container {
        display: flex;
        justify-content: center;

        .academy-wrapper {
            display: flex;
            flex-direction: row;
            overflow-x: auto;
            scroll-behavior: smooth;
            margin: 12px 0 12px 0;

            @media(min-width: $min-extra-large-device) {
                flex-direction: column;
                overflow-x: hidden;
            }

            &::-webkit-scrollbar {
                display: none;
            }

            .video-container {
                width: 227px;

                &:not(:last-child) {
                    margin-bottom: 12px;
                    margin-right: 12px;

                    @media(min-width: $min-extra-large-device) {
                        margin-right: 0px;
                    }
                }

                @media(min-width: $min-extra-large-device) {
                    width: auto;
                }

                .thumbnail-card {
                    position: relative;
                    height: 128px;
                    width: 227px;
                    border-radius: 12px;
                    filter: drop-shadow(0px 3px 6px #71738129);
                    overflow: hidden;
                    margin-bottom: 12px;

                    .thumbnail-img {
                        position: absolute;
                        top: 0;
                        left: 0;
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                    }

                    .thumbnail-play-btn {
                        position: absolute;
                        bottom: 8px;
                        right: 8px;

                        .watchNow-play-btn {
                            background-color: $watchNowBtn-bgColor-blacky;

                            .play-button {
                                width: 15px;
                                height: 15px;
                            }

                            .playBtn-text {
                                font-size: 9px;
                            }
                        }
                    }
                }

                .thumbnail-title {
                    @include fontWeightSize(bold, 14px)
                }
            }
        }
    }

    .btn-academy {
        .solid-secondary-button {
            width: auto;
            padding-left: 50px;
            padding-right: 50px;
        }
    }

}