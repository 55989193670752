@import 'style/colors.scss';
@import 'style/size.scss';

.onboarding-button-component {
    width: 647px;
    height: 200px;
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    position: fixed;
    bottom: 0;
    left: 50%;
    pointer-events: none;
    z-index: 9999;
    transform: translate(-50%);

    @media (max-width: $min-extra-large-device) {
        width: 100%;
    }


    .button-container-onboarding {
        background: linear-gradient(180deg, rgba(21, 24, 43, 0) 0%, #6677F5 100%);
        height: 200px;
        display: flex;
        flex-direction: column-reverse;
        align-items: center;
        width: 100%;

        .onboarding-button {
            width: 30%;
            background: white;
            border-radius: 5px;
            color: $tertiary-violet;
            position: relative;
            margin-bottom: 30px;
            pointer-events: auto;

            @media (max-width: $max-medium-device) {
                width: 95%;
            }
        }
    }


}