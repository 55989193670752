@import 'style/size.scss';
@import 'style/colors.scss';
@import 'style/font.scss';

.club-card {
    background: $club-bgColor;
    border-radius: 11px;
    padding: 16px;

    &:not(:last-child) {
        margin-bottom: 8px;

        @media (min-width: $min-small-device) {
            margin-bottom: 12px;
        }
    }

    .club-card__img {
        height: 195px;
        margin-bottom: 16px;

        @media (min-width: $min-small-device) {
            height: 300px;
        }

        img {
            border-radius: 14px;
            height: 100%;
            width: 100%;
        }
    }

    .club-card__heading {
        display: flex;
        grid-gap: 9px;

        .club-card__topic {
            color: club-text-greyColor;

            .club-card__title {
                @include fontWeightSize(bold, 14px);

            }

            .club-card__subtitle {
                color: club-text-greyColor;
                @include fontWeightSize(regular, 14px);
            }
        }
    }
}