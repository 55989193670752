
// font variables //

@font-face {
    font-family: 'Plus Jakarta Display';
    src: url('../jakarts-fonts/PlusJakartaDisplay-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Plus Jakarta Display';
    src: url('../jakarts-fonts/PlusJakartaDisplay-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Plus Jakarta Display';
    src: url('../jakarts-fonts/PlusJakartaDisplay-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Plus Jakarta Display';
    src: url('../jakarts-fonts/PlusJakartaDisplay-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

//Monument Extended
@font-face {
    font-family: 'Monument Extended regular';
    src:url('../fonts/MonumentExtended/MonumentExtended-Regular.otf') format('opentype');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'Monument Extended';
    src:url('../fonts/MonumentExtended/MonumentExtended-Ultrabold.otf') format('opentype');
    font-weight: normal;
    font-style: normal;
}

@import "components/_variable";
@import "components/_mixins";
@import "components/_animations";
@import "components/_common";
@import "components/_header";
@import "components/_main";
@import "components/_footer";


