.boost-detail-container-reroll {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .added {
        text-transform: uppercase;
        font-weight: bold;
        margin-left: 5px;
    }

    .green-button {
        font-size: 12px;
        pointer-events: auto;
        cursor: pointer;
    }
}