@import 'style/colors.scss';

$listCard-border-color: #15182B1A;
$listCard-value-color : #15182B80;
$listCard-value-icon : #15182B59;

@mixin justifyCenterText() {
    justify-content: center;
    text-align: center;
}

.list-card {
    border-radius: 12px;
    background: $medium-grey;
    padding: 20px;

    .information_row {
        font-size: 12px;
        display: flex;
        justify-content: space-between;
        padding: 15px 0px 15px 0px;

        &:first-child {
            padding: 0px 0px 15px 0px;
        }

        &:last-child {
            padding: 15px 0px 0px 0px;
        }

        &:not(:last-child) {
            border-bottom: 1px solid $listCard-border-color;
        }

        &:only-child {
            padding: 0px;
        }

        .information_row_icon-label {
            color: #9C9EAA;
            font-weight: 600;
            display: flex;
            align-items: center;
            width: 100%;

            .information_row_icon {
                margin-right: 7px;
            }

            .information_row_label {
                text-align: initial;
                width: 100%;

                &.justify-center {
                    @include justifyCenterText;
                }
            }

        }

        .information_row_value {
            color: $listCard-value-color;
            font-weight: 400;
            display: flex;
            align-items: center;
            justify-content: flex-end;
            text-align: right;
            width: 30%;

            .tooltip-helper {
                margin-left: 5px;

                svg {
                    color: $listCard-value-icon;
                    width: 13px;
                    height: 13px;
                }

                .message-modal-container .icon {
                    padding: 0px;
                }
            }
        }
    }

    .faded {
        opacity: 0.21;
    }

    .justify-center {
        @include justifyCenterText;
    }
}