@import 'style/size.scss';
@import 'style/colors.scss';
@import 'style/font.scss';

$login-img-height :122px;
$login-img-width :122px;

.reset-password-page {
    background: #f7f8ff;
    background-repeat: no-repeat;
    background-position: 50%;
    background-size: cover;
    width: 100%;
    height: 100vh;
    position: relative;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-align-items: center;
    align-items: center;
    box-sizing: border-box;
    padding: 10px;

    &::before {
        content: "";
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        -webkit-filter: blur(0);
        filter: blur(0);
        opacity: .3;
    }

    .reset_password_structure {
        width: 650px;
        height: 100%;
        padding: 15px;
        overflow-x: hidden;
        overflow-y: auto;
        background-color: #f7f8ff;
        -webkit-backdrop-filter: blur(42px) opacity(.85);
        backdrop-filter: blur(42px) opacity(.85);

        .title-reset-password {
            color: #15182b;
            @include fontWeightSize(bold, 20px);
            text-align: left;
        }

        .reset-password-top-section {
            margin: 2% 0 3%;
            text-align: center;
            padding: 20px;
            font-weight: 700;
            color: #222;

            .img-reset-password {
                margin-bottom: 5%;
                height: calc(2*115px);
                width: calc(2*115px);

                @media(max-width:$min-large-device) {
                    height: calc(2*90px);
                    width: calc(2*90px);
                }
            }
        }

        .reset_password_form {
            text-align: initial;

            .fields-container {
                .field-container.custinput_group {
                    position: relative;

                    .password-icon-toggle {
                        position: absolute;
                        width: 35px;
                        height: 35px;
                        right: 0;
                        top: 50%;
                        transform: translate(0, -50%);
                        padding: 5px;
                        z-index: 10;
                        cursor: pointer;
                    }

                    .MuiFormControl-root {
                        background: transparent !important;

                        .MuiInputBase-root {
                            background: transparent !important;
                        }

                        input {
                            padding: 0px 15px 0px 0px;
                        }
                    }
                }

                .contain-text {
                    padding: 20px 0px 20px 0px;

                    .text {
                        font-size: 14px;
                        color: #15182b;
                    }

                    .red {
                        color: #E66F81;
                    }

                    .green {
                        color: #53D081;
                    }
                }

                .container-btn {
                    .err-validate {
                        opacity: 0.5;
                        pointer-events: none;
                    }
                }

                .error {
                    @include fontWeightSize(medium, 12px);
                    color: #E66F81;
                    background-color: transparent;
                    text-shadow: 0px 0px 10px white;
                    display: inline-block;
                    padding-left: 0px;
                    bottom: -22px;
                    border-radius: 5px;
                    width: 100%;
                }

                .register-input-field {
                    width: 100%;
                    min-height: 45px;
                    border: none;
                    border-bottom: 1px solid #222222c2;
                    background: transparent;

                    &::before {
                        left: 0;
                        right: 0;
                        bottom: 0;
                        content: "\00a0";
                        position: absolute;
                        transition: border-bottom-color 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
                        border-bottom: 1px solid rgba(0, 0, 0, 0.42);
                        pointer-events: none;
                    }
                }
            }
        }
    }

    @media (max-width: 991px) {
        .auth_structure {
            padding: 0px 40px 40px 40px;
            width: 576px;

            .container-logo {
                padding: 8px 35px;

                &:after {
                    padding: 42px 135px;
                }

                &:before {
                    padding: 48px 152px;
                }
            }
        }
    }
}