@import "style/font.scss";
@import "style/colors.scss";

.selection-tabs-wrapper {
    width: fit-content;
    display: flex;
    gap: 6px;
    overflow-x: auto;
    margin-top: 18px;

    .selection-tab {
        @include plus-jakarta-bold-font;
        color: $tertiary-grey;
        padding: 10px 18px 10px 15px;
        border-radius: 25px;
        border: 2px solid #C9CAD4;
        font-size: 15px;
        min-width: auto;
        cursor: pointer;

        &.selected {
            color: white;
            background: $tertiary-violet;
            border-color: $tertiary-violet;
        }
    }
}