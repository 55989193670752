@import 'style/colors.scss';
@import 'style/size.scss';
@import 'style/font.scss';

.asset-profil-header {
    &.gradient-fun-cards {
        padding-top: 80px !important;
        padding-bottom: 20px !important;
        background: linear-gradient(180deg, #FFF1D8 20%, #e1e1eb94 60%);
    }

    padding-top: 27px;
    margin-bottom: -15px;
    border-radius: 20px;
    background: linear-gradient(180deg, #F7F8FF00, #edeff9e3);
    padding: 8px;

    .learn-more-fun-assets {
        font-style: italic;
        font-size: 10px;
        text-transform: none;
        cursor: pointer;
        background-color: #F4CC85;
        padding: 4px;
        border-radius: 10px;


        &:hover {
            background-color: #f0d9b1;
            transition: all 0.5s;
        }
    }

    .benzema-timer-container {
        margin-top: 32px;
        margin-bottom: 25px;
        text-align: center;
    }

    .benzema-timer-container {
        margin-top: 32px;
        margin-bottom: 25px;
        text-align: center;
    }

    .information-container {
        color: black;
        text-align: center;



        .pre-sales-disclaimer {
            color: black;
            margin-bottom: 25px;

            .icon-disclaimer {
                margin-right: 5px;
            }
        }

        &.early-bird {
            .benzema-timer-container {
                margin-top: 10px;
                margin-bottom: 0px;
            }

            .pre-sales-disclaimer {
                margin-bottom: 7px;

                @media (max-width: $mobile) {
                    font-size: 13px;
                }

                &:last-child {
                    margin-bottom: 0px;
                }
            }
        }
    }

    .asset-profil-header-share {
        display: flex;
        justify-content: right;
        padding-right: 3%;
        position: absolute;
        right: 0;

        svg {
            height: 17px;
            width: 17px;
        }
    }

    .asset-profil-header-img-container {
        display: flex;
        justify-content: center;

        .benzema-page-title {
            @media (max-width:$mobile) {
                margin-top: 20px;
            }
        }

        .asset-profil-header-img-block {
            position: relative;
            height: 230px;
            width: 180px;
            border: solid 3px;
            border-radius: 20%;

            &.asset-fun {
                box-shadow: 6px 6px 0px black;
                border: 4px solid black;
                border-radius: 20px;
            }

            .asset-profil-banner-bg-category,
            .asset-profil-banner-bg-avatar {
                position: absolute;
                width: 100%;
                height: 100%;
                border-radius: 17%;
            }

            .asset-profil-banner-bg-avatar {
                &.bg-avatar-fun {
                    border-radius: 16px;
                }

                position: absolute;
                width: 100%;
                height: 100%;
                border-radius: 17%;
                object-fit: cover;
            }

            .fun-cards-up-left {
                position: absolute;
                width: 40%;
                left: -7px;
                top: -7px;
            }

            .droplets {
                position: absolute;
                width: 40%;
                left: -80px;
                top: -50px;
            }

            .asset-profil-banner-bg-category {

                &.bg-category-fun {
                    border-radius: 20px;
                }

                box-shadow: 4px 3px 20px #71738159;
                object-fit: fill
            }

            .asset-profil-banner-IRL-score {
                @include fontWeightSize(bold, 16px);
                position: absolute;
                left: 86%;
                top: -10%;
                display: flex;
                align-items: center;
                padding: 3px 10px;
                border-radius: 20px;
                box-shadow: 0px 3px 6px #00000029;
                cursor: pointer;
            }
        }

    }



    .asset-profil-banner-name {
        &.banner-name-fun {
            margin-top: 20px;
        }

        @include plus-jakarta-bold-font;
        color: $AssetProfile-banner-text-color;
        text-align: center;
        @include fontWeightSize(bold, 24px);
        margin-top: 2px;
    }

    .asset-profil-banner-category-nameIcon {
        &.fun-cards-margin {
            margin-top: 0px;
            margin-bottom: 20px;
        }

        @include plus-jakarta-medium-font;
        color: $AssetProfile-banner-text-color;
        opacity: 0.8;
        text-align: center;
        @include fontWeightSize(medium, 14px);
        text-transform: capitalize;
        margin-top: 3px;

        .category-icon {
            &.fun-cards-icon {
                height: auto;
            }

            display: flex;
            align-items: center;
            width: fit-content;

            svg {
                width: 14px;
                height: 14px;
            }
        }

        .container-profil-banner {
            justify-content: center;
            column-gap: 7px;

            svg {
                fill: black;
            }
        }
    }

    .all-token-information-container {
        margin-top: 25px;
        margin-bottom: 0px;
        background-color: #F7F8FF;
        border: 1px solid #F7F8FF33;
        box-shadow: (0px 5px 15px #15182B14);

        @media (max-width: $mobile) {
            margin-top: 8px;
        }
    }

    .asset-profil-score {
        display: flex;
        justify-content: space-between;
        margin-top: 10px;

        .label {
            @include fontWeightSize(medium, 11px);
            color: $token-information-font-color;
            margin-left: 10px;
            margin-top: 1px;
            font-weight: 700;
        }

        .value {
            color: $token-information-font-color;
        }

        .asset-profil-banner-IRL-score {
            @include fontWeightSize(bold, 11px);
            padding: 3px 10px;
            border-radius: 20px;
            box-shadow: 0px 3px 6px #00000029;
            cursor: pointer;
        }

        &.border-top {
            border-top: 1px solid #FFFFFF59;
        }

        &.padding-top {
            padding-top: 10px;
        }

        .asset-profil-score__item {
            display: flex;
            align-items: center;
        }

        &__boosted {
            display: flex;
            align-items: center;

            svg {
                height: 12px;
                margin-left: 4px;
            }
        }
    }


    &.benzema-before-public-sale {
        background: inherit;

        .banner-container {
            background: linear-gradient(180deg, #F7F8FF00, #edeff9e3);
            border-radius: 20px;
            padding: 10px 10px 5px 10px;

            .asset-profil-header-img-container {
                flex-direction: column;
                border-radius: 10px;
                font-size: 13px;
                line-height: 20px;
                color: #15182B;
                letter-spacing: 1.4px;

                img {
                    background-repeat: no-repeat;
                    background-size: cover;
                    width: 100%;
                    margin-bottom: -20px;
                }
            }

            .lists-benefits-container {
                display: flex;
                flex-direction: column;
                color: #15182B;
                font-size: 14px;
                font-weight: bold;
                margin: 20px 0px 10px 0px;
                text-align: left;
                gap: 20px;

                &__content {
                    display: flex;
                    flex-direction: column;
                    color: #606373;
                    font-weight: 400;
                    font-size: 13px;
                    gap: 15px;
                    line-height: 21px;

                    .text {
                        position: relative;
                        width: 100%;
                        display: flex;
                        align-items: flex-start;
                        margin-bottom: 5px;

                        &:not(:first-of-type)::after {
                            content: "";
                            position: absolute;
                            width: 89%;
                            top: -10px;
                            right: 0;
                            border-bottom: 1px #DBDBE5 solid;
                        }
                    }

                    .icon {
                        margin-right: 10px;
                        width: 24px
                    }
                }
            }
        }

        .timer-container {
            margin: 10px 0px;
            text-align: center;
            color: #15182B;
            line-height: 29px;
            font-size: 19px;
        }

        .token-information-container {
            padding: 10px;
            border-radius: 20px;
            background: #f1f1fa;

            .all-token-information-container {
                margin-top: 0px;
            }
        }
    }

}