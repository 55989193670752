@import 'style/colors.scss';
@import 'style/font.scss';


.sell-asset-detail-wrapper {
    margin-top: 25px;
    margin-bottom: 25px;
    @include plus-jakarta-medium-font;

    .sell-asset-detail-container {
        @include plus-jakarta-medium-font;
        color: $steel-grey;
        background-color: white;
        display: flex;
        margin-top: 25px;
        justify-content: space-between;
        align-items: center;
        padding: 15px 16px;
        border-radius: 12px;

        .sell-asset-detail_value {
            @include plus-jakarta-bold-font;
            font-style: italic;
            word-break: break-word;

            .asset-value-change {
                &.red-text {
                    color: $error-red
                }

                &.green-text {
                    color: $medium-green;
                }

                svg {
                    width: none;
                    height: none;
                    position: relative;
                    top: 8px;
                }
            }
        }
    }
}