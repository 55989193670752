@import 'style/colors.scss';

.withdraw-request-profil {
    display: flex;
    justify-content: center;
    margin: 50px 0;

    .withdraw-card {
        background: white;
        text-align: center;
        border-radius: 5px;
        padding: 25px;
        color: $withdraw-font-color;

        .container-icon {
            width: 10vw;
            margin: auto auto 20px auto;

            img {
                border-radius: 50%;
                width: 100%;
            }
        }

        .container-information {
            padding: 20px;

            .success {
                color: #18d29c;
            }

            .error {
                color: #b62e3c;
            }

            .warning {
                color: #fbc02c;
            }
        }

        .container-withdraw-information {
            .withdraw-information {
                .field {
                    cursor: pointer;
                }

                .warning {
                    margin: 30px auto;
                    color: orange;
                    font-size: 16px;
                }
            }
        }

        .warning {
            font-weight: bold;
            width: 400px;
            margin: auto;
            color: orange;
        }

        .action-buttons-withdraw {
            display: flex;
            column-gap: 35px;
            width: 100%;
            justify-content: center;
            margin: auto;
            padding: 20px;
            color: black;
            align-items: center;
        }

        .refusal-form {
            margin: auto;
            padding: 20px;
            text-align: center;
        }
    }
}