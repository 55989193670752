@import '../../../style/size.scss';

.tutorial {
    & > div{
        background: #6855cb !important;
    }
    width: 100%;
    margin: auto;
    background: #6855cb;
    @media (max-width: $mobile){
        padding: inherit;
        background: inherit;
        width: 100%;
    }
    img.img-step-3{
        height: 100%;
        margin: auto;
        @media (max-width: $mobile){
            height: calc(100vh - 130px);
        }
    }
    div.btn-tutorial{
        position: absolute;
        bottom: 2%;
        width: 50%;
        z-index: 99999;
        left: 50%;
        transform: translateX(-50%);
        @media(max-width: $mobile){
            bottom: 0%;
        }
    }
}

