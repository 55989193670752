@import '../../../style/colors.scss';
@import '../../../style/font.scss';
.footer_sec .footer_section{
    padding: 40px 0 0;
}
.copyrights {
    padding: 20px;
    margin-bottom: 16px;
    border-radius: 12px;
    background: $footer-gradient;
    
    .copyright-texts {
        @include plus-jakarta-medium-font;
        display: flex;
        flex-direction: column;
        gap: 8px;
        width: 75%;
    }
    h4.copyright-bold-text{
        @include plus-jakarta-bold-font;
    }
}