@import "assets/scss/components/_variable.scss";

.buy-asset-slider {
    &.slide-in{
        animation: sideSlideIn $asset-txn-sideSlide-duration;

        .keyboard-section{
            animation: sideSlideIn $asset-txn-sideSlide-duration;
        }
    }
    &.slide-out {
        left: 100%;
        animation: sideSlideOut $asset-txn-sideSlide-duration;

        .keyboard-section{
            left: 100%;
            animation: sideSlideOut $asset-txn-sideSlide-duration;
        }
    }

    @keyframes sideSlideIn {
        0% {
            left: 100%;
        }
    
        100% {
            left: 0
        }
    }
    
    @keyframes sideSlideOut {
        0% {
            left: 0;
        }
    
        100% {
            left: 100%
        }
    }
}


.buy-asset-slider-halfScreen {
    &.halfScreen_slide-in{
        animation: halfScreen_sideSlideIn $asset-txn-sideSlide-duration;
    }
    &.halfScreen_slide-out {
        animation: halfScreen_sideSlideOut $asset-txn-sideSlide-duration;
    }

    @keyframes halfScreen_sideSlideIn {
        0% {
            bottom:-300px;
        }
    
        100% {
            bottom: 0%;
        }
    }
    
    @keyframes halfScreen_sideSlideOut {
        0% {
            bottom: 0%;
        }
    
        100% {
            bottom:-300px;
        }
    }
}