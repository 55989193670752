@import '../../assets/scss/components/mixins';
@import 'style/size.scss';
@import 'style/colors.scss';
@import 'style/font.scss';

$white-bell-icon-color : $white;

body:has(.buy-asset-slider-halfScreen) .asset-profil-page {
    pointer-events: none;
}

.asset-profil-page {
    width: 100%;
    overflow: auto;
    background: $app-background;
    padding: 60px 15px 200px 15px;
    overflow-y: auto;

    @media (max-width: $min-extra-large-device) {
        padding-bottom: 100px;
        width: 70%;
        margin: auto;
    }

    @media (max-width: $mobile) {
        width: 100%;
    }

    @media (min-width: $min-extra-large-device) {
        padding-bottom: 0px;
    }

    .instant-liquidity-chip {
        margin: 0px auto 20px;
    }


    .flex-box-content {
        width: 100%;
    }

    .countdown-ico-not-open {
        @include plus-jakarta-font;
        text-align: center;
        margin: 0px 0 0px 0;
    }

    .info-container {
        display: block;
        clear: both;
    }

    .dropdown-button {
        display: none;
    }

    .see-more {
        padding-top: 8px;
        @include fontWeightSize(bold);
    }

    .more-info-wrapper {
        display: flex;
        align-items: center;
        padding: 10px;
        border-radius: 10px;
        background: #f2f2f4;
        cursor: pointer;
        margin-left: 8px;
        margin-top: 4px;

        p {
            margin: 0 10px;
            font-size: 12px;
        }
    }

    .full-container {
        position: relative;
        color: #222222;
        border-radius: 20px;

        .info-container {
            padding: 10px 20px 20px 20px;
        }

        .info-wrapper {
            @include plus-jakarta-font;
            @include fontWeightSize(medium);
            display: flex;
            align-items: flex-start;
            justify-content: space-between;

            .more-info-wrapper {
                border-radius: 18px;
                background: #eaebf3;
                cursor: pointer;
            }
        }

        .instruction-wrapper {
            margin-bottom: 30px;
        }

        .full-yield-container {
            margin-bottom: -30px;
        }

        .private-sell__video-container {
            border-radius: 15px;
            height: 300px;
            overflow: hidden;
            margin-top: 16px;
            min-height: 300px;

            @media (max-width: $mobile) {
                min-height: 200px;
                height: 200px;
            }
        }
    }

    .asset-profil-name {
        font-size: 20px;
        margin-left: 10px;
        font-weight: 100;
        margin-top: 13px;
        margin-bottom: 10px;

        .name-label {
            align-items: center;
            display: inline-flex;
            width: 100%;
            justify-content: space-between;

            @media (max-width: $mobile) {
                display: initial;
            }

            .icon-visitor {
                width: 20px;
                height: 20px;
            }

            .visitor-counter {
                padding: 10px;
                width: auto;
                display: inline-flex;
                align-items: center;

                border-radius: 10px;
                background-color: #14172d;
                margin: 0 10px;
                cursor: pointer;

                @media (max-width: 768px) {
                    padding: 10px;
                    border-radius: 10px;
                }

                @media (max-width: 400px) {
                    padding: 7px;
                    border-radius: 8px;
                }

                .label-visitor-counter {
                    font-size: 14px;
                    margin-left: 4px;
                    font-weight: 400;
                }
            }
        }
    }

    @mixin container-subscribe-button {
        background: $notify-btn-bgColor;
        padding: 6px 15px 6px 6px;
        border-radius: 11px;
        display: flex;
        justify-content: center;
    }

    @mixin bell-container {
        width: 37px;
        height: 37px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
    }

    @mixin bell-size {
        height: 20px;
        width: 17px;
    }

    @mixin subscribe-button-text {
        color: $primary-button-color;
        font-size: 13px;
        margin: 0;
        line-height: 0;
    }

    .container-msg-already-subscribe {
        @include container-subscribe-button;
        background-color: rgba($medium-green, alpha(#ffffff18));
        ;

        .subscribe-bell {
            @include bell-container;
            background-color: rgba($medium-green, alpha(#ffffff1A));
            ;

            svg {
                @include bell-size;
                color: $medium-green;
            }
        }

        p {
            @include subscribe-button-text;
            @include fontWeightSize(extra-bold, 15px);
            color: $medium-green;
        }
    }

    .container-msg-subscribe {
        @include container-subscribe-button;

        .unsubscribe-bell {
            @include bell-container;

            svg {
                @include bell-size;
                color: $notify-btn-bell-color;
            }
        }

        p {
            @include subscribe-button-text;
            @include fontWeightSize(extra-bold, 15px);
        }
    }

    .container-tabs-asset-profile {
        padding: 10px;
    }

    @keyframes blinkElement {
        0% {
            opacity: 0.3;
        }

        50% {
            opacity: 1;
        }

        100% {
            opacity: 0.3;
        }
    }

    .parts-infos-container {
        display: flex;
        padding: 10px 10px 0 10px;
        justify-content: space-between;
        align-items: center;

        @media (max-width: $mobile) {
            justify-content: center;
            margin-top: 10px;
        }


        .remaining-time-container {
            text-align: right;
            color: #f44336;
            font-size: 14px;

            @media (max-width: $mobile) {
                text-align: center;
            }
        }

        .parts-infos__separator {
            width: 1px;
            margin: 8px 16px;
            background-color: #898c99;
        }

        .parts-infos__small-text {
            font-size: 14px;
            color: #f44336;

            @media (max-width: 362px) {
                font-size: 10px;
            }
        }

        .blink {
            animation: blinkElement 1.5s linear infinite;
        }

        .parts-infos__normal-text {
            margin-top: 5px;
            font-size: 18px;
            color: #f44336;
        }

        .parts-infos__last-day-container {
            .parts-infos__percentage-and-price-container {
                display: flex;

                .price-evolution {
                    margin-right: 8px;
                    white-space: nowrap;
                }

                .parts-infos__percentage-container {
                    display: flex;
                    align-items: flex-end;

                    .parts-infos__percentage-text {
                        font-size: 12px;
                        line-height: 1.2;

                        &.positive {
                            color: #78eb8e;
                        }

                        &.negative {
                            color: red;
                        }
                    }

                    .parts-infos__carret {
                        margin-right: 4px;

                        &.positive {
                            fill: #78eb8e;
                        }

                        &.negative {
                            margin-bottom: 2px;
                            fill: red;
                        }
                    }
                }
            }
        }
    }

    .top-investors-container {
        margin: 20px 0 20px 0;

        &.title {
            font-size: 18px;
        }
    }

    .selector-container {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 10px;
        margin-top: 5px;

        @media (max-width: $mobile) {
            flex-direction: column;
        }

        p {
            margin-bottom: 0;
        }

        .big-text__currentValue {
            margin-bottom: 0;
            color: #222222;
            font-size: 30px;
            font-weight: 450;
        }

        .small-text__currentValue {
            margin-bottom: 0;
            font-size: 14px;
        }
    }

    .no-price {
        justify-content: center;

        @media (max-width: $mobile) {
            .sale-container {
                margin-top: 0;
            }
        }
    }

    .cart-first-line-container {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 10px 20px 10px 20px;
        margin-top: 5px;
        text-align: left;

        p {
            margin-bottom: 0;
        }

        .big-text__currentValue {
            margin-bottom: 0;
            color: #222222;
            font-size: 30px;
            font-weight: 450;
        }

        .small-text__shares {
            color: #222222;
        }

        .small-text__currentValue {
            margin-bottom: 0;
            font-size: 14px;
        }

        .very-small-text__currentValue {
            margin-bottom: 2px;
            font-size: 10px;
            color: #23253e;
        }

        .change-payment-method {
            display: flex;
            justify-content: center;
            align-items: center;

            height: 40px;
            width: 40px;

            background: #7180e3;
            border-radius: 50px;
            color: white;
        }
    }

    .container-init-payment {
        .container-bouton-shares {
            background: #fbfbfc;
            // box-shadow: 0px 5px 20px 0px rgba(32, 36, 66, 0.15);
            text-align: center;
            box-sizing: border-box;
            margin: auto;
            margin-top: -10px;

            .container-payment {
                width: 100%;
                margin-top: 10px;
                border-radius: 15px;
                background: linear-gradient(125deg, #2feeb8 20%, #18d29c 80%);
                width: 100%;
                margin-top: 10px;
                border-radius: 15px;

                .title-init-payment {
                    font-size: 18px;
                }
            }

            .btn-init-payment {
                cursor: pointer;

                &.buy-tokens {
                    border-radius: 10px;
                    padding: 10px 10px;
                    font-size: 16px;
                    letter-spacing: 0.04em;
                    font-weight: 500;
                    background: linear-gradient(125deg, #2feeb8 20%, #18d29c 80%);
                    color: white;
                    border-radius: 55px;
                    width: 120px;
                }
            }

            .secondary-btn-init-payment {
                border: 1px solid #424177;
                padding: 10px;
                padding-left: 20px;
                padding-right: 20px;
                border-radius: 10px;
                color: #909092;
                font-weight: 500;

                &:hover {
                    background: linear-gradient(125deg, #7180e3 20%, #9371ef 80%);
                    border: 1px solid #7180e3;
                    padding: 10px;
                    padding-left: 20px;
                    padding-right: 20px;
                    border-radius: 10px;
                    color: #ffffff;
                    font-weight: 500;
                }
            }

            .btn-init-payment {
                &.activated {
                    background: linear-gradient(125deg, #7180e3 20%, #9371ef 80%);
                    border: 1px solid #7180e3;
                    padding: 10px;
                    padding-left: 20px;
                    padding-right: 20px;
                    border-radius: 10px;
                    color: #ffffff;
                    font-weight: 500;
                }
            }
        }
    }

    .sale-container {
        display: flex;
        flex-direction: row;
        justify-content: center;

        @media (max-width: 1100px) {
            flex-direction: column;
        }

        @media (max-width: $mobile) {
            margin-top: 15px;
            width: 80%;
        }

        &.native {
            width: 100%;
        }

        .initpayment-container {
            flex-basis: 70%;
            text-align: center;
            background: #fbfbfc;
            height: 100%;
            position: relative;
            border-radius: 15px;

            &.fullsize {
                flex-basis: 100%;
            }
        }

        .tokens-remaining {
            flex-basis: 30%;
            text-align: center;
            background: #202442;
            height: auto;
            position: relative;
            border-radius: 5px;
            display: flex;
            align-items: center;

            @media (max-width: 1100px) {
                padding: 15px 0 30px;
            }

            @media (max-width: 600px) {
                padding-bottom: 0;
            }

            .flex-box-content.only-tokens {
                position: inherit;
                border: 1px dashed;
                border-radius: 40px;
                text-align: center;
                width: 75%;
                margin-left: auto;
                margin-right: auto;
                display: block;
                margin-top: 15px;
                margin-bottom: 15px;
                padding-top: 18px;
                padding-bottom: 10px;
                line-height: 25px;
                color: #fff;
                max-width: 400px;

                .title-asset {
                    color: #3df5c1;
                }

                .title-asset.remaining-asset {
                    color: #f53d3d;
                }
            }
        }
    }

    .graph-container {
        display: block;
        width: 100%;
        align-items: center;

        @media (max-width: 1100px) {
            height: auto;
        }

        .buy-asset-history-graph {
            flex-basis: 96%;
            text-align: center;
            background: #202442;
            height: 100%;
            position: relative;
            border-radius: 5px;

            .container-loader-no-history {
                padding: 40px;
            }
        }
    }

    p.asset-description {
        line-height: 2.6 !important;
    }

    p.asset-description-see-more {
        color: #15182b96;
        font-family: Plus Jakarta Sans, Regular;
        line-height: 20px;
        font-size: 13px;
        margin-bottom: 0;
    }

    .container-actions-buttons {
        margin-top: 60px;
    }
}