.overview-assets {
    position: relative;
    margin-top: 70px;
}

.overview-assets .action-buttons {
    color: black;
    display: inline-flex;
    align-items: center;
    grid-column-gap: 30px;
    -webkit-column-gap: 30px;
    column-gap: 30px;
    width: 100%;
    margin-bottom: 30px;
}

.overview-assets .primary-button {
    width: 15%;
}
