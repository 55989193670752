.asset-request-profil {
    margin-top: 70px;

    .content {
        .identity-container {
            display: inline-flex;
            height: auto;
            width: 100%;
            grid-column-gap: 20px;
            column-gap: 20px;
            justify-content: space-between;
            align-items: flex-start;

            overflow: hidden;
        }

        .container-verify-identity {
            height: auto;

            .title-confirmed {
                color: #3df5c1;
            }

            .title-refused {
                color: #ff6659;
            }
        }

        .status-request {
            display: inline-flex;
            width: 100%;
            justify-content: center;
            padding: 20px 0 0 0;
            column-gap: 20px;
            align-items: center;

            .accept-button {}
        }

        .content-card {
            flex-basis: 100%;
            margin-top: 60px;
            border-radius: 5px;
            // padding: 20px;
            // line-height: 50px;
            box-sizing: border-box;
            color: black;
        }

        .request-information {
            .items {
                display: inline-flex;
                align-items: center;
                width: 100%;
                column-gap: 20px;
            }
        }

        .form-asset {
            width: 50%;
            margin: 60px auto auto auto;

            @media (max-width: 1100px) {
                width: 90%;
            }
        }
    }
}