@import "../../../../style/colors.scss";
.accordion-container {
    .override-accordion-summary {
        align-items: center;
        gap: 15px;
        .stripe-title {
            font-weight: bolder;
            font-size: 17px;
        }
    }
    .accordion {
        justify-content: center;
        .accordion-title {
            font-weight: normal;
            font-size: 14px;
            color: #007bff;
        }
    }
}
