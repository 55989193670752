@import "../../../style/size.scss";
@import "../../../style/colors.scss";
@import "../../../style/font.scss";
@import "../../../style/layout.scss";

.info.token-available.bonus.border-transparent::after {
    background-color: transparent;
}

.all-token-information-container {
    &.bonus {
        &.token-information-fun-cards {
            background-color: white;
        }

        flex-wrap: wrap;
    }

    display: flex;
    min-height: 120px;
    justify-content: space-evenly;
    background: $token-information-background;
    color: $token-information-primary-color;
    border-radius: 12px;
    margin: 15px auto;
    width: 100%;
    padding: 12px 0;

    .info.bonus {
        flex: 1
    }

    .info {
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%;

        .label {
            display: flex;
            margin: 10px 0 5px 0;
            text-align: center;
            align-items: center;
            @include fontWeightSize(medium, 11px);
            color: $token-information-font-color;
            width: 90%;
            justify-content: center;
        }

        .no-margin {
            margin-top: 0;
            margin-bottom: 0;
        }

        .specialPrice {
            @include plus-jakarta-bold-font;
            text-decoration: line-through;
            font-size: 12px;
            color: #606373;
        }

        .value {
            @include plus-jakarta-bold-font;
            font-size: 19px;
        }

        &:not(:last-child)::after {
            content: "";
            position: absolute;
            background-color: $token-information-vertical-line;
            width: 2px;
            height: 50px;
            right: 0;
            top: 25%;
        }
    }

    .token-price {
        .icon {
            background: $token-information-primary-color;
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 3px 1px;
            border-radius: 4px;

            svg {
                fill: $token-information-background;
            }
        }
    }

    .token-yield {
        justify-content: center;
        cursor: pointer;

        .icon {
            bottom: 0;
        }

        .tooltip-helper .icon {
            margin-left: 5px;
            cursor: help;
            fill: $token-information-font-color;
            width: 15px;
            height: 15px;
        }

        .label {
            display: flex;
            flex-direction: row;
            align-items: center;
            color: #6677f5;
            text-decoration: underline;
        }

        .value {
            .info-icon {
                color: #6677f5;
                width: 15px;
                height: 15px;
                margin-left: 5px;
                overflow: hidden;
                border-radius: 50px;
            }
        }
    }

    .token-available {
        .icon {
            display: flex;
        }

        .value {
            display: flex;
            align-items: center;

            .indicator {
                content: "";
                width: 7px;
                height: 7px;
                background: $token-information-primary-color;
                border-radius: 50%;
                margin-right: 5px;
            }

            .fire-icon {
                width: 20px;
                height: 20px;
                margin-right: 5px;
            }
        }
    }

    .green {
        color: $medium-green;
    }
}

.tooltip-range {
    @include default-flex-box;
    padding: 10px;
    margin: -10px 0 10px 0;
    flex-wrap: wrap;
    justify-content: center;
    align-items: self-end;

    .label-annual {
        color: $yield-text-color;
        font-size: 14px;

        transform: translate(0%, -10px);
        width: 100%;
        text-align: center;
        padding-left: 10px;
    }

    .range-container {
        width: 100px;
        position: relative;
        height: 10px;
        background: $light-green;
        border-radius: 25px;
        margin-top: 20px;

        .range-bar {
            @include default-flex-box;
            justify-content: space-between;
            border-radius: 25px;
            position: absolute;
            height: 10px;
            background: $dark-green;
            width: 60%;
            left: 50%;
            transform: translate(-50%, 0px);
        }
    }

    .range-price-container {
        @include default-flex-box;
        position: absolute;
        transform: translate(-50%, -50%);
        left: 50%;
        width: 100%;
        text-align: center;

        .price-container {
            width: 100%;
            transform: translateY(-7px);
            margin: 0px auto 0px auto;

            p {
                color: $yield-text-color;
                font-size: 12px;
                height: 10px;

                @media (max-width: $mobile) {
                    font-size: 10px;
                }
            }
        }
    }
}