.buy-asset-header {
    flex-direction: column;

    .pack-vip-container {
        margin-bottom: 8px;
        width: 100%;
    }

    .buy-asset-header_title {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        position: relative;
        width: 100%;
        padding: 15px;

        .buy-asset-header_title-label {
            margin-left: 2rem;
        }

        .drop-down-menu {
            align-self: center;
            flex-basis: 150px;
            padding: 8px 10px 8px 12px;
            position: static;
            font-size: 14px;


            .drop-down-menu_label {
                font-weight: bold;
            }

            .menu {
                margin: 0px;
                position: absolute;
                left: auto;
                right: 0;
                top: 100%;
                height: fit-content;
            }
        }
    }
}