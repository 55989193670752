@import 'style/colors.scss';
@import 'style/font.scss';

.achievement-card {
    border-radius: 12px;
    padding: 20px;
    color: #565A74;
    background: $white;
    text-align: center;
    margin-bottom: 10px;


    &.green {
        background: #F2FAF5;
        box-shadow: (0px 3px 20px #53D08140);
    }

    .title {
        @include fontWeightSize(bold);
    }

    .subtitle {
        @include fontWeightSize(medium);
    }

    .bottom-component {
        margin-top: 25px;
    }
}