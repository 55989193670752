$base-color: #d4d9fb;
$base-color-hover: #fff;
$dark-text: #8e8e8e;
$gradient2: linear-gradient(90deg, rgba(215, 61, 245, 1) 0%, rgba(143, 61, 245, 1) 100%);
$gradient-button-primary: linear-gradient(145deg, #ff7c6e 25%, #f5317f 50%);
$darktheme: #202442;
$light-violet: #896bed;
$card-background: #f4f4f5;
$card-team: #fafafa;
$tdx-red: #fa483f;
$violet: #7180e3;
$tdx-vert: #52bc79;
$tdx-light-green: #55BC7C;
$tdx-price-evolution-green: #53D081;
$filter-filled-slot: #20202021;
$stepper-filled-slot-shadow: #00000029;
$drag-message-color: #15182b6e;
$dropzone-border-color: #c2c2c2;
$tab-not-selected: #15182B;
$tournament-headband-linear-gradient: linear-gradient(136deg, #8965FD, #3F395D, #0C0D10);
/*linear-gradient(136deg, #fd8b85, #d0547dda, #d0547d);*/
$tournament-on-background: #F7F8FF;
$tournament-on-list-card-background: rgb(237, 238, 248);
$tournament-end-black-title: #15182B;
$cashprized-headband-background: #ffffff14;
$top-vip-card-background: linear-gradient(244deg, #F8F8F8, #F3F3F4);
$sidemenu-background-gradient: linear-gradient(159deg, #15182B 40%, #3D4471);
$top-50-page-background-color: #F7F8FF;
$top-50-page-title-color: #15182B;
$top-50-banner-playBtn-bgColor: #ffffff2b;
$top-50-token-greyColor: #72747F;
$top-50-token-greyColor-inGrid: #72747F;
$assetsTableRow-overlay-bgColor: #2020201c;
$AssetProfile-banner-text-color: #15182B;
$app-background: #F7F8FF;
$register-page-black-txt-color: #15182BCC;

//V2
$wallet-background: #15182B;
$wallet-item: #2D3041;
$become-vip-text-color: #15182B;
$become-vip-label-color: #868895;
$become-vip-gradient: linear-gradient(to top, #DDE2FF80 0%, $app-background 100%);
$email-not-verified-color: #E0AD22;
$footer-gradient: linear-gradient(99deg, #EBECF689, #EBECF6);
$footer-text-color: #7A7E96;
// Button
$primary-button-color: #6677F5;
$primary-button-hover-color: #6677f5d9;
$primary-button-disable-color: #6677f5b8;
$green-button-color: #53D081;
$green-button-shadow-color: #53D08199;
$red-button-color: #fb4b4cf7;
$sell-button-bgColor: #E66F8126;
$sell-button-TextColor: #E66F81;

// Bottom menu
$bottom-menu-color: #15182B;
$menu-active-background: #E5E7F8;
$menu-active-color: #6677F5;
$bottom-bar-background: #f7f8ff;

$yield-background: #E5E7FD;
$yield-secondary-background: #EEEEFE;
$yield-text-color: #18D29C;
$yield-yellow: #FFD713;
$yield-text-color-yellow: #8F781A;

$tertiary-violet: #6677F5;
$tertiary-light-violet: #6677F50D;
$tertiary-violet-faded: rgba(102, 119, 245, 0.25);
$dark-violet: #6573FE;
$light-violet: #C4CBFE;
$fade-violet: #8187D0;

$smoke-blue: #8187D01A;
$whitesh-blue: #C6C7CF;
$blacky-blue: #15182B1A;

$tertiary-grey: #15182B;
$tertiary-grey-fade: #15182BCC;
$medium-grey: #EBECF680;
$dimmed-grey: #868895;
$whitesh-grey: #EBECF6;
$light-grey: #15182B80;
$stormy-grey: #4C4E5E;
$steel-grey: #838592;

$coral-black: #565A74;

$dark-green: #18D29C;
$light-green: #18D29C80;
$medium-green: #53D081;
$disabled-medium-green: #a0edbd;

$medium-red : #E66F81;
$disabled-medium-red: #ebb2bb;

$white : #FFFFFF;
$fade-white : #FFFFFF80;
$black-warm : #000000;
$waiting-clock-bgColor: #FEEDD7;

$token-information-primary-color: #15182B;
$token-information-background: #EBECF6;
$token-information-vertical-line: #15182B1A;
$token-information-font-color: #15182B80;
$primary-red: #ff7700;
$error-red: #e66f81;
$success-green: #53D081;
$light-blue: #d7d8ff;

$withdraw-font-color: #15182B;
$price-slider-gradient: linear-gradient(90deg, #53D081, #6677F559, #E66F8159);
$price-slider-gradient-inverted: linear-gradient(90deg, #E66F8159, #6677F559, #53D081);
$modal-image-gradient: linear-gradient($app-background 70%, $white);
$reward-modal-gradient: linear-gradient(299deg, $primary-button-color, #919EF9, #CED4FF, #ADB7FC, $primary-button-color);
$register-gift-modal-gradient: linear-gradient(285deg, #FCC73C, #F09E36);

$caution-yellow: #E0AD22;
$carousel-indicators-color: #B2B5C559;

$link-color-blue: #007bff;
$link-color-blue-hover: #0056b3;

$watchNowBtn-bgColor-blacky : #00000045;

$bg-light-violet : #E0E4FE;
$bg-virtual-keyboard : #F7F8FF;

//discord-btn
$discord-bg-color : #EBEDFA;

//league-tournament
$lightWhite-inLeague : #FFFFFF71;
$upgradeLeagueProgress-borderColor: #DBDBE5;
$upgradeLeagueBtn-textColor: #E8EBFC;
$locked-icon-light-color: #d5d5de;
$progress-bar-light-color: #D4D6EF;
$shaded-violet: #6C7BEB;

$asset-bar-symbol: #B2B5C5;
$white-list-bgColor: #EBEDFA;
$club-bgColor: #F1F2FC;
$club-text-greyColor: #15182BA6;
$stat-textColor: #626574;

$countdown-bgColor: #F1F2FC;
$notify-btn-bgColor: #E2E5FD;
$notify-btn-bell-color: #6677f58f;

//team-page
$team-bgColor: #F7F8FF;
$team-light-black-textcolor: #838592;
$team-score-details-textColor: #838592;
$team-score-details-value-color: #70B87E;
$team-join-tournment-shadow-color: #85DA96;

$pale: #CBCBCB;
$background-light: #21212126;

//pending-card
$pending-bgColor: #FFFFFF1A;
$pending-secondary-color: #FFFFFFCC;
$pending-topic-color: #FFA584CC;
$pending-divider-color: #FFFFFF26;
$pending-cancel-color: #E66F81;
$pending-skeleton-loading-bgColor: #F8F2F21A;

//user-tournament
$ranking-card-borderColor: #A9B2EA;
$ranking-card-bgColor: #E8EBFF;

//score-booster
$score-booster-text-color: #595B6A;
$score-booster-amount-bg: #EBECF6CC;
$score-booster-subtitle-text-color: #15182B99;

$yield-banner-bg: #F1F2FA;
$yield-icon-bg: #F7F8FF;