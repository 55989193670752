@import "style/size.scss";

.fun-asset-modal {
    padding: 22px 25px 22px 25px;

    .container-image-fun-cards {
        .fun-cards-logo {
            width: 40%;

            @media(max-width: $mobile) {
                width: 70%;
            }
        }
    }

    .title-modal {
        margin-top: 30px;
        font-size: 20pt;
        color: #15182B;
        font-weight: 600;

        @media(max-width: $mobile) {
            font-size: 15pt;
        }
    }

    .container-description {
        p {
            margin-top: 20px;
            font-size: 11pt;
            line-height: 1.3;
            color: #15182B;
            opacity: 0.6;
            text-align: left;

            @media(max-width: $mobile) {
                font-size: 10pt;
            }
        }
    }

    .container-terms-of-use {
        border: 1px #15182B59 solid;
        border-radius: 10pt;
        background-color: white;

        p {
            padding: 20px 20px 0px 20px;
            line-height: 1.5;
            color: #727481;
            font-size: 10pt;
            text-align: left;

            @media(max-width: $mobile) {
                font-size: 9pt;
                line-height: 1.3;
            }
        }

        .checkbox-container {
            padding-bottom: 15px;

            .label-checkbox {
                display: flex;
                justify-content: center;
                align-items: center;
                cursor: pointer;

                .checkbox {
                    margin-right: 10px;

                    @media(max-width: $mobile) {
                        margin-right: 5px;
                    }
                }

                span {
                    color: #6677F5;
                    font-size: 10pt;
                    font-weight: 600;

                    @media(max-width: $mobile) {
                        font-size: 9pt;
                    }
                }
            }
        }
    }

    .confirm-button-fun-assets {
        margin-top: 20px;
        width: 60%;
        cursor: pointer;
    }
}