@import "style/colors.scss";
@import "style/size.scss";
@import "style/font.scss";
@import "assets/scss/custom_mixins.scss";

.league-progress-map {
    display: flex;
    justify-content: center;

    .individual-league{
        display: flex;
        align-items: center;
        height: 30px;

        &.locked-league{
            .league-icon-size{
                @include change-svgColor($locked-icon-light-color);
            }
        }
        
        .league-icon-size {
            height: 100%;
            width: 24px;
            
            svg {
                width: 100%;
                height: 100%;
            }
        }

        &:first-child{
            .league-icon-size {
                height: 70%;

            }  
        }
        
        .progress-bar-line{
            margin-left: 5px;
            margin-right: 5px;
            background-color: $progress-bar-light-color;
            width: 24px;
            height: 2px;
            opacity: 0.5;
            border-radius: 10px;
            overflow: hidden;

            .progress-bar-line__shaded{
                height: inherit;
                background-color: $shaded-violet;

            }
        }
    }
}