@import 'style/font.scss';
@import 'style/colors.scss';
@import 'src/style/size.scss';

@mixin backgrounColor($bgColor:$discord-bg-color) {
    background: $bgColor;
}

.join-discord-block {
    display: flex;
    justify-content: center;
    padding: 12px;
    margin: 0 auto;
    border-radius: 11px;
    margin-bottom: 13px;
    @include backgrounColor();

    @media screen and (min-width: $mobile) {
        width: 400px;
    }

    .icon-circle {
        margin-right: 8px;

        .circle {
            @include backgrounColor($tertiary-violet);
            border-radius: 50%;
            padding: 2px 4px;

            svg {
                width: 14px;
                height: 14px;
                fill: $white;
            }
        }

    }

    .text {
        color: $tertiary-violet;;
        @include fontWeightSize(extra-bold, 15px);
    }
}