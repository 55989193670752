@import 'style/colors.scss';
@import 'style/font.scss';

$assetDetail-border-color: #15182B1A;
$assetDetail-value-color : #15182B80;
$assetDetail-value-icon : #15182B59;

.asset-detail {
    background-color: $medium-grey;
    padding: 10px 20px;
    border-radius: 14px;
    margin-top:16px;

    .asset-information {
        .asset-information_row {
            font-size: 12px;
            display: flex;
            justify-content: space-between;
            padding: 15px 0;

            &:not(:last-child) {
                border-bottom: 1px solid $assetDetail-border-color;
            }

            .asset-information_row_label {
                color: $fade-violet;
                @include fontWeightSize(bold);
                display: flex;
                align-items: center;

                svg {
                    margin-right: 7px;
                }
            }

            .asset-information_row_value {
                color: $assetDetail-value-color ;
                @include fontWeightSize(medium);
                display: flex;
                align-items: center;

                .tooltip-helper {
                    margin-left: 5px;

                    svg {
                        color: $assetDetail-value-icon;
                    }
                }
            }
        }
    }

    .asset-history {
        padding-top: 20px;
        margin-bottom: 40px;

        .asset-history_title {
            color: $tertiary-grey;
            font-size: 13px;
            opacity: 0.5;
        }
        .mixed-chart {
            margin-top: 20px;

            // apexchart style start
            .apexcharts-tooltip {
                background: none;
                border: none;
                box-shadow: none;
                color: $medium-green;
                font-size: 16px;
                font-weight: 500;
            }

            .apexcharts-xaxistooltip-bottom {
                border: none;
                background: none;
                color: $medium-green;
                opacity: 0.8;

                &:before {
                    content: none;
                }
            }

            .apexcharts-xaxis line {
                display: none;
            }

            .apexcharts-xcrosshairs.apexcharts-active {
                display: none;
            }
        }
    }

    .yield-info-icon {
        color:$tertiary-violet;
        opacity:0.9;
        font-size: 18px;
        cursor: help;
    }

    .common-range-container {
        display:inline-block;
        margin-right: 12px;
    }

}