@import 'style/font.scss';
@import 'style/size.scss';
@import 'style/colors.scss';

$rightSection-top-padding: 140px;

.right_section {
    display: none;

    @media(min-width:$min-extra-large-device) {
        display: block;
        width: calc((100vw - 650px)/2);
        position: sticky;
        top: 0;
        overflow-y: auto;
        overscroll-behavior: contain;
        height: 100vh;
        padding: $rightSection-top-padding 0 0 24px;
    }

    .right_section-container {
        max-width: 227px;
        height: calc(100vh - #{$rightSection-top-padding});
        overflow-y: auto;
        overflow-x: hidden;
        overscroll-behavior: contain;
    }

}