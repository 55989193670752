@import "style/size.scss";
@import "style/font.scss";

// Header Section Start
header {
    position: relative;
}

.header_section {
    position: fixed;
    display: flex;
    align-items: center;
    width: 100%;
    z-index: 16;
    top: 0;
    padding-top: env(safe-area-inset-top);

    .total-credits {
        @include fontWeightSize(bold, 15px);
        margin-right: 10px;
    }

    @media(min-width:$min-extra-large-device) {
        width: $main-container-width;
            margin-left: $sidebar-container-width;
        .customize-menu {
            position: absolute;
            left: calc(100% + 24px);
            top: 35px;
        }

        .top-menu-widget-loading {
            position: absolute;
            left: calc(100% + 24px);
            top: 35px;
        }

        .top-bar-nav-button {
            display: none
        }
        .back-button-only {
            display: block;
        }
    }
    
    @media(min-width: 1300px) {
        margin-left: 50%;
        transform: translateX(-50%);
    }
}

.header_sec {
    display: flex;
    align-items: center;
    width: 100%;
    position: relative;

    .search_panel {
        width: 400px;

        .MuiPaper-root {
            background-color: #393f74;
        }

        .MuiInputBase-formControl {
            height: 50px;
        }

        .MuiAutocomplete-inputRoot .MuiAutocomplete-input {
            padding: 4px 4px;
        }

        .MuiPaper-elevation1 {
            box-shadow: none;
            border-radius: 9px;
            height: 52px;
        }

        .MuiIconButton-label {
            color: #7b7ab6;
        }

        .MuiFormControl-marginNormal {
            margin-top: 0 !important;
            margin-bottom: 0 !important;
        }
    }

    .user_header {
        display: flex;
        margin-left: auto;
        align-items: center;

        .header_links {
            a {
                padding: 0px 10px 0px 10px;
                width: auto;
                height: 50px;
                display: flex;
                align-items: center;
                border-radius: 7px;
                margin: 0 0 0 10px;
                background-color: rgba($color: #000000, $alpha: .3);
                backdrop-filter: blur(50px);

                p {
                    font-size: 16px;
                    display: flex;
                    align-items: center;
                    color: white;
                }

                &:hover {
                    opacity: .7;
                }
            }

            .portfolio_links {
                display: flex;
                align-items: center;
                color: #97a8de;

                svg {
                    margin: 0 5px 0px 0;
                }

                .percentage-number-wallet {
                    margin: 0 0px 0px 10px;
                    color: #3df5c1;
                    font-size: 16px;

                    i {
                        margin: 0 2px 0px 0;
                    }
                }
            }

            &.profil_icon {
                // margin: 0 0 0 10px;
            }
        }
    }
}

.responsive_menu {
    padding: 0px 10px 0px 10px;
    width: auto;
    height: 50px;
    display: none;
    align-items: center;
    border-radius: 7px;
    margin: 0px 15px 0px 0px;
    backdrop-filter: blur(50px);
    background-color: rgba($color: #000000, $alpha: .3);
    transition: .2s ease-in-out;

    &:hover {
        opacity: .7;
    }

    svg {
        font-size: 28px;
    }
}

// Header Section End