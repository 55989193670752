.form-private-sell-information-form {
    .field-container {
        .form_label.under-text {
            margin-top: 5px;
            margin-bottom: 15px;
            font-size: 11px;
        }
    }
    .form_label.under-text {
        margin-top: 5px;
        margin-bottom: 15px;
        font-size: 11px;

        .button-submit {
            width: 40%;
        }
    }
    .second-text-start-ico {
        line-height: 20px;
    }
}

.form-private-sell-information-form-light {
    .field-container {
        .form_label.under-text {
            color: black;
        }
    }
    .form_label.under-text {
        color: black;
    }
}
