@import "style/colors.scss";
@import "style/font.scss";
@import "style/size.scss";

.state-panel-container {
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap;
    grid-gap: 10px;

    .stat-panel {
        color: $tertiary-grey;
        text-transform: uppercase;

        @media (max-width: $mobile) {
            width: 100%;
        }

        &:not(:last-child) {
            margin-bottom: 20px;
        }

        .stat-panel__value {
            @include fontWeightSize(ultra-bold, 28px);
            font-family: "Monument Extended", sans-serif;
        }

        .stat-panel__title {
            @include fontWeightSize(light, 14px);
            margin-bottom: 24px;
        }

        &:last-child {
            :last-child {
                display: none;
            }
        }
    }
}