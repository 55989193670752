@import 'style/font.scss';
@import 'style/size.scss';

.buy-shares-button {
    overflow: hidden;
    white-space: pre-line;

    &.green-button {
        text-transform: none;
        flex-wrap: wrap;
    }

    .buy-button-label {
        @include plus-jakarta-font;
        @include fontWeightSize(extra-bold, 15px);

        svg {
            position: relative;
            top: 6px;
        }
    }

    &.euro,
    &.fun {
        position: fixed;
        bottom: 85px;
        z-index: 15;
        width: 90%;
        max-width: 460px;
        text-transform: uppercase;
        padding: 10px 10px;
        left: 50%;
        transform: translateX(-50%);

        @media (max-width: $mobile) {
            max-width: 360px;
        }
    }
}

.custom-button-early-bird {
    width: 70% !important;
    border-radius: 20px;
    padding: 15px 10px 10px 10px;
    margin: auto;

    @media (max-width: $mobile) {
        width: 100% !important;
        line-height: 20px;
        padding: 20px;
    }

    .buy-button-label {
        width: 100%;
        line-height: 15px;

        @media (max-width: $mobile) {
            line-height: normal;
        }
    }
}