@import 'style/colors.scss';
@import 'style/font.scss';
@import 'style/size.scss';

.sport-score-detail {
    padding: 0px 18px 18px 18px;

    display: flex;
    flex-direction: column;
    align-items: center;

    border-radius: 14px;

    @include plus-jakarta-font;

    .sport-score-detail-header {
        position: relative;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 14px;
        line-height: 18px;
        color: #606373;

        .cross-icon {
            position: absolute;
            right: 0px;
            cursor: pointer;
            fill: #4C4E5E;
        }
    }

    .sport-score-detail-content {
        width: 100%;
        display: flex;
        flex-direction: column;
        row-gap: 6px;

        .sport-score-detail-content-status {
            display: flex;
            align-items: center;

            padding: 18px;
            margin: 18px 0;
            border-radius: 11px;
            background-color: #53D0811F;
            color: #15182B;
            gap: 12px;

            .sport-score-detail-content-status-score {
                font-size: 40px;
                color: #53D081;
                line-height: 51px;
                @include plus-jakarta-bold-font;
            }

            .sport-score-detail-content-status-role {
                @include plus-jakarta-bold-font;
            }

            .sport-score-detail-content-status-text {
                display: flex;
                align-items: center;
                gap: 5px;
                font-size: 12px;

                .indicator {
                    background-color: #53D081;
                    border-radius: 50px;
                    width: 10px;
                    height: 10px;
                }
            }
        }

        .sport-score-detail-content-stat {
            position: relative;

            padding: 13px 16px;
            background-color: #8187D01A;
            border-radius: 11px;

            text-align: left;

            .sport-score-detail-content-stat-title {
                color: #15182B;
                font-size: 13px;
            }

            .sport-score-detail-content-stat-value {
                margin-left: 10px;
                color: #15182B80;
                font-size: 13px;
            }

            .sport-score-detail-content-stat-points {
                position: absolute;
                right: 13px;

                color: #53D081;
                font-size: 12px;

                &.grey {
                    color: #15182B80;
                }

                &.red {
                    color: #fa483f80;
                }
            }

            .MuiDivider-root {
                margin: 10px 0;
            }

            .sport-score-detail-content-stat-multiplier {
                display: block;
                text-align: center;
                font-size: 11px;
                color: #15182B73;

                @media (max-width: $min-medium-mobile) {
                    font-size: 9px;
                }

                @media (max-width: $min-small-mobile) {
                    font-size: 7px;
                }

                @media (max-width: $small-mobile) {
                    font-size: 6px;
                }
            }

        }

    }
}