.transfer-page {
  margin-top: 60px;

  .download-row{
    display: flex;
    justify-content: flex-end;
    margin: 0px 5px 5px 0px;

    .download-btn{
      width: auto;

      .text-btn{
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }

  .transfer-container {
    width: 100%;
    margin: auto;
    .card-style {
      margin-top: 25px;
    }
  }
}
