@import "style/font.scss";
@import "style/colors.scss";

.wallet-transactions-container {
    @include plus-jakarta-font;
    margin-top: 26px;
    display: flex;
    flex-direction: column;
    gap: 6px;

    .wallet-transaction-card {
        background: #FFFFFF1A;
        padding: 25px 19px;
        display: flex;
        position: relative;
        border-radius: 12px;
        gap: 12px;

        .wallet-transaction-avatar {
            height: 44px;
            width: 44px;
            position: relative;

            img {
                height: 44px;
                width: 44px;
                border-radius: 8px;
                object-fit: cover;
            }

            .transaction-type-icon {
                position: absolute;
                top: -8px;
                left: -10px;

                svg {
                    height: 20px;
                    width: 22px;
                }
                
                .bottom-clock-gif {
                    border-radius: 50%;
                    background: #FEEDD7;
                    width: 30px;
                    height: 30px;
                }
            }
        }

        .wallet-transaction-detail {
            display: flex;
            flex-direction: column;
            gap: 8px;

            .wallet-transaction-amount {
                @include plus-jakarta-bold-font;
                font-size: 1rem;
            }

            .wallet-transaction-description {
                font-size: 13px;
            }

            .transaction-failed-text {
                text-decoration: line-through;
            }
        }

        .wallet-transaction-date {
            position: absolute;
            top: 8px;
            right: 12px;
            font-size: 13px;
            color: #FFFFFFCC;
        }
    }

    .section-title {
        color: rgba(#FFF, 0.8);
    }

.see-more {
    margin: 8px 0px;
    text-align: center;
    color: $tertiary-violet;
    font-size: 13px;
    font-weight: bold;
    cursor: pointer;
}
}