@import "style/colors.scss";

.modal-image-container {
    position: relative;
    display: flex;
    justify-content: center;

    &::before {
        display: block;
        content: "";
        position: absolute;
        background: $modal-image-gradient;
        height: 173px;
        width: 100%;
        border-radius: 17px;
    }

    img {
        z-index: 1;
        padding: 20px 30px 15px;
    }
}