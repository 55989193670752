.buy-asset-dark {
    background-image: url(https://assets-global.website-files.com/653fbca1688b464e81f5aa03/653fbca1688b464e81f5aa2f_background.webp) !important;
    padding: 0 5px !important;
    overflow: hidden !important;
    background-size: cover;
    background-repeat: no-repeat;
    .info.token-price {
        display: none !important;
    }

    .buy-confirmation-view {
        margin-top: 12px !important;
    }

    .txn-asset-body .token-section,
    .txn-buy-asset-header .transaction-buy-asset-header_title {
        margin-bottom: 12px !important;
    }

    .all-token-information-container .info .label {
        color: white !important;
    }

    .tokens-icon rect,
    .tokens-icons path,
    .tokens-icons #Soustraction_18 {
        fill: white !important;
    }

    .all-token-information-container .token-price .icon {
        background: rgba(255, 255, 255, 0.4) !important;
    }

    .cgu-checkbox {
        border: 1px solid #fff !important;
    }

    .general-terms {
        color: white !important;
    }

    .trendex-checkout-form,
    .trendex-checkout-form #payment-form {
        background-color: transparent !important;
    }

    .txn-asset-body .button-increase-decrease {
        color: #b5b8e4 !important;
        border-color: #b5b8e4 !important;
    }

    txn-asset-body .token-section .trade-detail-box .input-field-tokens-value input {
        width: 45px !important;
    }

    .payment-card-wrapper .payment-card.active .payment-card-right svg path {
        fill: #ffffff !important;
    }

    .container.checked {
        border-color: #EEEEEE !important;
    }

    .payment-checkbox-container .fill-checked {
        background: #EEEEEE !important;
    }

    .payment-card-wrapper .payment-card.active .payment-card-left {
        color: white !important;
    }

    .info.token-available,
    .info.token-price,
    .payment-card-wrapper,
    .trade-detail-box,
    .trade-detail-box.highlight-border {
        background-color: rgba(255, 255, 255, .15) !important;
        border: 1px solid rgba(219, 219, 230, .37) !important;
        border-radius: 16px !important;
    }

    .trade-detail-box .trade-detail__info .trade-detail_label .sub-text {
        color: #FFFFFF !important;
    }

    .trade-detail_label {
        color: white !important;
    }

    .buy-asset-slider {
     

    }

    .all-token-information-container {
        color: white !important;
    }

    .arrow-back {
        display: none !important;
    }

    .trendex-checkout-form #payment-form .payment-form-information label {
        color: white !important;
    }

    .buy-asset-slider .buy-asset-wrapper .all-token-information-container .info .label {
        color: #FFFFFF;
    }
}