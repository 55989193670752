@import 'style/font.scss';
@import 'style/size.scss';

.sell-share-button {
    &.red-button {
        text-transform: none;
    }

    &.fun {
        position: fixed;
        bottom: 20px;
        z-index: 15;
        width: 90%;
        max-width: 460px;
        text-transform: uppercase;
        padding: 10px 10px;
        left: 50%;
        transform: translateX(-50%);

        @media (max-width: $mobile) {
            max-width: 360px;
        }
    }


    .sell-button-label {
        display: flex;
        justify-content: center;
        align-items: center;
        @include fontWeightSize(extra-bold, 15px);
    }
}