@import "style/colors.scss";
@import "style/font.scss";
@import "style/size.scss";

.steps-container {

    .divider-container {
        padding-top: 15px;
    }

    .text-wrapper {
        display: flex;
        justify-content: center;
        align-items: flex-start;
        margin-top: 15px;
        margin-bottom: 20px;

        .step-h3 {
            @include fontWeightSize(ultra-bold, 28px);
            font-family: 'Monument Extended', sans-serif;
            color: #404354;
            margin-right: 12px;
            margin-bottom: 0;
        }

        .step-span {
            text-align: left;
            font-size: 13px;
            font-weight: thin;
            line-height: 18px;
            color: #404354;
            width: 100%;
            text-wrap: balance;

            .divider {
                margin-top: 20px;
            }
        }
    }

    .signupstep-container {
        .img-container {
            img {
                width: 100%;
                border-radius: 12px;
            }
        }
    }
}