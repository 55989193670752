$TitleBg: #343a6a;
$side-bar-bg: #3d447a;

$extra-small-font: 10px;
$small-font: 12px;
$normal-font: 14px;
$big-font :16px;
$bigger-font:18px;
$extra-bigger-font:22px;
$head-title-font: 28px;

//grid-box-size
$grid-box-width: 90px;
$grid-box-height: 126px;
