.clickable {
    cursor: pointer;
}

.score-chart-bar {
    padding: 5px;
    margin-right: 7px;
    margin-left: 7px;
    display: flex;
    flex-direction: column;
    align-items: center;

    .label-score-chart-bar {
        display: flex;
        align-items: center;
        outline: solid 4px #FFFFFF;
        justify-content: center;
        text-decoration: underline;
        border-radius: 9px;
        padding-left: 8px;
        padding-right: 8px;
        color: white;
        box-shadow: #00000029 0 3px 6px;
        height: 40px;
        width: 45px;
        font-size: 1em;
        font-weight: bold;
    }

    .background-score-chart-bar {
        opacity: 0.5;
        display: flex;
        justify-content: center;
        position: relative;
        width: 32px;
        margin-top: -5px;
    }

    .didnotplayed-score-chart-bar {
        text-transform: uppercase;
        font-size: 11px;
        letter-spacing: 0.88px;
        writing-mode: vertical-lr;
        transform: rotate(180deg);
        padding-top: 15px; /* top/bottom inverted due to rotate */
        background: linear-gradient(0deg, #f7f8ff 0%, #f7f8ff 100%);
        height: 200px;
        width: 25px;
        display: flex;
        align-items: center;

        span {
            color: #15182b;
            opacity: 0.28;
        }
    }

    .value {
        width: 50px;
        text-align: center;
    }

    .date {
        font-size: 12px;
        margin-bottom: 20px;
        margin-top: 20px;
    }
}