@import "style/colors.scss";
@import "style/font.scss";

.wallet-action-container {
    margin-top: 90px;
    color: $tertiary-grey;
    padding: 0px 16px;
    overflow-x: hidden;
    min-height: calc(100% - 90px);
    position: relative;
    padding-bottom: 75px;
    margin-bottom: 50px;

    .wallet-action-title {
        @include plus-jakarta-bold-font;
        font-size: 24px;
        text-align: left;
        text-transform: none;
    }

    .action-value-display {
        margin-top: 31px;
        display: flex;
        width: 100%;
        background: #EBECF6;
        border-radius: 25px;
        border: 2px solid #FFFFFF80;
        padding: 13px 15px;
        justify-content: space-between;
        align-items: center;
        cursor: pointer;

        .action-value-notations {
            display: flex;
            color: #15182b80;

            svg {

                &#circle-plus-duotone {
                    margin-right: 5px;

                    path {
                        fill: $tertiary-grey;
                    }
                }

                &#wallet-icon-dark {
                    margin-right: 8px;
                }

            }
        }

        .action-value-fiat {
            @include plus-jakarta-bold-font;
            font-size: 20px;
        }
    }

    .value-input {
        @include plus-jakarta-bold-font;
        background: none;
        border: none;
        text-align: right;
        width: 150px;

        &:focus-visible {
            outline: none;
        }
    }

    .confirm-payment-type {
        margin-top: 44px;
        display: flex;
        flex-direction: column;
        gap: 8px;
    }

    .fiat-selection-tabs {
        width: 100%;
        overflow-x: auto;

        &::-webkit-scrollbar {
            width: 0;
            height: 0;
        }
    }

    .crypto-image {
        width: 100%;
        background: #FFFFFF59;
        padding: 18px 0px;
        height: 115px;

        img {
            width: 50%;
            margin-left: 50%;
            height: 100%;
            transform: translateX(-50%);
        }
    }

    .confirmation-button {
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        max-width: 350px;
        bottom: 35px;
    }

    @media screen and (min-height: 650px) {
        &.wallet-action-ios .confirmation-button {
            // bottom: 100px;
        }
    }
}