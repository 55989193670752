@import 'style/colors.scss';
@import 'style/font.scss';
@import 'style/size.scss';

.user-league {
    padding: 12px;
    color: $tertiary-grey;

    .user-league-header {
        background: linear-gradient(180deg, #F1F1FA, #F7F8FF);
        border-radius: 12px;
        padding: 30px 0;
        margin-bottom: 6px;

        .league-img {
            margin-bottom: 8px;
            display: grid;
            place-items: center;

            .league-icon {
                height: 134px;
                width: 134px;

                svg {
                    width: 100%;
                    height: 100%;
                }
            }
        }

        .league-title {
            @include fontWeightSize(bold, 18px);
            margin-bottom: 16px;

        }

        .league-token {
            @include fontWeightSize(medium, 13px);
            color: #777A90;
        }
    }

    .user-league__map-container {
        background: $medium-grey;
        border-radius: 14px;
        padding: 10px 12px;
        margin-bottom: 7px;

        .league-progress-map {
            margin: 12px 0 12px 0;

            @media(max-width: $min-small-mobile) {
                .progress-bar-line {
                    width: 12px;
                }
            }
        }

    }
}

.body-modal:has(.user-tournament_customModal) {
    background-color: $app-background;
}