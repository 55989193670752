
@import 'style/colors.scss';
@import 'style/font.scss';

$slidingPage-arrow-height:40px;
$grey-color-forgot-subtitle : #565A74;

.forgot_password {
    position: relative;
    padding: 60px 16px 60px;
    background: $app-background;
    backdrop-filter: blur(42px) opacity(0.85);

    .forgot-header{
        margin-bottom: 45px;

        .forgot-header-title{
            @include fontWeightSize(bold, 24px);
            margin: 0 0 16px;
            color: $tertiary-grey;
        }
        .forgot-header-subtitle{
            @include fontWeightSize(medium, 14px);
            color: $grey-color-forgot-subtitle;
            margin: 0;

        }
    }

    .forgot_password_form {
        .form_section{
            .form-group{
                margin-bottom: 18px;

                .your-email-field{
                    background: transparent;
                }

                input{
                    color: $tertiary-grey-fade;
                    @include fontWeightSize(medium, 16px)

                }

                input::placeholder{
                    color: $tertiary-grey-fade;
                    opacity: 1;
                }

                .form-control-error-message {
                    font-size: 12px;
                    color: $error-red;
                    margin: 2px;
                }

                p{
                    .icon{
                        margin-right: 2px;
                    }
                }
    
                .valid-message{
                    @include fontWeightSize(medium, 12px);
                    color: $success-green;
                    padding: 0 2px;
                    margin-top: 3px;
                }
            }

            .form_btn{
                .search-email{
                    @include fontWeightSize(bold, 15px);
                    border-radius: 11px;
                    padding-top: 10px;
                    padding-bottom: 10px;
                }
            }
        }
    }
}


// THEME LIGHT

.light_demo {

    .forgot_password .forgot_password_form .form_section {

        .MuiFormControl-root.MuiTextField-root {
            background: transparent !important;
        }
        
        .MuiInputBase-formControl{
            background: transparent
        }
    }
}

.sliding-page-container {
.forgot_password  {
        margin-top: $slidingPage-arrow-height + 0px;
    }
}

