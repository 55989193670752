@import "../../../../style/colors.scss";

.loader-box {
    width: 20px;
    height: 20px;
}

.primary-button {
    background: $primary-button-color;
    border: none;
    width: 100%;
    color: #fff;
    transition: 0.2s;
    border-radius: 40px;
    width: 100%;
    padding-right: 13px;
    padding-left: 13px;
    line-height: 35px;  
    transition: 0.2s all ease;
    cursor: pointer;
    &:hover {
        transition: 0.2s all ease;
        background: $primary-button-hover-color;
    }
    &:disabled{
        background-color: $primary-button-disable-color;
        cursor: not-allowed;
    }
}