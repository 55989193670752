$title-modal: 23px;
$title-weight-modal: 500;
$subtilte-modal: 16px;
$subtitle-weight-modal: 500;
$tablet: 1100px;
$desktop-min: 1200px;
$low-desktop: 1600px;
$high-desktop: 2000px;
$default-radius: 16px;
$default-padding: 20px;
$default-padding-mobile: 15px;
$top-bar-height: 55px;
$bottom-bar: 66px;
$top-bar: 60px;
$top-vip-card-height: 105px;
$top-vip-card-icon: 56px;
$main-container-width: 650px;
$half-of-main-container-width: calc(#{$main-container-width} / 2);
$sidebar-container-width: 325px;

//box-radius
$small-box-radius: 12px;

//team-page
$team-page-side-padding: 15px;
$sticky-top-size: 36px;
$team-collapse-btn-size: 36px;

// Mobile
$small-mobile: 320px;
$mobile: 650px;
$height-slot-mobile: 130px;
$height-slot-tablet: 130px;
$height-slot-desktop: 130px;
$slot-icon-width-height: 70px;
$slot-icon-width-height-desktop: 70px;

//media-query-max-approach(device breakpoints)
$max-medium-mobile: 480px;
$max-small-device: 624px;
$max-medium-device: 767px;
$max-large-device: 1100px;
$max-extra-large-device: 1366px;
$max-extra-extra-large-device: 1660px;

//media-query-min-approach(device breakpoints)
$min-small-mobile: 390px;
$min-medium-mobile: 421px;
$min-large-mobile: 577px;
$min-small-device: 600px;
$min-medium-device: 768px;
$min-large-device: 992px;
$min-extra-large-device: 1200px;

