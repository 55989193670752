@import "style/size.scss";

.container-modal-tournament-confirmation {
    text-align: left;
    padding: 30px;
    border-radius: 20px;
    background: white;
    width: 100%;

    @media (min-width: $tablet) {
        width: 40%;
        margin: auto;
    }

    .container-top-on-the-left-img {
        width: 100%;
        background: linear-gradient(180deg, #53d08196 0%, #53d08108 100%);
        border-radius: 12px;
        text-align: center;
    }

    h3 {
        font-weight: 700;
        font-size: 22px;
        margin-bottom: 15px;
        margin-top: 15px;
    }

    .first-sentence {
        font-weight: 700;
        color: #15182bb0;
    }

    .second-sentence {
        color: #15182bb0;
        font-weight: 500;
        margin-bottom: 20px;
    }

    .container-notice {
        padding: 15px;
        padding-bottom: 5px;
        background: #EBEDFA;
        border-radius: 12px;
        margin-bottom: 20px;

        .notice-sentence {
            color: #15182bb0;

            span {
                font-weight: 600;
                color: #15182b;
            }
        }
    }

    .primary-button.tournament-confirmation-modal-on-boarding-btn {
        border-radius: 12px;
    }

    @media (max-width: $mobile) {
        padding: 20px;
    }
}