@import 'style/size.scss';

.euro-2024-win-rules {
    margin-top: 25px;

    .win-title {
        font-size: 13px;
    }

    p {
        font-size: 12px;
    }

    .grid {
        margin-top: 15px;
        display: grid;
        grid-template-columns: 1fr 1fr;
    }
}