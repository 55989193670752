@import "style/colors.scss";
@import "style/font.scss";

.link-button {
    color: $primary-button-color;
    border: none;
    padding: 0px;
    @include fontWeightSize(bold, 14px);
    text-decoration: underline;
    line-height: 1;
    background: transparent;
    cursor: pointer;
}