@import 'style/colors.scss';
@import 'style/font.scss';
@import 'style/size.scss';

.loader-box {
    width: 20px;
    height: 20px;
}

.solid-secondary-button{
    @include fontWeightSize(bold, 15px);
    background: #6677F51A;
    color: $primary-button-color;
    border-radius: 40px;
    width: 100%;
    padding-right: 13px;
    padding-left: 13px;
    line-height: 35px;  
    transition: 0.2s all ease;
    border-color: transparent;
    cursor: pointer;

    &:hover {
        transition: 0.2s all ease;
        color: #6678ff8c;
    }
    &:disabled{
        color: $primary-button-disable-color;
        cursor: not-allowed;
    }
}