@import 'style/colors.scss';
@import 'style/size.scss';

.container-logo-blue-trendex {
    width: 85%;
    margin-top: 10px;
    margin-bottom: 10px;
    text-align: center;
    color: $top-50-page-title-color;
    font-size: 20px;
    font-family: 'Monument Extended';
    letter-spacing: 0.16px;
    text-transform: uppercase;
    display: flex;
    justify-content: center;
    align-items: center;

    .logo-blue-trendex {
        width: 35%;

        @media (min-width: $min-medium-device) {
            width: 25%;
        }
    }

    .text {
        width: 65%;
        margin: 0;
        font-size: 20px;
        letter-spacing: 2px;
        line-height: 26px;

        @media (min-width: $min-medium-device) {
            font-size: 24px;
            width: 50%;
        }

        .blue {
            color: $primary-button-color;
        }
    }
}