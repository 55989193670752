.progress-private-sell {
  text-align: center;

  .header-private-sell {
    .title {
      font-size: 28px;
      margin-bottom: 50px;
      margin-top: 60px;
    }
    .remaining-tokens {
      font-size: 16px;
      font-weight: 500;
    }
  }
  .monitoring-container {
    width: 50%;
    margin: 50px auto auto auto;

    @media (max-width: 1100px) {
      width: 100%;
    }
  }

  .social-info {
    border: solid white 0.5px;
    width: 60%;
    margin: 50px auto auto auto;
    padding: 20px;
    box-sizing: border-box;

    @media (max-width: 1100px) {
      width: 100%;
    }
  }
}
