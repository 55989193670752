.reload-account-block {
    display: flex;
    width: 100%;
    max-width: 400px;
    margin: 0 auto 10px auto;
    padding: 6px 6px;
    align-items: center;
    justify-content: center;
    border-radius: 11px;
    background-color: rgba(83, 208, 129, 0.0941176);

    &__icon {
        width: 37px;
        height: 37px;
        display: flex;
        align-items: center;
    }

    &__content {
        margin-left: 10px;
        color: #53D081;
        font-weight: bold;
    }

}