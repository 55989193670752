@import 'style/font.scss';
@import 'style/size.scss';
@import 'style/colors.scss';

.withdraw-container {
    @include plus-jakarta-font;
    color: $tertiary-grey;
    padding: 2px 10px;
    padding-bottom: 60px;
    max-width: 650px;
    margin: 0px auto 40px;

    .title {
        text-align: left;
        font-size: 24px;
        font-weight: 600;
        margin-bottom: 20px;
    }
}

.sliding-page-container {
    .withdraw-container {
        margin-top: 85px;
    }
}