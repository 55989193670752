@import "style/colors.scss";
@import "style/size.scss";
@import "style/font.scss";
@import "../../../assets/scss/components/mixins";
@import "../../../assets/scss/components/variable";

#mainbody:has(.asset-profile-slider) {
    overflow-y: hidden;
}

.asset-profile-slider {
    @include plus-jakarta-font;
    position: fixed;
    overflow: auto;
    background-color: $app-background;
    height: 100%;
    width: 100vw;
    z-index: 15;
    left: 0;
    right: 0;
    top: 0;

    .dropdown-button {
        width: 38px;
        height: 38px;
        display: none;
        position: absolute;
        left: 10px;
        top: 10px;
        color: $tertiary-grey;
        @media (max-width: $max-large-device){
            top: 11px;
            left: 14px;
        }

        svg {
            font-size: 35px;
        }
    }

    .asset-profil-page {
        width: 100%;
        height: 100%;

        @media (min-width: $min-extra-large-device) {
            @include scrollbar;
            padding-left: 37px;
            padding-right: 37px;
            padding-bottom: 120px;
        }
    }

    @media (min-width: $min-extra-large-device) {
        width: 650px;
        left: 325px;

    }

    @media (min-width: 1300px) {
        left: 6px;
        width: 640px;
        margin: 0 auto;

    }
}

.asset-profile-slider+.main-app {

    #scroll-to-top,
    .bottom-tab-bar {
        z-index: 10;
    }
}

.slide-enter {
    top: 100%;
}

.slide-enter-active {
    top: 0;
    .dropdown-button{
        display: block;
    }

    &+.main-app{ .top-bar-nav-button {
        opacity: 0;
    }
        .active-top-bar{
        background: none;
    }}
}

.slide-exit {
    top: 0;

    .slide-down {
        transform: translate(-50%, 300%);
        transition: transform $asset-view-slide-down-duration;

        @media (max-width: $max-large-device) {
            transform: translate(-50%, 300%);
        }
    }
}

.slide-exit-active {
    top: 100%;
}

.slide-enter-active {
    transition: top $asset-view-slide-up-duration;
}

.slide-exit-active {
    transition: top $asset-view-slide-down-duration;
}

.slide-enter-done {
    top: 0
}