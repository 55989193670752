@import 'style/colors.scss';

.input-tokens-caution {
    &.input-tokens-error {
        color: $error-red;
        text-align: right;

        svg g {
            fill: $error-red;
        }
    }
}