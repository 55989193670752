.overview-user-profil-page {
    .content {
        .crud-user-edit {
            margin-top: 70px;

            .identity-section {
                height: auto;
                width: 100%;
                color: #333333;
                text-align: center;
                margin-bottom: 50px;

                .identity {
                    display: block;

                    .identity-image {
                        width: 1000px;
                        height: 1000px;
                        margin: auto;
                        margin: 10px;
                        background-size: contain;
                        background-repeat: no-repeat;
                        background-position: center;
                    }
                }
            }

        }
    }
}