    @import "style/size.scss";

    .christmas-bonus {
        background: #E7F2ED;
        padding: 8px 10px 8px 10px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 5px;
        font-size: 14px;
        border-radius: 8px;
        flex-basis: 90%;

        @media (max-width: $mobile) {
            font-size: 12px;
        }

        @media (max-width: 400px) {
            font-size: 11px;
        }

        .sentence-christmas-bonus {
            color: #15182B80;
            margin-left: 3px;
            margin-right: 3px;

            @media (max-width: 400px) {
                font-size: 10px;
            }

            .title-christmas-bonus {
                color: #82C67D;
                font-weight: 600;

                @media (max-width: 400px) {
                    font-size: 11px;
                }
            }

            .gift-christmas-bonus {
                font-weight: 900;
                text-decoration: underline;
            }
        }
    }