@import "style/colors.scss";

.payment-checkbox-container {
    position: relative;
    height: 23px;
    width: 23px;
    border-radius: 100%;
    border: 2px solid #15182B59;

    &.checked {
        border-color: #8187D0CC;
    }

    .fill-checked {
        position: absolute;
        height: 15px;
        width: 15px;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        background: $tertiary-violet;
        border-radius: 100%;
    }
}