.asset-avatar-container {
    position: relative;

    .asset-bg-img {
        position: absolute;
        height: 100%;
        width: 100%;
        top: 0;
        left: 0;
    }

    .asset-avatar-img {
        position: absolute;
        object-fit: cover;
        height: 100%;
        width: 100%;
        top: 0;
        left: 0;
    }
}
