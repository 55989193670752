@import 'style/size.scss';

#scroll-to-top {
    display: none;
}

a.whatsapp-button {
    position: fixed;
    bottom: 45px;
    right: 20px;
    z-index: 99999;
    cursor: pointer;

    &.crisp-button {
        bottom: 115px;
        right: 20px;

        @media(max-width: $max-medium-device) {
            bottom: 175px;
            right: 1px;
        }
    }

    @media(max-width: $max-medium-device) {
        bottom: 115px;
        right: 1px;
    }
}