@import "style/colors.scss";
@import "style/font.scss";
@import 'style/size.scss';

.score-chart {
    margin-top: 20px;
    
    .uppercase {
        text-transform: uppercase;
    }

    h2 {
        color: #838592;
        font-size: 15px;

        @media (max-width: $mobile) {
            font-size: 11px;
        }
    }

    .score-chart-container {
        display: flex;
        flex-direction: row-reverse;
        align-items: flex-end;
        background: linear-gradient(180deg, #F7F8FF 10%, #FFFFFF 100%);
        box-shadow: #15182B0D 0px 5px 6px;
        border-radius: 14px;
        height: 300px;
        overflow: auto;
        justify-content: space-between;

        &::-webkit-scrollbar {
            display: none;
        }

        -ms-overflow-style: none;
        scrollbar-width: none;
    }

    .chart-header-container {
        margin-bottom: 10px;
    }
}