@import '../../../style/colors.scss';
@import '../../../style/size.scss';

.trendex-checkout-form {
    // background: $card-background;
    // padding: $default-padding;
    background: #EBECF6CC;


    .green-button-gradient {
        font-size: 14px;
    }

    .amount-tokens {
        text-align: center;

        .credit-container {
            display: flex;
            align-items: center;
            justify-content: center;
            margin-bottom: 10px;
            margin-top: 23px;

            .buy-credits-input {
                width: 33%;
                border-radius: 40px;
                border: 1px solid #d4d9fb4a;
                text-align: center;
                padding: 5px;
                font-size: 17px;
                margin: 6px;

                &:focus {
                    outline: none !important;
                }

                &::-webkit-outer-spin-button,
                &::-webkit-inner-spin-button {
                    -webkit-appearance: none;
                    margin: 0;
                }

                @media (max-width: $mobile) {
                    width: 50%;
                }
            }

            .currency-logo {
                font-size: 20px;
            }
        }
    }

    .container-divider {
        padding: $default-padding;
    }

    .payment-information {
        font-size: 13px;
        color: #96969e;
        font-style: italic;
    }

    #payment-form {
        padding: 0;
        background-color: #EBECF6CC;

        .payment-form-information {

            label {
                color: #868895;
                margin-bottom: 0;
            }

            .hidden {
                display: none !important;
            }

            .email-element {
                display: flex;
                flex-direction: column;
                margin-bottom: 20px;

                input {
                    height: 40px;
                    padding: 0 10px;
                    border: 1px #d3d4e0 solid;
                    border-radius: 5px;
                    background-color: #f3f5fb;
                }

                .invalid-email {
                    margin-left: 5px;
                }
            }

            .stripe-element {
                display: flex;
                flex-direction: column;

                .stripe-card-number {

                    .custom-card-number-element {
                        display: grid;
                        align-items: center;
                        height: 40px;
                        padding: 0 10px;
                        margin-bottom: 20px;
                        border: 1px #d3d4e0 solid;
                        border-radius: 5px;
                        background-color: #f3f5fb;

                        .CardNumberField-input-wrapper {
                            left: 0;
                        }

                        .CardBrandIcon-container {
                            right: 0;
                        }
                    }
                }

                .stripe-expiry-date-cvc-number {
                    display: flex;
                    justify-content: space-between;

                    .stripe-expiry-date,
                    .stripe-cvc-number {
                        width: 45%;

                        .StripeElement {
                            display: grid;
                            align-items: center;
                            height: 40px;
                            padding: 0 10px;
                            border: 1px #d3d4e0 solid;
                            border-radius: 5px;
                            background-color: #f3f5fb;
                        }
                    }
                }
            }
        }
    }

    .general-terms {
        .terms-content {
            align-items: center;
        }

        .cgu-label {
            white-space: nowrap;
            margin: 0;
        }
    }
}