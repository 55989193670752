@import "style/font.scss";

.sign-up {
    color: black;

    .hidden {
        display: none;
    }
    
    .apple-button {
        @include fontWeightSize(bold, 15px);
        border-radius: 11px;
        background: #15182B;
        flex-basis: 40%;
        display: flex;
        align-items: center;
        justify-content: center;
        color: white;
        height: 50px;
        column-gap: 10px;
        padding: 5px 10px 5px 10px;
        z-index: 10;
        border: 1.5px solid #15182B;

        img {
            width: 20px;
            height: 24px;
        }

        @media (max-height: 715px) {
            height: auto;
        }
    }

    .google-button {
        @include fontWeightSize(bold, 15px);
        border-radius: 11px;
        background: white;
        flex-basis: 40%;
        display: flex;
        height: 50px;
        align-items: center;
        justify-content: center;
        color: #15182B;
        column-gap: 10px;
        padding: 5px 10px 5px 10px;
        z-index: 10;
        border: 1.5px solid #15182B;

        img {
            width: 24px;
        }

        @media (max-height: 715px) {
            height: auto;
        }
    }

    .facebook-button {
        @include fontWeightSize(bold, 15px);
        border-radius: 11px;
        background: #3C66C4;
        display: flex;
        height: 50px;
        align-items: center;
        justify-content: center;
        color: white;
        column-gap: 10px;
        padding: 5px 7px 5px 7px;
        z-index: 10;
        border: 1.5px solid #3C66C4;

        img {
            width: 24px;
        }

        @media (max-height: 715px) {
            height: auto;
        }
    }

    .container-social-buttons {
        display: inline-flex;
        align-items: center;
        justify-content: space-between;
        grid-column-gap: 90px;
        grid-column-gap: 90px;
        column-gap: 25px;
        flex-wrap: nowrap;
        width: 100%;
    }

    .container-social-button {
        margin-bottom: 4%;
        text-align: center;
        width: 30%;
    }

    .container-underline {
        width: 40%;
        margin: 4% auto 4% auto;
    }

    .b-testing-text-signup {
        font-size: 36px;
        text-transform: uppercase;
    }

    .container-no-discord {
        border-bottom: solid #fff4f4 0.5px;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 7%;
    }

    .title-b-test-signup {
        text-transform: uppercase;
        margin-bottom: 10%;
        font-size: 27px;
    }

    .want-contact {
        width: 90%;
        margin-right: auto;
        margin-left: auto;
        font-weight: bold;
        text-transform: uppercase;
        border-color: #dfac49;
        line-height: 34px;
        text-decoration: none;
        border-radius: 6px;
        font-size: 14px;

        &:hover {
            background-color: #fff;
            color: #dfac49;
            font-weight: bold;
        }
    }

    .content-sign-up {
        position: relative;
        @media (max-width: 320px) {
            margin-top: 45px;
        }

        .logo {
            margin: auto;
            width: 13em;

            img {
                width: 100%;
            }
        }

        .loginsocial_buttons{
            p{
                margin-bottom: 7px;
            }
        }
    }
}