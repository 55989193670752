@import "style/size.scss";

.container-description-fun-cards-asset-profil-page {
    padding: 20px;
    background-color: white;
    border-radius: 20px;
    margin: 30px 0px 20px 0px;

    .logo-fun-cards {
        width: 40%;

        @media (max-width: $mobile) {
            width: 60%;
        }
    }

    .title-description-fun-cards {
        margin-top: 15px;
        font-weight: 800;
        font-size: 16px;

        .fun-cards-gold-text {
            color: #F7C566;
        }
    }

    .description-fun-cards {
        color: #727481;
    }

    .second-description-fun-cards {
        color: #727481;
    }

    .bold-description {
        font-weight: 600;
    }
}