@import "../../../../style/colors.scss";

.grey-button {
  width: 100%;
  text-transform: uppercase;
  transition: 0.2s;
  border-radius: 40px;
  width: 100%;
  padding-right: 13px;
  padding-left: 13px;
  line-height: 35px;
  cursor: pointer;
  background: transparent;
  color: #7b7b7b;
  border: 1px solid#7b7b7b;
  font-weight: normal;
}