@import "style/font.scss";
@import "style/colors.scss";

.pending-card {
    background: $pending-bgColor;
    position: relative;
    display: flex;
    flex-direction: column;
    border-radius: 12px;

    .pending-card__date {
        position: absolute;
        right: 12px;
        top: 8px;
        font-size: 13px;
        color: $pending-secondary-color;

        span {
            padding-right: 10px;
        }
    }

    .pending-card__body {
        display: flex;
        padding: 25px 19px 10px;

        .pending_card__avatar {
            height: 44px;
            width: 44px;
            position: relative;

            img {
                height: 100%;
                width: 100%;
                border-radius: 8px;
            }

            .avatar-icon {
                position: absolute;
                top: -8px;
                left: -10px;

                svg {
                    height: 20px;
                    width: 22px;
                }

                img {
                    &.clock-gif {
                        border-radius: 50%;
                        background: $waiting-clock-bgColor;
                        width: 30px;
                        height: 30px;
                    }
                }
            }
        }

        .pending-card__detail {
            display: flex;
            flex-direction: column;
            flex: 1;
            padding-left: 8px;

            .detail-topic {
                color: $pending-topic-color;
                display: flex;
                align-items: center;
                @include fontWeightSize(medium, 13px);

                svg {
                    margin-right: 5px;

                    path#Tracé_3210 {
                        fill: currentColor;
                    }

                    path#Tracé_3211 {
                        fill: rgba($pending-topic-color, 0.4);
                    }
                }
            }

            .detail-description {
                @include fontWeightSize(bold, 16px);
                line-height: 21px;
                margin-top: 6px;
                margin-bottom: 6px;
            }

            .pending-card__summary {
                display: flex;
                justify-content: space-between;
                text-transform: uppercase;
                color: $pending-secondary-color;
                line-height: 14px;
                @include fontWeightSize(medium, 10px);
            }
        }
    }

    .pending-card__action {
        display: flex;
        justify-content: center;
        border-top: 1px solid $pending-divider-color;
        padding: 15px;
        color: $pending-cancel-color;

        .pending-card__cancel-button {
            @include fontWeightSize(medium, 13px);
            background: none;
            color: inherit;
            cursor: pointer;
            border: none;
            display: inline-flex;
            align-items: center;
            gap: 4px;

            .cancel-order-icon {
                background-color: rgba($pending-cancel-color, .4);
            }
        }
    }
}