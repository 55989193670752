@import 'style/font.scss';
@import 'style/size.scss';
@import 'style/colors.scss';

@mixin color-border-with-shadow($border-color: #6677F580) {
    border: 1.5px solid $border-color;
    box-shadow: 0px 2px 12px #6677F533;
}

.withdraw-form-container {
    @include plus-jakarta-font;
    color: $tertiary-grey;
    padding: 2px 10px;
    text-align: center;

    .field-container {
        margin-top: 5px;
        margin-bottom: 5px;

        .input-field-box {
            display: flex;
            align-items: center;
            justify-content: space-between;
            background-color: $whitesh-grey;
            border-radius: 25px;
            border: 2px solid $fade-white;
            padding: 8px 20px;
            text-transform: uppercase;

            .left-part-box {
                color: $light-grey;
                @include fontWeightSize(medium, 12px);
                margin-right: 1px;
                white-space: nowrap;

                .icon-container {
                    display: flex;
                    align-items: center;

                    svg {
                        path {
                            fill: $tertiary-grey;
                        }
                    }

                    svg:first-child {
                        width: 14px;
                        height: 14px;
                        margin-right: 2px;
                    }

                    svg:nth-child(2) {
                        width: 18px;
                        height: 21px;
                        margin-right: 10px;
                    }
                }
            }

            .right-part-box {
                display: flex;
                align-items: center;
                justify-content: flex-end;
            }

            .react-tel-input .flag-dropdown {
                border: none
            }
        }

        &.withdraw-field {
            .input-field-box {
                padding: 5px 20px;

                .amount-field {
                    font-size: 20px;
                    font-weight: 700;
                    background: rgba(255, 255, 255, 0);
                }
            }
        }

        .phone-input {
            padding: 5px 20px;
            margin: auto;

            .flag-dropdown {
                height: 100%;
                border: 0;
                border-right: solid 1px #393f74;
            }

            .flag-dropdown:hover,
            .selected-flag:hover,
            .selected-flag,
            .open,
            .react-tel-input .flag-dropdown.open .selected-flag {
                background-color: $whitesh-grey;
            }
        }

        .react-tel-input {
            input {
                text-align: left;
                font-weight: normal;
                font-size: 16px;
                width: 100%;
                background-color: $whitesh-grey;
                border: none;
                border-color: rgba(99, 66, 255, 0.5);

                &.invalid-number {
                    background-color: $whitesh-grey;
                    border-color: indianred;
                }
            }


            .flag-dropdown {
                background-color: $whitesh-grey;
                border-color: rgba(99, 66, 255, 0.5);
            }

            .country-list {
                background-color: $whitesh-grey;
            }
        }



    }

    .warning {
        color: $medium-red;
        font-weight: bold;
        margin-bottom: 5px;
    }

    input {
        outline: none;
        background-color: transparent;
        @include fontWeightSize(bold, 16px);
        border: none;
        text-align: right;
        width: 100%;
    }

    input[type=number]::-webkit-inner-spin-button,
    input[type=number]::-webkit-outer-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    .display-message {
        @include fontWeightSize(medium, 12px);
        text-align: left;
        margin-left: 20px;

        &.correct {
            color: $medium-green;
        }

        &.incorrect {
            color: $medium-red;
        }
    }

    .info {
        display: flex;
        flex-direction: column;
        padding: 0px 15px;

        .available-amount-info {
            color: $light-grey;
            font-size: 12px;
            font-weight: 400;
        }
    }

    .radio-group {
        label.MuiFormControlLabel-root {
            margin: 0px;
        }
    }

    .button-submit {
        position: fixed;
        bottom: 40px;
        transform: translate(-50%, 0%);
        left: 50%;
        width: 90%;
        display: flex;
        flex-direction: column;    
        gap: 10px;

        @media (min-width : $min-small-device) {
            width: 50%;
        }

        @media (min-width : $min-extra-large-device) {
            width: 25%;
        }

        .primary-button {
            font-size: 15px;
            font-weight: 800;
            padding: 5px 10px;
        }
    }

    .input-field {
        display: flex;
        flex-direction: row-reverse;
        align-items: center;
        background: rgba(255, 255, 255, 0);
    }

    .form-control-error-message {
        color: indianred;
        width: 100%;
        margin: 0;
    }

    .country-list {
        display: flex;
        flex-direction: column;
        text-align: left;
    }

}

.withdraw-onboarding-confirm_customModal strong {
    white-space: pre-wrap;
}