@import 'style/size.scss';
@import "style/colors.scss";
@import "style/font.scss";

.euro-countdown-component {
    margin: auto;

    padding: 25px;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: white;
    font-variant: tabular-nums;
    box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.25);
    background: url("/img/bg-image-countdown-euro.webp");
    background-position: center 85%;
    background-size: cover;

    .title-euro-countdown-component {
        font-family: 'Monument Extended regular', sans-serif;
        text-transform: uppercase;
        font-size: 20px;
    }

    .euro-countdown-wrapper {
        flex-direction: row;

        .title {
            text-align: center;
            font-family: 'Plus Jakarta Sans', sans-serif;
            text-transform: uppercase;
            font-weight: bold;
            font-size: 13px;
        }

        .countdown-widget {
            text-align: center;
        }

        .countdown-widget .content {
            display: flex;

            & .value span {
                padding: 5px;
                margin: 0px 2px;
                flex-direction: row;
                border-radius: 6px;
                border: solid 0.5px rgba(255, 255, 255, 0.2);
                background-color: #0117B6;
                box-shadow: 0px 5px 15px rgba(118, 8, 39, 0.25);
                font-size: 25px;
                font-weight: bold;
            }

            & .separator {
                font-family: 'Plus Jakarta Sans', sans-serif;
                font-weight: 1000;
                text-transform: uppercase;
                margin: 0px 5px;
                text-align: center;
                font-size: 18px;
            }

            & .text {
                font-family: 'Plus Jakarta Sans', sans-serif;
                font-weight: bold;
                text-transform: uppercase;
                margin: 10px 5px;
                font-size: 9px;
                text-align: center;
                font-size: 12px;
            }
        }
    }
}