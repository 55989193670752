@import 'style/colors.scss';
@import 'style/font.scss';

.user-profile-container {
    padding-bottom: 7.5em;

    .user-profile-top-section {
        margin-bottom: 20px;
        color: $tertiary-grey;
        display: flex;
        align-items: center;

        .profil_section {
            .profile_img {
                &::before {
                    background-image: none
                }

                img {
                    border: 2px solid #15182B33;
                    box-shadow: 0px 3px 20px #15182B40;
                    height: 80px;
                    width: 80px;
                }

                span {
                    position: absolute;
                    right: 5px;
                    bottom: 5px;
                    width: 45px;
                    height: 45px;
                    border-radius: 50%;
                    text-align: center;
                    align-items: center;
                    display: flex;
                    justify-content: center;
                    font-size: 14px;
                }

                .level {
                    background-image: $gradient2;
                    border: 5px solid $darktheme;
                }

                .league-icon {
                    width: 28px;
                    right: -5px;
                    bottom: 2px;
                    height: 28px;
                    border: none;
                    background-image: linear-gradient(90deg, #fffeff 0%, #ffffff 100%);

                    @media (min-width:480px) {
                        right: 15px;
                        bottom: 15px;
                    }
                }
            }
        }

        .user-profile-details {
            margin-left: 20px;

            .profile-name {
                margin: 0px;
                font-size: 24px;
                font-weight: bold;
                word-break: break-word;
            }

            .edit-myProfile {
                color: $tertiary-violet;
                font-size: 14px;
                font-weight: bold;
            }
        }
    }

    .user-profile-verification {
        font-weight: 500;
        margin: 28px 0px;
        border-radius: 14px;
        padding: 18px 28px 0px 24px;
        background-color: $white-list-bgColor;


        .message {
            display: block;
            font-size: 14px;
            padding-bottom: 20px;
            color: $coral-black;
        }

        .step {
            font-size: 13px;
            font-weight: bold;
            padding-bottom: 20px;
            color: $tertiary-violet;

            .content {
                cursor: pointer;
                padding-left: 0px;
                align-items: center;
                display: inline-flex;
                text-decoration: underline;
            }

            span {
                padding-left: 10px;
            }

            .disabled {
                opacity: 0.4;
                pointer-events: none;
                text-decoration: none;
            }
        }


    }

    .user-league {
        margin: 28px 0px;
        border-radius: 14px;
        padding: 13px 16px 24px 16px;
        background-color: $medium-grey;

        .title {
            font-size: 13px;
            text-align: center;
            color: $coral-black;
            margin-bottom: 18px;
        }

        .progress-map {
            .league-icon-size {
                width: 25px;
                height: 27px;
            }
        }

    }

    .achievements-section {
        .achievement-topic {
            margin-bottom: 16px;
            font-size: 20px;
            font-weight: bold;
        }

        .loading-skeleton {
            margin-top: -40px;
        }

        .achievement-card {
            .bottom-component {
                button {
                    width: 70%;
                }

                .achievement-completed {
                    color: $medium-green;
                    font-size: 8px;
                    font-weight: bold;
                    margin-bottom: 10px;

                    .icon {
                        margin-right: 4px;
                    }
                }

                .green-button {
                    background: $medium-green;
                    text-transform: capitalize;
                }

                .grey-button {
                    font-weight: bold;
                    background-color: #6677F51A;
                    color: $tertiary-violet;
                    text-transform: none;
                    border: none;
                    border-radius: 25px;
                }
            }
        }
    }

    .invite-banner-section {
        border-radius: 18px;
        background: linear-gradient(293deg, #DBE0FF, #EEF1FF);
        box-shadow: 0px 3px 20px #15182B1A;
        padding: 25px 20px;

        .banner-title-img-container {
            margin-bottom: 24px;

            .banner-img {
                width: 100%;
                border-radius: 16px;
            }
        }

        .invite-button {
            width: 40%;
            margin: 0 auto;

            .primary-button {
                @include fontWeightSize(extra-bold)
            }
        }
    }

    .rectangle-cards-section {
        margin-top: 20px;

        .rectangle-card {
            color: $tertiary-grey;
            @include fontWeightSize(medium, 15px);
            display: flex;
            margin-bottom: 10px;
            border-radius: 9px;
            background: $white;
            padding: 12px;

            &.logout-btn {
                margin-top: 10px;
            }

            .icon {
                margin-right: 10px;

                svg {
                    height: 20px;
                    width: 22px;
                }
            }
        }
    }
}