@import 'style/colors.scss';
@import 'style/font.scss';

.asset-reward-debt-info {
    @include plus-jakarta-medium-font;
    background-color: white;
    color: $steel-grey;
    padding: 15px 16px;
    border-radius: 12px;
    font-size: 13px;

    span {
        @include fontWeightSize('bold', 13px);
        color: $fade-violet;
    }

    .info-header {
        margin: 0px;
        font-size: 13px;
    }

    .amount-detail {
        margin: 16px 0px 12px 0px;
        font-size: 13px;

        svg {
            margin-right: 5px;
            width: 16px;
            height: 15px;

            path {
                fill: $fade-violet;
            }

        }

        :last-child {
            margin: 0px;
        }
    }
}