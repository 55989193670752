@import 'style/colors.scss';
@import 'style/font.scss';
@import 'style/size.scss';

.asset-evolutions {
    margin: 30px 0px;

    .MuiDivider-root {
        margin: 24px 0px;
    }

    .asset-trend-score {
        display: flex;
        flex-direction: column;
        gap: 20px;

        .score-header {
            display: flex;
            flex-direction: column;
            gap: 16px;

            .title {
                display: flex;
                align-items: center;

                line-height: 21px;
                font-size: 16px;
                @include plus-jakarta-bold-font;
            }

            .content {
                padding: 18px;
                border-radius: 16px;

                .score {
                    @include plus-jakarta-bold-font;
                    font-size: 40px;
                }

                .status {
                    margin-bottom: 10px;

                    .indicator {
                        display: inline-block;
                        width: 10px;
                        height: 10px;
                        border-radius: 50%;
                        background-color: black;
                        margin-right: 6px;
                    }

                    .text {
                        font-size: 12px;
                        line-height: 15px;
                    }
                }

                .description {
                    color: $steel-grey;
                }
            }

            .graph-title {
                font-size: 12px;
                color: $steel-grey;
            }
        }

        .mixed-chart-score {
            width: 100%;

            .apexcharts-canvas {
                border-radius: 14px;
                background: linear-gradient(180deg, #F7F8FF 0%, #FFFFFF 100%);
                box-shadow: 0 5px 6px 0 #15182B0D;
            }
        }
    }

}