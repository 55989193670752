@import 'style/colors.scss';

.rotate-circular-btn{
    width: inherit;
    height: inherit;
    display: flex;
    align-items: center;
    border: 2px solid #FFFFFF59;
    border-radius: 50%;
    background-color: $primary-button-color;
    transform: rotate(0deg);
    transition: transform 0.2s ease-in-out;

    &.rotate{
        transform: rotate(180deg);
    }

    svg{
        width: 100%;
        height:100%;
    }
}