@import '../../../assets/scss/components/mixins';
@import 'style/colors.scss';

.top-filter {
    @include scrollbar;
    overflow: auto;

    &::-webkit-scrollbar {
        height: 4px;
        background-color: #d4d9fb00;
        scrollbar-width: thin;
    }

    &:hover {
        cursor: grabbing;
    }

    .asset-tab {
        min-width: fit-content;
        color: #15182B;
        opacity: .5;
        padding: 5px 20px;
        border-radius: 20px;
        min-height: 60px;
        transition: 0.5s;
        font-weight: 600;
    }

    .asset-tab-selected {
        color: $violet;
        opacity: 1;
        font-weight: 600;

        svg {
            fill: $violet;
        }
    }
}