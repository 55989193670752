@import "style/colors.scss";

.sliding-page-container {
    height: 100vh;
    width: 100%;
    background: $app-background ;
    position: absolute;
    top: 0;
    left: 0;
    overflow-y: auto;
    z-index: 20;

    .arrow-back {
        color: black;
        position: absolute;
        top: 10px;
        left: 10px;
        cursor: pointer;
        z-index: 1;

        svg {
            height: 48px;
            width: 36px;
        }
    }
}

.disable-scroll{
    position: relative;
    .main_section{
        height:100vh;
        overflow: hidden;
    }
}