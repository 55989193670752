@import "style/colors.scss";
@import "style/size.scss";

.video-modal {
    width: 600px;
    height: 340px;
    overflow: hidden;

    @media (max-width: $mobile) {
        width: 100%;
        height: 185px;
    }

    .video-container {
        height: 100%;
        width: 100%;

        .banner-slide {
            position: relative;
            width: 100%;
            height: 100%;

            .overlay-banner {
                color: $white;
                background-size: cover;
                background-position: top center;
                width: 100%;
                height: 100%;
                position: absolute;
                z-index: 1;
                left: 0;
                transition: opacity 0.3s ease-in-out;
                background-position-y: center;
            }

            .youtube-video {
                display: none;
            }
        }

        .video-visible {
            .overlay-banner {
                opacity: 0;
                pointer-events: none;
            }

            .youtube-video {
                height: 100%;
                display: block;
            }
        }
    }
}