
// Footer Section Start
.footer_section{
    display: none;
    @media(min-width:$min-extra-large-device){
        display: block;
    }
    position: relative;
    padding: 30px 0 0 0;
    .footer_nav{
        display: flex;
        width: 100%;
        align-items: center;
        justify-content: space-between;
        margin: 0 0 0 0;
        padding: 0 0px 30px 0;
        border-bottom: 1px solid #3a3982;
        ul{
            li{
                .f_links{
                    text-decoration: none;
                    text-transform: uppercase;
                    font-size: 14px;
                    font-weight: 400;
                       
                    margin: 0 60px 0px 0;
                    &:hover{
                        color: $themelight;
                    }
                }
                &:last-child{
                    .f_links{
                        margin: 0 0px 0px 0;
                    }   
                }
            }
        }
        .android_app{
            .app_links{
                margin: 0 20px 0 0;
                &:last-child{
                    margin: 0;
                }
                img{
                    opacity: 0.9;
                    transition: 0.2s all ease;
                }
                &:hover{
                    img{
                        opacity: 1;
                        transition: 0.2s all ease;
                    }   
                }
                .img-fluid{
                    @media(max-width: 768px){
                        margin-top: 10px;
                    }
                }
            }
        }
    }
}
// Footer Section End


// Copyright Section Start
.copyrights{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 25px 0;
    padding-bottom: 15px;
    @media(max-width: 600px){
        display: block;
        text-align: center;
    }
    h4{
        font-size: 14px;
           
        margin: 0 0 0 0;
        @media(max-width: 600px){
            margin-top: 5px;
        }
    }
}
.copyrights_social{
    display: flex;
    align-items: center;
    h4{
        margin: 0 10px 0 0;
    }
}
// Copyright Section End
