@import 'style/_variable.scss';
@import 'style/colors.scss';
@import 'style/size.scss';
@import 'style/font.scss';

$transition-time-to-rotate: 300ms;

.welcome {
    @media (min-width: $max-medium-mobile) and (max-width: $min-extra-large-device) {
        width: 50%;
        margin: auto;
    }

    .title-container {
        color: $white;

        p {
            @include fontWeightSize(bold, 16px);
            margin: 0 0 31px;
        }

        .referral-name {
            @include plus-jakarta-bold-font;
        }
    }

    .assets-container {
        display: flex;
        justify-content: center;
        margin-bottom: 23px;

        .asset-grid-wrapper {
            display: grid;
            grid-template-columns: repeat(4, 1fr);
            grid-gap: 6px;


            /* flip card with click */
            .flipping-card {
                position: relative;

                .card-front,
                .card-back {
                    width: $grid-box-width;
                    height: $grid-box-height;
                    border-radius: 1.2em;
                    backface-visibility: hidden;
                    transition: transform $transition-time-to-rotate;
                    transition-timing-function: linear;

                    @media (min-width: $min-medium-device) {
                        width: calc(1.5 * #{$grid-box-width});
                        height: calc(1.5 *  #{$grid-box-height});
                    }
                }


                .card-front {
                    transform: rotateY(0deg);

                    img{
                        width: 100%;
                        height: 100%;
                    }
                    
                    &.card-front-rotate {
                        transform: rotateY(-180deg);
                    }

                    /* hide front-card image after certain time*/
                    .hide-front-img{
                        -webkit-animation: hideAnimation $transition-time-to-rotate forwards; 
                        animation: cssAnimation $transition-time-to-rotate forwards;

                        @keyframes hideAnimation {
                            0%   {opacity: 1;}
                            99%  {opacity: 1;}
                            100% {opacity: 0;}
                        }
                    }
                }

                .card-back {
                    position: absolute;
                    top: 0;
                    background: transparent;
                    transform: rotateY(180deg);
                    
                    &.card-back-rotate {
                        transform: rotateY(0deg);
                    }
                }
            }
             /* end of flip card */

            .asset-grid-box {
                pointer-events: none;
            }
        }
    }

    /* delay the letsGo btn appearance */
    .primary-button {
        &.lets-go-btn {
            opacity: 0;
            visibility: hidden;
            transition: opacity 200ms;
            transition-timing-function: ease;
            transition-delay: 600ms;
        }

        &.show {
            opacity: 1;
            visibility: visible;
        }
    }

    &.onboarding-v2 {
        width: 100%;
            
        .asset-grid-wrapper {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            gap: 15px;

            @media (min-width: $min-large-device) {
                gap: 35px;
            }
        }
    }
}