@import 'style/colors.scss';
@import 'style/font.scss';
@import 'style/size.scss';

.trade-detail-box {
    display: flex;
    align-content: center;
    align-items: center;
    justify-content: space-between;
    padding: 12px 5px 12px 5px;
    border-radius: 16px;
    background-color: #EBECF6CC;

    &.highlight-border {
        border: 2px solid $tertiary-violet;
    }

    .trade-detail__info {
        display: flex;

        .trade-detail_img {
            border-radius: 20px;
            overflow: hidden;
            margin-right: 16px;
            width: 63px;
            height: 63px;

            div,img{
                height: 100%;
                width: 100%;
            }
        }

        .trade-detail_label {
            display: flex;
            flex-direction: column;
            justify-content: center;

            .title {
                @include fontWeightSize(bold, 16px);
            }

            .sub-text {
                color: #15182B80;
                @include fontWeightSize(medium, 10px);
            }
        }
    }

    .trade-detail-value {
        margin: auto 0px auto 8px;
        @include fontWeightSize(bold, 20px);
        text-align: right;

        @media(min-width: $min-small-mobile) {
            margin: auto 8px;
        }
    }
}