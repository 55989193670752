@import "style/colors.scss";
@import "style/size.scss";
@import "style/font.scss";

$zIndex-tournament-level-text : 1;

.tournament-progress-bar {
    border-radius: 9px;
    overflow: hidden;
    position: relative;
    color: black;
    width: 100%;
    height: 100%;
    @include fontWeightSize(bold, 13px);


    .bar__primaryColor {
        height: inherit;
        position: relative;

        .bar__overlay-filter {
            position: relative;
            width: 100%;
            height: 100%;
            background: transparent;
            z-index: $zIndex-tournament-level-text;

            .tournament-level-wrapper {
                height: 100%;
                padding-left: 6px;
                padding-right: 6px;
                display: flex;
                gap: 6px;
                align-items: center;
                justify-content: center;

                .tournament-league-icon {
                    div {
                        display: flex;
                        align-items: center;
                        height: 22px;
                        width: 22px;

                        svg {
                            width: 100%;
                            height: 100%;
                        }
                    }
                }

                .tournament-level-score {
                    color: $coral-black;

                }
            }
        }

        .bar__SecondColor-progressBar {
            height: 100%;
            position: absolute;
            top: 0;
        }
    }
}