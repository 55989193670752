.asset-information-form {

    .field-container {
        .MuiFormControl-root {
            width: 100%;
        }
    }

    .influence-container {
        background: #E5E8FE;
        margin-top: 20px;
        padding: 20px;
        border-radius: 16px;
    }

    .container {
        margin: 40px auto auto auto;

        h3 {
            color: #343a40
        }

        h5 {
            color: #343a40;
            margin-top: 20px
        }
    }

    .flex {
        display: inline-flex;
        width: 100%;
        column-gap: 35px;

        @media (max-width: 1100px) {
            flex-wrap: wrap;
        }

        .item {
            flex-basis: 100%;
            margin: 20px auto auto auto;
        }
    }

    .button-submit {
        margin-top: 50px;
    }

    .sports-container {
        width: 100%;
        padding: 20px 10px;
        margin-top: 20px;
        background: #E5E8FE;
        border-radius: 16px;
    }
}