@import 'style/size.scss';
@import 'style/colors.scss';
@import 'style/font.scss';

.benzema-club {
    margin: 25px 0px 15px;

    .button-buy-container {
        text-align: center;
        margin-bottom: 25px;
    }


    .benzema-faq-container {
        margin-bottom: 25px
    }

    .pack-button-container-asset-profil {
        margin-bottom: 25px;
    }

    .benzema-club__articles {
        .benzema-club__articles-title {
            @include fontWeightSize(bold, 20px);
            margin: 20px 0px;
            text-transform: uppercase;
            color: $club-text-greyColor;
        }

        .benzema-club__article-list {
            margin-bottom: 18px;
        }
    }

    .benzema-club__trends-detail {
        margin-bottom: 25px;
    }

    .benzema-club__footer-detail {
        color: $tertiary-grey;
        text-align: center;
        text-transform: uppercase;
        @include fontWeightSize(medium, 14px);


        .benzema-club__footer-title {
            display: flex;
            align-items: normal;
            justify-content: center;
            margin-bottom: 25px;

            img {
                width: 64px;
                height: 17px;
            }
        }

    }
}