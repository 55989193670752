@import 'style/colors.scss';
@import 'style/size.scss';
@import 'style/font.scss';

.asset-token-card{
    display: inline-block;
    position: relative;

    svg{
        height: 32px;
        width: 32px;

        path{
            fill: $white;
        }
        rect{
            fill: $white;
        }
    }

    .available-token{
        @include fontWeightSize(bold, 8px);
        position: absolute;
        color: $top-50-token-greyColor-inGrid;
        top: 43%;
        left: 43%;
        transform: translate(-50%,-50%);
    }
}