.overview-private-sell {
  margin-top: 60px;

  .stepper-launch-private-sell {
    width: 90%;
    margin: auto;

    @media (max-width: 750px) {
      width: 100%;
    }
  }
  .content {
    padding: 20px;
    box-sizing: border-box;
  }

  .content.container-start-ico {
    padding: 40px;
    text-align: center;
    background: #393f7473;
    margin-top: 15px;
    border-radius: 10px;
    .accordion-resell {
      background: transparent;
      box-shadow: none;
      padding: 5px;
      color: white;
      .sell-done-container {
        width: 100%;
        text-align: center;
        color: white;
        h2.title-start-ico {
          margin-top: 15px;
          margin-bottom: 15px;
        }
        .second-text-start-ico {
          width: 75%;
          margin-left: auto;
          margin-right: auto;
          line-height: 20px;
          font-size: 14px;
          color: #d7d8ff;
        }
        .container-form-private-sell {
          margin-top: 30px;
          width: 40%;
          margin-left: auto;
          margin-right: auto;
        }
      }
      .summary-container {
        width: 100%;
        text-align: center;
        color: white;
        .title-first-accordion {
          font-size: 28px;
          padding-left: 35px;
        }
        .summary-title {
          font-size: 28px;
          padding-left: 35px;
          font-weight: bold;
        }
      }
    }
  }
}
