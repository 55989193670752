@import 'style/size.scss';
@import 'style/colors.scss';
@import 'style/font.scss';

$login-img-height :122px;
$login-img-width :122px;

.auth-section {

    .back-chevron {
        position: absolute;
        top: 20px;
        color: $black-warm;
        z-index: 1;

        @media(min-width:$min-extra-large-device) {
            display: none;
        }
    }

    .auth_structure.is-modal {
        padding: 5px 0px 20px 0px;

        @media (max-width: $mobile) {
            padding: 20px;
        }

        .login-top-section {
            margin: 0.5% 0%;
        }
    }

    .auth_structure {
        width: 100%;
        margin-top: 20px;

        @media (max-width: $mobile) {
            padding: 0px 0px 0px 0px;
        }

        .euro-countdown-container {
            width: 85%;
            margin: 20px auto 40px auto;
        }

        .message {
            text-align: center;
            padding: 20px;
            font-weight: bold;
            color: #222222;

            @media (max-width: $mobile) {
                padding: 0px 0px 5px 0;
            }

        }

        .steps-container-fullwidth {
            position: relative;

            img {
                border-radius: 12px;
            }

            .steps-container {

                .steps-container {
                    width: 85%;
                    margin: auto;
                }
            }
        }

        .login-top-section {
            margin: 2% 0 3% 0;

            @media (max-width: $max-large-device) {
                margin-top: 60px;
            }

            .img-login {
                height: $login-img-height;
                width: $login-img-width;
                margin-bottom: 5%;

                @media(min-width:$min-medium-device) {
                    height: calc(1.5*#{$login-img-height});
                    width: calc(1.5*#{$login-img-height});
                }

                @media(min-width:$min-large-device) {
                    height: calc(2*#{$login-img-height});
                    width: calc(2*#{$login-img-height});
                }
            }

            h2 {
                @include fontWeightSize(bold, 28px);
                background: -webkit-linear-gradient(95deg, #6677F5, #363C64, $tertiary-grey);
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
            }

            p {
                @include fontWeightSize(medium, 16px);
                color: $register-page-black-txt-color;
            }

        }

        .second-container-logo-blue {
            display: flex;
            justify-content: center;
            align-items: center;
            margin-bottom: 25px;
            margin-top: 30px;
            text-wrap: balance;
        }

        .container-social-buttons {
            .sign-up {
                width: 80%;
                margin: auto;

                .content-sign-up {
                    div.loginsocial_buttons {
                        display: block;
                    }
                }
            }
        }

        .separate_or {
            margin-bottom: 5px;

            &::before {
                display: none;
            }

            span {
                color: $register-page-black-txt-color;
                @include fontWeightSize(medium, 18px);
                background: transparent;
            }
        }

        .auth-form {
            .loginregi_form {
                form {
                    div.fields-container {
                        text-align: initial;
                    }

                    .container-send-login {
                        margin: 25px auto auto auto;

                        @media (max-width: $mobile) {
                            margin: auto auto auto auto;
                        }

                        p.forgot-password {
                            a {
                                color: black;
                                font-weight: 500;
                            }
                        }
                    }
                }
            }
        }

        .no-account {
            color: $register-page-black-txt-color;
            @include fontWeightSize(medium, 16px);
            text-align: center;
            margin-top: 10px;

            .no-account-span {
                cursor: pointer;
                color: $tertiary-violet;
                font-weight: bold;
                margin-left: 2px;
            }

        }

        .width-signup-form {
            width: 85%;
            margin-left: auto;
            margin-right: auto;
            margin-top: 30px;

            .signup-step {
                &:last-child {
                    .signupstep-container {
                        .divider-container {
                            display: none;
                        }
                    }
                }
            }
        }

        .trendex-footer {
            padding: 50px 0px 30px 0px;
            text-align: center;
            width: 80%;
            margin: auto;

            .trendex-its-already {
                display: flex;
                justify-content: center;
                align-items: center;
                text-align: center;

                img {
                    @media (max-width: $mobile) {
                        height: 30px;
                    }

                    height: 45px;
                }

                span {
                    @media (max-width: $mobile) {
                        font-size: 18px;
                    }

                    font-size: 30px;
                    color: $tertiary-grey;
                }
            }

            .stat-list {
                margin-top: 30px;
                color: $tertiary-grey;
                margin-bottom: 50px;
            }

            .partners-logo {
                display: flex;
                flex-wrap: wrap;
                justify-content: center;
                align-items: center;
                gap: 40px;

                .bfm {
                    width: 80px;

                    @media (max-width: $mobile) {
                        width: 60px;
                    }
                }

                .figaro {
                    width: 250px;

                    @media (max-width: $mobile) {
                        width: 150px;
                    }
                }

                .bpi {
                    width: 120px;

                    @media (max-width: $mobile) {
                        width: 60px;
                    }
                }

                .tc {
                    width: 150px;

                    @media (max-width: $mobile) {
                        width: 90px;
                    }
                }

                .ycombinator {
                    width: 80px;

                    @media (max-width: $mobile) {
                        width: 60px;
                    }
                }
            }

            .container-message-footer {
                margin-top: 20px;

                a {
                    color: #15182BCC;
                }
            }

        }
    }
}