@import 'style/font.scss';
@import 'style/colors.scss';
@import 'style/size.scss';

.onboarding-lightwallet-button-container {
    position: fixed;
    bottom: 0;
    z-index: 100;
    height: 300px;
    width: 100%;
    background: linear-gradient(180deg, #15182B00 0%, #6677F5 100%);

    
    &::before {
        content: '';
        position: fixed;
        top: 0;
        width: 100%;
        height: 100%;
        background: #11111136;
        z-index: -1;
    }

    &.desktop {
        height: 70%;
        width: 500px;
        position: fixed;
        right: 0;
        overflow-x: hidden;
    }

    .onboarding-lightwallet-button-carousel {
        position: absolute;
        width: 100%;
        bottom: 30px;

        display: flex;
        align-items: flex-end;
        flex-shrink: 0;

        &-content {
            display: flex;
            flex-direction: column;
            margin: 12px;
            background: #7984D980;
            backdrop-filter: blur(30px);
            color: #fff;
            padding: 16px;
            border-radius: 12px;
            min-width: calc(100% - 24px);
            box-sizing: border-box;
            transition: transform 0.5s ease;

            .title {
                margin-top: 15px;
                @include plus-jakarta-bold-font;
                font-size: 18px;
            }

            .subtitle {
                margin-top: 8px;
                font-size: 14px;
            }

            &.slide-1 {
                transform: translateX(calc(-100% - 24px));

                &.active {
                    transform: translateX(0);
                }
            }

            &.slide-2 {
                &.active {
                    transform: translateX(calc(-100% - 24px));
                }

                .score {
                    padding: 12px;
                    display: flex;
                    flex-direction: column;
                    border: 2px solid #ffffffbf;
                    border-radius: 16px;
                    font-size: 14px;
                    position: relative;
                    overflow: hidden;

                    .score-title {
                        @include plus-jakarta-bold-font;
                        font-size: 18px;
                        display: flex;
                        gap: 10px;
                        margin-bottom: 10px;
                    }

                    &:first-child {
                        margin-bottom: 10px;
                    }
                }

                .icon {
                    position: absolute;
                    width: 150px;
                    height: 150px;
                    z-index: -1;
                    right: -10px;
                    fill: #ffffff33;
                }
            }

            .next-btn {
                border: none;
                border-radius: 16px;
                padding: 12px;
                color: #7984D9;
                @include plus-jakarta-bold-font;
                font-size: 18px;
                margin-top: 20px;
                cursor: pointer;
            }
        }
    }

    .onboarding-lightwallet-button-carousel-indicator {
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        bottom: 20px;
        display: flex;
        gap: 10px;
        cursor: pointer;

        span::before {
            content: '';
            display: block;
            width: 10px;
            height: 10px;
            border-radius: 50%;
            background: #AFB8F3;
            margin-top: 20px;
        }

        .active {
            &::before {
                background: #fff;
            }
        }
    }
}