@import "style/font.scss";

.timer {
    text-align: center;

    .timer-container {
        -webkit-text-fill-color: #363C64;

        .chart:not(:last-child) {
            margin-right: 7px;
        }

        .chart {
            display: inline-block;
            box-sizing: border-box;
            text-align: center;
            font-weight: bold;
            background: transparent;

            .time {
                width: 100%;
                font-family: 'Monument Extended regular', sans-serif;
                text-transform: uppercase;

                .time__number {
                    margin-right: 2px;
                    font-size: 30px;
                }

                .time__text {
                    font-size: 18px;
                }
            }
        }
    }

    .title-countdown {
        padding-bottom: 15px;

        @media(max-width : 768px) {
            font-size: 20px;
        }
    }
}