@import 'style/font.scss';
@import 'style/colors.scss';
@import 'style/size.scss';

.onboarding-lightwallet {
    height: auto;
    background: rgba(21, 24, 43, .75);
    -webkit-backdrop-filter: blur(30px);
    backdrop-filter: blur(30px);
    z-index: 99;
    top: 32px;
    border-radius: 12px 12px 0 0;

    [data-rsbs-header] {
        cursor: inherit;

        &::before {
            background-color: #ffffff40;
        }
    }

    .onboarding-lightwallet-container {
        @include plus-jakarta-font;
        @include fontWeightSize(medium);
        position: relative;
        width: 100%;
        min-height: calc(100vh - 50px);
        z-index: 999;
        border-radius: 12px 12px 9px 9px;
        padding: 0 12px;
        margin-bottom: 10px;

        .close-wallet {
            position: absolute;
            top: -8px;

            &.rotate {
                transform: rotate(0deg);
                transition: transform 0.2s ease-in;
            }

            svg {
                height: 32px;
                width: 32px
            }
        }

        .wallet-title {
            @include plus-jakarta-bold-font;
            text-align: center;
            font-size: 14px;
            border-bottom: 3px solid #5C5B6A;
            padding-bottom: 10px;
        }

        .header {
            display: flex;
            flex-direction: column;
            align-items: center;

            .subtitle {
                text-align: center;
            }
        }

        .cards-container {
            .content {
                display: flex;
                flex-direction: column;
                gap: 10px;

                .wallet-portfolio-list-item .cell-container .content-container {
                    .header-container .portfolio-item-introduction .asset-pseudo-detail {
                        flex-direction: column;
                        align-items: flex-start;
                    }

                    .portfolio-item-contents {
                        grid-column-gap: 0px;
                        column-gap: 0px;
                    }
                }
            }
        }
    }

    &.desktop {
        position: fixed;
        top: 0;
        border-radius: 0;
        padding-top: 12px;
        height: 100%;
        margin-left: auto;
        right: 0;
        width: 500px;
        padding: 20px 0px 20px 20px;

        
        .wallet-title {
            border-bottom: 0;
        }
    }

    &-bg {
        backdrop-filter: blur(10px);
        z-index: 98;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        position: fixed;
        background-color: rgba(0,0,0,.3);
    }
}