@import 'style/colors.scss';
@import 'style/font.scss';

.buy-asset-wrapper {
    .buy-asset-header {
        @include fontWeightSize(bold, 20px);
        display: flex;
        align-items: center;
        gap: 17px;

        .buy-asset-name {
            @include plus-jakarta-bold-font;
            font-size: 20px;
        }

        .price-selector-wrapper {
            margin-bottom: 10px;
        }
    }

    .price-change{
        text-align: right;
    }
}