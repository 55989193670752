.confirm-sale {
    text-align: center;

    .message {
        margin-top: 50px;
        margin-bottom: 50px;
        display: flex;
        align-items: center;
        width: 100%;
    }
}