@import 'style/colors.scss';

.general-terms {
    position: relative;
    display: flex;
    justify-content: center;

    .terms-content {
        display: inline-flex;
        gap: 10px;
        align-items: center;
        justify-content: center;

        .terms-label {
            font-size: .9rem;
            font-weight: 400;
            line-height: 1.5;
            letter-spacing: 0.00938em;
            cursor: pointer;
            margin-bottom: 0;

            .terms-link {
                color: $link-color-blue;

                &:hover {
                    color: $link-color-blue-hover;
                }
            }
        }
    }
}

.cgu-checkbox {
    position: relative;
    cursor: pointer;
    appearance: none;
    width: 18px;
    height: 18px;
    min-width: 18px;
    max-height: 18px;
    border: 2px solid white;
    border-radius: 4px;
}

.cgu-checkbox:checked {
    background-color: white;
}

.cgu-checkbox:checked::after {
    content: '\2714';
    font-size: 14px;
    position: absolute;
    top: 0px;
    left: 4px;
    color: #202442;
}

.vertical-align-center {
    @media (min-width: 600px) {
        margin-top: .1rem;
        margin-bottom: auto;
    }
}