@import 'style/size.scss';
@import 'style/colors.scss';

.container-header-register {
    .container-logo-blue {
        width: 100%;
        display: flex;
        justify-content: center;
    }

    .container-video-signup {
        width: 85%;
        margin: auto;
        border-radius: 19px;

        .banner-slide {
            .overlay-banner {
                border-radius: 19px;
            }

            &.video-visible {
                .youtube-video {
                    height: 25vh;

                    iframe {
                        border-radius: 19px;
                    }
                }
            }

            .youtube-video {
                height: 25vh;

                iframe {
                    border-radius: 19px;
                }
            }
        }

        .container-img-cards-talents {
            position: relative;
            z-index: 1;
            margin-top: -40px;

            .cards-team-signup {
                display: block;
                margin-left: auto;
                margin-right: auto;
                width: 85%;

                @media (max-width: $mobile) {
                    width: 100%;
                }
            }

            .steps-container-message {
                .cards-team-signup {
                    margin-top: -20px;
                }

                h2 {
                    font-size: 13px;
                    text-transform: uppercase;
                    color: #424455;
                    letter-spacing: 1px;
                    font-weight: 400;
                    line-height: 20px;
                    white-space: pre-line;
                    text-align: center;
                    text-wrap: balance;

                    @media (max-width: $mobile) {
                        font-size: 15px;
                    }
                }
            }
        }
    }
}