@import "style/font.scss";
@import "style/colors.scss";

.wallet-orders-container {
    @include plus-jakarta-font;
    display: flex;
    flex-direction: column;
    gap: 6px;

    .wallet-order-card {
        background: #FFFFFF1A;
        position: relative;
        display: flex;
        flex-direction: column;
        border-radius: 12px;

        .wallet-order-date {
            position: absolute;
            right: 12px;
            top: 8px;
            font-size: 13px;
            color: #FFFFFFCC;

            span {
                padding-right: 10px;
            }
        }

        .wallet-order-body {
            display: flex;
            padding: 25px 19px 10px;
        }

        .wallet-order-avatar {
            height: 44px;
            width: 44px;
            position: relative;

            img {
                height: 44px;
                width: 44px;
                border-radius: 8px;
                object-fit: cover;
            }

            .avatar-icon {
                position: absolute;
                top: -8px;
                left: -10px;

                svg {
                    height: 20px;
                    width: 22px;
                }
            }
        }

        .wallet-order-detail {
            display: flex;
            flex-direction: column;
            flex: 1;
            padding-left: 8px;

            .wallet-order-waiting {
                color: #FFA584CC;
                display: flex;
                align-items: center;
                @include fontWeightSize(medium, 13px);

                svg {
                    margin-right: 5px;

                    path#Tracé_3210 {
                        fill: currentColor;
                    }

                    path#Tracé_3211 {
                        fill: rgba(#FFA584CC, 0.4);
                    }

                }
            }

            .wallet-order-summary {
                display: flex;
                justify-content: space-between;
                text-transform: uppercase;
                color: #FFFFFFCC;
                line-height: 14px;
                @include fontWeightSize(medium, 10px);
            }

            .wallet-transaction-description {
                @include fontWeightSize(bold, 16px);
                line-height: 21px;
                margin-top: 6px;
                margin-bottom: 6px;
            }

            .wallet-transaction-yield {
                margin-bottom: 12px;
                color: $caution-yellow;
            }

        }

        .wallet-order-action {
            display: flex;
            justify-content: center;
            border-top: 1px solid #FFFFFF26;
            padding: 15px;
            color: #E66F81;

            .wallet-order-cancel-button {
                @include fontWeightSize(medium, 13px);
                background: none;
                color: inherit;
                cursor: pointer;
                border: none;
                display: inline-flex;
                align-items: center;
                gap: 4px;

                .cancel-order-icon {
                    background-color: rgba(#E66F81, .4);
                }
            }
        }
    }

    .divider {
        margin: 24px 0;
        align-self: center;
    }

    .see-more {
        margin-bottom: 0;
    }
}