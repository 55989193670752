@import "style/colors.scss";
@import "style/size.scss";
@import "assets/scss/custom_mixins.scss";

.upgrade-league-progress-box {
    display: inline-flex;
    flex-direction: column-reverse;
    align-items: center;
    justify-content: center;
    border-radius: 14px;
    border: 1px solid $upgradeLeagueProgress-borderColor;
    padding: 8px;
    gap: 5px;
    font-size: 12px;

    .upgrade__bar-section {
        display: flex;
        align-items: center;
        width: 90%;
        height: 51px;

        .tournament-progress-bar {
            font-size: 20px;
        }
    }

    .upgrade__tokens-section {
        color: #606373;

        .total-tokens {
            text-align: left;
            display: flex;
            column-gap: 2px;
        }

        .total-league__number {
            text-align: left;
        }
    }

}