.top50-skeleton-loading {
    width: inherit;
    max-width: 650px;
    padding: 50px 16px 0px;
    border-left: 1px solid #33395a25;
    border-right: 1px solid #33395a25;

    &.no-border {
        border: none;
    }

    .loading__carousel-content {
        margin-bottom: 50px;
    }

    .loading__title {
        margin-top: 30px;
    }
}